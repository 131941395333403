import {Component, Input, OnInit} from '@angular/core';
import {CadastroEmpresaTunaComponent} from "../../fidelidade/tuna/cadastro-empresa/cadastro-empresa-tuna.component";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {DialogCloseResult, DialogService} from "@progress/kendo-angular-dialog";
import {EmpresaService} from "../../services/empresa.service";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";

@Component({
  selector: 'app-meucardapio-pay-pagamento',
  templateUrl: './meucardapio-pay-pagamento.component.html',
  styleUrls: ['./meucardapio-pay-pagamento.component.scss']
})
export class MeucardapioPayPagamentoComponent implements OnInit {
  @Input() empresa: any = {}
  @Input() formasDePagamentoOnline: any = [];
  @Input() formasDePagamentoMciOnline: any = [];
  meucardapioPagamentosOnline: Array<FormaDePagamento> = [];
  loading = true;
  constructor(private empresaService: EmpresaService, private dialogService: DialogService) { }

  ngOnInit(): void {
    if(this.empresa.id && !this.empresa.meucardapioPay){
      this.empresaService.obntehaPadraoMCIPay().then((meucardapioPay: any) => {
          if(meucardapioPay && meucardapioPay.id)
            this.empresa.meucardapioPay = meucardapioPay

      })
    } else {
      if(this.processando())
        this.sincronizeStatusRegistroMCIPay();

    }

    if(this.empresa.meucardapioPay && this.empresa.meucardapioPay.pixLiberado){
      if(this.empresa.meucardapioPay.ativacaoStatus === '0'){
        //todo: verifiar com pessoal tuna se depois liberado pix a conta ainda pode ser reprovada
        //todo: depois tiver cartao funcioando melhorar esse codigo, em analise devera ser por forma de pagamento
      //  this.empresa.meucardapioPay.ativacaoStatus = '1'
      }
    }


    this.formasDePagamentoMciOnline =
      this.formasDePagamentoOnline.filter((item: any) => item.configMeioDePagamento.meioDePagamento === 'tunapay')

    this.formasDePagamentoMciOnline.forEach((item: any) => {
      if(!item.pix)
        item.soAdminAtiva = true;
    })

    this.loading  = false;
  }


  abrirModalRegistrarEmpresaTuna() {

    const windowRef = this.dialogService.open( {
        title: null,
        content: CadastroEmpresaTunaComponent,
        minWidth: 800,
        width: window.innerWidth > 800 ? 800 : window.innerWidth,
        maxHeight: window.innerHeight - 100,
        cssClass: 'bsModal'
      }
    );

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    let tela: any = windowRef.content.instance;

    tela.empresa = this.empresa;

    windowRef.result.subscribe((result: any) => {
      if( !(result instanceof DialogCloseResult  ))
        this.recarreguePagina();
    });

  }

  recarreguePagina(){
    window.location.assign('/admin/loja/formaspagamento/onlinemci');
  }

  sincronizeStatusRegistroMCIPay() {
    if(this.empresa.meucardapioPay.sincronizando) return;

    this.empresa.meucardapioPay.sincronizando = true;

    this.empresaService.verifiqueRetornoRegistroTuna( ).then((retorno: any) => {
      this.empresa.meucardapioPay.sincronizando = false;
      if(retorno && retorno.id){
        if(this.empresa.meucardapioPay.ativacaoStatus !== retorno.ativacaoStatus ||
           retorno.pixLiberado && !this.empresa.meucardapioPay.pixLiberado ){
          this.recarreguePagina();
        }
        this.empresa.meucardapioPay = retorno;
      }
    }).catch((err) => {
      this.empresa.meucardapioPay.sincronizando = false; alert(err)
    });
  }

  estaEmAnalise() {
    return this.empresa.meucardapioPay && this.empresa.meucardapioPay.ativacaoStatus === '0';
  }

  processando(){
    return this.empresa.meucardapioPay && this.empresa.meucardapioPay.ativacaoStatus ===  "P";
  }

  naoAprovado() {
    return this.empresa.meucardapioPay && this.empresa.meucardapioPay.ativacaoStatus === '2'
  }
}
