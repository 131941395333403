<kendo-tabstrip class="nav-bordered">

  <kendo-tabstrip-tab [title]="'Uber Direct'" [selected]="true">
    <ng-template kendoTabContent>
      <app-cad-integracao-uberdirect [empresa]="empresa"></app-cad-integracao-uberdirect>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Open Delivery (Logistica)'">
    <ng-template kendoTabContent>
      <app-cad-integracao-opendelivery-logistica [empresa]="empresa"></app-cad-integracao-opendelivery-logistica>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Foody Delivery'">
    <ng-template kendoTabContent>
      <app-form-integracao-foodydelivery  [empresa]="empresa"></app-form-integracao-foodydelivery>
    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>

