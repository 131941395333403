export class Mesa {
  id: number;
  nome: string;
  identificador: string;
  disponivel: boolean;
  codigoPdv: string
  naoGerarComanda: boolean;
  somenteLeitura: boolean;
  mesaIntegrada: any
  comandas: any[];
}
