import {HttpClient} from "@angular/common/http";
import { Injectable } from "@angular/core";

const ERROS: any = {
  0 : 'Falha de conexão com servidor',
  404: 'Falha ao invocar servidor, recurso não encontrado',
  500: 'Falha ao tentar realizar operação, tente de novo mais tarde.',
  504: 'Falha ao tentar realizar operação, tente de novo mais tarde.' };

@Injectable()
export class ServerService {
  protected baseUrl = '';

  constructor(protected http: HttpClient) { }

  protected salve(endpoint: string, obj: any): Promise<any> {
    if ( !obj.id ) {
      return this.http.post(endpoint, obj)
        .toPromise().then(this.retorno).catch(this.handleError);
    } else {
      return this.http.put(endpoint, obj)
        .toPromise().then(this.retorno).catch(this.handleError);
    }
  }

  protected facaPost(endpoint: string, obj: any, isFormData: boolean = false): Promise<any> {
    return this.http.post(endpoint, obj)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  protected facaPut(endpoint: string, obj: any): Promise<any> {
    return this.http.put(endpoint, obj)
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  protected upload(endpoint: string, file: any, obj: any = null): Promise<any> {
    let formData = new FormData()

    if( file ) {
      formData.append("file", file, file.name)
    }

    formData.append("data",  JSON.stringify(obj))

    return this.salve(endpoint, formData)
  }

  protected remova(endpoint: string, obj: any){
    return this.http.delete(endpoint, obj)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  protected obtenha(endpoint: string, params: any): Promise<any> {
    return this.http.get(endpoint , {params: params})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  protected retorno(response: any): Promise<any> {
    if ( response.sucesso ) {
      return Promise.resolve(response.data );
    } else {
      console.log( 'Houve um erro ao enviar');
      return Promise.reject(response.erro);
    }
  }

  protected handleError(error: any): Promise<any> {
    if( !error ) {
      return Promise.reject(error);
    }

    let erro = error && error.status != null ? ERROS[error.status] : ( error && error.message || error)

    if(!erro && error.url.indexOf('/login') >= 0)
      erro = 'Faça login novamente para realizar essa operação.'

    return Promise.reject(erro);
  }
}
