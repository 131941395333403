<kendo-dialog-titlebar (close)="fecheModal(this.insumo)"  >
  <h4 class="modal-title">
    <img src="assets/fidelidade/icones/adicionar-insumo.png" style="height: 32px;"/>
    <ng-container *ngIf="insumo.id"> {{insumo.fichaTecnica ?  'Ficha Técnica': 'Insumo' }} {{insumo.id}} - {{insumo.nome}}</ng-container>
    <ng-container *ngIf="!insumo.id">  {{insumo.fichaTecnica ? 'Nova Ficha Técnica' : 'Novo Insumo'}} </ng-container>
  </h4>
</kendo-dialog-titlebar>

<div   class="modal-body">

  <kendo-tabstrip class="nav-bordered"   #tabSet       >
    <kendo-tabstrip-tab [title]="'Detalhes'" [selected]="abaSelecionada === 1">
      <ng-template kendoTabContent>
        <div *ngIf="!insumo.tipo && insumo.fichaTecnica">
          <h4 class="text-center">Qual será o tipo da ficha tecnica? </h4>

          <div class="button-row mt-3">
            <div class="button-column">
              <button kendoButton look="outline" class="btn-verde" icon="cart" (click)="escolheuTipo(this.FICHA_PRODUTO_VENDA)">
                Produto para Venda
              </button>


              <div class="button-description">
                 Use este tipo para itens vendidos diretamente aos clientes, como pratos prontos.
                <br><em>Exemplos:</em> &nbsp;<b>Pizza Margherita, Suco de Laranja, Salada Caesar.</b>
              </div>

            </div>
            <div class="button-column">
              <button kendoButton look="outline" class="btn-laranja"   (click)="escolheuTipo(this.FICHA_PRODUTO_BENEFICIADO)">
                <i class="fas fa-utensils"></i> Ingrediente Beneficiado
              </button>
              <div class="button-description">
                  Escolha este tipo para ingredientes preparados internamente que compõem outros pratos.
                <br><em>Exemplos:</em> &nbsp;<b>Molho de Tomate Caseiro, Frango Desfiado, Caldo de Legumes.</b>
              </div>
            </div>

          </div>
        </div>

        <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
              novalidate #frm="ngForm" (ngSubmit)="onSalvar()" *ngIf="insumo.tipo || !insumo.fichaTecnica"  >

          <div class=" mt-2 mb-2 destaque" [hidden]="!insumo.fichaTecnica">
            <b>Ficha Técnica do</b>
            <label class="text-blue ml-1"><b>{{this.fichaTecnicaDeProdutoVenda() ? 'produto para venda' : 'ingrediente beneficiado'}}</b>
            </label>
          </div>

          <div class="row mb-2">
            <div class="form-group col-6"  >
              <label for="nome">Nome</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="nome" name="nome" [(ngModel)]="insumo.nome" #nome="ngModel"
                     placeholder="Informe um nome" value="" required appAutoFocus [autoFocus]="true">
              <div class="invalid-feedback">
                <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
              </div>
            </div>

            <div class="form-group  col-3">
              <label  >Código Sistema</label>

              <input type="text" class="form-control" autocomplete="off"
                     name="codigoPdv" [(ngModel)]="insumo.codigo" #codigo="ngModel"
                     placeholder="Cod. Sistema"       />
            </div>

          </div>

          <div class="row mb-2" >
            <div class="form-group  col-4">
              <label  >Unidade</label>

              <kendo-dropdownlist   name="unidade" [(ngModel)]="insumo.unidadeMedida" [data]="unidades"
                                    (ngModelChange)="alterouValorUnidade()"
                                    class="form-control"  [textField]="'nome'" required  #unidade="ngModel"

                                    style="border: none;">
              </kendo-dropdownlist>

              <div class="invalid-feedback">
                <p *ngIf="unidade.errors?.required">Unidade é obrigatório</p>
              </div>
            </div>

            <div class="form-group  col-4 ">
              <label >Grupo
                <button type="button" class="btn btn-xs btn-outline-blue   ml-1" (click)="abraModalNovoGrupo()" style="    position: absolute;top: -5px;">
                  <i class="k-icon k-i-plus "></i> adicionar grupo</button>
              </label>

              <kendo-dropdownlist  name="grupo" [(ngModel)]="insumo.grupo" [data]="grupos" [filterable]="true" required
                                   placeholder="Selecione um grupo" class="form-control" [textField]="'nome'"  #grupo="ngModel"
                                   style="border: none;"  (filterChange)="handleFilter($event)">
              </kendo-dropdownlist>

              <div class="invalid-feedback">
                <p *ngIf="grupo.errors?.required">Grupo é obrigatório</p>
              </div>

            </div>

          </div>

          <fieldset *ngIf=" !fichaTecnicaDeProdutoVenda()">
            <legend>Controle de estoque</legend>

            <div class="row">
              <div class="form-group  col-4">
                <label  >Estoque atual</label>
                <kendo-numerictextbox id="estoque" [min]="0"   [format]="obtenhaFormato()"  [disabled]="insumo.id"
                                      class="form-control" name="quantidade" #quantidade=ngModel

                                      [(ngModel)]="insumo.estoque.quantidade" required placeholder="Estoque atual">
                </kendo-numerictextbox>

                <div class="invalid-feedback">
                  <p *ngIf="quantidade.errors?.required">Estoque atual é obrigatório</p>
                </div>
              </div>

              <div class="form-group  col-4">
                <label  >Estoque minimo</label>
                <kendo-numerictextbox id="quantidadeMinima"    [format]="obtenhaFormato()"
                                      class="form-control" name="quantidadeMinima" #quantidadeMinima=ngModel

                                      [(ngModel)]="insumo.estoque.quantidadeMinima" required placeholder="Estoque minimo">
                </kendo-numerictextbox>

                <div class="invalid-feedback">

                </div>
              </div>

              <div class="form-group  col-4" *ngIf="insumo.id">
                <label  >Estoque reservado</label>
                <kendo-numerictextbox [min]="0"   [format]="obtenhaFormato()"  [disabled]="insumo.id"
                                      class="form-control" name="quantidadeReservada" #quantidadeReservada=ngModel

                                      [(ngModel)]="insumo.estoque.quantidadeReservada"    >
                </kendo-numerictextbox>

              </div>

             <!-- <div class="form-group  col-4">
                <label  >Preço custo</label>
                <kendo-numerictextbox id="precoDeCusto" [min]="0"   [format]="'c3'"
                                      class="form-control" name="precoDeCusto" #precoDeCusto=ngModel

                                      [(ngModel)]="insumo.precoDeCusto" required placeholder="Preço de custo">
                </kendo-numerictextbox>

                <div class="invalid-feedback">

                </div>
              </div> -->



            </div>
          </fieldset>

          <div class="modal-footer">
            <p *ngIf="mensagemSucesso" class="text-success ">
              <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
            </p>

            <p  *ngIf="mensagemErro" class="text-danger ">
              <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
            </p>

            <button type="submit" class="btn btn-primary waves-effect waves-light"
                    [disabled]="!frm.dirty || salvando" [hidden]="!insumo.id && insumo.fichaTecnica" >
              <i *ngIf="salvando" class="k-icon k-i-loading"></i>Salvar</button>

            <button type="button" class="btn btn-blue waves-effect waves-light" (click)="selecioneAba(2)"
                    [disabled]=" salvando || !frm.valid"  *ngIf="!insumo.id && insumo.fichaTecnica">
              <i *ngIf="salvando" class="k-icon k-i-loading"></i>Proximo</button>


            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="fecheModal(this.insumo)">Fechar</button>
          </div>

        </form>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Composição'"  *ngIf="insumo.fichaTecnica"
                        [selected]="abaSelecionada === 2"   >

      <ng-template kendoTabContent>

        <form [ngClass]="{'needs-validation': !frmFicha.submitted, 'was-validated': frmFicha.submitted}"
              novalidate #frmFicha="ngForm" (ngSubmit)="onSalvarReceita()" >


          <div style="max-height: 600px">
            <div class="row">
              <div class="form-group  col-6">
                <label  >Rendimento</label>
                <kendo-numerictextbox id="rendimento" [min]="0"   [format]="obtenhaFormato()"
                                      class="form-control" name="rendimento" #rendimento=ngModel
                                      [(ngModel)]="insumo.receita.rendimento" required
                                      placeholder="rendimento em {{obtenhaDescricaoUnidade(insumo)}}">
                </kendo-numerictextbox>

                <div class="invalid-feedback">
                  <p *ngIf="rendimento.errors?.required">Obrigatório</p>
                  <p *ngIf="rendimento.errors?.minError">Informa valor maior que 0</p>
                </div>

                <div *ngIf="fichaTecnicaDeProdutoVenda()" class="text-muted font-11">
                    recomendado para ficha tecnica do tipo produto é <b>1 {{obtenhaDescricaoUnidade(insumo)}}</b>
                </div>
              </div>
            </div>

            <h4>
              Insumos
              <button class="btn btn-outline-blue btn-xs ml-2" (click)="novoIngrediente()">
                <b> <i class="fe-plus"></i> Adicionar</b> </button>
            </h4>

            <kendo-grid [data]="insumo.receita.ingredientes">
              <kendo-grid-column    [width]="30">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
                  <span [class.removido]="dataItem.removido">         {{rowIndex + 1}}</span>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column   title="Ingrediente" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-selecionar-insumo [exbirLabel]="false" [selectedItem]="dataItem" [indice]="rowIndex" [formClass]="''">

                  </app-selecionar-insumo>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="valor" title="Custo Médio" [width]="0">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <kendo-numerictextbox   class="form-control" name="valor{{rowIndex}}"  [disabled]="dataItem.removido"
                                          [format]="'$0.00'"   [(ngModel)]="dataItem.custoMedio"  >
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="quantidade" title="Quantidade" [width]="70">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <kendo-numerictextbox  [min]="0.0001"  placeholder="{{obtenhaDescricaoUnidade(dataItem.insumo)}}"
                                         class="k-input form-control" name="quantidade{{rowIndex}}"
                                          [format]="obtenhaFormatoDoInsumo(dataItem.insumo)"
                                         [(ngModel)]="dataItem.quantidade"  [disabled]="dataItem.removido"
                                         required>
                  </kendo-numerictextbox>

                  <div class="invalid-feedback">
                    <p *ngIf="dataItem.quantidade == null">Obrigatório</p>
                    <p *ngIf="dataItem.quantidade <= 0">Valor maior que 0</p>
                  </div>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="total" title="Custo na receita" [width]="0">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.total | currency: "BRL" }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column  [width]="60">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
                  <button kendoGridRemoveCommand kendoTooltip title="remover" (click)="removeIngrediente(rowIndex)" [hidden]="dataItem.removido"  >
                    <i class="fa fa-trash text-danger"></i>
                  </button>

                  <button class="btn btn-xs btn-warning" *ngIf="dataItem.removido"  (click)="dataItem.removido = null"
                          kendoTooltip title="desfazer remoção">
                    <i class="k-i-undo k-icon"></i>
                  </button>

                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

          </div>

          <div class="modal-footer">
            <p *ngIf="mensagemSucesso" class="text-success ">
              <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
            </p>

            <p  *ngIf="mensagemErro" class="text-danger ">
              <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
            </p>

            <button type="submit" class="btn btn-primary waves-effect waves-light"
                    [disabled]="salvando"  >
              <i *ngIf="salvando" class="k-icon k-i-loading"></i>Salvar</button>
            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="fecheModal(this.insumo)">Fechar</button>
          </div>

        </form>



      </ng-template>

    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Vinculos com produtos' + (insumo.id ? '(' + insumo.totalVinculos + ')'  : '')" *ngIf="!fichaTecnicaDeProdutoBeneficiado()"
                        [disabled]="!insumo.id" [selected]="abaSelecionada === 3">
      <ng-template kendoTabContent>
        <app-tela-associar-insumo [insumo]="insumo"></app-tela-associar-insumo>
      </ng-template>


    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Movimentações'" [disabled]="!insumo.id" [selected]="abaSelecionada === 4">
      <ng-template kendoTabContent>

        <div *ngIf="!novaMovimentacao">
          <button  class="btn btn-blue mb-2" (click)="gerarMovimentacao()">
            <i class="fe-plus"></i> Gerar movimentaçao
          </button>

          <app-tela-historico-movimentacoes [insumo]="insumo" #telaMovimentacoes>

          </app-tela-historico-movimentacoes>
        </div>

        <div *ngIf="novaMovimentacao">
          <div class="card-box">
            <h4>Movimentação de <b>{{insumo.objeto}} - {{insumo.nome}}</b></h4>

            <p class="mb-0"  *ngIf="insumo.estoque">
              <label>
                Estoque Atual: <b>{{insumo.estoque.quantidade}} {{obtenhaDescricaoUnidade(insumo)}}</b>
              </label>
            </p>

            <p class="mb-0">
              <label >
                Rendimento da composição: <b>{{insumo.receita.rendimento}} {{obtenhaDescricaoUnidade(insumo)}}</b>
              </label>
            </p>
          </div>

          <app-form-movimentacao-insumo [movimentacao]="novaMovimentacao"
                (onSalvou)="salvouMovimentacao($event)" (onCancelou)="cancelouMovimentacao()"></app-form-movimentacao-insumo>

        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab id="Histórico" [title]="'Histórico'" *ngIf="insumo.id && usuarioLogado && usuarioLogado.admin">
      <ng-template kendoTabContent>
        <app-historico-insumo [insumo]="insumo"></app-historico-insumo>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

</div>



