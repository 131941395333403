import { Injectable } from '@angular/core';
import { ServerService } from './ServerService';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaletaCoresService extends ServerService {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Gera uma paleta de cores baseada em uma cor principal usando IA
   * @param corBase A cor base em formato hexadecimal (ex: #FF5733)
   * @param descricao Descrição adicional para orientar a geração da paleta (opcional)
   */
  gerePaletaDeCores(corBase: string, descricao?: string): Promise<any> {
    return this.facaPost('/paleta-cores', { corBase, descricao });
  }

  /**
   * Gera uma paleta de cores a partir de uma imagem usando IA
   * @param imagem Arquivo de imagem para extrair a paleta de cores
   * @param descricao Descrição adicional para orientar a geração da paleta (opcional)
   */
  gerePaletaDeImagem(imagem: File, descricao?: string): Promise<any> {
    const formData = new FormData();
    formData.append('imagem', imagem);

    if (descricao) {
      formData.append('descricao', descricao);
    }

    return this.facaPost('/paleta-cores/imagem', formData, true);
  }

  /**
   * Gera uma paleta de cores a partir de uma URL de imagem usando IA
   * @param imageUrl URL da imagem para extrair a paleta de cores
   * @param descricao Descrição adicional para orientar a geração da paleta (opcional)
   */
  gerePaletaDeImagemUrl(imageUrl: string, descricao?: string): Promise<any> {
    const data = { imageUrl };

    if (descricao) {
      data['descricao'] = descricao;
    }

    return this.facaPost('/paleta-cores/imagem', data);
  }
}
