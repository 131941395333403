<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>

  <div class="row">
    <div class="form-group mb-3 col" *ngIf="!formaDePagamento.formaDePagamentoPdv">

      <label for="nome">Descrição da Forma de Pagamento</label>
      <input type="text" class="form-control" autocomplete="off"  [disabled]="pagamentoPorFidelidade()"
             id="nome" name="nome" [(ngModel)]="formaDePagamento.descricao" #nome="ngModel"

             placeholder="Nome da Forma de Pagamento" value="" required appAutoFocus [autoFocus]="true">
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Descrição é obrigatório</p>
      </div>
    </div>
    <div class="col mb-3" *ngIf="formaDePagamento.formaDePagamentoPdv">
      <b>{{formaDePagamento.descricao}}</b>
    </div>
  </div>

  <ng-container *ngIf="formaDePagamento.online && formaDePagamento.configMeioDePagamento">
    <fieldset class="mb-3">
      <legend>Pagamento online configuraçoes</legend>
      <div class="row mb-2">
        <div class="col-8">
          <label for="nome">Meio de Pagamento</label>

          <kendo-combobox   name="meioDePagamento"  [kendoDropDownFilter]="filterSettings" [filterable]="true"
                            [(ngModel)]="meioDePagamento" [data]="meiosPagamentosOnline"
                            [disabled]="formaDePagamento.id > 0"
                            textField="nome" textValue="id"
                            placeholder="Selecione um meio de pagamento" class="form-control"
                            required  (selectionChange)="selecionouMeioOnline($event)"  >
          </kendo-combobox>

        </div>

        <div class="col-4" *ngIf="suportePix()">
          <div class="form-group mb-3 col col-4">
            <label class="k-checkbox-label" style="    margin-top: 30px;" >
              <input type="checkbox" id="pix" name="pix" class="k-checkbox " kendoCheckBox
                     [(ngModel)]="formaDePagamento.pix" />
              Pix
            </label>
          </div>

        </div>

      </div>


      <ng-container *ngIf="gatewayPagarme()">
        <div class="row" *ngIf="!hubPagarme()">
          <div class="form-group mb-3 col">
            <label for="publickKey">Public Key</label>
            <input type="text" class="form-control" autocomplete="off"
                   name="publickKey" [(ngModel)]="formaDePagamento.configMeioDePagamento.publicKey" #publickKey="ngModel"
                   placeholder="Public Key" value="" required>
            <div class="invalid-feedback">
              <p *ngIf="publickKey.errors?.required">Public Key é obrigatório</p>
            </div>
          </div>

          <div class="form-group mb-3 col">
            <label  >Private Key</label>
            <input type="text" class="form-control" autocomplete="off"
                   name="privateKey" [(ngModel)]="formaDePagamento.configMeioDePagamento.token" #privateKey="ngModel"
                   placeholder="Private Key" value="" required>
            <div class="invalid-feedback">
              <p *ngIf="privateKey.errors?.required">Private Key é obrigatório</p>
            </div>
          </div>
        </div>

        <p *ngIf="empresa.integracaoGatewayPagamento">
          Instalação: <b>{{empresa.integracaoGatewayPagamento?.instalacaoId}}</b></p>

        <span  *ngIf="!formaDePagamento.pix">
          <div class="row" >
            <div class="form-group mb-1 col">
              <label >Nome Fatura Cartão</label>
              <input type="text" class="form-control" autocomplete="off" maxlength="22" pattern="[a-zA-Z]*"
                     name="nomeFaturaCartao" [(ngModel)]="formaDePagamento.configMeioDePagamento.nomeFaturaCartao" #nomeFaturaCartao="ngModel"
                     placeholder="Nome na fatura do cartão do seu cliente"    (focusout)="tireEspacosNomeFatura(22)"
                     [required]="formaDePagamento.online">
              <p class="form-text text-muted">Esse é o nome que seu cliente vai ver na fatura do cartão de crédito dele</p>
              <div class="invalid-feedback">
                <p *ngIf="nomeFaturaCartao.errors?.required">Nome é obrigatório</p>
                <p *ngIf="nomeFaturaCartao.errors?.pattern">Apenas letras (sem acentos e espaços) são permitidas</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-3 col-6" >
              <label for="numeroParcelasFixas">Numero parcelas fixas</label>
              <div class="input-group">
                <kendo-numerictextbox  [attr.type]="'number'" id="numeroParcelasFixas"    name="numeroParcelasFixas"  class="form-control"
                                       [(ngModel)]="formaDePagamento.numeroParcelasFixas" [min]="1" [max]="12"  >
                </kendo-numerictextbox>

              </div>
            </div>
          </div>

          <div class="row">

            <div class="form-group mb-3 col-6">
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="voucher" name="voucher" class="k-checkbox " kendoCheckBox
                       [(ngModel)]="formaDePagamento.voucher" />
                Voucher
              </label>
            </div>

            <div class="form-group mb-3 col-6">
              <label class="k-checkbox-label"  >
                <input type="checkbox" id="antifraude" name="antifraude" class="k-checkbox " kendoCheckBox
                       [(ngModel)]="formaDePagamento.configMeioDePagamento.antifraudeDesabilitado" />
                Desabilitar antifradue
              </label>
            </div>

          </div>
        </span>

      </ng-container>

      <ng-container *ngIf="formaDePagamento.configMeioDePagamento.meioDePagamento?.toString() === 'cielocheckout'">
        <div class="row">
          <div class="form-group mb-1 col">
            <label for="nome">Nome Fatura Cartão</label>
            <input type="text" class="form-control" autocomplete="off" maxlength="13" pattern="[a-zA-Z]*"
                   id="nomeFaturaCartao" name="nomeFaturaCartao" [(ngModel)]="formaDePagamento.configMeioDePagamento.nomeFaturaCartao" #nomeFaturaCartao="ngModel"
                   placeholder="Nome na fatura do cartão do seu cliente" value="" [required]="formaDePagamento.online">
            <p class="form-text text-muted">Esse é o nome que seu cliente vai ver na fatura do cartão de crédito dele</p>
            <div class="invalid-feedback">
              <p *ngIf="nomeFaturaCartao.errors?.required">Nome é obrigatório</p>
              <p *ngIf="nomeFaturaCartao.errors?.pattern">Apenas letras (sem acentos e espaços) são permitidas</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group mb-1 col">
            <label for="merchantId">Merchant ID</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="merchantId" name="merchantId" [(ngModel)]="formaDePagamento.configMeioDePagamento.merchantId" #merchantId="ngModel"
                   placeholder="Merchant Id" value="" [required]="formaDePagamento.online">
            <div class="invalid-feedback">
              <p *ngIf="merchantId.errors?.required">Merchant Id é obrigatório</p>
            </div>
          </div>

          <div class="form-group mb-3 col">
            <label for="nome">Merchant Key</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="merchantKey" name="merchantKey" [(ngModel)]="formaDePagamento.configMeioDePagamento.merchantKey" #merchantKey="ngModel"
                   placeholder="Merchant Key" value="" [required]="formaDePagamento.online">
            <div class="invalid-feedback">
              <p *ngIf="merchantKey.errors?.required">Merchant Key é obrigatório</p>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col ">
            <fieldset>
              <legend>3DS</legend>
              <div class="row">
                <div class="col-5">
                  <label for="nome">Establishment Code</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="establishmentCode" [(ngModel)]="formaDePagamento.configMeioDePagamento.establishmentCode" #establishmentCode="ngModel"
                         placeholder="Código do estabelecimento" value="" [required]="formaDePagamento.online">
                  <div class="invalid-feedback">
                    <p *ngIf="establishmentCode.errors?.required">Codigo estabelecimento é obrigatório</p>
                  </div>
                </div>
                <div class="col-5">
                  <label for="nome">Merchant Name</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="merchantName" [(ngModel)]="formaDePagamento.configMeioDePagamento.merchantName" #merchantName="ngModel"
                         placeholder="Merchant Name" value="" [required]="formaDePagamento.online">
                  <div class="invalid-feedback">
                    <p *ngIf="merchantName.errors?.required">Merchant Name é obrigatório</p>
                  </div>
                </div>
                <div class="col-2">
                  <label for="nome">MCC</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="mcc" [(ngModel)]="formaDePagamento.configMeioDePagamento.mcc" #mcc="ngModel"
                         placeholder="MCC" value="" [required]="formaDePagamento.online">
                  <div class="invalid-feedback">
                    <p *ngIf="mcc.errors?.required">MCC é obrigatório</p>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="form-group mb-3 col">
                  <label for="nome">Cliente Id</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="dsclientID" [(ngModel)]="formaDePagamento.configMeioDePagamento.clientID" #dsclientID="ngModel"
                         placeholder="Merchant Key" value="" [required]="formaDePagamento.online">
                  <div class="invalid-feedback">
                    <p *ngIf="dsclientID.errors?.required">clientID é obrigatório</p>
                  </div>
                </div>


                <div class="form-group mb-3 col">
                  <label for="nome">Cliente Secret</label>
                  <input type="text" class="form-control" autocomplete="off"
                         name="dsclientSecret" [(ngModel)]="formaDePagamento.configMeioDePagamento.clientSecret" #dsclientSecret="ngModel"
                         placeholder="Cliente Secret" value="" [required]="formaDePagamento.online">
                  <div class="invalid-feedback">
                    <p *ngIf="dsclientSecret.errors?.required">clientID é obrigatório</p>
                  </div>
                </div>

              </div>
            </fieldset>


          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="formaDePagamento.configMeioDePagamento.meioDePagamento === 'cielo'">
        <div class="row">
          <div class="form-group mb-1 col">
            <label for="nomeFaturaCartao2">Nome Fatura Cartão</label>
            <input type="text" class="form-control" autocomplete="off" maxlength="13" pattern="[a-zA-Z]*"
                   id="nomeFaturaCartao2" name="nomeFaturaCartao" [(ngModel)]="formaDePagamento.configMeioDePagamento.nomeFaturaCartao" #nomeFaturaCartao="ngModel"
                   placeholder="Nome na fatura do cartão do seu cliente" value="" [required]="formaDePagamento.online">
            <p class="form-text text-muted">Esse é o nome que seu cliente vai ver na fatura do cartão de crédito dele</p>
            <div class="invalid-feedback">
              <p *ngIf="nomeFaturaCartao.errors?.required">Nome é obrigatório</p>
              <p *ngIf="nomeFaturaCartao.errors?.pattern">Apenas letras (sem acentos e espaços) são permitidas</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group mb-1 col">
            <label for="clientID">Client ID</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="clientID" name="clientID" [(ngModel)]="formaDePagamento.configMeioDePagamento.clientID" #clientID="ngModel"
                   placeholder="Client ID" value="" [required]="formaDePagamento.online">
            <div class="invalid-feedback">
              <p *ngIf="clientID.errors?.required">Client ID é obrigatório</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group mb-3 col">
            <label for="clientSecret">Client Secret</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="clientSecret" name="clientSecret" [(ngModel)]="formaDePagamento.configMeioDePagamento.clientSecret" #clientSecret="ngModel"
                   placeholder="Client Secret" value="" [required]="formaDePagamento.online">
            <div class="invalid-feedback">
              <p *ngIf="clientSecret.errors?.required">Client Secret é obrigatório</p>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="gatewayPagseguro()">

        <ng-container *ngIf="pagseguro()">
          <div class="row">
            <div class="form-group mb-3 col">
              <label for="nome">Email</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="email" name="email" [(ngModel)]="formaDePagamento.configMeioDePagamento.email" #email="ngModel"
                     placeholder="Email" value="" [required]="formaDePagamento.configMeioDePagamento.meioDePagamento === 'pagseguro'">
              <div class="invalid-feedback">
                <p *ngIf="email.errors?.required">Email é obrigatório</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-3 col">
              <label for="nome">Token</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="token" name="token" [(ngModel)]="formaDePagamento.configMeioDePagamento.token" #token="ngModel"
                     placeholder="Token" value="" required>
              <div class="invalid-feedback">
                <p *ngIf="token.errors?.required">Token é obrigatório</p>
              </div>
            </div>
          </div>
        </ng-container>


        <ng-container  *ngIf="pagBankConnect()">
          <div class="row" *ngIf="!empresa.integracaoGatewayPagamento">
            <div class="col" >
              <p *ngIf="gerandolink"> <i class="k-icon k-i-loading mr-1"></i>
                Aguarde, gerando link autorização</p>

              <p *ngIf="mensagemErro && !linkAutorizacaoPagBank" class="text-danger">
                <b> {{mensagemErro}}</b>
              </p>

              <a href="{{linkAutorizacaoPagBank}}" class="btn btn-warning" *ngIf="linkAutorizacaoPagBank" >Autorizar Loja no Pagseguro</a>

              <br><br>
            </div>


          </div>

          <div class="row" *ngIf="ehPagseguroConnectETemAutorizacao() && !this.formaDePagamento.id">
            <div class="col"  >
              <p class="text-danger">Já existe um integração ativa com <b>{{empresa.integracaoGatewayPagamento.gateway}}</b></p>
            </div>
          </div>

          <ng-container  *ngIf="this.formaDePagamento.id">
            <div class="row">
              <div class="form-group mb-1 col">
                <label for="nome">Nome Fatura Cartão</label>
                <input type="text" class="form-control" autocomplete="off" maxlength="17" pattern="[a-zA-Z]*"
                       name="nomeFaturaCartao" [(ngModel)]="formaDePagamento.configMeioDePagamento.nomeFaturaCartao" #nomeFaturaCartao="ngModel"
                       placeholder="Nome na fatura do cartão do seu cliente" value="" [required]="formaDePagamento.online">
                <p class="form-text text-muted">Esse é o nome que seu cliente vai ver na fatura do cartão de crédito dele</p>
                <div class="invalid-feedback">
                  <p *ngIf="nomeFaturaCartao.errors?.required">Nome é obrigatório</p>
                  <p *ngIf="nomeFaturaCartao.errors?.pattern">Apenas letras (sem acentos e espaços) são permitidas</p>
                </div>
              </div>
            </div>

            <ng-container  *ngIf="empresa.integracaoGatewayPagamento">
              <div class="row">
                <div class="col">
                  Token válido até <b>{{empresa.integracaoGatewayPagamento.dataExpiracao | date: 'dd/MM/yyyy'}}</b>

                  <div class="form-group k-display-inline-block ml-1 " >
                    <button class="btn btn-warning mr-1" type="button" (click)="renoveToken()" [disabled]="renovandoToken">
                      <i class="k-i-loading k-icon mr-1" *ngIf="renovandoToken"></i>
                      Renovar token </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col" [hidden]="confirmarRemocaoAutorizacao">


                  <div class="form-group mb-1  " >
                    <button class="btn btn-danger" type="button" (click)="confirmeRemoverAutorizacao()" >
                      Remover Autorização Da Loja</button>
                  </div>

                </div>

                <div class="col" *ngIf="confirmarRemocaoAutorizacao">
                  <p class="text-center" >
                    Se remover autorização a forma de pagamento será desativada na loja, confirma a remoção? <br>

                    <button class="btn btn-danger" type="button" [disabled]="removendoAutorizacao" (click)="removaAutorizacaoPagBankConnect()">
                      <i class="k-i-loading k-icon mr-1" *ngIf="removendoAutorizacao"></i>  Confirmar a remoção da Autorização</button>

                    <button class="btn btn-secondary ml-2" type="button" (click)="canceleRemoverAutorizacao()">Voltar</button>

                  </p>

                </div>

              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="gatewayErede()">
        <div class="row">
          <div class="form-group mb-3 col-8">
            <label  >Número de filiação (PV)</label>
            <input type="text" class="form-control" autocomplete="off"
                     name="pv" [(ngModel)]="formaDePagamento.configMeioDePagamento.publicKey" #pv="ngModel"
                   placeholder="PV" value="" required>
            <div class="invalid-feedback">
              <p *ngIf="pv.errors?.required">PV é obrigatório</p>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="form-group mb-3 col">
            <label >Token</label>
            <input type="text" class="form-control" autocomplete="off"
                   name="token" [(ngModel)]="formaDePagamento.configMeioDePagamento.token" #actoken="ngModel"
                   placeholder="Token" value="" required>
            <div class="invalid-feedback">
              <p *ngIf="actoken.errors?.required">Token é obrigatório</p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!formaDePagamento.pix">
          <div class="form-group mb-1 col">
            <label >Nome Fatura Cartão</label>
            <input type="text" class="form-control" autocomplete="off" maxlength="18" pattern="[a-zA-Z]*"
                   name="nomeFaturaCartao" [(ngModel)]="formaDePagamento.configMeioDePagamento.nomeFaturaCartao" #nomeFaturaCartao="ngModel"
                   placeholder="Nome na fatura do cartão do seu cliente"    (focusout)="tireEspacosNomeFatura(18)"
                   [required]="formaDePagamento.online">
            <p class="form-text text-muted">Esse é o nome que seu cliente vai ver na fatura do cartão de crédito dele</p>
            <div class="invalid-feedback">
              <p *ngIf="nomeFaturaCartao.errors?.required">Nome é obrigatório</p>
              <p *ngIf="nomeFaturaCartao.errors?.pattern">Apenas letras (sem acentos e espaços) são permitidas</p>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="form-group mb-3 col-9" [hidden]="formaDePagamento.pix">
            <label class="k-checkbox-label"  >
              <input type="checkbox" id="tokenizar" name="tokenizar" class="k-checkbox " kendoCheckBox
                     [(ngModel)]="formaDePagamento.configMeioDePagamento.tokenizar" />
              Tokenização de bandeira Rede
            </label>
          </div>

        </div>



        <div class="row" *ngIf="!formaDePagamento.pix && formaDePagamento.configMeioDePagamento?.tokenizar">
          <div class="form-group mb-1 col">
            <label >Url Notificações dos eventos Tokenização:</label>
            <input kendoTextBox name="urlTokenizar" class="form-control"
                   [value]="obtenhaBaseUrlWookERedeTokenizar()" readonly/>

            <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(obtenhaBaseUrlWookERedeTokenizar())"
               [ngClass]="{'text-success': textoCopiado === obtenhaBaseUrlWookERedeTokenizar()}"
               title="copiar url tokenizaçao" kendoTooltip></i>

            <p class="mt-2">
              O cliente Rede deve realizar a contratação do serviço via Portal logado: Menu principal “para vender”/ Opção E-commerce/Tokenização de bandeira Rede/Contratar
            </p>
            <p class="mt-2">
              Após a contratação do serviço de Tokenização de Bandeira e o cumprimento do prazo SLA de até 02 horas, assim que o Cliente e.Rede entrar novamente na sua área logada do portal “userede.com.br” a
              opção de cadastro de endpoint estará disponível.
            </p>

          </div>
        </div>

        <div class="row" *ngIf="formaDePagamento.pix">
          <div class="form-group mb-1 col">
            <label >Url Notificações dos eventos Pix:</label>
            <input kendoTextBox   name="urlPix"    class="form-control"
                   [value]="obtenhaBaseUrlWebwookERede()" readonly/>

            <i class="fe-copy fa-2x ml-1 cpointer" (click)="copyMessage(obtenhaBaseUrlWebwookERede())"
               [ngClass]="{'text-success': textoCopiado === obtenhaBaseUrlWebwookERede()}"
               title="copiar url pix notificação" kendoTooltip></i>


            <p class="mt-2">
              O estabelecimento deverá informar essa url para receber as notificações dos eventos
            </p>
            <p>
              Para esse cadastro, o estabelecimento deve ligar na central de atendimento da Rede nos telefones:
               Capitais e regiões metropolitanas <b>4001 4433</b> ou  Demais localidades <b>0800 728 4433 </b>e informar o número de CNPJ, PV,
              email para contato com a URL acima para receber as notificações do Pix.
              O prazo para ativação é de 2 dias uteis após a abertura do chamado.
            </p>
          </div>


        </div>



      </ng-container>

      <ng-container *ngIf="gatewayMercadoPago()">
        <div class="row">
          <div class="form-group mb-3 col">
            <label for="nome">Public Key</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="publickKey" name="publickKey" [(ngModel)]="formaDePagamento.configMeioDePagamento.publicKey" #publickKey="ngModel"
                   placeholder="Public Key" value="" required>
            <div class="invalid-feedback">
              <p *ngIf="publickKey.errors?.required">Token é obrigatório</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group mb-3 col">
            <label for="nome">Access Token</label>
            <input type="text" class="form-control" autocomplete="off"
                   id="actoken" name="actoken" [(ngModel)]="formaDePagamento.configMeioDePagamento.token" #actoken="ngModel"
                   placeholder="Access Token" value="" required>
            <div class="invalid-feedback">
              <p *ngIf="actoken.errors?.required">Access Token é obrigatório</p>
            </div>
          </div>
        </div>

      </ng-container>
    </fieldset>



  </ng-container>

  <ng-container *ngIf="configurarOpendelivery()">
    <fieldset>
      <legend>Integração Open Delivery</legend>
      <div class="row">
        <div class="form-group mb-3 col-6">
          <label for="nome">Metodo </label>
          <kendo-dropdownlist  #formaOpendelivery  name="formaOpendelivery" (selectionChange)="mudouFormaOpenDelivery($event)"
                               [textField]="'descricao'"   [valueField]="'codigo'"
                               [data]="obtenhaOpendeliveryMetodos()"
                               placeholder="Selecione um metodo de pagamento" class="form-control"
                               required   >
          </kendo-dropdownlist>
          <div class="invalid-feedback">
            Referência OpenDelivery é obrigatório
          </div>
        </div>

        <div class="form-group mb-3 col-6" *ngIf="tipoBandeiraObritatorio()">
          <label  >Bandeira</label>
          <kendo-combobox #bandeiraOpenDelivery   name="bandeiraOpenDelivery"  [kendoDropDownFilter]="filterSettings" [filterable]="true"
                          [loading]="carregandoBandeiras"    (selectionChange)="mudouBandeiraOpenDelivery($event)"
                          [data]="bandeirasOpendelivery"   [textField]="'nome'"   [valueField]="'id'"    placeholder="Selecione uma bandeira" class="form-control"
                          required   >
          </kendo-combobox>

          <div class="invalid-feedback">
            Bandeira OpenDelivery é obrigatório
          </div>
        </div>
      </div>
    </fieldset>

  </ng-container>

  <ng-container *ngIf=" empresa?.integracaoDelivery">
    <fieldset>
      <legend>Integração com <b>{{empresa.integracaoDelivery.sistema}}</b></legend>

      <div class="row"  *ngIf="pagamentoPorFidelidade()">
        <div class="form-group mb-3 col-6"  >
          <label for="enviarComoDesconto">Enviar como desconto para <b>{{ empresa.integracaoDelivery.sistema}}</b></label>
          <div>
            <kendo-switch id="enviarComoDesconto" name="habilitarTaxa" [(ngModel)]="formaDePagamento.enviarComoDesconto"
                          [onLabel]="'Sim'"
                          [offLabel]="'Não'"></kendo-switch>
          </div>
        </div>
      </div>

      <div class="row"  *ngIf="!formaDePagamento.enviarComoDesconto && ( empresa.integracaoDelivery.sistema !== 'gcom' || !formaDePagamento.online)" >
        <div class="form-group mb-3 col-5">
          <label for="nome">Referência externa</label>
          <kendo-dropdownlist   name="referenciaExterna" (selectionChange)="mudouFormaIntegrada($event)"
                                [textField]="'descricao'"  [valueField]="'id'"
                                [(ngModel)]="formaDePagamento.formaIntegrada" [data]="empresa.integracaoDelivery.formasDePagamento"
                                placeholder="Selecione uma referência" class="form-control"
                                required   >
          </kendo-dropdownlist>
          <div class="invalid-feedback">
            Referência é obrigatório
          </div>
        </div>
        <div *ngIf="integradoComSaipos() && !formaDePagamento.online" class="form-group mb-3 col-7">
          <label  >Complemento   </label>
          <input type="text" class="form-control" autocomplete="off"
                 name="complemento" [(ngModel)]="bandeiraCartaoIntegrada.codigoPdv"    placeholder="Complemento"  >
        </div>

        <div class="form-group mb-3 col-7" *ngIf="bandeiraObrigatoria()"  >
          <label for="nome">Bandeira
          </label>

          <kendo-combobox   name="bandeira"  [kendoDropDownFilter]="filterSettings" [filterable]="true" [loading]="carregandoBandeiras"
                            [(ngModel)]="bandeiraCartaoIntegrada" [data]="bandeiras" textField="nome"
                            placeholder="Selecione uma bandeira" class="form-control"
                            required   >
          </kendo-combobox>

          <div class="invalid-feedback">
            Bandeira é obrigatório
          </div>

          <p class="text-danger mb-0" *ngIf="erroBandeiras"><b>{{erroBandeiras}}</b></p>
        </div>

      </div>

      <div class="row" *ngIf="bandeirasOnlineCartoesIntegrados.length">
        <div class="form-group mb-3 col-6" *ngFor="let bandeiraCartaoIntegradaOnline of bandeirasOnlineCartoesIntegrados; let i = index">
          <label for="nome">Bandeira - <b>{{bandeiraCartaoIntegradaOnline.descricaoPdvOnline}}</b>
          </label>

          <kendo-combobox   name="bandeira{{i}}"  [kendoDropDownFilter]="filterSettings" [filterable]="true" [loading]="carregandoBandeiras"
                            [(ngModel)]="bandeiraCartaoIntegradaOnline.bandeiraCartaoIntegrada" [data]="bandeiras" textField="nome"
                            placeholder="Selecione uma bandeira" class="form-control"
                            required   >
          </kendo-combobox>

          <div class="invalid-feedback">
            Bandeira é obrigatório
          </div>
        </div>
      </div>
    </fieldset>

  </ng-container>

  <ng-container *ngIf="!pagamentoPorFidelidade()">
    <fieldset>
      <legend>Configuraçoes</legend>

      <div class="row" >
        <ng-container *ngIf="formaDePagamento.online">
          <div class="form-group mb-3 col-6">
            <kendo-switch name="notificarNovoPedido" [(ngModel)]="formaDePagamento.notificarNovoPedido"
                          [onLabel]="'Sim'"
                          [offLabel]="'Não'"></kendo-switch>

            <label  class=" ml-1">Alertar pag. pendente</label>

          </div>

          <div class="form-group mb-3 col-6">
            <kendo-switch name="notificarConfirmacaoPagamento" [(ngModel)]="formaDePagamento.notificarConfirmacaoPagamento"
                          [onLabel]="'Sim'"
                          [offLabel]="'Não'"></kendo-switch>
            <label   class=" ml-1">Alertar pag. confirmado</label>

          </div>
        </ng-container>

        <div class="form-group mb-3 col-6"  >
          <kendo-switch id="habilitarTaxa" name="habilitarTaxa" [(ngModel)]="habilitarTaxa"
                        [onLabel]="'Sim'" (ngModelChange)="aletrouHabilitarTaxa()"
                        [offLabel]="'Não'"></kendo-switch>

          <label for="habilitarTaxa" class=" ml-1">Cobrar taxa</label>

        </div>

        <div class="form-group mb-3 col-6" *ngIf="habilitarTaxa">

          <kendo-numerictextbox   [attr.type]="'number'" [min]="0.1" [max]="100"  class="form-control" name="taxaPercentual" #taxaPercentual=ngModel
                                  [format]="obtenhaFormatoPercentual()" [placeholder]="'valor em % da taxa a ser cobrada'"
                                  [(ngModel)]="formaDePagamento.taxaCobranca.percentual" required>
          </kendo-numerictextbox>
          <div class="invalid-feedback">
            <p *ngIf="taxaPercentual.errors?.required">Valor da taxa é obrigatório</p>
            <p *ngIf="taxaPercentual.errors?.minError">Valor mínimo é  {{taxaPercentual.errors?.minError?.minValue}}</p>
            <p *ngIf="taxaPercentual.errors?.maxError">Valor máximo é  {{taxaPercentual.errors?.maxError?.maxValue}}</p>

          </div>
        </div>

        <div class="form-group mb-3 col-6"  >
          <kendo-switch id="possuiDesconto" name="possuiDesconto" [(ngModel)]="formaDePagamento.possuiDesconto"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>

          <label for="habilitarTaxa" class=" ml-1">Habilitar desconto</label>

        </div>

        <div class="form-group mb-3 col-6" *ngIf="formaDePagamento.possuiDesconto">

          <kendo-numerictextbox   [attr.type]="'number'" [min]="0.1" [max]="100"  class="form-control" name="desconto" #desconto=ngModel
                                  [format]="obtenhaFormatoPercentual()" [placeholder]="'valor em % do desconto aplicado no pedido'"
                                  [(ngModel)]="formaDePagamento.desconto" required>
          </kendo-numerictextbox>
          <div class="invalid-feedback">
            <p *ngIf="desconto.errors?.required">Valor da taxa é obrigatório</p>
            <p *ngIf="desconto.errors?.minError">Valor mínimo é  {{desconto.errors?.minError?.minValue}}</p>
            <p *ngIf="desconto.errors?.maxError">Valor máximo é  {{desconto.errors?.maxError?.maxValue}}</p>

          </div>
        </div>

        <div class="form-group mb-3 col-6"  *ngIf="!formaDePagamento.online && temTaxaRetorno()">
          <kendo-switch id="cobrarTaxaRetorno" name="cobrarTaxaRetorno" [(ngModel)]="formaDePagamento.cobrarTaxaRetorno"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>

          <label  class=" ml-1">Cobrar taxa de retorno</label>

        </div>

      </div>

      <div class="row">

        <div class="form-group mb-3 col-6">
          <kendo-switch   name="exibirCardapio" [(ngModel)]="formaDePagamento.exibirCardapio"
                          [onLabel]="'Sim'"
                          [offLabel]="'Não'"></kendo-switch>
          <label   class=" ml-1">Exibir Cardápio Online?</label>
        </div>

        <ng-container *ngIf="formaDePagamento.exibirCardapio">
          <div class="form-group mb-3 col-6"  >
            <kendo-switch id="habilitarRetirada" name="habilitarRetirada" [(ngModel)]="formaDePagamento.habilitarRetirada"
                          [onLabel]="'Sim'"
                          [offLabel]="'Não'"></kendo-switch>
            <label for="habilitarRetirada"  class=" ml-1">Habilitado para retirada</label>
          </div>

          <div class="form-group mb-3 col-6"  >
            <kendo-switch id="habilitarEntrega" name="habilitarEntrega" [(ngModel)]="formaDePagamento.habilitarEntrega"
                          [onLabel]="'Sim'"
                          [offLabel]="'Não'"></kendo-switch>
            <label for="habilitarEntrega"  class=" ml-1">Habilitado para entrega</label>
          </div>
        </ng-container>

      </div>
    </fieldset>

  </ng-container>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light"
            [disabled]="enviando || !podeSalvarComPagseguroConnect()" >Salvar</button>
    <!--<button type="button" (click)="onRemover()" class="btn btn-danger waves-effect waves-light" *ngIf="formaDePagamento.id">Remover</button>-->
    <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Fechar</button>
  </div>
</form>
