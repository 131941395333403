<kendo-dialog-titlebar (close)="fecheModal()" >
  <h4 class="modal-title">
    <i class="fe-briefcase mr-2"></i>
    <span>Registrar Empresa no <b>Meucardapio Pay</b></span>
  </h4>

</kendo-dialog-titlebar>

<div class="container-fluid p-4 empresa-dialog">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="salvar()">

    <div class="card">
      <div class="card-body">
        <h5 class="card-title mb-2  ">
          <i class="fe-info mr-2"></i>
          Dados Básicos   <i class="k-i-loading k-icon ml-1" *ngIf="carregando"></i>
        </h5>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Id da loja<span class="text-danger">*</span></label>
              <kendo-textbox      name="idLoja"     required [disabled]="true"
                                  [(ngModel)]="empresaTuna.externalId"
                                  #idLoja="ngModel" placeholder="Id da loja">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="idLoja.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Razão Social <span class="text-danger">*</span></label>
              <kendo-textbox
                name="razaoSocial"
                [(ngModel)]="empresaTuna.name"
                #razaoSocial="ngModel"
                required
                placeholder="Razão Social da empresa">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="razaoSocial.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Nome Fantasia <span class="text-danger">*</span></label>
              <kendo-textbox
                name="nomeFantasia"
                [(ngModel)]="empresaTuna.fantasyName"
                #nomeFantasia="ngModel"
                required
                placeholder="Nome Fantasia">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="nomeFantasia.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Tipo de Documento <span class="text-danger">*</span></label>
              <kendo-dropdownlist  [disabled]="empresaTuna.precadastro"
                [data]="['CNPJ', 'CPF']"
                [(ngModel)]="empresaTuna.documentType"
                name="documentType"
                #documentType="ngModel"  (selectionChange)="mudouTipoDocumento($event)"
                required>
              </kendo-dropdownlist>
              <div class="invalid-feedback">
                <p *ngIf="documentType.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>{{empresaTuna.documentType || 'CNPJ'}} <span class="text-danger">*</span></label>
              <kendo-maskedtextbox name = 'document'  [(ngModel)]="empresaTuna.document"
                                   #documento="ngModel" [disabled]="empresaTuna.precadastro"
                                   required
                                   [mask]="empresaTuna.documentType === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00'">
              </kendo-maskedtextbox>
              <div class="invalid-feedback">
                <p *ngIf="documento.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <!-- Campos específicos para CPF -->
          <ng-container *ngIf="empresaTuna.documentType === 'CPF'">
            <div class="col-md-4">
              <div class="form-group">
                <label>Profissão <span class="text-danger">*</span></label>
                <kendo-textbox
                  name="profissao"
                  [(ngModel)]="empresaTuna.occupation"
                  #profissao="ngModel"
                  required
                  placeholder="Profissão">
                </kendo-textbox>
                <div class="invalid-feedback">
                  <p *ngIf="profissao.errors?.required">Obrigatório</p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Nome da Mãe <span class="text-danger">*</span></label>
                <kendo-textbox
                  name="nomeMae"
                  [(ngModel)]="empresaTuna.motherName"
                  #nomeMae="ngModel"
                  required
                  placeholder="Nome da Mãe">
                </kendo-textbox>
                <div class="invalid-feedback">
                  <p *ngIf="nomeMae.errors?.required">Obrigatório</p>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Data de Nascimento <span class="text-danger">*</span></label>
                <kendo-datepicker
                  class="form-control"
                  name="dataNascimento"
                  [(ngModel)]="empresaTuna.birthday"
                  #dataNascimento="ngModel"
                  required
                  [format]="'dd/MM/yyyy'"
                  placeholder="dd/mm/aaaa">
                </kendo-datepicker>
                <div class="invalid-feedback">
                  <p *ngIf="dataNascimento.errors?.required">Obrigatório</p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>


        <h5 class="card-title mt-2 mb-2">
          <i class="fe-map-pin mr-2"></i>
          Dados Acesso  <i class="k-i-loading k-icon ml-1" *ngIf="carregando"></i>
        </h5>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Nome <span class="text-danger">*</span></label>
              <kendo-textbox name="nomeContato"  required  placeholder="Nome" #nomeContato="ngModel"
                             [(ngModel)]="empresaTuna.contact.name"  >
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="nomeContato.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Email <span class="text-danger">*</span></label>
              <kendo-textbox   id="email"  name="email"      #email="ngModel"
                               [(ngModel)]="empresaTuna.contact.email" required
                               placeholder="email@exemplo.com">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="email.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6">
             <div class="form-group">
               <label>Telefone <span class="text-danger">*</span></label>
               <input type="text"
                      class="form-control"
                      name="cellPhone"
                      [(ngModel)]="empresaTuna.contact.cellPhone"
                      required
                      mask="(00) 00000-0000"
                      placeholder="(00) 00000-0000">
             </div>
           </div> -->

        </div>

        <!-- Informações Bancárias -->
        <h5 class="card-title mt-2 mb-2">
          <i class="fe-credit-card mr-2"></i>
          Informações Bancárias  <i class="k-i-loading k-icon ml-1" *ngIf="carregando"></i>
        </h5>

        <p class="text-black">
          Os dados bancários devem estar vinculados ao documento <b>{{ this.empresaTuna.documentType  }}</b>
          cadastrado  , podendo ser o da matriz ou de uma de suas filiais.
        </p>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label>Banco <span class="text-danger">*</span></label>
              <kendo-combobox [kendoDropDownFilter]="filterSettings" [filterable]="true"
                              [data]="bancos"
                              [textField]="'descricao'"
                              [valueField]="'codigo'"
                              name="banco"  [(ngModel)]="bancoSelecionado"  #banco="ngModel"
                              (selectionChange)="escolheuBanco($event)"
                              required placeholder="Selecione o banco">
              </kendo-combobox>
              <div class="invalid-feedback">
                <p *ngIf="banco.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Tipo de Conta <span class="text-danger">*</span></label>
              <kendo-dropdownlist
                [data]="tiposDeConta"
                [textField]="'text'"
                [valueField]="'value'"
                [(ngModel)]="bankAccount.accountType"
                name="tipoConta"
                #tipoConta="ngModel"
                required>
              </kendo-dropdownlist>
              <div class="invalid-feedback">
                <p *ngIf="tipoConta.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Agência <span class="text-danger">*</span></label>
              <kendo-textbox
                [(ngModel)]="bankAccount.agency"
                name="agencia"
                #agencia="ngModel"
                required
                placeholder="Número da agência">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="agencia.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Conta <span class="text-danger">*</span></label>
              <kendo-textbox
                [(ngModel)]="bankAccount.number"
                name="conta"
                #conta="ngModel"
                required
                placeholder="Número da conta">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="conta.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>Tipo da Chave Pix</label>
              <kendo-dropdownlist required       #tipoChavePix="ngModel"
                                  [data]="tiposChavesPix"
                                  [textField]="'text'"
                                  [valueField]="'value'" [disabled]="empresaComCNPJ()"
                                  [(ngModel)]="empresaTuna.pixKeyType"
                                  name="tipoChavePix">
              </kendo-dropdownlist>
              <div class="invalid-feedback">
                <p *ngIf="tipoChavePix.errors?.required">Obrigatório</p>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="!empresaTuna.pixKeyType || empresaTuna.pixKeyType.value !== '6'">
            <div class="form-group">
              <label>Chave Pix <span class="text-danger">*</span></label>
              <kendo-textbox
                [(ngModel)]="empresaTuna.pixKey" [disabled]="empresaTuna.precadastro"
                name="chavePix"
                #chavePix="ngModel"
                required
                [placeholder]="'Informe o ' + (empresaTuna.pixKeyType ? empresaTuna.pixKeyType.text : '')">
              </kendo-textbox>
              <div class="invalid-feedback">
                <p *ngIf="chavePix.errors?.required">Obrigatório</p>
              </div>

              <div class="alert alert-info mb-4 mt-1" *ngIf="empresaComCNPJ()">
                <i class="fas fa-info-circle mr-2"></i>
                <small >Obrigatorio que a chave seja o CNPJ da empresa</small>
              </div>

            </div>
          </div>
        </div>


        <!--  <ng-container *ngIf="false">

    <h5 class="card-title mt-4 mb-4">
      <i class="fe-map-pin mr-2"></i>
      Endereço
    </h5>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>CEP <span class="text-danger">*</span></label>
          <input type="text"
                 class="form-control"
                 name="cep"
                 [(ngModel)]="empresaTuna.cep"
                 required
                 mask="00000-000"
                 placeholder="00000-000">
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Logradouro <span class="text-danger">*</span></label>
          <input type="text"
                 class="form-control"
                 name="logradouro"
                 [(ngModel)]="empresaTuna.logradouro"
                 required
                 placeholder="Rua, Avenida, etc">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Número <span class="text-danger">*</span></label>
          <input type="text"
                 class="form-control"
                 name="numero"
                 [(ngModel)]="empresaTuna.numero"
                 required
                 placeholder="Número">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Bairro <span class="text-danger">*</span></label>
          <input type="text"
                 class="form-control"
                 name="bairro"
                 [(ngModel)]="empresaTuna.bairro"
                 required
                 placeholder="Bairro">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Cidade <span class="text-danger">*</span></label>
          <input type="text"
                 class="form-control"
                 name="cidade"
                 [(ngModel)]="empresaTuna.cidade"
                 required
                 placeholder="Cidade">
        </div>
      </div>
    </div>
  </ng-container> -->

        <div class="row">
          <div class="col">
            <p class="text-danger"><b>{{mensagemErro}}</b></p>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

<kendo-dialog-actions>
  <div class="d-flex justify-content-end w-100">
    <button kendoButton
            [disabled]="salvando"
            (click)="fecheModal()"
            class="mr-2">
      Cancelar
    </button>

    <button kendoButton
            [primary]="true"
            [disabled]="salvando"
            (click)="salvar()">
      <i class="k-icon k-i-loading" *ngIf="salvando"></i>
      Salvar
    </button>
  </div>
</kendo-dialog-actions>
