<form [ngClass]="{'needs-validation': !frmOpcaoAdicional.submitted, 'was-validated': frmOpcaoAdicional.submitted}"
      novalidate #frmOpcaoAdicional="ngForm" (ngSubmit)="salvarOpcaoDeAdicional(frmOpcaoAdicional)"
      style="padding: 20px;">


  <p *ngIf="adicionalDeProduto.compartilhado" class="mb-1 text-warning">
    <b>Esse adicional é compartilhado com outros, altera-lo irá afetar também nos outros produtos.</b>
    <span class="h4 d-inline-block p-1 ml-1" style="border-radius: 5px;background: #f1ecff;color: #5f2cb6"
          title="Esse adicional é compartilhado com outros." kendoTooltip >
                              <i class="fas fa-link"></i>
                            </span>
  </p>

  <div class="row" *ngIf="!selecionarProduto && !novasOpcoes.length">
    <div class="col-12 col-md-4 mb-3">
      <app-upload-imagem [objeto]="opcaoDeAdicional" [menor]="true" [maxsize]="800"></app-upload-imagem>
    </div>

    <div class="col-12 col-md-8">
      <div class="row">
        <div class="form-group mb-2 col-12 col-md-8">
          <label>Nome da Opção</label>
          <input type="text" class="form-control" autocomplete="off" [disabled]="opcaoDeAdicional.idProduto || !podeEditar"
                 id="nomeOpcao" name="nomeOpcao" [(ngModel)]="opcaoDeAdicional.nome" #nomeOpcao="ngModel"
                 placeholder="Ex: Molho barbecue" value="" required appAutoFocus [autoFocus]="true">
          <div class="invalid-feedback">
            <p *ngIf="nomeOpcao.errors?.required">Nome é obrigatorio</p>
          </div>
        </div>

        <div class="form-group mb-2 col-12 col-md-4">
          <label>Codigo {{sistemaIntegrado ? 'PDV' : ''}} </label>
          <input type="text" class="form-control" autocomplete="off"  [required]="sistemaIntegrado==='ecletica'"
                 name="codigoPdv" [(ngModel)]="opcaoDeAdicional.codigoPdv" #codigoPdv="ngModel" [disabled]="opcaoDeAdicional.idProduto || !podeEditar"
                 placeholder="Cod. " >
          <div class="invalid-feedback">
            <p *ngIf="codigoPdv.errors?.required">Codigo do PDV é obrigatório.</p>
          </div>
        </div>

        <div class="form-group mb-2 col-12">
          <label>Descrição da Opção</label>
          <input type="text" class="form-control" autocomplete="off"
                 id="descricaoOpcao" name="descricaoOpcao" [(ngModel)]="opcaoDeAdicional.descricao" #descricaoOpcao="ngModel"
                 [disabled]="!podeEditar"
                 placeholder="Ex: Delicioso molho barbecue para incrementar o seu lanche" value="">
        </div>

        <div class="form-group mb-2 col-12 col-md-6" *ngIf="!catalogo || !catalogo.precoPorEmpresa || !opcaoDeAdicional.id">
          <label for="valor">Valor adicionado ao preço do produto:</label>
          <kendo-numerictextbox id="valor" [min]="0" class="form-control w-100" name="valor" #valor=ngModel
                                [disabled]="produtoDeBrinde() || !podeEditar"
                                placeholder="Informe R$ 0,00 se a opção não afeta o preço." [value]="0"
                                [(ngModel)]="opcaoDeAdicional.valor" required>
          </kendo-numerictextbox>
          <div class="invalid-feedback">
            <p *ngIf="valor.errors?.required">Valor é obrigatório. Informe 0 se não afeta o preço</p>
          </div>
        </div>

        <div class="form-group mb-2 col-12 col-md-6"  *ngIf="catalogo && catalogo.precoPorEmpresa && opcaoDeAdicional.id">
          <label for="valorNaEmpresa">Valor adicionado ao preço do produto:</label>
          <kendo-numerictextbox [disabled]="!podeEditarPrecos" id="valorNaEmpresa" [min]="0"  class="form-control" name="valorNaEmpresa" #valorNaEmpresa=ngModel
                                placeholder="Informe R$ 0,00 se a opção não afeta o preço." [value]="0"
                                [(ngModel)]="opcaoDeAdicional.valorNaEmpresa" required>
          </kendo-numerictextbox>

          <div class="invalid-feedback">
            <p *ngIf="valorNaEmpresa.errors?.required">Valor é obrigatório. Informe 0 se não afeta o preço</p>
          </div>
        </div>

        <div class="form-group mb-2 col-6">
          <label class="k-checkbox-label">
            <input type="checkbox"   name="obrigatorio" class="k-checkbox " kendoCheckBox
                   [disabled]="!podeEditar"
                   [(ngModel)]="opcaoDeAdicional.depende" />
            Exibir somente quando outra opção for incluida </label>
        </div>


        <ng-container *ngIf="opcaoDeAdicional.depende">
          <div class="form-group mb-2 col-12 col-md-8" >
            <label>Selecione o adicional</label>
            <kendo-combobox textField="nome" valueField="id"
                          [(ngModel)]="opcaoDeAdicional.adicionalDependente"
                          [data]="obtenhaAdicionaisProdutos(adicionalDeProduto)"
                          class="form-control w-100"
                          name="adicionalQueDepende"
                          placeholder="Selecione o adicional" >
            </kendo-combobox>
          </div>

          <div class="form-group mb-2 col-12 col-md-8" >
            <label>Selecione a opção</label>
            <kendo-multiselect id="dependencias"
                            name="dependencias"
                            [(ngModel)]="opcaoDeAdicional.opcoesDependentes"
                            [data]="obtenhaOpcoesDoAdicional()"
                            placeholder="Selecione as opções"
                            class="form-control w-100"
                            textField="nome"
                            valueField="id"
                            (ngModelChange)="alterouOpcao()">
            </kendo-multiselect>
          </div>
        </ng-container>
      </div>

      <app-associar-insumo #telaInsumoAssociado  [objeto]="opcaoDeAdicional" [tipo]="'complemento'"
                           *ngIf="(opcaoDeAdicional.id || opcaoDeAdicional.nova) && temModuloEstoque && !produtoDeBrinde()">

      </app-associar-insumo>



      <p *ngIf="adicionalDeProduto.compartilhado" class="text-warning font-12 mb-2 float-right">
        <b>Esta alteração irá afetar também nos outros produtos.</b>
      </p>

    </div>
  </div>

  <div  *ngIf="novasOpcoes.length > 1">
    <h3>Confirme o preço das novas opções</h3>
    <div class="row  mb-2 mt-2">
      <div class="col-6">
        <label  ><b>Nome da Opção</b></label>
      </div>
      <div class="col-3">
        <label  ><b>Cód. <span *ngIf="sistemaIntegrado">PDV</span></b></label>
      </div>
      <div class="col-3">
        <label  ><b>Preço</b></label>
        <button class="btn btn-sm btn-warning ml-1" type="button" (click)="zereTodos()">zerar todos</button>
      </div>
    </div>
    <div class="scroll">
      <div *ngFor="let novaOpcao of novasOpcoes; let i = index" class="row mb-2">
        <div class="col-6">
          <label  class="text-primary"><b>{{novaOpcao.nome}}</b></label>
        </div>
        <div class="col-3">
          {{novaOpcao.codigoPdv ? novaOpcao.codigoPdv : '-' }}
        </div>
        <div class="col-3">
          <kendo-numerictextbox   [min]="0"  class="form-control" name="valor{{i}}"
                                  placeholder="Informe R$ 0,00 se a opção não afeta o preço."
                                  [(ngModel)]="novaOpcao.valor" required>
          </kendo-numerictextbox>

        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="selecionarProduto">
    <div class="col" *ngIf="pizzaria && !tipoProduto">
      <h4>Qual tipo de produto: </h4>
      <div class="row mb-2">
        <div class="col-12 col-sm-6 mt-2">
          <button class="btn btn-outline-blue btn-block" (click)="escolheuTemplate(TIPOSDEPRODUTOS.Normal)">Produto normal</button>
        </div>
        <div class="col-12 col-sm-6 mt-2" *ngFor="let template of templatesPiza">
          <button class="btn btn-outline-primary btn-block" (click)="escolheuTemplate(TIPOSDEPRODUTOS.Pizza,  template)">
            Sabor de  {{template.nome}}
          </button>
        </div>
      </div>
    </div>
    <div class="col"  *ngIf="!pizzaria || tipoProduto">
      <p>Selecione um ou mais produtos
         <ng-container *ngIf="filtro.template">
            em
           <kendo-combobox name="templates" [data]="templatesPiza" style="width: 200px; display: inline-block;"
                           (selectionChange)="escolheuTemplate(TIPOSDEPRODUTOS.Pizza, $event)"
                           class="form-control"     [allowCustom]="false"  [clearButton]="false"
                           [(ngModel)]="filtro.template" [valueField]="'id'"   [textField]="'nome'">
           </kendo-combobox>

         </ng-container>
      </p>

      <p class="mt-2 text-danger" *ngIf="erroAdicionarProduto">{{erroAdicionarProduto}}</p>

      <kendo-grid [kendoGridBinding]="produtosAdicionar" [selectable]="selectableSettings"
                  [(selectedKeys)]="produtosSelecionados"  kendoGridSelectBy="id"
                  (selectedKeysChange)="selecionouProduto($event)"
                  [loading]="carregandoProdutos" [height]="500" [scrollable]="'scrollable'"
                  style="width: 100%; overflow-x: auto;">
        <ng-template kendoGridToolbarTemplate [position]="'top'">

          <div class="row mt-2">
            <div class="col-12 col-md-6 mb-2">
              <input kendoTextBox placeholder="Busque por nome do produto" class="form-control busca"
                     [(ngModel)]="filtro.texto"
                     appAutoFocus   (input)="onFilterProdutos($event)"/>
            </div>

            <div class="col-12 col-md-6 mb-2" *ngIf="filtro.template">
              <kendo-combobox id="tamanhos" name="tamanhos" [data]="filtro.template.tamanhos" placeholder="filtre pro tamanho"
                          (selectionChange)="alterouTamanho($event)"
                          class="form-control w-100" [allowCustom]="false" [clearButton]="false"
                          [(ngModel)]="filtro.tamanho" [valueField]="'id'" [textField]="'descricao'">
              </kendo-combobox>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <kendo-combobox id="cboCategorias" class="form-control w-100" [data]="catalogo.categorias" placeholder="filtre por categoria"
                              textField="nome" valueField="id" [(ngModel)]="filtro.categoria" [allowCustom]="false" [clearButton]="true"
                              (valueChange)="carregueProdutosDisponiveis()">
                </kendo-combobox>
              </div>
            </div>
          </div>

        </ng-template>
        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="{{mensagemSemProdutos}}"
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>
        <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true"></kendo-grid-checkbox-column>
        <kendo-grid-column title="Imagem" class="coluna_menor" [width]="110">
          <ng-template kendoGridCellTemplate let-produto let-rowIndex="rowIndex">

            <img [src]="'https://promokit.com.br/images/empresa/'+produto.imagens[0].linkImagem" style="width: 80px;" *ngIf="produto.imagens && produto.imagens.length > 0">

            <i class="fe-image fa-2x" *ngIf="!produto.imagens || produto.imagens.length == 0"></i>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Nome"   >
          <ng-template kendoGridCellTemplate let-produto  >
            <span class="text-blue"><b>{{produto.nome}}</b></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Cod {{sistemaIntegrado ? 'PDV': ''}}"   >
          <ng-template kendoGridCellTemplate let-produto  >
            <span  *ngIf="!filtro.tamanho"><b>{{produto.codigoPdv ? produto.codigoPdv : '-'}}</b></span>
            <span  *ngIf="filtro.tamanho"><b>{{ obtenhaTamanhoProduto(produto).codigoPdv  || '-'}}</b></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Categoria"    *ngIf="!filtro.tamanho">
          <ng-template kendoGridCellTemplate let-produto  >
            <span > {{produto.categoria ? produto.categoria.nome : '-'}} </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Tamanho"   *ngIf="filtro.tamanho">
          <ng-template kendoGridCellTemplate let-produto  >
            <span > {{filtro.tamanho.descricao  }} </span>
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>
    </div>
  </div>

  <div class="modal-footer">

    <div class="alert alert-danger mt-1 mb-2" *ngIf="erroSalvar">
      {{erroSalvar}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <button type="submit" class="btn btn-primary waves-effect waves-light" *ngIf="!selecionarProduto"
            [disabled]="salvando">
      <i class="k-i-loading k-icon " *ngIf="salvando"></i>Salvar</button>
    <button type="button" class="btn btn-blue waves-effect waves-light" *ngIf="selecionarProduto"
            (click)="adicionarProdutosComoOpcao()">Adicionar</button>
    <button type="button" (click)="fecharDialogOpcao()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>



  </div>

</form>
