<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title" id="myModalLabel">
    <img src="../../../../assets/fidelidade/icones/novocartao.svg" style="height: 32px;"/> Novo Plano
  </h4>
</kendo-dialog-titlebar>

<div #pnl class="modal-body">

  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"  novalidate #frm="ngForm"
        (ngSubmit)="onSubmit(frm)">

    <div class="row">
      <div class="col-6">
        <div class="form-group mb-3">
          <label for="nome">Nome do Plano</label>
          <input type="text" class="form-control" autocomplete="off"
                 id="nome" name="nome" [(ngModel)]="plano.nome" #nome="ngModel"
                 placeholder="Nome do Plano" value="" required appAutoFocus [autoFocus]="true">
          <div class="invalid-feedback">
            <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
          </div>
        </div>

      </div>

      <div class="col-6">
        <div class="form-group mb-3">
          <label for="nome">Titulo cartão</label>
          <input type="text" class="form-control" autocomplete="off"
                 id="tituloCartao" name="tituloCartao" [(ngModel)]="plano.tituloCartao"
                 placeholder="Titulo cartão (cliente vai ver)" value="" required   >

          <div class="invalid-feedback" *ngIf="!plano.tituloCartao">
            <p  >Nome é obrigatório</p>
          </div>

          <div class="text-muted">
            sugestão: "Cartão {{plano.nome}}"
          </div>

        </div>

      </div>

    </div>

    <div class="row">
      <div class="col-5">
        <div class="form-group mb-0">
          <label for="tipoDePontuacao">Tipo de Pontuação:</label>

          <kendo-dropdownlist id="tipoDePontuacao" name="tipoDePontuacao" [(ngModel)]="plano.tipoDePontuacao" [data]="tiposDePontuacoes"
                              placeholder="Selecione um tipo de pontuação" class="form-control" textField="nome" valueField="tipo"
                              required (selectionChange)="alterouTipoPontuacao($event)">
          </kendo-dropdownlist>


          <div class="text-muted"  *ngIf="!plano.tipoDePontuacao">
            {{plano.tipoDePontuacao?.descricao}}
          </div>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group mb-0">
          <label for="tipoDeAcumulo">Tipo De Acúmulo</label>

          <kendo-dropdownlist id="tipoDeAcumulo" name="tipoDeAcumulo" [(ngModel)]="plano.tipoDeAcumulo" [data]="getTiposAcumulo()"
                              placeholder="Selecione um tipo de Acúmulo" class="form-control"
                              (selectionChange)="alterouTipoAcumulo($event)" required >
          </kendo-dropdownlist>
          <div class="invalid-feedback" *ngIf="!plano.tipoDeAcumulo">
            Tipo de aculumo é obrigatório
          </div>
        </div>
      </div>

      <div class="col-4"   *ngIf="plano.tipoDePontuacao && plano.tipoDePontuacao.tipo === 'por-valor'" >

        <div class="form-group mb-0" >
          <label for="valorPorPonto">Reais para ganhar 1 {{getAcumulo()}}</label>
          <kendo-numerictextbox id="valorPorPonto" [min]="0"   class="form-control" name="valorPorPonto" #valorPorPonto=ngModel
                                [(ngModel)]="plano.tipoDePontuacao.valorPorPonto" required>
          </kendo-numerictextbox>

          <div class="invalid-feedback"  *ngIf="!plano.tipoDePontuacao?.valorPorPonto">
            Campo obrigatório
          </div>

        </div>

      </div>

      <div class="col-4"  *ngIf="plano.tipoDePontuacao && plano.tipoDePontuacao.tipo === 'por-pontos'" >

        <div class="form-group mb-0" >
          <label for="pontosPorValor">{{this.plano.tipoDeAcumulo}} ganhos por R$ 1,00</label>
          <kendo-numerictextbox id="pontosPorValor" [min]="0" class="form-control" name="pontosPorValor" #pontosPorValor=ngModel    [format]="'n0'"
                                [(ngModel)]="plano.tipoDePontuacao.pontosPorValor" required>
          </kendo-numerictextbox>
          <div class="invalid-feedback"  *ngIf="!plano.tipoDePontuacao?.pontosPorValor">
            Campo obrigatório
          </div>

        </div>


      </div>

      <div class="col-4"  *ngIf="plano.tipoDePontuacao && plano.tipoDePontuacao.tipo === 'qtde-fixa'" >

        <div class="form-group mb-0" >
          <label for="selosPorAtividade">Qtde de  {{plano.tipoDeAcumulo}}</label>
          <kendo-numerictextbox id="selosPorAtividade" [min]="0" [max]="100" class="form-control" name="valorPorPonto"
                                #selosPorAtividade=ngModel [(ngModel)]="plano.tipoDePontuacao.selosPorAtividade">
          </kendo-numerictextbox>

          <div class="invalid-feedback">
            <p *ngIf="selosPorAtividade.errors?.required">Nome é obrigatório</p>
            <p *ngIf="selosPorAtividade.errors?.nomeCompleto">Informe um nome completo</p>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <small class="help mt-1 d-block text-primary"  >
          <b>{{getAjuda(plano.tipoDePontuacao)}}</b>
        </small>
      </div>

      <ng-container *ngIf="validarMinimos()">
        <div class="col-4" >

          <div class="form-group mb-3"  >
            <label for="validade">Valor mínimo pedido pontuar
              <i class="k-icon k-i-info text-blue" title="irá acumular saldo somente em pedidos que forem maior ou igual ao definido como mínimo" kendoTooltip></i>
            </label>
            <kendo-numerictextbox id="valorMinimo" [min]="0" class="" name="valorMinimo"     [format]="'c2'"  class="form-control"
                                  [(ngModel)]="plano.valorMinimoPontuar"  >
            </kendo-numerictextbox>
          </div>

        </div>

        <div class="col-4" >
          <div class="form-group mb-3">
            <label for="valorMinimoResgate">Valor mínimo pedido resgatar
              <i class="k-icon k-i-info text-blue" title="usar saldo acumulado somente em pedidos que forem maior ou igual ao definido como mínimo" kendoTooltip></i>
            </label>
            <kendo-numerictextbox id="valorMinimoResgate" [min]="0" class="" name="valorMinimoResgate"     [format]="'c2'"  class="form-control"
                                  [(ngModel)]="plano.valorMinimoResgate"  >
            </kendo-numerictextbox>

          </div>
        </div>
      </ng-container>

    </div>

    <div class="row mt-1">
      <div class="col-4">

        <div class="form-group mb-3"  >
          <label for="validade">Validade dos pontos em dias</label>
          <kendo-numerictextbox id="validade" [min]="0" class="" name="validade"     [format]="'n0'"  class="form-control"
                                [(ngModel)]="plano.validade"  >
          </kendo-numerictextbox>
        </div>

      </div>

      <div class="col-4">
        <div class="form-group mb-3"  >
          <label for="validade">Vencimento dos pontos</label>
          <kendo-datepicker id="vencimento"  name="vencimento"     class="form-control" [(ngModel)]="plano.vencimento">
          </kendo-datepicker>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group mb-3"  >
          <label for="validade">Acumular somente até a data</label>
          <kendo-datepicker id="dataFimAcumulo"  name="dataFimAcumulo"     class="form-control" [(ngModel)]="plano.dataFimAcumulo">
          </kendo-datepicker>
        </div>
      </div>

    </div>

    <div class="mt-2">
      <input type="checkbox" id="cartaoConsumo" name="cartaoConsumo" class="k-checkbox  " kendoCheckBox   [(ngModel)]="plano.cartaoConsumo" />
      <label class="k-checkbox-label " for="cartaoConsumo">Ativar  modulo cartão consumo</label>
    </div>

    <div class="mt-2">
      <input type="checkbox" id="referenciaExterna" name="referenciaExterna" class="k-checkbox  " kendoCheckBox   [(ngModel)]="plano.referenciaExterna" />
      <label class="k-checkbox-label " for="referenciaExterna">Informar referência  externa (ex.: venda ) na pontuação</label>
    </div>

    <div class="mt-2">
      <input type="checkbox" id="renovarAoPontuar" name="renovarAoPontuar" class="k-checkbox  " kendoCheckBox   [(ngModel)]="plano.renovarAoPontuar" />
      <label class="k-checkbox-label " for="renovarAoPontuar">Renovar vencimento de pontos ganhos ao pontuar</label>
    </div>

    <div class="mt-4">
      <label>Regras Extras</label>
    </div>

    <kendo-grid
      [kendoGridBinding]="regrasExtras"

      editable="incell"
      [loading]="carregando" style="min-height: 150px;"
      (edit)="editarRegra($event)"
      (remove)="removerRegra($event)"
      (pageChange)="carregue($event)">
      <ng-template kendoGridToolbarTemplate [position]="'top'">
        <button class="btn btn-blue btn-sm" (click)="adicionarRegra()">
          <i class="fe-plus"></i>
          Nova Regra
        </button>
      </ng-template>
      <kendo-grid-messages
        loading="Carregando"
        noRecords="Sem regras extras"
      >
      </kendo-grid-messages>
      <kendo-grid-column title="Regra">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span>{{dataItem.descricao}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" [width]="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
          <button kendoGridEditCommand [primary]="true"  (selectedChange)="editarRegra(dataItem)"><i class="fa fa-edit"></i></button>
          <button kendoGridRemoveCommand><i class="fa fa-trash"></i></button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>

    <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemSucesso()">&times;</button>
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
    </div>

    <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible mt-2">
      <button type="button" class="close" (click)="fecheMensagemErro()">&times;</button>
      <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
    </div>

    <div class="modal-footer mt-2">
      <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >
        <i class="k-icon k-i-loading" *ngIf="salvando"></i>
        Salvar</button>
      <button type="button" class="btn btn-light waves-effect" data-dismiss="modal" (click)="fecheModal()">Fechar</button>
    </div>

  </form>


</div>

<kendo-dialog title="Inserir regra" *ngIf="abraDialogo" (close)="fecharDialogRegra()" [minWidth]="250" [width]="450">
  <div class="form-group mb-3" style="padding: 20px">
    <label for="nome">Descrição da Regra</label>
    <input type="text" class="form-control" autocomplete="off"
           id="descricaoRegra" name="descricaoRegra" [(ngModel)]="regra.descricao" #descricaoRegra="ngModel"
           placeholder="Descrição da Regra" value="" required appAutoFocus [autoFocus]="true">
    <div class="invalid-feedback">
      <p *ngIf="descricaoRegra.errors?.required">Descrição é obrigatória</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="salvarRegraExtra()" class="btn btn-primary waves-effect waves-light" >Salvar</button>
    <button type="button" (click)="fecharDialogRegra()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</kendo-dialog>

<kendo-dialog title="Remover regra" *ngIf="abraDialogoRemover" (close)="fecharDialogRemover()" [minWidth]="250" [width]="450">
  <div class="form-group mb-3">
    Tem certeza que você deseja remover a regra "{{regra.descricao}}"?
  </div>
  <div class="modal-footer">
    <button type="button" (click)="removerRegraExtra()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" (click)="fecharDialogRemover()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>
</kendo-dialog>
