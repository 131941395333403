import {Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormasDePagamentoService} from "../../services/formas-de-pagamento.service";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {NovaFormaDePagamentoComponent} from "../nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {ActivatedRoute} from "@angular/router";
import {TabStripComponent} from "@progress/kendo-angular-layout";
import {DOCUMENT} from "@angular/common";
import {AutorizacaoService} from "../../services/autorizacao.service";


@Component({
  selector: 'app-ativar-formas-de-pagamento',
  templateUrl: './ativar-formas-de-pagamento.component.html',
  styleUrls: ['./ativar-formas-de-pagamento.component.scss']
})
export class AtivarFormasDePagamentoComponent implements OnInit {
  @ViewChild('tabstrip', { static: true }) private tabstrip: TabStripComponent;
  @ViewChild('tabstriponline', { static: false }) private tabstriponline: TabStripComponent;
  nomeTabOnline = 'Pagamentos online/site';
  loading: any = true;
  empresa: any = {};
  formasDePagamento: Array<FormaDePagamento> = [];
  formasDePagamentoOnline: Array<FormaDePagamento> = [];
  formasDePagamentoOnlineExterna: Array<FormaDePagamento> = [];
  gridDataResult: any = []
  usuario: any = {};
  confirmarMigrar = false;
  migrandoFormasPagamento = false;
  constructor(private formasDePagamentoService: FormasDePagamentoService,  private dialogService: DialogService,
              private _renderer2: Renderer2,  @Inject(DOCUMENT) private  _document: Document,
              private  activatedRoute: ActivatedRoute, private autorizacaoService: AutorizacaoService,
              private constantsService: ConstantsService) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.formasDePagamentoService.listeFormasPagamentosLoja().then((resp: any) => {
      this.formasDePagamento = resp.offline;
      this.formasDePagamentoOnline = resp.online;

      this.formasDePagamentoOnlineExterna =
        this.formasDePagamentoOnline.filter((item: any) => item.configMeioDePagamento.meioDePagamento !== 'tunapay')
      this.loading = false;
    })

    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;
      this.empresa = empresa;
    })

    this.usuario = this.autorizacaoService.getUser() || {};

    setTimeout(() => {
      let formapagamento = this.activatedRoute.snapshot.params.formapagamento;

      if(formapagamento && formapagamento !== 'offline' )
        this.exibaTabOnline(formapagamento)
    })

  }


  exibaTabOnline(nomeiopagamento: string){
    const tabs = this.tabstrip.tabs.toArray(); // Obtenha todas as abas como um array
    const tabFormasPagamento: any = tabs.find((_tab: any) => _tab.title ===  this.nomeTabOnline);

    if (tabFormasPagamento)
      this.tabstrip.selectTab(tabs.indexOf(tabFormasPagamento))

    setTimeout(() => {
      const tabsOnline =  this.tabstriponline.tabs.toArray();
      if(tabsOnline.length > 1 && nomeiopagamento !== 'onlinemci')
        this.tabstriponline.selectTab(1);
    }, 500);
  }



  obtenhaFormasPagamentoMetodo(metodo: string, prefixo = null) {
    if (prefixo)
      return this.formasDePagamento.filter((item: any) =>
        item.formaDePagamentoPdv && item.formaDePagamentoPdv.metodo.startsWith(prefixo))


    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.metodo === metodo)
  }

  obtenhaFormasPagamentoTipo(tipo: string ) {

    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.tipo === tipo)
  }

  obtenhaFormasPagamentoIfoodPdv() {
    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.nome === 'Ifood Loja')
  }

  obtenhaFormasPagamentoIfoodOnline() {
    return this.formasDePagamento.filter((item: any) =>
      item.formaDePagamentoPdv && item.formaDePagamentoPdv.nome === 'Ifood Online')
  }

  novoOnline(){
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro Pagamento Online',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;
    telaNovaFormaDePagamento.novaForma(true, this.empresa);
    telaNovaFormaDePagamento.windowRef = windowRef;

    windowRef.result.subscribe((result: any) => {
      if(result && result.id)
        this.formasDePagamentoOnline.push(result)

    });
  }

  abraModalMigrarFormasBandeira(){
    this.confirmarMigrar = true;
  }


  confirmarMigrarBanderias(){
    this.migrandoFormasPagamento = true;

    this.formasDePagamentoService.migreParaSemBandeiras().then(() => {
      this.migrandoFormasPagamento  = false;
      this.confirmarMigrar = false;
      window.location.assign('/admin/loja/formaspagamento/offline');
    }).catch((err) => {
      this.migrandoFormasPagamento  = false;
      alert(err)
    })
  }

}
