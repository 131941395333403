import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import { PaletaCoresService } from '../services/paleta-cores.service';
import { FidelidadeComponent } from './fidelidade.component';
import {HomeComponent} from "../home/home.component";
import {MenuFidelidadeComponent} from "../componentes/menu-fidelidade/menu-fidelidade.component";
import {CanDeactivateNovoPedido, FidelidadeRoutingModule} from './fidelidade.routing';
import {NotificacoesComponent} from "./noticacoes/notificacoes.component";
import {NovoCartaoComponent} from "../novo-cartao/novo-cartao.component";
import {ContatosComponent} from "../contatos/contatos.component";
import {AdicionarPontosComponent} from "./adicionar-pontos/adicionar-pontos.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TrocarPontosComponent} from "../trocar-pontos/trocar-pontos.component";
import {FirstWordPipe} from "../first-word.pipe";
import {BuscarContatoComponent} from "../componentes/buscar-contato/buscar-contato.component";
import {InlineSVGModule} from "ng-inline-svg";
import {InserirPontosComponent} from "../inserir-pontos/inserir-pontos.component";
import {NovoContatoComponent} from "../novo-contato/novo-contato.component";
import {NomeConmpletoValidator, NomeValidoValidator} from "../directives/form-validadores.directive";
import {NgxMaskModule} from "ngx-mask";
import {VerContatoComponent} from "../ver-contato/ver-contato.component";
import {CardContatoComponent} from "./card-contato/card-contato.component";
import {SeloComponent, SelosComponent} from "../componentes/selo/selo.component";
import {FiltroAvancadoComponent} from "../componentes/filtro-avancado/filtro-avancado.component";
import {DropDownsModule} from "@progress/kendo-angular-dropdowns";
import {DateInputsModule} from "@progress/kendo-angular-dateinputs";
import {CldrIntlService, IntlModule, IntlService} from '@progress/kendo-angular-intl';
import {DialogModule, WindowModule} from "@progress/kendo-angular-dialog";
import { NotificationModule } from '@progress/kendo-angular-notification';
import {
    CheckBoxModule,
    MaskedTextBoxModule,
    NumericTextBoxModule, RadioButtonModule,
    SwitchModule,
    TextBoxModule,
    InputsModule
} from "@progress/kendo-angular-inputs";
import {ButtonModule, ChipModule, DropDownButtonModule} from "@progress/kendo-angular-buttons";
import {ListaDeMensagensComponent} from "../lista-de-mensagens/lista-de-mensagens.component";
import {ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import {MarketingComponent} from "../marketing/marketing.component";
import {NovaCampanhaComponent} from "../nova-campanha/nova-campanha.component";
import {TelaEnviarMensagensComponent} from "../tela-enviar-mensagens/tela-enviar-mensagens.component";
import {ProgressBarModule} from "@progress/kendo-angular-progressbar";
import {CampanhaDetalhesComponent} from "../campanha-detalhes/campanha-detalhes.component";
import { ChartsModule } from '@progress/kendo-angular-charts';
import {MensagemBloqueioComponent} from "../mensagem-bloqueio/mensagem-bloqueio.component";
import {AfterValueChangedDirective} from "../directives/after-value-changed.directive";
import {TooltipModule, TooltipsModule, PopoverModule} from "@progress/kendo-angular-tooltip";
import {DialogRemoverContatoComponent} from "../dialog-remover-contato/dialog-remover-contato.component";
import {AtivacaoAssinaturaComponent} from "../ativacao-assinatura/ativacao-assinatura.component";
import {CadCartaocreditoComponent} from "./cad-cartaocredito/cad-cartaocredito.component";
import {SaldoCartaoComponent} from "../saldo-cartao/saldo-cartao.component";
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../../environments/environment";
import {MeuPlanoComponent} from "../meuplano/meu-plano.component";
import {PagamentoCartaoComponent} from "./pagamento-cartao/pagamento-cartao.component";
import {FaturaDetalhesComponent} from "../fatura-detalhes/fatura-detalhes.component";
import { PagamentoCartaoDescontoComponent } from './pagamento-cartao-desconto/pagamento-cartao-desconto.component';
import {IconeAcaoComponent} from "../componentes/icone-acao/icone-acao.component";
import {VerContatoIframeComponent} from "../ver-contato-iframe/ver-contato-iframe.component";
import {TelaNovoPedidoComponent} from "../pedidos/tela-novo-pedido/tela-novo-pedido.component";
import {TelaAdicionarProdutoComponent} from "../pedidos/tela-adicionar-produto/tela-adicionar-produto.component";
import {TelaEnderecoEntregaComponent} from "../pedidos/tela-endereco-entrega/tela-endereco-entrega.component";
import {TelaAcompanharPedidosComponent} from "../pedidos/tela-acompanhar-pedidos/tela-acompanhar-pedidos.component";
import {InfoFidelidadeComponent} from "../componentes/info-fidelidade/info-fidelidade.component";
import {TelaEscolherPagamentoComponent} from "../pedidos/tela-escolher-pagamento/tela-escolher-pagamento.component";
import {ListaDePedidosComponent} from "../pedidos/lista-de-pedidos/lista-de-pedidos.component";
import {AcoesModulosComponent} from "../componentes/acoes-modulos/acoes-modulos.component";
import {InfoPedidosComponent} from "../componentes/info-pedidos/info-pedidos.component";
import {TelaConfirmarPedidoComponent} from "../pedidos/tela-confirmar-pedido/tela-confirmar-pedido.component";
import {HistoricoModulosComponent} from "../componentes/historico-modulos/historico-modulos.component";
import {TelaCriarEnderecoComponent} from "../pedidos/tela-criar-endereco/tela-criar-endereco.component";
import {TelaMudarStatusComponent} from "../tela-mudar-status/tela-mudar-status.component";
import {ExibirNovoContatoComponent} from "../componentes/exibir-novo-contato/exibir-novo-contato.component";
import {ConstantsService} from "./ConstantsService";
import {BotoesHomeComponent} from "../componentes/botoes-home/botoes-home.component";
import {ApplicationPipesModule} from "../pipes/application-pipes.module";
import {CompartilhadoModule} from "../compartilhado/compartilhado.module";
import {TelaCadastroProdutosComponent} from "../tela-cadastro-produtos/tela-cadastro-produtos.component";
import {TelaPedidosComponent} from "../pedidos/tela-pedidos/tela-pedidos.component";
import {TelaPedidosPagosComponent} from "../pedidos/tela-pedidos-pagos/tela-pedidos-pagos.component";
import {TelaAdicionarBrindeComponent} from "../pedidos/tela-adicionar-brinde/tela-adicionar-brinde.component";
import { FormNotificacaoComponent } from './form-notificacao/form-notificacao.component';
import {CurrencyMaskModule} from "ng2-currency-mask";
import { TelaConfigEmpresaComponent } from './tela-config-empresa/tela-config-empresa.component';
import { WizardConfigEmpresaComponent } from './wizard-config-empresa/wizard-config-empresa.component';
import { CadProdutoSimplesComponent } from './cad-produto-simples/cad-produto-simples.component';
import {HttpClient} from "@angular/common/http";
import {GridHorariosEditService} from "../superadmin/cad-empresas/grid-horarios-edit-service";
import {SiteCampoAdicionalComponent} from "../pedidos/site-campo-adicional/site-campo-adicional.component";
import {TelaPedidosCanceladosComponent} from "../pedidos/tela-pedidos-cancelados/tela-pedidos-cancelados.component";
import { AssinaturaPagamentoComponent } from './assinatura-pagamento/assinatura-pagamento.component';
import {TelaResgateCashbackComponent} from "../pedidos/tela-resgate-cashback/tela-resgate-cashback.component";
import {MonitoradorPedidos} from "./MonitoradorPedidos";
import { FinanceiroComponent } from './financeiro/financeiro.component';
import {NovaFormaDePagamentoComponent} from "../formas-de-pagamento/nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {CadastroFormasDePagamentoComponent} from "../formas-de-pagamento/cadastro-formas-de-pagamento/cadastro-formas-de-pagamento.component";
import {WhatsappInvalidoComponent} from "../whatsapp-invalido/whatsapp-invalido.component";
import {NovaMesaComponent} from "../mesa/nova-mesa/nova-mesa.component";
import {CadastroMesaComponent} from "../mesa/cadastro-mesa/cadastro-mesa.component";
import {FileSelectModule, UploadModule} from "@progress/kendo-angular-upload";
import {TestadorchatBotComponent} from "../chatbot/testadorchat-bot/testadorchat-bot.component";
import {ChatModule} from "@progress/kendo-angular-conversational-ui";
import {AdicionaisCustomizadosComponent} from "../pedidos/adicionais-customizados/adicionais-customizados.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {ConversasChatbotComponent} from "../chatbot/conversas-chatbot/conversas-chatbot.component";
import {ConfigMensagensComponent} from "../chatbot/config-mensagens/config-mensagens.component";
import {FormConfigMensagemBotComponent} from "../chatbot/form-config-mensagem-bot/form-config-mensagem-bot.component";
import {PagerModule} from "@progress/kendo-angular-pager";
import {CadAdicionaisPedidoComponent} from "../cad-adicionais-pedido/cad-adicionais-pedido.component";
import {TelaFecharComandaComponent} from "../pedidos/tela-fechar-comanda/tela-fechar-comanda.component";
import {TelaPedidosMesasComponent} from "../pedidos/mesa/tela-pedidos-mesas/tela-pedidos-mesas.component";
import {TelaCadastroBannerComponent} from "../banner/tela-cadastro-banner/tela-cadastro-banner.component";
import {NovoBannerComponent} from "../banner/novo-banner/novo-banner.component";
import {GerenciadorEnvioCampanhaComponent} from "../marketing/gerenciador-envio-campanha/gerenciador-envio-campanha.component";
import {PedidosMesaComponent} from "../pedidos/mesa/pedidos-mesa/pedidos-mesa.component";
import {TelaMesaEscolhidaComponent} from "../pedidos/tela-mesa-escolhida/tela-mesa-escolhida.component";
import {TelaNovoPedidoMesaComponent} from "../pedidos/mesa/tela-novo-pedido-mesa/tela-novo-pedido-mesa.component";
import {TelaTrocarMesaComponent} from "../pedidos/tela-trocar-mesa/tela-trocar-mesa.component";
import {FiltroObjetosNomePipe} from "../pipes/filtro-objetos-nome.pipe";
import { SaldoMensagensComponent } from './saldo-mensagens/saldo-mensagens.component';
import {TelaFinalizarPedidoMesaComponent} from "../tela-finalizar-pedido-mesa/tela-finalizar-pedido-mesa.component";
import { ModalPedidoAceitarComponent } from './modal-pedido-aceitar/modal-pedido-aceitar.component';
import { ModalVincularGarcomComponent } from './modal-vincular-garcom/modal-vincular-garcom.component';
import {TelaExpandidaComponent} from "../pedidos/TelaExpandida";
import { AssinaturaTrocarcartaoComponent } from './assinatura-trocarcartao/assinatura-trocarcartao.component';
import { ModalTrocarcartaoComponent } from './assinatura-trocarcartao/modal-trocarcartao/modal-trocarcartao.component';
import {GarconsEmpresaComponent} from "../pedidos/mesa/garcons-empresa/garcons-empresa.component";
import {TelaTaxasDeEntregasCalculadasComponent} from "../tela-areas-de-entrega/tela-taxas-de-entregas-calculadas/tela-taxas-de-entregas-calculadas.component";
import {LabelModule} from "@progress/kendo-angular-label";
import { VitrinesComponent } from './vitrines/vitrines.component';
import { CadastroVitrineComponent } from './cad-vitrine/cadastro-vitrine.component';
import { TelaAssociarVitrineProdutosComponent } from './tela-associar-vitrine-produtos/tela-associar-vitrine-produtos.component';
import { TelaConfiguracaoTemasComponent } from './temas/tela-configuracao-temas/tela-configuracao-temas.component';
import { ModalFinalizarPedidosComponent } from './modal-finalizar-pedidos/modal-finalizar-pedidos.component';
import {GridPagamentoComponent} from "../pedidos/grid-pagamento/grid-pagamento.component";
import {ConfigWhatsappComponent} from "../cloud-whatsapp/config-whatsapp/config-whatsapp.component";
import {TelaListaAvaliacoesComponent} from "../avaliacoes/tela-lista-avaliacoes/tela-lista-avaliacoes.component";
import { TemplatesDeMensagemComponent } from './templates-de-mensagem/templates-de-mensagem.component';
import {GridEntregadorComponent} from "./grid-entregador/grid-entregador.component";
import { CadEntregadorComponent } from './cad-entregador/cad-entregador.component';
import {ModuloEntregadoresComponent} from "./modulo-entregadores/modulo-entregadores.component";
import {LayoutModule, PanelBarModule} from "@progress/kendo-angular-layout";
import {AnalyticsComponent} from "../analytics/analytics.component";
import {
  UploadContatosCampanhaComponent
} from "../marketing/upload-contatos-campanha/upload-contatos-campanha.component";
import {ListViewModule} from "@progress/kendo-angular-listview";
import {NotificacoesPromokitComponent} from "../chatbot/notificacoes-promokit/notificacoes-promokit.component";
import {
  TelaConfigAtendenteVirtualComponent
} from "../chatbot/tela-config-atendente-virtual/tela-config-atendente-virtual.component";
import {
  SeletorCodigoPaisServerComponent
} from "../componentes/seletor-codigo-pais/seletor-codigo-pais/seletor-codigo-pais-server.component";
import {CadQrcodeComponent} from "../qrcode/cad-qrcode/cad-qrcode.component";
import {ListagemQrcodeComponent} from "../qrcode/listagem-qrcode/listagem-qrcode.component";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ModalOpendeliveryEventsComponent } from './modal-opendelivery-events/modal-opendelivery-events.component';
import { EditorTemplateNotificacoesComponent } from './noticacoes/visual/editor-template-notificacoes/editor-template-notificacoes.component';
import { PopupModule } from "@progress/kendo-angular-popup";
import { ModalSolicitarCancelamentoDeliveryComponent } from './modal-solicitar-cancelamento-delivery/modal-solicitar-cancelamento-delivery.component';
import { TelaChamadasIaComponent } from '../chatbot/tela-chamadas-ia/tela-chamadas-ia.component';
import { ListaIntencoesComponent } from '../ia/intencoes/lista-intencoes/lista-intencoes.component';
import { MeuPdvComponent } from './meu-pdv/meu-pdv.component';
import {GestaoDeCaixaComponent} from "../pdv/gestao-de-caixa/gestao-de-caixa.component";
import {HistoricoDeCaixaComponent} from "../pdv/historico-de-caixa/historico-de-caixa.component";
import {GridTransacoesCaixaComponent} from "../pdv/grid-transacoes-caixa/grid-transacoes-caixa.component";
import {HistoricoSangriasComponent} from "../pdv/historico-sangrias/historico-sangrias.component";
import { ModalUberdirectdetalhesComponent } from './modal-uberdirectdetalhes/modal-uberdirectdetalhes.component';
import { ModalDeliverydetalhesComponent } from './modal-deliverydetalhes/modal-deliverydetalhes.component';
import {MatCardModule} from "@angular/material/card";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {TelaCrmLeadsComponent} from "../tela-crm-leads/tela-crm-leads.component";

import {
  AtivarFormasDePagamentoComponent
} from "../formas-de-pagamento/ativar-formas-de-pagamento/ativar-formas-de-pagamento.component";
import {
  GridFormasDePagamentoComponent
} from "../formas-de-pagamento/grid-formas-de-pagamento/grid-formas-de-pagamento.component";
import { ModalFoodydeliveryDetathesComponent } from './modal-foodydelivery-detathes/modal-foodydelivery-detathes.component';
import { AssinaturaBlackfridayComponent } from './assinatura-blackfriday/assinatura-blackfriday.component';
import {
  TelaPedidosErrosImportarComponent
} from "../pedidos/tela-pedidos-erros-importar/tela-pedidos-erros-importar.component";
import {
  PayloadJsonViewComponent
} from "../pedidos/tela-pedidos-erros-importar/payload-json-view/payload-json-view.component";
import {JsonDisplayComponent} from "../pedidos/tela-pedidos-erros-importar/payload-json-view/json-display";
import {ControleMenuWhatsappComponent} from "./controle-menu-whatsapp/controle-menu-whatsapp.component";
import {ControleTextoMensagemComponent} from "../controle-texto-mensagem/controle-texto-mensagem.component";
import { ControleEditorMensagemComponent } from '../controle-editor-mensagem/controle-editor-mensagem.component';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {
  ControlePreviaMensagemWhatsappComponent
} from "../controle-previa-mensagem-whatsapp/controle-previa-mensagem-whatsapp.component";
import {MatListModule} from "@angular/material/list";
import { AtivarHubPagarmeComponent } from './ativar-hub-pagarme/ativar-hub-pagarme.component';
import {NgbRatingModule} from "@ng-bootstrap/ng-bootstrap";
import { RelatorioPontuacaoComponent } from './relatorio-pontuacao/relatorio-pontuacao.component';
import {PedidosMesasComErroComponent} from "../pedidos/mesa/pedidos-mesas-com-erro/pedidos-mesas-com-erro.component";
import * as Sentry from "@sentry/angular-ivy";
import {Router} from "@angular/router";
import {AtivarPagbankConnectComponent} from "./ativar-pagbank-connect/ativar-pagbank-connect.component";
import { ModalRequestParceiroComponent } from './modal-request-parceiro/modal-request-parceiro.component';
import {FaturaAbertaPagarComponent} from "../home/fatura-aberta-pagar/fatura-aberta-pagar.component";
import {
  FreeTrialConfirmationModalComponent
} from "../free-trial-confirmation-modal/free-trial-confirmation-modal.component";

import { ModalIfoodNotificacoesComponent } from './modal-ifood-notificacoes/modal-ifood-notificacoes.component';
import { AlertaPedidosComponent } from './alerta-pedidos/alerta-pedidos.component';
import { ModalCotacaoDeliveryComponent } from './modal-cotacao-delivery/modal-cotacao-delivery.component';
import {
  TelaCriarTemaPersonalizadoComponent
} from "../temas/tela-criar-tema-personalizado/tela-criar-tema-personalizado.component";
import { DashboardMiaComponent } from '../chatbot/dashboard-mia/dashboard-mia.component';
import {SelecionarInsumoComponent} from "../tela-gerenciar-estoque/selecionar-insumo/selecionar-insumo.component";
import {TelaGerenciarEstoqueComponent} from "../tela-gerenciar-estoque/tela-gerenciar-estoque.component";
import {CadInsumoComponent} from "../tela-gerenciar-estoque/cad-insumo/cad-insumo.component";
import {CadGrupoInsumoComponent} from "../tela-gerenciar-estoque/cad-grupo-insumo/cad-grupo-insumo.component";
import {
  TelaAssociarInsumoComponent
} from "../tela-gerenciar-estoque/tela-associar-insumo/tela-associar-insumo.component";
import {
  TelaHistoricoMovimentacoesComponent
} from "../tela-gerenciar-estoque/tela-historico-movimentacoes/tela-historico-movimentacoes.component";
import {ModalMovimentacoesEstoqueComponent} from "../tela-gerenciar-estoque/modal-movimentacoes-estoque";
import {ListagemInsumosComponent} from "../tela-gerenciar-estoque/listagem-insumos/listagem-insumos.component";
import {DeviceDetectorService} from "ngx-device-detector";
import { EditorFluxoComponent } from '../chatbot/typebot/editor-fluxo/editor-fluxo.component';
import {
  ListagemGrupoInsumoComponent
} from "../tela-gerenciar-estoque/listagem-grupo-insumo/listagem-grupo-insumo.component";
import {NgImageSliderModule} from "ng-image-slider";
import {
  FormMovimentacaoInsumoComponent
} from "../tela-gerenciar-estoque/form-movimentacao-insumo/form-movimentacao-insumo.component";
import {ModalGerarMovimentacoesEstoqueComponent} from "../tela-gerenciar-estoque/modal-gerar-movimentacoes-estoque";
import {HistoricoInsumoComponent} from "../tela-gerenciar-estoque/historico-insumo/historico-insumo.component";
import { TelaConfigTypebotComponent } from '../chatbot/tela-config-typebot/tela-config-typebot.component';
import {ClassificacaoContatoComponent} from "../components/classificacao-contato/classificacao-contato.component";
import {KanbanContatosComponent} from "./kanban-contatos/kanban-contatos.component";
import { TelaIfoodEventsComponent } from './tela-ifood-events/tela-ifood-events.component';
import { ListaComandasComponent } from '../pedidos/mesa/lista-comandas/lista-comandas.component';
import {
  CadastroCartaoClienteComponent
} from "../cartao-cliente/cadastro-cartao-cliente/cadastro-cartao-cliente.component";
import {NovoCartaoClienteComponent} from "../cartao-cliente/novo-cartao-cliente/novo-cartao-cliente.component";
import {CadastroEmpresaTunaComponent} from "./tuna/cadastro-empresa/cadastro-empresa-tuna.component";
import { MeucardapioPayPagamentoComponent } from '../formas-de-pagamento/meucardapio-pay-pagamento/meucardapio-pay-pagamento.component';

import { SelecionarCartaoClienteComponent } from '../cartao-cliente/selecionar-cartao-cliente/selecionar-cartao-cliente.component';
import {
  PagarmehubAcoesComponent
} from "../formas-de-pagamento/ativar-formas-de-pagamento/pagarmehub-acoes/pagarmehub-acoes.component";
import {ConfigColunasComponent} from "../pedidos/config-colunas/config-colunas.component";
import {ModalEditarItempedidoMesaComponent} from "./modal-editar-mesa/modal-editar-itempedido-mesa.component";

@NgModule({
    declarations: [
        FidelidadeComponent,
        AfterValueChangedDirective,
        NomeConmpletoValidator,
        NomeValidoValidator,
        HomeComponent,
        MenuFidelidadeComponent,
        NotificacoesComponent,
        NovoCartaoComponent,
        NovoContatoComponent,
        CardContatoComponent,
        ContatosComponent, VerContatoComponent, FiltroAvancadoComponent, MensagemBloqueioComponent,
        VerContatoIframeComponent, PagarmehubAcoesComponent,
        IconeAcaoComponent,
        AdicionarPontosComponent,
        TrocarPontosComponent,
        InserirPontosComponent,
        BuscarContatoComponent,
        FirstWordPipe,
        SeloComponent, SelosComponent,
        ListaDeMensagensComponent,
        MarketingComponent,
        NovaCampanhaComponent,
        TelaEnviarMensagensComponent,
        CampanhaDetalhesComponent,
        SaldoCartaoComponent, MeuPlanoComponent,
        DialogRemoverContatoComponent, AtivacaoAssinaturaComponent, CadCartaocreditoComponent,
        FaturaDetalhesComponent, PagamentoCartaoComponent, PagamentoCartaoDescontoComponent,
        TelaNovoPedidoComponent, TelaPedidosComponent, TelaPedidosPagosComponent,
        TelaPedidosCanceladosComponent,
        TelaAdicionarProdutoComponent,
        TelaEnderecoEntregaComponent,
        TelaAcompanharPedidosComponent,
        TelaEnderecoEntregaComponent,
        InfoFidelidadeComponent,
        AcoesModulosComponent,
        InfoPedidosComponent,
        TelaEscolherPagamentoComponent,
        TelaConfirmarPedidoComponent,
        ListaDePedidosComponent,
        InfoFidelidadeComponent,
        HistoricoModulosComponent,
        InfoFidelidadeComponent,
        TelaCriarEnderecoComponent,
        TelaMudarStatusComponent,
        TelaFinalizarPedidoMesaComponent,
        ExibirNovoContatoComponent,
        BotoesHomeComponent,
        TelaCadastroProdutosComponent,
        TelaAdicionarBrindeComponent,
        TelaResgateCashbackComponent,
        FormNotificacaoComponent,
        TelaConfigEmpresaComponent,
        WizardConfigEmpresaComponent,
        CadProdutoSimplesComponent,
        SiteCampoAdicionalComponent,
        AssinaturaPagamentoComponent,
        FinanceiroComponent,
        NovaFormaDePagamentoComponent,
        CadastroFormasDePagamentoComponent, AtivarFormasDePagamentoComponent, GridFormasDePagamentoComponent,
        WhatsappInvalidoComponent,
        NovaMesaComponent,
        CadastroMesaComponent,
        TestadorchatBotComponent,
        ConversasChatbotComponent,
        AdicionaisCustomizadosComponent,
        ConfigMensagensComponent,
        FormConfigMensagemBotComponent,
        CadAdicionaisPedidoComponent,
        TelaFecharComandaComponent,
        TelaPedidosMesasComponent, PedidosMesasComErroComponent,
        TelaPedidosErrosImportarComponent, PayloadJsonViewComponent, JsonDisplayComponent,
        TelaCadastroBannerComponent,
        NovoBannerComponent,
        PedidosMesaComponent,
        NovoBannerComponent,
        GerenciadorEnvioCampanhaComponent,
        TelaMesaEscolhidaComponent,
        TelaNovoPedidoMesaComponent,
        TelaTrocarMesaComponent,
        FiltroObjetosNomePipe,
        SaldoMensagensComponent,
        ModalPedidoAceitarComponent,
        ModalVincularGarcomComponent,
        TelaExpandidaComponent,
        AssinaturaTrocarcartaoComponent,
        ModalTrocarcartaoComponent,
        GarconsEmpresaComponent,
        TelaTaxasDeEntregasCalculadasComponent,
        VitrinesComponent, CadastroVitrineComponent, TelaAssociarVitrineProdutosComponent, TelaConfiguracaoTemasComponent,
        ModalFinalizarPedidosComponent, GridPagamentoComponent, ConfigWhatsappComponent,
        TelaListaAvaliacoesComponent,
        TemplatesDeMensagemComponent,
        AnalyticsComponent,
        GridEntregadorComponent,
        CadEntregadorComponent,
        ModuloEntregadoresComponent,
        NotificacoesPromokitComponent,
        TelaConfigAtendenteVirtualComponent,
        UploadContatosCampanhaComponent,
        SeletorCodigoPaisServerComponent,
        CadQrcodeComponent,
        ListagemQrcodeComponent,
        ConfirmDialogComponent,
        EditorTemplateNotificacoesComponent,
        ModalOpendeliveryEventsComponent,
        ModalSolicitarCancelamentoDeliveryComponent,
        TelaChamadasIaComponent,
        ListaIntencoesComponent,
        MeuPdvComponent,
        GestaoDeCaixaComponent,
        HistoricoDeCaixaComponent,
        GridTransacoesCaixaComponent,
        HistoricoSangriasComponent,
        ModalUberdirectdetalhesComponent,
        ModalDeliverydetalhesComponent, ModalFoodydeliveryDetathesComponent,
        TelaCrmLeadsComponent,
        AssinaturaBlackfridayComponent,
        ControleMenuWhatsappComponent,
        ControleTextoMensagemComponent,
        ControlePreviaMensagemWhatsappComponent,
      AtivarHubPagarmeComponent,
      RelatorioPontuacaoComponent,
      AtivarPagbankConnectComponent,
      ModalRequestParceiroComponent,
      FaturaAbertaPagarComponent,
      ModalIfoodNotificacoesComponent,
      AlertaPedidosComponent, ModalCotacaoDeliveryComponent, ModalEditarItempedidoMesaComponent,
      FreeTrialConfirmationModalComponent,
      TelaCriarTemaPersonalizadoComponent,
      DashboardMiaComponent,
      TelaGerenciarEstoqueComponent,
      CadInsumoComponent, CadGrupoInsumoComponent, TelaAssociarInsumoComponent,
      TelaHistoricoMovimentacoesComponent, ModalMovimentacoesEstoqueComponent, ModalGerarMovimentacoesEstoqueComponent,
      SelecionarInsumoComponent, ListagemInsumosComponent, ListagemGrupoInsumoComponent,
      EditorFluxoComponent, FormMovimentacaoInsumoComponent, HistoricoInsumoComponent,
      TelaConfigTypebotComponent,
      ClassificacaoContatoComponent,
      KanbanContatosComponent,
      TelaIfoodEventsComponent,
      ListaComandasComponent,
      CadastroCartaoClienteComponent,
      NovoCartaoClienteComponent, MeucardapioPayPagamentoComponent, CadastroEmpresaTunaComponent,
      SelecionarCartaoClienteComponent, ConfigColunasComponent
    ],
  imports: [
    CommonModule,
    ApplicationPipesModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    IntlModule,
    TooltipsModule, PopoverModule,
    CompartilhadoModule,
    FidelidadeRoutingModule,
    NotificationModule,
    InlineSVGModule, ChartsModule,
    NgxMaskModule, DropDownsModule, DateInputsModule,
    MatCardModule,
    DragDropModule,
    SwitchModule, WindowModule, ButtonModule, DialogModule, ChatModule,
    GridModule, ProgressBarModule, NumericTextBoxModule, MaskedTextBoxModule, TooltipModule, ExcelModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }), TextBoxModule, CheckBoxModule, CurrencyMaskModule, RadioButtonModule, DropDownButtonModule,
    FileSelectModule, PagerModule, UploadModule,
    ListViewModule, InputsModule, LabelModule, PanelBarModule, ChipModule, PopupModule, MatBottomSheetModule, MatListModule, NgbRatingModule, NgImageSliderModule
  ],
    providers: [
        CurrencyPipe, ConstantsService, MonitoradorPedidos, CanDeactivateNovoPedido,
        { provide: IntlService, useExisting: CldrIntlService },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          })
        },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
        DatePipe,
        {
            deps: [HttpClient],
            provide: GridHorariosEditService,
            useFactory: (jsonp: HttpClient) => () => new GridHorariosEditService(jsonp)
        },
        PaletaCoresService
    ],
    exports: [
        FirstWordPipe,
        AfterValueChangedDirective,
        NomeConmpletoValidator,
        NomeValidoValidator,
        IconeAcaoComponent
    ]
})
export class FidelidadeModule {
}
