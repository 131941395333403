import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {CartaoCliente} from "../cartao-cliente/cadastro-cartao-cliente/cadastro-cartao-cliente.component";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartaoClienteService extends ServerService {
  constructor(protected http: HttpClient) {
    super(http);  
  }

  obtenhaCartoes() {
    return this.obtenha('/cartao-cliente', {});
  }

  obtenhaCartao(id: number) {
    return this.obtenha(`/cartao-cliente/${id}`, {});
  }

  insiraCartao(cartao: CartaoCliente) {
    return this.facaPost('/cartao-cliente', cartao);
  }

  atualizeCartao(cartao: CartaoCliente) {
    return this.facaPut(`/cartao-cliente/${cartao.id}`, cartao);
  }

  removaCartao(id: number) {
    return super.remova(`/cartao-cliente/${id}`, {id: id});
  }

  crieCartao(cartao: any): Promise<any> {
    // Garantir que apenas os campos necessários sejam enviados
    const dadosParaEnvio: any = {
      codigo: cartao.codigo,
      ativo: cartao.ativo
    };

    return this.http.post(`/cartao-cliente`, dadosParaEnvio)
      .toPromise();
  }
}
