import {AfterViewInit, Component, OnInit, Renderer2, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {MonitoradorPedidos} from "../../fidelidade/MonitoradorPedidos";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {Location} from "@angular/common";
declare var $;
@Component({
  selector: 'app-menu-fidelidade',
  templateUrl: './menu-fidelidade.component.html',
  styleUrls: ['./menu-fidelidade.component.css']
})
export class MenuFidelidadeComponent implements OnInit, AfterViewInit, OnDestroy {
  url = 'index';
  usuario: any;
  empresa: any = {};
  exibirFuncoesPedidos: boolean;
  exibirProdutos: boolean;
  exibirFuncoesFidelidade: boolean;
  exibirFuncoesChatbot: boolean;
  possuiModuloGarcom: boolean;
  possuiModuloApp: boolean;
  somenteCardapio: boolean;
  carregou = false;
  wizard: any;
  botAtivo: boolean;
  exibirChatBot: boolean;
  ehGarcom: boolean;
  resumoMesas: any = {}
  resumoPedidos: any = {totalErroParceiro : 0, totalPendenteOnline: 0, totalContestados: 0, totalErrosImportar: 0,
    totalErroParceiroMesa: 0};
  resumoEstoque: any  = { totalAlertas: 0}
  possuiModuloInstagramBot = false;
  exibirEntregadores: boolean;
  possuiModuloPDV: boolean;
  exibirEstoque: boolean;

  private clickHandler: Function;

  constructor( private router: Router, private renderer: Renderer2, private route: ActivatedRoute,
               private location: Location,
               private autorizacaoService: AutorizacaoService, private  constantsService: ConstantsService,
               public monitoradorPedidos: MonitoradorPedidos ) {

    if(this.location.path()){
      const segments = this.location.path().split('/');
      this.url =   segments[segments.length - 1];
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = route.snapshot.firstChild.routeConfig.path;
      }
    });
  }

  ngAfterViewInit(): void {
    const leftSide = document.querySelector('.left-side-menu');
    if (leftSide) {
      this.clickHandler = this.renderer.listen(leftSide, 'click', (event: Event) => {
        const target = event.target as HTMLElement;
        let currentElement = target;

        // Verifica o elemento atual e seus pais até encontrar um com data-toggle="collapse"
        while (currentElement && currentElement !== leftSide) {
          if (currentElement.getAttribute('data-toggle') === 'collapse') {
            if (document.body.classList.contains('enlarged')) {
              event.preventDefault();
              event.stopPropagation();
              return false;
            }
            break;
          }
          currentElement = currentElement.parentElement;
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.clickHandler) {
      this.clickHandler();
    }
  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser();
    this.ehGarcom = this.usuario && this.usuario.garcom;
    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if(empresa && empresa.id){
        this.empresa = empresa;
        this.carregou = true;
        this.wizard = empresa.exibirWizard;

        if(this.wizard)
          return this.naveguePara('wizard');

        if(this.somenteCardapio && location.pathname === '/admin/index'  )
          this.naveguePara('produtos');
      }
      $('.slimscroll-menu').slimscroll({
        height: 'auto',
        position: 'right',
        size: "8px",
        color: '#9ea5ab',
        wheelStep: 5,
        touchScrollStep: 20
      });

    })
    this.constantsService.moduloPedido$.subscribe( data =>   this.exibirFuncoesPedidos = data && !this.ehGarcom);
    this.constantsService.exibirProdutos$.subscribe( data =>   {
      this.exibirProdutos = data && !this.ehGarcom

      if(!RotaGuardClient.cadastrarProdutos(this.usuario))
        this.exibirProdutos = false;

    });
    this.constantsService.moduloEntregadores$.subscribe(data => this.exibirEntregadores = data && !this.ehGarcom);
    this.constantsService.moduloPdv$.subscribe(data => this.possuiModuloPDV = data && !this.ehGarcom);
    this.constantsService.moduloEstoque$.subscribe(data =>
      this.exibirEstoque = data && !this.ehGarcom && !this.empresa.estoqueVinculadoProduto);

    this.constantsService.moduloFidelidade$.subscribe(data => this.exibirFuncoesFidelidade = data && !this.ehGarcom);
    this.constantsService.moduloChatbot$.subscribe( data => this.exibirFuncoesChatbot = data && !this.ehGarcom);
    this.constantsService.moduloGarcom$.subscribe( data => this.possuiModuloGarcom = data && !this.ehGarcom);

    this.constantsService.moduloApp$.subscribe( data => this.possuiModuloApp = data && !this.ehGarcom);
    this.constantsService.moduloChatbotInstagram$.subscribe( (data) => {
      this.possuiModuloInstagramBot = data && !this.ehGarcom
    });
    this.constantsService.somenteCardapio$.subscribe(data => {
      this.somenteCardapio = data;
    });
    this.monitoradorPedidos.resumoMesas$.subscribe( resumo =>  {
       this.resumoMesas = resumo || {}
    })
    this.monitoradorPedidos.resumoPedidos$.subscribe( resumo =>  {
       if(resumo)  this.resumoPedidos = resumo ;
    })

    this.monitoradorPedidos.mesasComErros$.subscribe( resumo =>  {
       if(resumo && resumo.totalErroParceiroMesa)
         this.resumoPedidos.totalErroParceiroMesa = resumo.totalErroParceiroMesa ;
    })

    this.monitoradorPedidos.resumoEstoque$.subscribe( resumo =>  {
      if(resumo && resumo.totalAlertas)
        this.resumoEstoque.totalAlertas = resumo.totalAlertas ;
    })

  }

  naveguePara( url: string ) {
    this.router.navigateByUrl( '/admin/' + url).then( (resposta: boolean) => {
      if( !resposta ) {
        return;
      }

      if(document.body.classList.contains('sidebar-enable'))
        this.renderer.removeClass(document.body, 'sidebar-enable')
      else
        this.renderer.addClass(document.body, 'sidebar-enable')

      this.url = url;
    });
  }

  visualizarLoja() {
     return RotaGuardClient.gerenciaLoja(this.usuario)
  }

  visualizarCaixa() {
    return RotaGuardClient.operarCaixa(this.usuario)
  }

  visualizarNotificacoes(){
    return RotaGuardClient.cadastrarNotificacoes(this.usuario)
  }

  visualizarContatos(){
    return RotaGuardClient.cadastrarContatos(this.usuario)
  }

  visualizaRelatorios(){
    return RotaGuardClient.visualizaRelatorios(this.usuario)
  }

  temIntegracaoParceiro() {
    return this.empresa.integracaoDelivery != null;
  }

  vendeOnline(){
    let formaPagametento = this.empresa.formasDePagamento.find((item: any) => item.online);

    return formaPagametento != null;

  }

  integracaoIfood(){
    return this.empresa && this.empresa.integracoesIfood.length
  }

  toggleSubmenu(event: any, targetId: string) {


  }
}
