<div class="paper" [hidden]="carregando">

  <div class="titulo" *ngIf="!resumido">
    <img class="imagem_empresa" src="https://promokit.com.br/images/empresa/{{pedido.empresa.logo}}" style="    width: 65px;"/>
    <h3 class="empresa mt-3"><b>{{pedido.empresa?.nome}}</b></h3>
    <h4>comprovante pedido</h4>

    <div class="col-12" *ngIf="pedido.ifood?.localizadorPedido">
      <label style="margin-bottom: 0;"><b>IFOOD</b></label>
      <div class="caixa-centralizada">
        <p>Pedido: #{{pedido.ifood.displayId}}</p>
        <p>Localizador Pedido: {{pedido.ifood.localizadorPedido}}</p>
        <p> {{pedido.ifood.entregadorPor}}</p>
        <p *ngIf="pedido.ifood?.codigoRetirada">
          <br> Codigo coleta: {{pedido.ifood.codigoRetirada}}</p>

      </div>

    </div>

  </div>

  <div class="dados mt-3 ">
    <div class="row">
      <div class="col-4">
        <label>Codigo</label>
        <p>   #{{pedido.codigo}} </p>
      </div>
      <div class="col-8">
        <label>Data </label>
        <p>{{pedido.horarioDescricao}}</p>
      </div>
      <ng-container *ngIf="!resumido">
        <div class="col-7" *ngIf="pedido.cliente?.nome">
          <label>Nome Cliente</label>
          <p>{{pedido.cliente.nome}}</p>
        </div>

        <div class="col-5" *ngIf="pedido.cliente?.telefone  && !pedido.empresa.configImpressao.ocultarNumeroCliente ">
          <label>Telefone  </label>
          <p>{{pedido.cliente.telefone  | tel: pedido.cliente.codigoPais}}</p>
        </div>

        <div class="col-12" style="    position: relative;top: -10px;" *ngIf="pedido.cliente?.qtdePedidos">
          <label> Quantidade de pedidos:  <b>{{pedido.cliente.qtdePedidos}}</b> </label>
        </div>

        <div class="col-12" *ngIf="!pedido.retirar && pedido.formaDeEntrega">
          <label>Endereço de entrega</label>
          <p>{{pedido.enderecoCompleto}}</p>
        </div>
        <div class="col-12" *ngIf="pedido.retirar && pedido.formaDeEntrega">
          <label>{{pedido.mensagemRetirada}}</label>
        </div>

        <div class="col-12" *ngIf="pedido.horarioEntregaAgendada">
          <label>Pedido agendado para {{pedido.horarioEntregaAgendada | date: 'dd/MM/yyyy HH:mm'}}</label>
        </div>



      </ng-container>
    </div>


    <div class="row pt-2 itens">
      <div class="col-12">
        <label class="text-center" style="display: block" >
          <span *ngIf="pedido.mesa"> {{pedido.empresa.identificadorMesa}} {{pedido.mesa.nome}} - </span>
          Detalhes do pedido

        </label>

        <div class="row">
          <div class="font-italic" [class.col-6]="!resumido"
                                   [class.col-9]="resumido"><label><b>Descrição</b></label></div>
          <div class="col-3 font-italic" ><label><b>Qtde.</b></label></div>
          <div class="col-3 font-italic" *ngIf="!resumido"><label><b>Total</b></label></div>
        </div>

        <div class="row item" *ngFor="let item of pedido.itens">
          <div [class.col-7]="!resumido"
               [class.col-9]="resumido">{{item.descricao}}
            <span *ngIf="!pedido.agruparAdicionais && item.adicionaisImprirmir?.length > 0" class="font-12"><br>
              <ng-container *ngFor="let last = last;let adicional of item.adicionaisImprirmir ">
                {{adicional.descricao}}{{last ? '' : ', '}}<br>
              </ng-container>
            </span>
            <span *ngIf="pedido.agruparAdicionais && item.adicionaisAgrupados" class="font-12"><br>
              <ng-container *ngFor="let grupo of item.adicionaisAgrupados">
                &nbsp;<b>{{grupo.nomeAdicional}}</b><br>
                <ng-container *ngFor="let last = last; let adicional of grupo.opcoes">
                  &nbsp;&nbsp;{{adicional.descricao}}{{last ? '' : ', '}}<br>
                </ng-container>
              </ng-container>
            </span>

            <span *ngIf="item.observacao" class="font-11"><br>"{{item.observacao}}"</span>
          </div>
          <div class="col-2  ">{{item.descricaoQtde}}</div>
          <ng-container *ngIf="!resumido">
            <div class="col-3 " *ngIf="!item.troca && !item.valorResgatado">
              <span class="float-right">{{item.total | currency: "BRL"}}</span>
            </div>
            <div class="col-3 float-right" *ngIf="item.troca || item.valorResgatado">

              <span class="float-right"> {{item.valorResgatado  ? (-item.valorResgatado ) : item.total | number}} {{pedido.acumulo}}</span>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="!resumido">
          <div class="row">
            <div class="col-8">
              <label class="float-right ">Total produtos (=)</label>
            </div>

            <div class="col-4">
              <span class="float-right">{{pedido.subvalor | currency: "BRL" }}</span>
            </div>

            <div class="col-8">
              <label class="float-right">Taxa entrega (+)</label>
            </div>

            <div class="col-4">
              <span class="float-right" *ngIf="pedido.taxaEntrega !== -1">{{pedido.taxaEntrega + pedido.descontoTaxaEntrega | currency: "BRL" }}</span>
              <span class="float-right" *ngIf="pedido.taxaEntrega === -1">A Informar</span>
            </div>
          </div>
          <div class="row" *ngIf="pedido.descontoTaxaEntrega">
            <div class="col-8">
              <label class="float-right ">{{pedido.cupom ? "Cupom " + pedido.cupom : "Desconto Taxa"}} (-)</label>
            </div>
            <div class="col-4">
              <span class="float-right"  >{{pedido.descontoTaxaEntrega | currency: "BRL" }}</span>

            </div>
          </div>
          <div class="row" *ngIf="pedido.taxaFormaDePagamento">
            <div class="col-8">
              <label class="float-right ">Taxa forma pagamento (+)</label>
            </div>
            <div class="col-4">
              <span class="float-right"  >{{pedido.taxaFormaDePagamento | currency: "BRL" }}</span>

            </div>
          </div>

          <div class="row" *ngIf="pedido.ifood?.taxaServico">
            <div class="col-8">
              <label class="float-right ">Taxa de serviço Ifood (+)</label>
            </div>
            <div class="col-4">
              <span class="float-right"  >{{pedido.ifood?.taxaServico | currency: "BRL" }}</span>
            </div>
          </div>

          <div class="row" *ngIf="pedido.desconto">
            <div class="col-8">
              <label class="float-right" *ngIf="!pedido.cupom || pedido.descontoTaxaEntrega">
                Descontos   <span *ngIf="pedido.patrocinadoresDesconto">{{pedido.patrocinadoresDesconto}}</span>
                (-)</label>
              <label class="float-right" *ngIf="pedido.cupom && !pedido.descontoTaxaEntrega">Cupom  {{pedido.cupom}}  (-)</label>
            </div>

            <div class="col-4">
              <span class="float-right">{{pedido.desconto | currency: "BRL" }}</span>
            </div>
          </div>

          <div class="row" *ngIf="pedido.cashback">
            <div class="col-8">
              <label class="float-right">Descontos (cashback):</label>
            </div>

            <div class="col-4">
              <span class="float-right">{{pedido.cashback | currency: "BRL" }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <label class="float-right font-15"  >Total (=)</label>
            </div>
            <div class="col-4">
              <p class="float-right font-15">{{pedido.total | currency: "BRL" }}</p>
            </div>
          </div>

            <div class="row "  *ngFor="let pagamento of pedido.pagamentos; let primeiro = first;">
              <ng-container *ngIf="primeiro">
                <ng-container  *ngIf=" pedido.pagamentos.length === 1">
                  <div class="col-5"  >
                    <label class="float-right" >Pagamento em:</label>
                  </div>
                  <div class="col-7"  >
                    {{pagamento.descricao}}
                  </div>
                </ng-container>


                <div class="col-12"   *ngIf=" pedido.pagamentos.length > 1">
                  <label class="float-right" >Formas de pagamento:</label>
                </div>
              </ng-container>

              <ng-container *ngIf="pedido.pagamentos.length > 1">
                <div   class="col-12"  >
                  <span class="float-right">{{pagamento.descricao.substring(0,28)}}
                    <span *ngIf="!pagamento.resgate">({{pagamento.valor | currency: "BRL" }})</span>
                    <span *ngIf="pagamento.resgate">(-{{pagamento.valor}} {{pedido.acumulo}})</span>
                  </span>
                </div>
              </ng-container>


              <div class="col-12"  *ngIf="pagamento.levarTroco" style="padding-top: 0;">
                <div class="row">
                  <div class="col-8">
                    <span class="float-right">Troco para:</span>
                  </div>
                  <div class="col-4">
                    <span class="float-right">    {{pagamento.trocoPara | currency: "BRL"}}</span>
                  </div>

                  <div class="col-8">
                    <span class="float-right">Valor do troco:</span>
                  </div>
                  <div class="col-4">
                    <span class="float-right">    {{pagamento.valorTroco | currency: "BRL"}}</span>
                  </div>
                </div>

              </div>
            </div>

            <div class="row" *ngIf="pedido.foiPagoOnline">
              <div class="col-12">

                <span class="float-right">
                 <b> ***Pedido pago online, nao cobrar do cliente***</b>
                </span>
              </div>
            </div>
            <ng-container *ngIf="!pedido.foiPagoOnline && !pedido.mesa && !pedido.ocultarCobranca">
              <div class="row"   *ngIf="pedido.pago ||  pedido.totalPagar === 0">
                <div class="col-12">
              <span class="float-right"><b>
                ***Não cobrar cliente, pedido pago***
              </b></span>
                </div>
              </div>
              <div class="row linhas"   *ngIf="!pedido.pago && pedido.totalPagar > 0">
                <div class="col-12" >



                  <span class="float-right"><b>
                    ***Cobrar do cliente na entrega***
                  </b></span>
                  <br>
                  <span class="float-right">Valor restante a pagar: <b>{{pedido.totalPagar   | currency: "BRL"}}</b></span>
                </div>
              </div>
            </ng-container>
          </ng-container>

        <div  *ngIf="pedido.observacoes || pedido.ifood?.cpfNota" class="mt-3">
          Observaçao:
          <span *ngIf="pedido.observacoes"><br>"{{pedido.observacoes}}"</span>
          <span  *ngIf="pedido.ifood?.cpfNota" >
            <br>
              Incluir cpf na nota fiscal:
              <b>{{pedido.ifood?.cpfNota}}</b>
          </span>
        </div>

        <p  *ngIf="pedido.pontosGanhos && !resumido" class="mt-3 float-right"> Pontuação fidelidade     {{pedido.pontosGanhosDescricao}} </p>
      </div>
    </div>

  </div>


  <div class="assinatura" *ngIf="pedido.id && !resumido">

    <div class="campo">
      <label class="text-center">Assinatura<Br></label>
    </div>

    <span class="text-muted   " *ngIf="pedido.operador">Operador: <b>{{pedido.operador?.nome}}</b>
      <Br>
    </span>
    <span class="text-muted   ">Horário da impressão: {{pedido.agoraDescricao}}</span>
    <Br>
    <span>Meucardapio.ai - Web (V 1.0)</span>
  </div>

  <div class="assinatura" style="margin-top: 20px;" *ngIf="pedido.id && resumido">
    <span class="text-muted   ">Horário da impressão: {{pedido.agoraDescricao}}</span>
  </div>

  <!--<button (click)="imprima()">versão texto</button>-->
</div>
