import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataBindingDirective} from "@progress/kendo-angular-grid";
import {DialogService} from "@progress/kendo-angular-dialog";
import {EmpresasService} from "../../services/empresas.service";
import {FormZonaDeEntregaComponent} from "../form-zona-de-entrega/form-zona-de-entrega.component";
import {process} from "@progress/kendo-data-query";

@Component({
  selector: 'app-grid-cadastro-regioes',
  templateUrl: './grid-cadastro-regioes.component.html',
  styleUrls: ['./grid-cadastro-regioes.component.scss']
})
export class GridCadastroRegioesComponent implements OnInit, AfterViewInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  regioesEscolhidas: any = []
  gridView: any[];

  @Input() empresa;
  @Input() zonasDeEntrega = [];
  carregando = false;
  modalDeletar: any;
  zonaARemover: any;

  constructor(private dialogService: DialogService, private empresasService: EmpresasService) { }

  ngOnInit(): void {
    this.onFilter('');
  }

  ngAfterViewInit(): void {  }

  public onFilterEvento($evento: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    this.onFilter(inputValue);
  }

  public onFilterEvent($event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    this.onFilter(inputValue);
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.zonasDeEntrega, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'nome',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'taxa',
            operator: 'equals',
            value: inputValue
          }
        ],
      }
    }).data;

    if( this.dataBinding)
      this.dataBinding.skip = 0;
  }


  abraModalZonaEntrega(item: any = null){
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: item ? 'Alterar zona de entrega' : 'Adicione uma nova zona de entrega',
      content: FormZonaDeEntregaComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const tela: FormZonaDeEntregaComponent = windowRef.content.instance;
    //telaNovaFormaDePagamento.novaForma();
    tela.empresa = this.empresa;
    tela.windowRef = windowRef;
    if(item)
      tela.zonaDeEntrega = item;

    windowRef.result.subscribe((result: any) => {
      if(!result) return;

      if(!item) {
        // novo item
        this.zonasDeEntrega.splice(0, 0, result)
        this.onFilter('');
      }
    });
  }

  removerZona({sender, rowIndex, dataItem}){
    dataItem.rowIndex = rowIndex;
    this.modalDeletar = true;
    this.zonaARemover = dataItem;
  }

  editHandler({sender, rowIndex, dataItem}){
    this.abraModalZonaEntrega(dataItem)
  }

  confirmouRemover(){
    this.zonaARemover.removendo = true;
    this.empresasService.removaZonaEntrega(this.empresa, this.zonaARemover).then( () => {
      this.zonasDeEntrega.splice( this.zonaARemover.rowIndex, 1);
      this.fecheModalRemover();
      this.onFilter('');
    });
  }

  fecheModalRemover(){
    this.modalDeletar = false;
    this.zonaARemover = null;
  }

  pausarZona(zona: any) {
    zona.processando = true;

    this.empresasService.desativeZonaEntrega(this.empresa, zona).then( () => {
      zona.desativada = true;
      zona.processando = false;
    });

  }

  ativarZona(zona: any){
    zona.prcessando = true;

    this.empresasService.ativeZonaEntrega(this.empresa, zona).then( () => {
      zona.desativada = false;
      zona.processando = false;
    });

  }
}
