import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {NavigationEnd, Router} from "@angular/router";
import {ModulosService} from "../../services/modulos.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'info-pedidos',
  templateUrl: './info-pedidos.component.html',
  styleUrls: ['./info-pedidos.component.scss']
})
export class InfoPedidosComponent implements OnInit, AfterViewInit {
  @Input() public empresa: any = {};
  _contato: any;

  pedidos: any[] = [];

  possuiModuloPedidos: boolean;

  constructor(private pedidosService: PedidosService, private modulosService: ModulosService,
              private constantsService: ConstantsService) {
    this.constantsService.moduloPedido$.subscribe( data => this.possuiModuloPedidos  = data  )
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  @Input()
  set contato(val: any) {
    this._contato = val;


    if(!val) return;

    this.pedidosService.listePedidosContatoAbertos(0, 10, this._contato).then((resposta) => {
      if(!resposta || !resposta.pedidos) {
        this.pedidos = []

        return;
      }
      this.pedidos = resposta.pedidos;
    }).catch((erro) => {
      console.log('Houve um erro ao carregar os pedidos: ' + erro);
      this.pedidos = []
    })

  }

  get contato() {
    return this._contato;
  }





  /*
  private classifiquePedidos() {
    if(!this.contato) return [];

    if(!this.contato.pedidos) return [];

    this.pedidosEmAberto = []
    this.pedidosAnteriores = []

    for(let i = 0; i < this.contato.pedidos.length; i++) {
      if(['Entregue', 'Cancelado', 'Devolvido'].indexOf(this.contato.pedidos[i].status) < 0)
        this.pedidosEmAberto.push(this.contato.pedidos[i])
      else if(this.contato.pedidos[i].status === 'Entregue')
        this.pedidosAnteriores.push(this.contato.pedidos[i])
    }
  }
   */


}

