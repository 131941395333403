import {OpcaoDeAdicionalDeProduto} from "./OpcaoDeAdicionalDeProduto";
import {ProdutoTemplateAdicional} from "../templates/ProdutoTemplateAdicional";
import {AdicionalNoProduto} from "./AdicionalNoProduto";

export abstract class AdicionalDeProduto {
  id: number;
  template: ProdutoTemplateAdicional;
  ordem: number;
  codigoPdv: string;
  classe: string;
  campoOrdenar: string;
  compartilhado = false;
  produto: any;
  codigoIfood: string;
  dadosJson: string;
  adicionalNoProduto: AdicionalNoProduto;
  oculto: boolean;
  constructor(public nome: string, public obrigatorio: boolean = false, public tipo: string,
              public opcoesDisponiveis: Array<OpcaoDeAdicionalDeProduto>, public entidade: string = 'produto') {
    this.entidade = entidade;
  }

  getOrdem(){
    return this.adicionalNoProduto ? this.adicionalNoProduto.ordem : this.ordem
  }
  abstract permiteMultiplaEscolha(): boolean;

  abstract clone(): AdicionalDeProduto;

  abstract obtenhaDescricao(): string;

  abstract podeIrParaProximo(qtdeEscolhidos: number): boolean;

  public obtenhaQtdeOpcoesDisponiveis(): number {
    let qtde = 0;

    for( let i = 0; i < this.opcoesDisponiveis.length; i++ ) {
      const opcao = this.opcoesDisponiveis[i];

      if( !opcao.disponivel )
        continue;

      qtde ++;
    }

    return qtde;
  }

  obtenhaOpcaoPorIndice(posicao: number): OpcaoDeAdicionalDeProduto {
    let indiceDisponiveis = 0;

    for( let i = 0; i < this.opcoesDisponiveis.length; i++ ) {
      const opcao = this.opcoesDisponiveis[i];

      if( !opcao.disponivel )
        continue;

      if( indiceDisponiveis === posicao ) {
        return opcao;
      }

      indiceDisponiveis ++;
    }

    return null;
  }

  adicioneDependenciaDaMassa(adicionaisMassas: any){
    this.opcoesDisponiveis.forEach((opcaoBorda: any) => {
      adicionaisMassas.forEach((adicionalMassa: any) => {
        adicionalMassa.opcoesDisponiveis.forEach((opcaoMassa: any) => {
          if(opcaoMassa.template && !opcaoMassa.template.semborda){
            opcaoBorda.dependencias.push({
              adicionalId: adicionalMassa.id,
              opcaoDependente: {
                id: opcaoMassa.id
              }
            })
          }
        })
      })
    })
  }
}
