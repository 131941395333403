export enum TipoDeNotificacaoEnum {
  GanhouPontos = 'Ganhou Pontos',
  Aniversario = 'Aniversário',
  ResgatouCartao = 'Resgatou Cartão',
  SentimosSuaFalta = 'Sentimos Sua Falta',
  CartaoCompleto = 'Cartão Completo',
  ClientePerdido = 'Cliente Perdido',

  CarrinhoAbandonado = 'Carrinho Abandonado',

  NovoCartao = 'Novo Cartão',
  PontosExpirar = 'Pontos Expirar',
  ConfirmarCartao = 'Confirmar Cartão',
  Marketing = 'Marketing',
  TesteCampanha = 'TesteCampanha',
  PedidoAlterado= 'Pedido Alterado',

  PedidoSaiuParaEntrega = 'Pedido Saiu Para Entrega',
  PedidoEmPreparacao= 'Pedido Em Preparação',
  PedidoPronto = 'Pedido Pronto',
  PedidoCancelado = 'Pedido Cancelado',
  PedidoEntregue = 'Pedido Entregue',
  ComandaFechada = 'Comanda Fechada',

  PedidoConfirmado = 'Pedido Confirmado',
  PedidoRealizadoCardapioOnline= 'Pedido Realizado Cardapio Online',
  Cardapio = 'Cardapio',
  LinkExtratoCartao = 'Link Extrato Cartao' ,
  ConfirmacaoPedido = 'ConfirmacaoPedido',
  CodigoConfirmacao = 'Codigo Confirmacao',
  MensagemSaudacaoWhatsappPedido = 'Mensagem Saudação Whatsapp Pedido',
  SejaBemVindo = 'Bem Vindo',
  AvaliarPedido = 'Avaliar Pedido',

  PedidoAssociadoEntregador = 'Pedido Associado a Entregador',
  LinkPagamentoPedido = 'Link Pagamento do Pedido'
}
