<!-- Cabeçalho com ações principais -->
<div class="header-actions">
  <button class="btn btn-primary btn-novo-pedido" (click)="novoPedido()">
    <i class="fas fa-plus-circle me-2"></i>
    &nbsp;Abrir mesa
  </button>

  <div class="search-box">
    <kendo-textbox
      name="filtro"
      placeholder="Buscar mesa..."
      [(ngModel)]="filtro"
      [showSuccessIcon]="false">
      <ng-template kendoTextBoxSuffixTemplate>
        <kendo-textbox-separator></kendo-textbox-separator>
        <button kendoButton [look]="'clear'" [icon]="'search'"></button>
      </ng-template>
    </kendo-textbox>
  </div>
</div>

<!-- Loading state -->
<div class="loading-state" *ngIf="carregando">
  <i class="k-icon k-i-loading"></i>
  <span>Carregando mesas...</span>
</div>

<!-- Grid de mesas -->
<div class="mesas-grid" *ngIf="!carregando">
  <div class="mesa-card" *ngFor="let comanda of comandas | filtroObjetosNome: filtro">
    <!-- Mensagem quando nenhuma mesa é encontrada -->
    <div class="alert-box" *ngIf="comanda === -1">
      <div class="alert alert-warning">
        <i class="fas fa-exclamation-triangle me-2"></i>
        Nenhuma mesa encontrada para <strong>{{filtro}}</strong>
      </div>
    </div>

    <!-- Card da mesa -->
    <div class="card mesa-item" *ngIf="comanda !== -1" [class.disponivel]="comanda.mesa.disponivel">
      <!-- Cabeçalho do card -->
      <div class="card-header" [class.disponivel]="comanda.mesa.disponivel" [class.ocupada]="!comanda.mesa.disponivel">
        <div class="mesa-info">
          <h4 class="mesa-numero">{{comanda.mesa.nome}}</h4>
          <div class="mesa-status" *ngIf="comanda.mesa.totalNovo > 0">
            <span class="badge-pedidos">{{comanda.mesa.totalNovo}}</span>
            <span class="pedidos-texto">{{comanda.mesa.totalNovo > 1 ? 'novos pedidos' : 'novo pedido'}}</span>
          </div>
        </div>
      </div>

      <!-- Corpo do card -->
      <div class="card-body">
        <!-- Mesa ocupada -->
        <div class="mesa-ocupada" *ngIf="!comanda.mesa.disponivel">
          <div class="info-principal">
            <div class="valor-total">
              <span class="label">Total</span>
              <span class="valor">{{comanda.valor | currency: 'BRL'}}</span>
            </div>

            <div class="tempo">
              <i class="far fa-clock"></i>
              <span>{{comanda.tempo}}</span>
            </div>
          </div>

          <div class="acoes">
            <button class="btn-acao detalhes" (click)="verDetalhes(comanda)">
              <i class="fas fa-info-circle"></i>
              <span class="d-none d-sm-inline">Detalhes</span>
            </button>

            <button class="btn-acao novo-pedido" (click)="novoPedidoMesa(comanda)">
              <i class="fas fa-cart-plus"></i>
              <span class="d-none d-sm-inline">Novo Pedido</span>
            </button>
          </div>

          <!-- Erros de notificação -->
          <div class="erros-notificacao" *ngIf="comanda.errosNotificacao?.length">
            <div class="erro-header">
              <i class="fas fa-exclamation-circle"></i>
              <span>Erro ao fechar comanda</span>
            </div>

            <div class="erro-lista">
              <div class="erro-item" *ngFor="let notificacaoErro of comanda.errosNotificacao">
                <span class="erro-hora">{{notificacaoErro.horario | date: 'dd/MM/yyyy HH:mm'}}</span>
                <span class="erro-msg">{{notificacaoErro.erro}}</span>
              </div>
            </div>

            <button class="btn-retry" (click)="tenteNovamenteExecutarNotificacao(comanda)" [disabled]="comanda.executando">
              <i class="k-icon k-i-loading" *ngIf="comanda.executando"></i>
              <span>Tentar novamente</span>
            </button>
          </div>
        </div>

        <!-- Mesa disponível -->
        <div class="mesa-disponivel" *ngIf="comanda.mesa.disponivel">
          <div class="status-icon">
            <i class="fas fa-check-circle"></i>
          </div>
          <span class="status-text">Mesa disponível</span>
          <button class="btn-novo-pedido" (click)="novoPedidoMesa(comanda)">
            <i class="fas fa-plus-circle me-2"></i>
            Novo Pedido
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Mensagem quando não há comandas -->
  <div class="sem-comandas" *ngIf="comandas.length === 0">
    <div class="alert alert-info">
      <i class="fas fa-info-circle me-2"></i>
      <span>Você não tem comandas em aberto</span>
    </div>
  </div>
</div>
