<div class="py-1">
    <!-- Card Principal -->
    <div class="card">
        <div class="card-header">
            <div class="d-flex">
                <a [routerLink]="['/admin/chatbot/configuracoes']" class="btn btn-rounded btn-outline-blue">
                    <i class="fas fa-arrow-left"></i>
                </a>
                <h2 class="h4 mb-0 text-center ml-1">Configuração dos Fluxos</h2>
        </div>

        <div class="card-body">
            <!-- Conteúdo quando não configurado -->
            <div *ngIf="fluxoConversasAtivado && !fluxoConversasConfigurado">
                <!-- Barra de Progresso -->
                <div class="wizard-progress mb-5">
                    <div class="wizard-progress-bar" [style.width]="((etapaAtual - 1) / 2) * 100 + '%'"></div>
                    <div class="wizard-step" [class.active]="etapaAtual === 1" data-step="1">1</div>
                    <div class="wizard-step" [class.active]="etapaAtual === 2" data-step="2">2</div>
                    <div class="wizard-step" [class.active]="etapaAtual === 3" data-step="3">3</div>
                </div>

                <!-- Passo 1: Login no Typebot -->
                <div class="step-content" [style.display]="etapaAtual === 1 ? 'block' : 'none'" data-step="1">
                    <h3 class="h5 mb-4">1. Acesse o Typebot</h3>
                    <div class="instructions">
                        <p>Para começar a configuração, você precisa acessar sua conta no Typebot.
                           Clique no botão abaixo para fazer login:</p>
                        <div class="alert alert-info mt-2" *ngIf="conectando">
                            <i class="fas fa-info-circle mr-2"></i>
                            Após realizar o login no Typebot, feche a janela para continuar.
                        </div>
                        <div class="alert alert-success mt-2" *ngIf="loginSucesso">
                            <i class="fas fa-check-circle mr-2"></i>
                            Login realizado com sucesso! Você já pode prosseguir.
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <button class="btn btn-lg"
                                [ngClass]="{
                                    'btn-blue': !loginSucesso,
                                    'btn-success': loginSucesso
                                }"
                                (click)="loginTypebot()"
                                [disabled]="conectando || loginSucesso">
                            <ng-container *ngIf="!loginSucesso">
                                <i class="fas fa-sign-in-alt mr-2" *ngIf="!conectando"></i>
                                <i class="fas fa-spinner fa-spin mr-2" *ngIf="conectando"></i>
                                {{ conectando ? 'Conectando...' : 'Fazer Login no Conversas' }}
                            </ng-container>
                            <ng-container *ngIf="loginSucesso">
                                <i class="fas fa-check mr-2"></i>
                                Login Realizado
                            </ng-container>
                        </button>
                    </div>
                </div>

                <!-- Passo 2: Criar API Key -->
                <div class="step-content" [style.display]="etapaAtual === 2 ? 'block' : 'none'" data-step="2">
                    <h3 class="h5 mb-4">2. Configurar API Key</h3>

                    <div class="card instruction-card mb-4">
                        <div class="card-body">
                            <h5 class="card-title mb-4">
                                <i class="fas fa-key text-primary mr-2"></i>
                                Como gerar sua API Key
                            </h5>
                            <div class="steps-container">
                                <div class="api-step-item">
                                    <div class="step-number">1</div>
                                    <div class="step-content">
                                        <h6>Acesse as Configurações</h6>
                                        <a href="https://promokit.app.br:8443/pt-BR/typebots" target="_blank" class="btn btn-primary mb-1">
                                            Abrir o Typebot
                                        </a>
                                        <p class="text-muted mt-2">Após abrir a Ferramenta de Fluxos, no menu superior direito, clique em "Configura es e Membros"</p>
                                    </div>
                                </div>

                                <div class="api-step-item">
                                    <div class="step-number">2</div>
                                    <div class="step-content">
                                        <h6>Crie um novo Token</h6>
                                        <p class="text-muted">Na seção "Tokens de API", clique em "Criar" e dê um nome para seu token (ex: "PromoKit")</p>
                                    </div>
                                </div>

                                <div class="api-step-item">
                                    <div class="step-number">3</div>
                                    <div class="step-content">
                                        <h6>Copie o Token</h6>
                                        <p class="text-muted">Copie o token gerado e cole no campo abaixo para validação</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="form-group mb-4">
                                <label for="apiKey" class="form-label">
                                    <i class="fas fa-paste text-primary mr-2"></i>
                                    Cole sua API Key aqui
                                </label>
                                <div class="input-group">
                                    <input type="text"
                                           class="form-control form-control-lg"
                                           id="apiKey"
                                           [(ngModel)]="apiKey"
                                           name="apiKey"
                                           placeholder="Insira sua API Key"
                                           required>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                                type="button"
                                                onclick="copyToClipboard('apiKey')"
                                                title="Copiar">
                                            <i class="far fa-copy"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <button class="btn btn-primary"
                                    (click)="verificarChaveApi()"
                                    [disabled]="verificandoChave">
                                <i class="fas fa-check-circle mr-2"></i>
                                {{ verificandoChave ? 'Verificando...' : 'Verificar Chave de API' }}
                                <span class="spinner-border spinner-border-sm ml-2"
                                      *ngIf="verificandoChave"></span>
                            </button>
                        </div>
                    </div>

                    <div class="test-result alert mt-3" role="alert" style="display: none;"></div>
                </div>

                <!-- Passo 3: Confirmação -->
                <div class="step-content" [style.display]="etapaAtual === 3 ? 'block' : 'none'" data-step="3">
                    <h3 class="h5 mb-4">3. Confirmar Configuração</h3>
                    <div class="alert alert-success">
                        <i class="fas fa-check-circle mr-2"></i>
                        Configuração concluída com sucesso!
                    </div>
                    <div class="mt-4">
                        <h5>Resumo da configuração:</h5>
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                API Key
                                <span class="badge badge-success">Configurada</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Status da Integração
                                <span class="badge badge-success">Ativa</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Botões de Navegação -->
                <div class="d-flex justify-content-between mt-4">
                    <button class="btn btn-secondary btn-prev" (click)="voltarEtapa()"
                            [style.display]="etapaAtual > 1 ? 'block' : 'none'">
                        <i class="fas fa-arrow-left mr-2"></i>Anterior
                    </button>

                    <button class="btn btn-primary btn-next" *ngIf="etapaAtual < 3" (click)="proximaEtapa()"
                            [disabled]="etapaAtual === 1 && !botaoHabilitado">
                        <i class="fas fa-arrow-right mr-2"></i>Próximo
                    </button>

                    <button class="btn btn-success btn-finish" *ngIf="etapaAtual === 3" (click)="finalizar()">
                        <i class="fas fa-check mr-2"></i>Finalizar Configuração
                    </button>
                </div>


                <!-- Indicador de Carregamento -->
                <div *ngIf="criandoFluxosTypebot" class="text-center mt-4">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Carregando...</span>
                    </div>
                    <p>Aguarde, criando fluxos Typebot...</p>
                </div>
            </div>

            <!-- Conteúdo quando configurado -->
            <div *ngIf="fluxoConversasConfigurado" class="text-center">
                <div class="alert alert-success mb-4">
                    <i class="fas fa-check-circle mr-2"></i>
                    Fluxo de conversas configurado com sucesso!
                </div>
            </div>
        </div>
    </div>
</div>
