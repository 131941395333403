import { Component, OnInit } from '@angular/core';
import { MesaService } from "../../../services/mesa.service";
import { Mesa } from "../../objetos/Mesa";
import { ConstantsService } from "../../../fidelidade/ConstantsService";
import { Router } from "@angular/router";
import { AutorizacaoService } from "../../../services/autorizacao.service";
import { ModalVincularGarcomComponent } from "../../../fidelidade/modal-vincular-garcom/modal-vincular-garcom.component";
import { PedidoLocalService } from "../../../services/pedido-local.service";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { KendoPopupUtils } from "../../../lib/KendoPopupUtils";

@Component({
  selector: 'app-tela-novo-pedido-mesa',
  templateUrl: './tela-novo-pedido-mesa.component.html',
  styleUrls: ['./tela-novo-pedido-mesa.component.scss']
})
export class TelaNovoPedidoMesaComponent implements OnInit {
  mesas: Array<Mesa>;
  empresa: any;
  filtro = '';
  modoGarcom: boolean;
  usuario: any;
  mesasIntegracao: any = [];
  carregandoMesasIntegradas = false;

  // Novas variáveis para controle do modal
  modalAberto = false;
  mesaSelecionada: Mesa | null = null;
  comandasAtuais: any[] = [];

  constructor(
    private router: Router,
    private constantsService: ConstantsService,
    private pedidoLocalService: PedidoLocalService,
    private dialogService: DialogService,
    private mesaService: MesaService,
    private autorizacaoService: AutorizacaoService
  ) { }

  ngOnInit(): void {
    this.modoGarcom = this.autorizacaoService.getUser().garcom;
    this.constantsService.empresa$.subscribe((empresa) => {
      if (!empresa) { return; }
      this.empresa = empresa;
      this.pedidoLocalService.removaPedido('+5500000000000');
      this.carregueComandas();
    });
  }

  async carregueComandas() {
    this.mesas = await this.mesaService.obtenhaMesas(this.empresa);
    console.log(this.mesas);

    if (!this.mesasIntegracao.length && this.empresa.integracaoDelivery) {
      this.carregandoMesasIntegradas = true;
      this.mesasIntegracao = await this.mesaService.obtenhaMesasIntegradas();
      this.carregandoMesasIntegradas = false;
      if (this.mesasIntegracao.length) this.setMesasIntegradas();
    }
  }

  setMesasIntegradas() {
    this.mesas.forEach((mesa: any) => {
      if (mesa.codigoPdv && !mesa.mesaIntegrada)
        mesa.mesaIntegrada = this.mesasIntegracao.find((item: any) => Number(item.num) === Number(mesa.codigoPdv))
    });
  }

  async mesaEscolhida(mesa: Mesa) {
    if (!this.modoGarcom && this.empresa.integracaoDelivery && this.empresa.integracaoDelivery.sistema === 'ecletica') {
      this.abraModalVincularGarcom(mesa);
    } else {
      const comandas = await this.mesaService.obtenhaComandas(mesa.id);

      if (mesa.disponivel && (!comandas || comandas.length === 0)) {
        this.criarNovaComanda(mesa);
        return;
      }

      this.abrirModalSelecionarComanda(mesa, comandas);
    }
  }

  abrirModalSelecionarComanda(mesa: Mesa, comandas: any[]) {
    console.log('Abrindo modal com comandas:', comandas);
    document.body.classList.add('modal-lateral-aberto');

    this.mesaSelecionada = mesa;
    this.comandasAtuais = comandas || [];
    this.modalAberto = true;
  }

  onSelecionarComanda(comanda: any) {
    console.log('Comanda selecionada:', comanda);
    document.body.classList.remove('modal-lateral-aberto');
    this.navegarParaPedido(comanda);
    this.fecharModal();
  }

  onCriarNovaComanda() {
    if (this.mesaSelecionada) {
      console.log('Criando nova comanda para mesa:', this.mesaSelecionada);
      document.body.classList.remove('modal-lateral-aberto');
      this.criarNovaComanda(this.mesaSelecionada);
      this.fecharModal();
    }
  }

  onFecharModal() {
    console.log('Fechando modal');
    document.body.classList.remove('modal-lateral-aberto');
    this.fecharModal();
  }

  fecharModal() {
    this.modalAberto = false;
    this.mesaSelecionada = null;
    this.comandasAtuais = [];
  }

  async selecionarComanda(mesaOuComanda: Mesa | any) {
    if ('mesa' in mesaOuComanda) {
      this.navegarParaPedido(mesaOuComanda);
      return;
    }

    const mesa = mesaOuComanda;
    console.log('Buscando comandas para mesa:', mesa);
    const comandas = await this.mesaService.obtenhaComandas(mesa.id);
    console.log('Comandas encontradas:', comandas);

    if (!comandas || comandas.length === 0) {
      console.log('Nenhuma comanda encontrada');
      return;
    }

    if (comandas.length === 1) {
      console.log('Uma única comanda encontrada, navegando direto');
      this.navegarParaPedido(comandas[0]);
      return;
    }

    console.log('Múltiplas comandas encontradas, abrindo modal');
    this.abrirModalSelecionarComanda(mesa, comandas);
  }

  navegarParaPedido(comanda: any) {
    this.router.navigate(['/admin/pedidos/novo'], {
      queryParams: {
        idm: comanda.mesa.id,
        idc: comanda.id,
        modoEdicao: true,
        urlRetorno: '/admin/pedidos-mesas'
      }
    });
  }

  criarNovaComanda(mesa: Mesa) {
    this.router.navigate(['/admin/pedidos/novo'], {
      queryParams: {
        idm: mesa.id,
        novaComanda: true,
        modoEdicao: false,
        urlRetorno: '/admin/pedidos-mesas'
      }
    });
  }

  abraModalVincularGarcom(mesa: any) {
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalVincularGarcomComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef);
    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.mesa = mesa;

    windowRef.result.subscribe((comanda: any) => {
      if (!comanda) return;
      const garcom = comanda.garcom,
        codigoComanda = comanda.codigoPdv;

      if (garcom && garcom.id)
        this.criarNovaComanda(mesa);
    });
  }

  voltar() {
    window.history.back();
  }
}
