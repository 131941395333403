<div class="modal-overlay" (click)="fecharModal()"></div>

<div class="modal-lateral">
  <div class="modal-header">
    <div class="header-content">
      <h5 class="modal-title">Mesa {{mesa?.nome}}</h5>
      <span class="subtitle">Selecione uma comanda para continuar</span>
    </div>
    <button class="btn-fechar" (click)="fecharModal()">
      <i class="fas fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="comandas-list">
      <!-- Estado vazio -->
      <div class="sem-comandas" *ngIf="!comandas?.length">
        <div class="empty-state">
          <i class="fas fa-receipt"></i>
          <h3>Nenhuma comanda aberta</h3>
          <p>Crie uma nova comanda para começar</p>
        </div>
      </div>

      <!-- Lista de Comandas -->
      <div class="comanda-item" *ngFor="let comanda of comandas" (click)="selecioneComanda(comanda)">
        <div class="comanda-card">
          <!-- Cabeçalho do Card -->
          <div class="card-header">
            <div class="comanda-identificacao">
              <span class="comanda-numero">{{comanda.codigoPdv ? '#' + comanda.codigoPDV : 'Comanda #' + comanda.id}}</span>
              <span class="comanda-status" [class.status-aberta]="comanda.status === 'ABERTA'">
                <i class="fas fa-circle"></i>
                {{comanda.status}}
              </span>
            </div>
            <div class="comanda-valor">
              {{comanda.valor | currency:'BRL'}}
            </div>
          </div>

          <!-- Corpo do Card -->
          <div class="card-body">
            <!-- Informações de Tempo -->
            <div class="info-row">
              <div class="info-item">
                <i class="far fa-clock"></i>
                <span>Aberta há {{comanda.tempo | number:'1.0-0'}}h {{((comanda.tempo % 1) * 60) | number:'1.0-0'}}min</span>
                <span class="warning-expired" *ngIf="comanda.tempo >= 5">
                  <i class="fas fa-exclamation-triangle"></i>
                  Comanda expirou
                </span>
              </div>
              <div class="info-item" *ngIf="comanda.garcom">
                <i class="fas fa-user"></i>
                <span>{{comanda.garcom.nome}}</span>
              </div>
            </div>

            <!-- Indicador de Pedidos -->
            <div class="pedidos-indicator" *ngIf="comanda.pedidos?.length">
              <div class="pedidos-count">
                <i class="fas fa-utensils"></i>
                <span>{{comanda.pedidos.length}} {{comanda.pedidos.length === 1 ? 'pedido' : 'pedidos'}}</span>
              </div>
            </div>
          </div>

          <!-- Rodapé do Card -->
          <div class="card-footer">
            <div class="d-flex gap-2">
              <button class="btn btn-outline-secondary flex-fill" (click)="$event.stopPropagation(); verDetalhes(comanda)">
                <i class="fas fa-eye mr-2"></i>
                Ver Detalhes
              </button>
              <button class="btn btn-primary flex-fill" (click)="$event.stopPropagation(); selecioneComanda(comanda)">
                <i class="fas fa-utensils mr-2"></i>
                Adicionar Pedido
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Botão Nova Comanda -->
    <div class="nova-comanda" *ngIf="exibirBotaoNovaComanda">
      <button class="btn-nova-comanda" (click)="crieNovaComanda()">
        <i class="fas fa-plus"></i>
        Criar Nova Comanda
      </button>
    </div>
  </div>
</div>
