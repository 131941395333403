<div class="card">
  <div class="card-body">
    <!-- Loading -->
    <div class="card-body" *ngIf="loading">
      <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;"></div>
    </div>

    <!-- Abas Principais -->
    <kendo-tabstrip #tabstrip="kendoTabStrip" (tabSelect)="onTabSelect($event)">
      <!-- Aba: Pagamento em Loja -->
      <kendo-tabstrip-tab title="Pagamento em Loja" [selected]="true">
        <ng-template kendoTabTitle>
          <i class="fas fa-store mr-2"></i>
          Pagamento em Loja
        </ng-template>
        <ng-template kendoTabContent>
          <div class="k-display-inline-flex">
            <div class="flex-row">
              <h5>Cadastre as opções de pagamento aceitas pela loja.</h5>
            </div>
            <div class="flex-row">
            </div>
          </div>

          <div class="mt-2">
            <kendo-grid [data]="gridDataResultLoja"
                       [loading]="carregando"
                       style="min-height: 300px;"
                       [scrollable]="'none'"
                       [group]="groups"
                       [groupable]="true"
                       (edit)="editar($event)"
                       (remove)="abraDialogRemover($event)">

              <!-- Toolbar para Pagamento em Loja -->
              <ng-template kendoGridToolbarTemplate [position]="'top'">

                <kendo-button   (click)="novo(false)"
                              class="btn-nova-forma">
                                  <span class="d-flex align-items-center">
                                    <i class="fas fa-plus-circle mr-2"></i>
                                    <span>
                                             <strong>Nova Forma de Pagamento</strong>
                                    </span>
                                  </span>
                </kendo-button>



                <button class="btn-migrar ml-2" *ngIf="usuario.admin" (click)="abraModalMigrarFormasBandeira()">
                  <span class="d-flex align-items-center">
                    <i class="fas fa-exchange-alt mr-2"></i>
                    <span>
                      <strong>Migrar para Bandeiras</strong>
                    </span>
                  </span>
                </button>

              </ng-template>

              <!-- Colunas do Grid -->
              <ng-template kendoGridCellTemplate let-dataItem  >
                <span class="text-blue"><b>{{dataItem.id}}</b></span>
              </ng-template>
              <kendo-grid-column title="Descrição">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span><b>{{dataItem.descricao}}</b></span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Pagamento" field="tipoDePagamento"  media="(min-width: 1300px)" [width]="0" [hidden]="true">
                <ng-template kendoGridGroupHeaderTemplate let-value="value">
                  {{value}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title=" {{'Referência (' +empresa?.integracaoDelivery?.sistema + ')'}}"   *ngIf="empresa.integracaoDelivery" >
                <ng-template kendoGridCellTemplate let-dataItem  >
                  <ng-container *ngIf="dataItem.referenciaExterna">
                    {{dataItem.referenciaExterna}}
                     <ng-container *ngIf="dataItem.bandeirasCartaoIntegrada.length">
                       (
                         <span *ngFor="let bandeira of dataItem.bandeirasCartaoIntegrada; let ultimo = last; ">
                            {{bandeira.nome}} <span *ngIf="!ultimo">, </span>
                         </span>
                       )</ng-container>
                  </ng-container>

                  <span *ngIf="!dataItem.referenciaExterna" class="text-danger">
                    <i class="fa fa-times "></i> nao configurada
                  </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="OpenDelivery"   *ngIf="empresa.integracaoOpendelivery?.ativa || empresa.integracaoOpendeliveryLogistica?.ativa" >
                <ng-template kendoGridCellTemplate let-dataItem  >
                  <ng-container *ngIf="dataItem.opendeliveryMethod">
                    {{dataItem.opendeliveryMethod}}
                    <ng-container *ngIf="dataItem.opendeliveryBrand">
                      ({{dataItem.opendeliveryBrand}})
                    </ng-container>
                  </ng-container>
                  <span *ngIf="!dataItem.opendeliveryMethod" class="text-danger">
                    <i class="k-icon k-i-error"></i> nao configurada
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Exibir"  media="(min-width: 1300px)" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span [class.sim]="dataItem.exibirCardapio" [class.nao]="!dataItem.exibirCardapio">{{dataItem.exibirCardapio ? 'Sim' : 'Não'}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Taxa retorno"  media="(min-width: 1300px)" [width]="150" *ngIf="temTaxaRetorno()">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span [class.sim]="dataItem.cobrarTaxaRetorno" [class.nao]="!dataItem.cobrarTaxaRetorno">{{dataItem.cobrarTaxaRetorno ? 'Sim' : 'Não'}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-command-column title="" [width]="100">
                <ng-template kendoGridCellTemplate let-isNew="isNew" let-formaPagamento >
                  <button kendoGridEditCommand [primary]="true" (selectedChange)="editar(formaPagamento)">
                    <i class="fa fa-edit" kendoTooltip title="editar"></i>
                  </button>
                  <button kendoGridRemoveCommand kendoTooltip title="remover" *ngIf=" formaPagamento.tipoDePagamento != 'Fidelidade' " >
                    <i class="fa fa-trash"></i></button>


                </ng-template>
              </kendo-grid-command-column>

              <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- Aba: Pagamento Online -->
      <kendo-tabstrip-tab [title]="nomeTabOnline" >
        <ng-template kendoTabTitle>
          <i class="fas fa-globe mr-2"></i>
          {{nomeTabOnline}}
        </ng-template>
        <ng-template kendoTabContent>
          <!-- Sub-abas para Pagamento Online -->
          <kendo-tabstrip class="mt-2" #tabstriponline="kendoTabStrip">
            <!-- Sub-aba: Meu Cardápio Pay -->
            <kendo-tabstrip-tab [selected]="true">
              <ng-template kendoTabTitle>
                <i class="fas fa-wallet mr-2"></i>
                Meu Cardápio Pay
              </ng-template>
              <ng-template kendoTabContent>
                <div class="mt-1" *ngIf="!loading">
                  <app-meucardapio-pay-pagamento [empresa]="empresa" [formasDePagamentoOnline]="formasDePagamentoOnline">

                  </app-meucardapio-pay-pagamento>

                </div>
              </ng-template>
            </kendo-tabstrip-tab>

            <!-- Sub-aba: Integrações Online -->
            <kendo-tabstrip-tab>
              <ng-template kendoTabTitle>
                <i class="fas fa-plug mr-2"></i>
                Integrações Online
              </ng-template>
              <ng-template kendoTabContent>
                <!-- Links e Botões de Integração -->
                <div class="mt-2">
                  <app-pagarmehub-acoes [empresa]="empresa"  [nomeTabOnline]="nomeTabOnline">

                  </app-pagarmehub-acoes>
                </div>

                <div class="mt-2">
                  <div class="row">
                    <div class="col">
                      <kendo-grid [data]="formasDePagamentoOnlineExterna"
                                 [loading]="carregando"
                                 style="min-height: 300px;"
                                 [scrollable]="'none'"
                                 (edit)="editar($event)"
                                 (remove)="abraDialogRemover($event)">

                        <!-- Toolbar para Integrações Online -->
                        <ng-template kendoGridToolbarTemplate [position]="'top'">

                          <kendo-button *ngIf="pagamentosOnline.length" (click)="novo(true)"
                            class="btn-nova-forma">
                                  <span class="d-flex align-items-center">
                                    <i class="fas fa-plus-circle mr-2"></i>
                                    <span>
                                      <strong>Nova Integração Online</strong>
                                    </span>
                                  </span>
                          </kendo-button>


                        </ng-template>

                        <!-- Colunas do Grid -->
                        <ng-template kendoGridCellTemplate let-dataItem  >
                          <span class="text-blue"><b>{{dataItem.id}}</b></span>
                        </ng-template>
                        <kendo-grid-column title="Descrição">
                          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span><b>{{dataItem.descricao}}</b></span>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Gateway"    [width]="100" >
                          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                             <span [ngClass]="{'pagarme': ehPagarme(dataItem), 'mci text-blue': ehMeucardapio(dataItem)}">
                              <b>  {{dataItem.configMeioDePagamento?.meioDePagamento}}</b>
                             </span>

                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title=" {{'Referência (' +empresa?.integracaoDelivery?.sistema + ')'}}"   *ngIf="empresa.integracaoDelivery" >
                          <ng-template kendoGridCellTemplate let-dataItem  >
                            <ng-container *ngIf="dataItem.referenciaExterna">
                              {{dataItem.referenciaExterna}}
                               <ng-container *ngIf="dataItem.bandeirasCartaoIntegrada.length">
                                 (
                                   <span *ngFor="let bandeira of dataItem.bandeirasCartaoIntegrada; let ultimo = last; ">
                                        {{bandeira.nome}} <span *ngIf="!ultimo">, </span>
                                   </span>
                                 )</ng-container>
                            </ng-container>

                            <span *ngIf="!dataItem.referenciaExterna" class="text-danger">
                              <i class="fa fa-times "></i> nao configurada
                            </span>

                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="OpenDelivery"   *ngIf="empresa.integracaoOpendelivery?.ativa || empresa.integracaoOpendeliveryLogistica?.ativa" >
                          <ng-template kendoGridCellTemplate let-dataItem  >
                            <ng-container *ngIf="dataItem.opendeliveryMethod">
                              {{dataItem.opendeliveryMethod}}
                              <ng-container *ngIf="dataItem.opendeliveryBrand">
                                ({{dataItem.opendeliveryBrand}})
                              </ng-container>
                            </ng-container>
                            <span *ngIf="!dataItem.opendeliveryMethod" class="text-danger">
                              <i class="k-icon k-i-error"></i> nao configurada
                            </span>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Exibir"  media="(min-width: 1300px)" [width]="150">
                          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span [class.sim]="dataItem.exibirCardapio" [class.nao]="!dataItem.exibirCardapio">{{dataItem.exibirCardapio ? 'Sim' : 'Não'}}</span>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Taxa retorno"  media="(min-width: 1300px)" [width]="150" *ngIf="temTaxaRetorno()">
                          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span [class.sim]="dataItem.cobrarTaxaRetorno" [class.nao]="!dataItem.cobrarTaxaRetorno">{{dataItem.cobrarTaxaRetorno ? 'Sim' : 'Não'}}</span>
                          </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-command-column title="" [width]="100">
                          <ng-template kendoGridCellTemplate let-isNew="isNew" let-formaPagamento >
                            <button kendoGridEditCommand [primary]="true" (selectedChange)="editar(formaPagamento)">
                              <i class="fa fa-edit" kendoTooltip title="editar"></i>
                            </button>
                            <button kendoGridRemoveCommand kendoTooltip title="remover" *ngIf=" formaPagamento.tipoDePagamento != 'Fidelidade' " >
                              <i class="fa fa-trash"></i></button>


                          </ng-template>
                        </kendo-grid-command-column>

                        <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>
                      </kendo-grid>
                    </div>
                  </div>
                </div>



              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>


  </div>
</div>

<!-- Template do modal de confirmação -->
<kendo-dialog *ngIf="confirmarMigrar" (close)="confirmarMigrar = false" >
  <kendo-dialog-titlebar>
    Confirmação
  </kendo-dialog-titlebar>
  <p>Ao migrar as formas de pagamentos atuais serão removidas <br> e as novas deverão se ativadas manualmente.
  </p>

  <p>
    Você tem certeza que deseja migrar para bandeiras?
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="k-primary" (click)="confirmarMigrarBanderias()" [disabled]="migrandoFormasPagamento">
      <i class="k-i-loading k-icon" *ngIf="migrandoFormasPagamento"></i>
      Confirmar</button>
    <button kendoButton  (click)="confirmarMigrar = false" [disabled]="migrandoFormasPagamento">Cancelar</button>
  </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p style="margin: 30px; text-align: center;">Você tem certeza que deseja apagar a forma de pagamento <strong>{{formaApagar.descricao}}</strong>?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" [primary]="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
