import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Mesa } from '../../objetos/Mesa';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SelecionarCartaoClienteComponent } from '../../../cartao-cliente/selecionar-cartao-cliente/selecionar-cartao-cliente.component';
import { ConstantsService } from '../../../fidelidade/ConstantsService';

@Component({
  selector: 'app-lista-comandas',
  templateUrl: './lista-comandas.component.html',
  styleUrls: ['./lista-comandas.component.scss']
})
export class ListaComandasComponent implements OnInit, OnDestroy {
  @Input() mesa: Mesa;
  private _comandas: any[] = [];
  empresa: any;
  private intervalId: any;

  @Input()
  set comandas(value: any[]) {
    console.log('Comandas sendo atualizadas:', value);
    this._comandas = value || [];
  }

  get comandas(): any[] {
    return this._comandas;
  }

  @Input() mostrarBotaoNovo = true;

  // Propriedade calculada para determinar se deve mostrar o botão de nova comanda
  get exibirBotaoNovaComanda(): boolean {
    // Se não deve mostrar o botão, retorna false
    if (!this.mostrarBotaoNovo) {
      return false;
    }
    
    // Se a empresa não permite múltiplas comandas e já existe pelo menos uma comanda, não exibe o botão
    if (this.empresa && !this.empresa.permitirMultiplasComandasMesa && this.comandas && this.comandas.length > 0) {
      return false;
    }
    
    // Caso contrário, exibe o botão
    return true;
  }

  @Output() selecionarComanda = new EventEmitter<any>();
  @Output() criarNovaComanda = new EventEmitter<any>();
  @Output() fechar = new EventEmitter<void>();

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private constantesService: ConstantsService
  ) {}

  ngOnInit() {
    console.log('Comandas recebidas:', this.comandas);
    this.constantesService.empresa$.subscribe((empresa: any) => {
      this.empresa = empresa;
    });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  selecioneComanda(comanda: any) {
    console.log('Comanda selecionada:', comanda);
    
    // Navega direto para tela de novo pedido sem abrir diálogo de cartão
    this.router.navigate(['/admin/pedidos/novo'], {
      queryParams: {
        idm: this.mesa.id,
        idc: comanda.id,
        modoEdicao: true,
        urlRetorno: '/admin/pedidos-mesas'
      }
    });
  }

  crieNovaComanda() {
    console.log('Criando nova comanda');

    // Verifica se a empresa usa cartão cliente
    if (this.empresa && this.empresa.usarCartaoCliente) {
      // Se usa cartão cliente, abre o diálogo de seleção
      const dialogRef = this.dialogService.open({
        title: 'Abrir Comanda',
        content: SelecionarCartaoClienteComponent,
        width: 600,
        height: 800
      });

      dialogRef.result.subscribe((cartaoCliente: any) => {
        if (cartaoCliente && cartaoCliente.id) {
          // Só navega para a tela de pedido se um cartão foi selecionado
          this.router.navigate(['/admin/pedidos/novo'], {
            queryParams: {
              idm: this.mesa.id,
              idcc: cartaoCliente.id,
              modoEdicao: true,
              urlRetorno: '/admin/pedidos-mesas'
            }
          });
        }
        // Se cancelou (cartaoCliente é null/undefined), não faz nada
      });
    } else {
      // Se não usa cartão cliente, navega direto para a tela de novo pedido
      this.router.navigate(['/admin/pedidos/novo'], {
        queryParams: {
          idm: this.mesa.id,
          modoEdicao: true,
          urlRetorno: '/admin/pedidos-mesas'
        }
      });
    }
  }

  fecharModal() {
    console.log('Fechando modal');
    this.fechar.emit();
  }

  verDetalhes(comanda: any) {
    this.router.navigate([`/admin/fechar-mesa/${comanda.id}`], {
      queryParams: {
        idm: this.mesa.id,
        urlRetorno: '/admin/pedidos-mesas'
      }
    });
  }
}
