<div class="container-fluid">
  <div class="row mb-4">
    <div class="col">
      <h2>Classificação de Clientes</h2>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-md-4">
      <kendo-multiselect
        [data]="classificacoes"
        [(ngModel)]="classificacoesSelecionadas"
        (valueChange)="onClassificacaoChange()"
        [valuePrimitive]="true"
        placeholder="Selecione as classificações"
      >
      </kendo-multiselect>
    </div>
    <div class="col-md-4">
      <button
        class="btn btn-primary"
        (click)="processarClassificacao()"
        [disabled]="processando"
      >
        <i class="fas fa-sync-alt" [class.fa-spin]="processando"></i>
        {{ processando ? 'Processando...' : 'Classificar Clientes' }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <kendo-grid
        [data]="contatos"
        [loading]="carregando"
        [sortable]="true"
        [pageable]="true"
        [pageSize]="pageSize"
        [skip]="skip"
        (pageChange)="onPageChange($event)"
      >
        <kendo-grid-column field="nome" title="Nome" [width]="200">
        </kendo-grid-column>

        <kendo-grid-column field="telefone" title="Telefone" [width]="150">
        </kendo-grid-column>

        <kendo-grid-column field="email" title="Email" [width]="200">
        </kendo-grid-column>

        <kendo-grid-column field="qtdePedidos" title="Qtde. Pedidos" [width]="120">
        </kendo-grid-column>

        <kendo-grid-column field="dataUltimoPedido" title="Último Pedido" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.dataUltimoPedido | date:'dd/MM/yyyy'}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="classificacao" title="Classificação" [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [class]="'badge badge-' + getClassificacaoBadge(dataItem.classificacao)">
              {{dataItem.classificacao}}
            </span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
