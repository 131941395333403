import {IUITipoDePontuacao} from "./IUITipoDePontuacao";
import {ProdutoCashbackUtils} from "./ProdutoCashbackUtils";

export class UITipoDePontuacaoCashback implements IUITipoDePontuacao {
  private empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any,  itens: any) {
    return ProdutoCashbackUtils.calculeCashbakDosProdutos(itens, pontuacaoRegistrada)
  }
}
