import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PedidosService} from "../../services/pedidos.service";

@Component({
  selector: 'app-comprovante-pedido',
  templateUrl: './comprovante-pedido.component.html',
  styleUrls: ['./comprovante-pedido.component.scss' , '../../componentes/imprimir.component.scss']
})
export class ComprovantePedidoComponent implements OnInit {
  pedido: any = { cliente: {}, empresa: {}, totalPagar: 0};
  carregando: boolean;
  texto: string
  layoutPedido = 'PedidoCompleto';
  resumido: boolean;
  constructor(private activatedRoute: ActivatedRoute, private pedidosService: PedidosService) {
    let codigo =  this.activatedRoute.snapshot.params.codigo;
    let modoTexto = this.activatedRoute.snapshot.queryParams.t === '1';
    let layoutPedido = this.activatedRoute.snapshot.queryParams.l;
    this.carregando = true;
    this.pedidosService.obtenhaPorCodigo(codigo, null,  true).then( (pedido) => {
      this.pedido = pedido;
      this.resumido = layoutPedido === 'PedidoResumido';
      this.carregando = false;
/*
      if(pedido.agruparAdicionais) {
        for(let item of pedido.itens) {
          if(!item.adicionaisImprirmir || item.adicionaisImprirmir.length < 1)
            continue;

          item.adicionaisAgrupados = []

          let adicionalAtual = null
          let grupoAtual = null

          console.log("adicionais imprimir: ", item.adicionaisImprirmir)
          for(let opcaoSelecionada of item.adicionaisImprirmir) {
            console.log("opção", opcaoSelecionada)
            if(adicionalAtual === null || adicionalAtual.id !== opcaoSelecionada.adicional.id) {
              if(grupoAtual)
                item.adicionaisAgrupados.push(grupoAtual)
              adicionalAtual = opcaoSelecionada.adicional
              grupoAtual = {
                nomeAdicional: adicionalAtual.nome,
                opcoes: []
              }
            }

            grupoAtual.opcoes.push(opcaoSelecionada)
          }
          if(grupoAtual)
            item.adicionaisAgrupados.push(grupoAtual)




        }

      }
*/
      console.log(pedido);
      setTimeout( () => {
        //impressao.imprima(this.texto)
        window.print()
      }, 500)
    });

  }



  ngOnInit() {
  }
}
