<form  >
  <div class="mt-2">
    <h5 class="card-title mt-2">Regiões de Entrega</h5>
  </div>

  <div class="row">
    <div class="col-12 col-xl-9">
      <kendo-grid
        [kendoGridBinding]="gridView"
        [pageSize]="10"
        [pageable]="true"
        [sortable]="true"
        [reorderable]="true"
        [resizable]="false"
        [loading]="carregando"
        (edit)="editHandler($event)"
        (remove)="removerZona($event)"
        [columnMenu]="{ filter: false, columnChooser: false }"
      >
        <ng-template kendoGridToolbarTemplate>
          <input placeholder="Filtrar zona de entrega" kendoTextBox (input)="onFilterEvento($event)"/>
          <button class="btn btn-blue" (click)="abraModalZonaEntrega()" type="button">+ Nova Zona de Entrega</button>
          <p>{{regioesEscolhidas.length}} zonas de entrega</p>
        </ng-template>
        <kendo-grid-column
          field="nome"
          title="Descrição da zona"
          [resizable]="false"     >

          <ng-template kendoGridCellTemplate let-dataItem >
            <span [ngClass]="{'desativada': dataItem.desativada}">{{dataItem.nome }}</span>
          </ng-template>

        </kendo-grid-column>
        <kendo-grid-column
          field="valor"
          title="Taxa de entrega"
          [width]="170"
          [resizable]="false"
          filter="numeric">
          <ng-template kendoGridCellTemplate let-dataItem >
            <span [ngClass]="{'desativada': dataItem.desativada}">
                  {{ dataItem.valor | currency:'BRL' }}
            </span>

          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column   title="Permite Frete Gratis"
                              [width]="120"
                              [resizable]="false">
          <ng-template kendoGridHeaderTemplate let-column>
            <div style="white-space: normal; text-align: center;">
              {{ column.title }}
            </div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem >
            <span *ngIf="dataItem.permiteFreteGratis" class="text-success"><b>Sim</b></span>
            <span *ngIf="!dataItem.permiteFreteGratis" class="text-muted"><b>Não</b></span>
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column [width]="150">
          <ng-template kendoGridCellTemplate let-zona let-rowIndex="rowIndex">
            <button kendoGridEditCommand type="button" class="mr-1">
              <i class="fa fa-edit"></i>
            </button>

            <button class="btn btn-xs btn-danger mr-1"  type="button" (click)="pausarZona(zona)"
                    kendoTooltip title="Parar Temporariamente essa zona"
                    *ngIf="!zona.desativada" [disabled]="zona.processando">
              <i class="far fa-pause-circle fa-lg">  </i>
            </button>

            <button class="btn btn-xs btn-primary mr-1 "   type="button" (click)="ativarZona(zona)"
                    kendoTooltip title="Ativar essa zona" *ngIf="zona.desativada"
                    [disabled]="zona.processando">
              <i class="far fa-play-circle fa-lg"  ></i>&nbsp;

            </button>

            <button kendoGridRemoveCommand type="button" [primary]="true">&nbsp;<i class="fa fa-trash"></i>&nbsp;</button>


          </ng-template>
        </kendo-grid-column>

        <kendo-grid-messages noRecords="Nenhuma zona de entrega encontrada."></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>

  <kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="fecheModalRemover()" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center;">Você tem certeza que deseja remover a <strong>{{zonaARemover?.nome}}</strong>?</p>
    <kendo-dialog-actions>
      <button kendoButton (click)="confirmouRemover()" [primary]="true" [disabled]="this.zonaARemover?.removendo">Sim</button>
      <button kendoButton (click)="fecheModalRemover()">Não</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</form>




