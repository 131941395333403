import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassificacaoContatoService {
  private apiUrl = '/classificacao-contato';

  constructor(private http: HttpClient) {}

  atualizarClassificacoes(idEmpresa: number, dataInicio: any, dataFim: any): Observable<any> {
    const parametros = {
      dataInicio: dataInicio,
      dataFim: dataFim
    };

    return this.http.post(`${this.apiUrl}/atualizar/${idEmpresa}`, parametros).pipe(
      map((response: any) => response.data)
    );
  }

  obterContatosPorClassificacao(idEmpresa: number, classificacao: string, skip: number = 0, take: number = 10): Observable<any> {
    return this.http.get(`${this.apiUrl}/${idEmpresa}/${classificacao}?skip=${skip}&take=${take}`).pipe(
      map((response: any) => response.data)
    );
  }
}
