import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TypebotService } from '../services/typebot.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfiguracoesMiaService} from "../../services/configuracoes-mia.service";

@Component({
  selector: 'app-tela-config-typebot',
  templateUrl: './tela-config-typebot.component.html',
  styleUrls: ['./tela-config-typebot.component.scss']
})
export class TelaConfigTypebotComponent implements OnInit, OnChanges {
  loginRealizado = false;
  botaoHabilitado = false;
  conectando = false;
  loginSucesso = false;
  etapaAtual = 1;
  verificandoChave = false;
  apiKeyValida = false;
  fluxoConversasAtivado = false;
  fluxoConversasConfigurado = false;
  apiKey: string = '';
  private readonly RETURN_URL = '/admin/chatbot/configuracoes';

  @Input() configuracoes: any;
  criandoFluxosTypebot: boolean;

  constructor(
    private typebotService: TypebotService,
    private route: ActivatedRoute,
    private router: Router,
    private configuracoesMiaService: ConfiguracoesMiaService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['configuracoes']) {
        this.configuracoes = JSON.parse(params['configuracoes']);
      }
    });
    this.atualizarStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuracoes) {
      this.atualizarStatus();
    }
  }

  private atualizarStatus() {
    if (this.configuracoes) {
      this.fluxoConversasAtivado = this.configuracoes.usarFluxoTypebot;
      this.fluxoConversasConfigurado = this.configuracoes.typebotConfigurado;
    } else {
      this.verificarStatusTypebot();
    }
  }

  verificarStatusTypebot() {
    this.typebotService.verificarTypebotConfigurado().subscribe(
      (response: any) => {
        this.fluxoConversasAtivado = response.usarFluxoTypebot;
        this.fluxoConversasConfigurado = response.configurado;
      },
      (error) => {
        console.error('Erro ao verificar status do typebot:', error);
      }
    );
  }

  verificarChaveApi() {
    if (!this.apiKey) {
      this.mostrarMensagem('Por favor, insira uma chave de API', 'danger');
      return;
    }

    this.verificandoChave = true;
    this.typebotService.verificarApiKey(this.apiKey).then(
      (response: any) => {
        debugger;
        this.apiKeyValida = true;
        this.mostrarMensagem('Chave de API válida!', 'success');
        this.botaoHabilitado = true;
        this.verificandoChave = false;

        setTimeout(() => {
          this.etapaAtual++;
          const progressBar = document.querySelector('.wizard-progress-bar') as HTMLElement;
          if (progressBar) {
            progressBar.style.width = `${((this.etapaAtual - 1) / 2) * 100}%`;
          }
        }, 1000);
      },
      (error) => {
        this.verificandoChave = false;
        this.apiKeyValida = false;
        this.mostrarMensagem('Chave de API inválida. Por favor, verifique e tente novamente.', 'danger');
      }
    );
  }

  private mostrarMensagem(mensagem: string, tipo: 'success' | 'danger') {
    const alertElement = document.querySelector('.test-result');
    if (alertElement) {
      alertElement.textContent = mensagem;
      alertElement.className = `test-result alert alert-${tipo} mt-3`;
      alertElement.setAttribute('style', 'display: block');
    }
  }

  proximaEtapa() {
    if (this.etapaAtual === 2) {
      this.verificarChaveApi();
      return;
    }

    if (this.etapaAtual < 3) {
      this.etapaAtual++;
      const progressBar = document.querySelector('.wizard-progress-bar') as HTMLElement;
      if (progressBar) {
        progressBar.style.width = `${((this.etapaAtual - 1) / 2) * 100}%`;
      }
    }
  }

  finalizar() {
    this.criandoFluxosTypebot = true;
    this.configuracoesMiaService.crieFluxosTypebotPadrao().then( (resposta: any) => {
      this.criandoFluxosTypebot = false;
      this.configuracoes.idFluxoTypebotWhatsapp = resposta.idFluxoTypebotWhatsapp;
      this.configuracoes.idFluxoTypebotInstagram = resposta.idFluxoTypebotInstagram;

      this.fluxoConversasConfigurado = true;
      if (this.configuracoes) {
        this.configuracoes.typebotConfigurado = true;
        this.configuracoes.usarFluxoTypebot = true;
        this.router.navigate([this.RETURN_URL]);
      }
    }).catch( (erro: any) => {
      this.criandoFluxosTypebot = false;
    });
  }

  editarFluxoWhatsapp() {
    if (this.configuracoes?.idFluxoTypebotWhatsapp) {
      window.open(`https://promokit.app.br:8443/pt-BR/typebots/${this.configuracoes.idFluxoTypebotWhatsapp}/edit`, '_blank');
    } else {
      this.criarFluxoWhatsapp();
    }
  }

  editarFluxoInstagram() {
    if (this.configuracoes?.idFluxoTypebotInstagram) {
      window.open(`https://promokit.app.br:8443/pt-BR/typebots/${this.configuracoes.idFluxoTypebotInstagram}/edit`, '_blank');
    } else {
      this.criarFluxoInstagram();
    }
  }

  private criarFluxoWhatsapp() {
    this.typebotService.criarFluxosPadrao().subscribe(
      (response: any) => {
        if (this.configuracoes) {
          this.configuracoes.idFluxoTypebotWhatsapp = response.idFluxoTypebotWhatsapp;
        }
        window.open(`https://promokit.app.br:8443/pt-BR/typebots/${response.idFluxoTypebotWhatsapp}/edit`, '_blank');
      },
      (error) => {
        console.error('Erro ao criar fluxo do WhatsApp:', error);
      }
    );
  }

  private criarFluxoInstagram() {
    this.typebotService.criarFluxosPadrao().subscribe(
      (response: any) => {
        if (this.configuracoes) {
          this.configuracoes.idFluxoTypebotInstagram = response.idFluxoTypebotInstagram;
        }
        window.open(`https://promokit.app.br:8443/pt-BR/typebots/${response.idFluxoTypebotInstagram}/edit`, '_blank');
      },
      (error) => {
        console.error('Erro ao criar fluxo do Instagram:', error);
      }
    );
  }

  loginTypebot() {
    this.conectando = true;

    const width = 600;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const popup = window.open(
      'https://promokit.app.br:8443/pt-BR/signin?redirectPath=%2Ftypebots',
      'Login Typebot',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    const checkPopup = setInterval(() => {
      if (popup?.closed) {
        clearInterval(checkPopup);
        this.conectando = false;
        this.loginRealizado = true;
        this.loginSucesso = true;

        setTimeout(() => {
          this.botaoHabilitado = true;
        }, 1000);
      }
    }, 500);
  }

  voltarEtapa() {
    if (this.etapaAtual > 1) {
      this.etapaAtual--;
      const progressBar = document.querySelector('.wizard-progress-bar') as HTMLElement;
      if (progressBar) {
        progressBar.style.width = `${((this.etapaAtual - 1) / 2) * 100}%`;
      }
    }
  }
}
