<div class="row" *ngIf="!grupos.length">
  <div class="card-box"  >
    <div class="categoria">
      <ng-container *ngIf="carregando">
        <i class="k-icon k-i-loading mr-1" ></i> <b>Carregando...</b>
      </ng-container>

      <div class="alert alert-info" *ngIf="!carregando">
        Nenhum insumo cadastrado
      </div>
    </div>

  </div>
</div>



<ng-container *ngFor="let grupo of grupos">
  <div class="card" [hidden]="!carregando && !insumosPorGrupo[grupo.id]">
    <div class="categoria" >
      <h3 class="ml-2 ">
        <b> {{grupo.nome}}</b>
      </h3>

      <kendo-grid
        [loading]="carregando"
        (edit)="editarInsumo($event)"
        (pageChange)="mudouPaginacao($event)"
        (remove)="abraDialogRemoverInsumo($event)"
        [data]="insumosPorGrupo[grupo.id]"
        [class]="'gridProdutos'"   >
        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="Sem insumos cadastrados"
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>

        <kendo-grid-column title="Codigo"  [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="text-muted"><b>  {{dataItem.codigo || '-'}}</b></span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Nome"  >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span><b>  {{dataItem.nome}}</b></span>

            <span class="badge badge-info ml-1" *ngIf="dataItem.beneficiado">beneficiado</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Estoque Atual"    [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <ng-container *ngIf="dataItem.estoque && !dataItem.removido">
                 <span [ngClass]="{'text-success': dataItem.nivelEstoque > 1.5,
                              'text-warning': dataItem.nivelEstoque <= 1.5 ,
                              'text-danger': dataItem.nivelEstoque < 1 }" >
              <b> {{dataItem.estoqueAtual}} {{dataItem.unidadeMedida.sigla}}</b></span>

              <i class="k-icon k-i-list-bulleted cpointer text-blue ml-1" (click)="abraModalMovimentacoes(dataItem)"
                 style="    margin-top: -3px  " title="ver movimentações" kendoTooltip></i>
            </ng-container>


            <i class="k-icon k-i-exclamation-circle text-warning ml-1" kendoTooltip [title]=" dataItem.estoque.quantidadeReservada + ' reservados'"
               *ngIf="dataItem.estoque?.quantidadeReservada"></i>


            <span *ngIf="!dataItem.estoque || dataItem.removido">-</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Estoque Minimo"    [width]="150">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span *ngIf="dataItem.estoque" [hidden]="dataItem.removido">
                       <b>{{dataItem.estoque.quantidadeMinima}} {{dataItem.unidadeMedida.sigla}}</b>
            </span>

            <span *ngIf="!dataItem.estoque || dataItem.removido">
                -
            </span>
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column title="Preço de custo"    [width]="150">
           <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             {{dataItem.precoDeCusto | currency: "BRL"}}
           </ng-template>
         </kendo-grid-column> -->

        <kendo-grid-column title="Vínculos"    [width]="150"  media="(min-width: 1200px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <label class="text-blue cpointer" (click)="abraModalVincularInsumo(dataItem)" [hidden]="dataItem.removido">
              {{dataItem.totalVinculos}}
              <i class="fas fa-box-open"></i>
            </label>

          </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column title="" [width]="160" [class]="{'comandos': true}">
          <ng-template kendoGridCellTemplate let-isNew="isNew" let-insumo>

            <ng-container *ngIf="!insumo.removido">
              <button kendoGridEditCommand [primary]="true" (selectedChange)="editarInsumo(insumo)">
                <i class="fa fa-edit" kendoTooltip title="editar"></i>
              </button>

              <button   kendoGridRemoveCommand kendoTooltip ><i
                kendoTooltip [title]=" 'Remover insumo'"  class="fa fa-trash"></i>
              </button>
            </ng-container>

            <span class="badge badge-danger" *ngIf="insumo.removido">removido</span>

          </ng-template>

        </kendo-grid-command-column>

      </kendo-grid>
    </div>
  </div>
</ng-container>




<kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="fecheRemover()" [minWidth]="250" [width]="450">
  <div *ngIf="itemSelecionado && !excluindo">
    <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir o insumo
      "<strong>{{itemSelecionado.nome}}?</strong>"</p>
  </div>
  <div *ngIf="excluindo">
    <p style="margin: 30px; text-align: center;">Excluindo o insumo "<strong>{{itemSelecionado.nome}}"...</strong></p>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
  </div>
  <div *ngIf="mensagemRemocao">
    <p style="margin: 30px; text-align: center;" class="text-danger">
      <b>{{mensagemRemocao}}</b>
    </p>
  </div>

  <div class="modal-footer" *ngIf="!excluindo">
    <button type="button" *ngIf="itemSelecionado" (click)="removaInsumo()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" *ngIf="itemSelecionado" (click)="fecheRemover()" primary="true"  class="btn btn-light waves-effect">Cancelar</button>
    <button type="button" *ngIf="!itemSelecionado" (click)="fecheRemover()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
  </div>
</kendo-dialog>
