<div class="header-section">
  <div class="header-top">
    <button class="btn-voltar" (click)="voltar()">
      <i class="fas fa-arrow-left"></i>
    </button>
    <h4 class="page-title">
      <i class="fa fa-table"></i>
      Escolha a Mesa para Abrir
    </h4>
  </div>

  <div class="search-container">
    <kendo-textbox
      class="busca"
      name="filtro"
      placeholder="Para buscar uma mesa só digitar aqui"
      [(ngModel)]="filtro"
      [showSuccessIcon]="false">
      <ng-template kendoTextBoxSuffixTemplate>
        <kendo-textbox-separator></kendo-textbox-separator>
        <button kendoButton [look]="'clear'" [icon]="'search'"></button>
      </ng-template>
    </kendo-textbox>
  </div>
</div>

<div class="mesas-grid">
  <div class="alert alert-warning mt-2" *ngIf="(mesas | filtroObjetosNome: filtro) === -1">
    <h4>
      <i class="fas fa-exclamation-triangle"></i>
      Nenhuma {{empresa.identificadorMesa}} encontrada para <strong>{{filtro}}</strong>
    </h4>
  </div>

  <div class="mesa-card" *ngFor="let mesa of mesas | filtroObjetosNome: filtro">
    <div class="card mesa-item" *ngIf="mesa !== -1" [class.disponivel]="mesa.disponivel" [class.nao-gera-comanda]="mesa.naoGerarComanda">
      <div class="card-header" [class.disponivel]="mesa.disponivel" [class.ocupada]="!mesa.disponivel">
        <div class="mesa-info">
          <h4 class="mesa-numero">{{mesa.nome}}</h4>
          <div class="mesa-status">
            <span class="status-badge" [class.status-livre]="mesa.disponivel" [class.status-ocupada]="!mesa.disponivel">
              {{mesa.disponivel ? 'Livre' : 'Ocupada'}}
            </span>
            <span class="status-badge nao-gera-comanda" *ngIf="mesa.naoGerarComanda"
                  kendoTooltip [tooltipTemplate]="template" filter="span">
              <ng-template #template>
                <span>
                  Esta mesa está configurada para não gerar comandas.<br/>
                  Os pedidos serão registrados diretamente na lista de pedidos.
                </span>
              </ng-template>
              <i class="fas fa-exclamation-triangle"></i> Não gera comanda
            </span>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="mesa-disponivel" *ngIf="mesa.disponivel">
          <div class="status-icon">
            <i class="fas fa-check-circle"></i>
          </div>
          <span class="status-text">Mesa disponível para novas comandas</span>
          <button class="btn-selecionar" (click)="mesaEscolhida(mesa)">
            <i class="fas fa-check"></i>
            Selecionar Mesa
          </button>
        </div>

        <div class="mesa-ocupada" *ngIf="!mesa.disponivel">
          <div class="info-principal">
            <div class="valor-total">
              <span class="label">Total da Mesa</span>
              <span class="valor">{{mesa.valorTotal | currency:'BRL'}}</span>
            </div>
            <div class="info-secundaria">
              <div class="tempo">
                <i class="far fa-clock"></i>
                <span>{{mesa.tempo | number:'1.0-0'}}h em uso</span>
              </div>
              <div class="comandas-count" *ngIf="mesa.comandas?.length">
                <i class="fas fa-receipt"></i>
                <span>{{mesa.comandas.length}} {{mesa.comandas.length === 1 ? 'Comanda' : 'Comandas'}}</span>
              </div>
            </div>
          </div>

          <div class="acoes-mesa">
            <button class="btn-selecionar" (click)="mesaEscolhida(mesa)">
              <i class="fas fa-check"></i>
              Selecionar Mesa
            </button>
          </div>
        </div>

        <div class="pdv-integration" *ngIf="empresa?.integracaoDelivery?.sistema === 'ecletica'">
          <div class="pdv-status">
            <ng-container *ngIf="carregandoMesasIntegradas">
              <span class="pdv-label">{{empresa.integracaoDelivery.sistema}}:</span>
              <i class="k-i-loading k-icon"></i>
            </ng-container>

            <span class="pdv-badge" *ngIf="!carregandoMesasIntegradas && mesasIntegracao.length"
                  [ngClass]="{
                    'status-livre': mesa.mesaIntegrada?.status === 'Livre',
                    'status-ocupada': mesa.mesaIntegrada?.status === 'EmUso',
                    'status-fechando': mesa.mesaIntegrada?.status === 'Fechando',
                    'status-nao-integrada': !mesa.codigoPdv || (mesa.codigoPdv && !mesa.mesaIntegrada)
                  }">
              <ng-container *ngIf="mesa.mesaIntegrada">
                <b>{{mesa.mesaIntegrada.status}}</b>
              </ng-container>
              <ng-container *ngIf="!mesa.codigoPdv">Não integrada</ng-container>
              <ng-container *ngIf="mesa.codigoPdv && !mesa.mesaIntegrada">
                Código PDV inválido: <b>{{mesa.codigoPdv}}</b>
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Comandas -->
<app-lista-comandas
  *ngIf="modalAberto"
  [mesa]="mesaSelecionada"
  [comandas]="comandasAtuais"
  [mostrarBotaoNovo]="true"
  (selecionarComanda)="onSelecionarComanda($event)"
  (criarNovaComanda)="onCriarNovaComanda()"
  (fechar)="onFecharModal()">
</app-lista-comandas>
