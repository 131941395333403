import {Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
declare  var Hub;
@Component({
  selector: 'app-pagarmehub-acoes',
  templateUrl: './pagarmehub-acoes.component.html',
  styleUrls: ['./pagarmehub-acoes.component.scss']
})
export class PagarmehubAcoesComponent implements OnInit , OnDestroy{
  @Input() empresa: any = {}
  @Input() nomeTabOnline: any = {}
  @ViewChild('pagarmehub',  {static: false} ) pagarmehub: any;
  timeout: any;
  tentativas = 0;
  constructor(private _renderer2: Renderer2,  @Inject(DOCUMENT) private  _document: Document,
              private  activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.addScriptHugPagarme();
     if(!this.empresa.integracaoGatewayPagamento || this.empresa.integracaoGatewayPagamento.gateway === 'pagarme'){
      this.configureHubPargarme(this.empresa.integracaoGatewayPagamento ?
        this.empresa.integracaoGatewayPagamento.instalacaoId : null);
    }
  }

  private addScriptHugPagarme() {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src =  '/assets/js/hub.min.js';
    this._renderer2.appendChild(this._document.body, script);
  }


  configureHubPargarme(installId: string = null){
    if(typeof Hub === 'function' && this.pagarmehub){
      let redirectUrl =  String(`${window.location.origin}/admin/loja/retorno/pagarme`)
      let config: any = {
        publicAppKey : "21a6596d-5eeb-4324-9dc2-c5742d5db0d1",
        redirectUrl : redirectUrl , // "https://my-service.com/callback",
        language: "pt-BR",
        environment: "production", // "production", "dev"
      };

      if(installId)
        config.installId = installId;

      Hub(config);

      //button disparando form do angular pq esta sem tipo
      this.marqueTipoBotaoInserido();
    } else {
      if(this.timeout) clearTimeout(this.timeout);

      if(this.tentativas <= 30){
        this.timeout =  setTimeout(() => {
          console.log('aguardando scripts/tela: scripts: ' + (typeof Hub) + '-> tela: ' + (this.pagarmehub != null) )
          this.configureHubPargarme(installId)
          this.tentativas++;
        }, 1000);      }

    }

  }

  marqueTipoBotaoInserido(){
    const buttonInsideSpan = this.pagarmehub.nativeElement.querySelector('button');
    if (buttonInsideSpan) {
      buttonInsideSpan.setAttribute('type', 'button');
    } else {
      setTimeout(() => {
        this.marqueTipoBotaoInserido()
      }, 1000)
    }
  }

  ngOnDestroy(): void {
    if(this.timeout) clearTimeout(this.timeout);
  }


}
