import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ServerService} from "../../services/ServerService";

@Injectable({
  providedIn: 'root'
})
export class TypebotService extends ServerService {
  private apiUrl = '/configuracoesMia';

  constructor(protected http: HttpClient) {
    super(http);
  }

  verificarApiKey(apiKey: string): Promise<any> {
    return this.facaPost(`${this.apiUrl}/verificarChaveApi`, { chaveApi: apiKey });
  }

  verificarTypebotConfigurado(): Observable<any> {
    return this.http.get(`${this.apiUrl}/verificarTypebotConfigurado`);
  }

  criarFluxosPadrao(): Observable<any> {
    return this.http.post(`${this.apiUrl}/crieFluxosTypebotPadrao`, {});
  }

  publicarFluxo(idFluxo: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/publicarFluxo`, { idFluxo });
  }
}
