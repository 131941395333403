import {ObjetoPersistente} from "../ObjetoPersistente";
import {MapeadorBasico} from "../../mapeadores/MapeadorBasico";
import {ProdutoTemplateOpcao} from "../templates/ProdutoTemplateOpcao";
import {AdicionalDeProduto} from "./AdicionalDeProduto";
import {DependenciaOpcaoDeAdicional} from "./DependenciaOpcaoDeAdicional";

export class OpcaoDeAdicionalDeProduto extends ObjetoPersistente {
  id: number;
  adicional: AdicionalDeProduto;
  codigoPdv: string
  idIfood: string
  idProduto: number
  opcaoNaEmpresa: any
  dependencias: Array<DependenciaOpcaoDeAdicional> = [];
  qtdeMaxima: number;
  qtdeMinima: number;
  insumo: any;
  oculta: boolean;
  constructor(public nome: string, public  valor: number, public disponivel: boolean,
              public descricao: string = null, public template: ProdutoTemplateOpcao = null,
              public linkImagem: string = null) {
    super();
  }

  mapeador(): MapeadorBasico {
    return undefined;
  }

  clone(){
    let opcao =  new OpcaoDeAdicionalDeProduto(this.nome, this.valor, this.disponivel, this.descricao, null, this.linkImagem);

    opcao.codigoPdv = this.codigoPdv;
    opcao.idIfood = this.idIfood;

    return opcao
  }

  async estaDisponivel() {
     if(this.template && !this.template.disponivel) return false;

     let disponivel = this.disponivel ? true : false;

     if(this.opcaoNaEmpresa && this.opcaoNaEmpresa.id && this.opcaoNaEmpresa.disponivel != null)
       disponivel =  this.opcaoNaEmpresa.disponivel

    if(disponivel && this.insumo){
      let temEstoque = await this.insumo.temEstoque(1);

      return temEstoque;
    } else {
      return disponivel;
    }
  }

  mesmoParaImportar(outraOpcao: any){
    if(this.idIfood != null)   return this.idIfood === outraOpcao.idIfood
    if(this.codigoPdv != null)   return this.codigoPdv === outraOpcao.codigoPdv

    return this.nome === outraOpcao.nome
  }
}
