import { Component, OnInit } from '@angular/core';
import {NotificacoesService} from "../../services/notificacoes.service";


@Component({
  selector: 'app-tela-ifood-events',
  templateUrl: './tela-ifood-events.component.html',
  styleUrls: ['./tela-ifood-events.component.scss']
})
export class TelaIfoodEventsComponent implements OnInit {
  notificacoesPedido: any = [];
  notificacoesDelivery: any = [];
  carregando = false;
  constructor(private notificacoesService: NotificacoesService) { }

  ngOnInit(): void {

    this.notificacoesService.listeNotificacaoIfood({}).then((notificacoes) => {
      this.notificacoesPedido = notificacoes;
      this.carregando = false;
    })
  }

}
