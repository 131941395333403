import {PontuacaoRegistrada} from "./PontuacaoRegistrada";

import {EnumTipoDePontuacao} from "../lib/emun/EnumTipoDePontuacao";
import {Empresa} from "./Empresa";
import {IItemVenda, IVenda} from "./interfaces/venda/ivenda";

export abstract class TipoDePontuacao {

  constructor(tipo: any) {
    this.tipo = tipo
  }

  static  tipos = [
    { tipo: EnumTipoDePontuacao.Cashback, nome: 'Cashback', descricao: 'Valor da compra é retornado em crédito.',
      ajuda: "O cliente acumula um percentual de cashback em reais a cada compra realizada."},
    { tipo: EnumTipoDePontuacao.PorValor, nome: 'Valor por Ponto/Selo', descricao: ' 1 ponto a cada R$X comprados' ,
      ajuda: "O cliente acumula a cada R$X,xx em compras  1 ${acumulo}" },
    { tipo: EnumTipoDePontuacao.PorPontos, nome: 'Pontos/Selos por Real', descricao: 'X pontos a cada R$1,00 em compras',
      ajuda: "O cliente acumula X ${acumulo}s  a cada R$1,00 em compras " },
    { tipo: EnumTipoDePontuacao.QtdeFixa, nome: 'Quantidade fixa', descricao: 'Pontuação fixada para todas atividade',
      ajuda: "O cliente acumula X ${acumulo}(s) por compra feira" },
    { tipo: EnumTipoDePontuacao.QtdeFixaPorAtividade, nome: 'Quantidade fixa na atividade',
      descricao: 'Pontuação será definida por atividade', ajuda: "O cliente acumula X ${acumulo}  por compra feira"  },
    { tipo: EnumTipoDePontuacao.QtdeVariavelPorAtividade, nome: 'Quantidade variável',
      descricao: 'Pontuação será informada no ato do registro da atividade.',
      ajuda: "O cliente acumula X ${acumulo} dependendo do produto comprado" }
  ]

  static tiposIntegraAhPedidos: any = [
    EnumTipoDePontuacao.Cashback,  EnumTipoDePontuacao.PorValor, EnumTipoDePontuacao.PorPontos
  ]
  id: number;
  nome: string;
  tipo: string;
  descricao: string;

  abstract calculePontos(empresa: Empresa, pontuacaoRegistrada: PontuacaoRegistrada, venda: IVenda ): number

  abstract getDescricao(plano: any): string
  abstract getDescricaoAtividades(plano: any): string
  abstract clone(): TipoDePontuacao;

  calculeCashbackDosProdutos(pontuacaoRegistrada: PontuacaoRegistrada, venda: IVenda){
    if (!pontuacaoRegistrada || !pontuacaoRegistrada.atividades.length) return 0;

    if (pontuacaoRegistrada.valor < pontuacaoRegistrada.cartao.plano.valorMinimoPontuar)
      return 0;

    const atividade: any = pontuacaoRegistrada.atividades[0];
    // Calcula o total bruto da venda antes do desconto
    const totalBruto = venda.itens.reduce((soma, item) => soma + (item.total), 0);
    const fatorDesconto: number = totalBruto > 0 &&  venda.valor !== totalBruto ? (venda.valor ) / totalBruto : 1;

    return venda.itens.reduce((soma: number, item: IItemVenda) => {
      // Criamos um novo objeto mantendo a referência do método
      const itemAjustado: IItemVenda = fatorDesconto !== 1 ?
        Object.create(Object.getPrototypeOf(item), Object.getOwnPropertyDescriptors(item)) : item;
      // Ajusta o valor do item considerando o desconto proporcional
      itemAjustado.total =  item.total * fatorDesconto;
      // Usa a função obtenhaPontosFidelidade() com o valor ajustado
      return soma + itemAjustado.obtenhaPontosFidelidade(atividade);
    }, 0);
  }
}
