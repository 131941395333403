import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalKendo} from "../../lib/ModalKendo";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {CadGrupoInsumoComponent} from "../cad-grupo-insumo/cad-grupo-insumo.component";
import {NgForm} from "@angular/forms";
import {EstoqueService} from "../../services/estoque.service";
import {UnidadeMedidaUtils} from "../UnidadeMedidaUtils";
import {
  TelaHistoricoMovimentacoesComponent
} from "../tela-historico-movimentacoes/tela-historico-movimentacoes.component";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-cad-insumo',
  templateUrl: './cad-insumo.component.html',
  styleUrls: ['./cad-insumo.component.scss']
})
export class CadInsumoComponent   extends ModalKendo implements OnInit {
  @ViewChild('frm', {static: false}) frm: NgForm;
  @ViewChild('frmFicha', {static: false}) frmFicha: NgForm;
  @ViewChild('telaMovimentacoes', {static: false}) telaMovimentacoes: TelaHistoricoMovimentacoesComponent;
  usuarioLogado: any;
  insumo: any = {  estoque: { quantidade: 0, quantidadeMinima: 1}, totalVinculos: 0, precoDeCusto: 0};
  abaSelecionada  = 1;
  empresa: any = {};
  unidades = [];
  mensagemSucesso: any;
  mensagemErro: string;
  grupos = [];
  salvando = false;
  novaMovimentacao: any;

  FICHA_PRODUTO_VENDA  = 'produto-venda';
  FICHA_PRODUTO_BENEFICIADO  = 'produto-beneficiado';
  constructor( private dialogService: DialogService,  private estoqueService: EstoqueService,
               private autorizacaoService: AutorizacaoService) {

    super()
  }

  static abraModalInsumo(dialogService: any, grupos: any, insumo: any = null, aba = null, fichaTecnica: boolean = null,
                         fnSalvou: any = null) {
    //scrollable: true
    const windowRef: DialogRef = dialogService.open({
      content: CadInsumoComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100

    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.grupos = grupos;

    if(insumo)     windowRef.content.instance.insumo = insumo;
    if(aba)        windowRef.content.instance.abaSelecionada = aba

    if(fichaTecnica)
      windowRef.content.instance.insumo.fichaTecnica = true;

    windowRef.result.subscribe( (result: any) => {
        if(fnSalvou && result.salvou) fnSalvou(result);
      },
      (a) => {      });
  }


  ngOnInit(): void {

    this.estoqueService.listeUnidades().then( (unidades: any) => {
      this.unidades = unidades;
      if(this.insumo.unidadeMedida)
        this.insumo.unidadeMedida = this.unidades.find( unidade => unidade.id === this.insumo.unidadeMedida.id );
      else
        this.insumo.unidadeMedida = unidades.find((item: any) => item.sigla === 'und');

      if(this.insumo.grupo)
        this.insumo.grupo = this.grupos.find((item: any) => item.id ===      this.insumo.grupo.id)
    });


    if(this.insumo.fichaTecnica && !this.insumo.receita){
      this.insumo.receita = {
        ingredientes: []
      }
      //receitas via regra tem no minimo 2 indredientes
      this.novoIngrediente();
      this.novoIngrediente();
    }
    this.usuarioLogado = this.autorizacaoService.getUser();
  }

  novoIngrediente(){
    this.insumo.receita.ingredientes.push( {insumo: null})
  }

  removeIngrediente(i){
    let item = this.insumo.receita.ingredientes[i]

    if(!item.id){
      this.insumo.receita.ingredientes.splice(i, 1);
    } else {
      item.removido = true;
    }

  }

  onSalvar() {
    if(this.frm.valid)   this.salveInsumo();
  }

  salveInsumo(){
    this.salvando = true;
    this.estoqueService.salveInsumo(this.insumo).then((insumo) => {
      if(!this.insumo.id) this.insumo.id = insumo.id;
      this.insumo.salvou = true;
      if(this.insumo.receita)
        this.insumo.receita.ingredientes = this.insumo.receita.ingredientes.filter((item: any) => !item.removido)
      this.salvando = false;
      this.fecheModal(this.insumo);
    }).catch((err) => {
      this.mensagemErro = err
      this.salvando = false;
    })
  }

  onSalvarReceita() {
    if(this.frmFicha.valid)  this.salveInsumo();
  }

  alterouValorUnidade() {

  }

  handleFilter(value) {

  }

  obtenhaFormato(){
    return this.obtenhaFormatoDoInsumo(this.insumo)
  }

  obtenhaFormatoDoInsumo(insumo: any){
    return UnidadeMedidaUtils.obtenhaFormatoItem(insumo)
  }

  obtenhaDescricaoUnidade(insumo: any){
    return UnidadeMedidaUtils.obtenhaDescricaoUnidade(insumo)
  }

  abraModalNovoGrupo(){
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      content: CadGrupoInsumoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100

    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)
    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result: any) => {
        if(result && result.id){
          this.grupos.push(result)
          this.insumo.grupo = result;
        }
      },
      (a) => {      });
  }

  selecioneAba(aba){
    this.abaSelecionada = aba
  }

  escolheuTipo(tipo: any) {
    this.insumo.tipo = tipo;
    if(this.fichaTecnicaDeProdutoVenda()){
      delete this.insumo.estoque
      this.insumo.receita.rendimento = 1;
    }

  }

  gerarMovimentacao() {
    this.novaMovimentacao = { horario: new Date(), insumo: this.insumo};
  }

  fichaTecnicaDeProdutoVenda(){
    return this.insumo.tipo === this.FICHA_PRODUTO_VENDA
  }

  fichaTecnicaDeProdutoBeneficiado(){
    return this.insumo.tipo === this.FICHA_PRODUTO_BENEFICIADO
  }

  salvouMovimentacao(movimentacao: any) {
    Object.assign(this.insumo.estoque, movimentacao.insumo.estoque)
    this.insumo.salvou = true;
    this.cancelouMovimentacao();

    setTimeout(() => {
      this.telaMovimentacoes.movimentacoes.splice(0, 0, movimentacao)
    }, 500)


  }

  cancelouMovimentacao(){
    delete this.novaMovimentacao;
  }
}
