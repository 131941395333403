<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

<kendo-tabstrip class="nav-bordered vertical-tabstrip">
  <kendo-tabstrip-tab [title]="'Detalhes'" [selected]="true">
    <ng-template kendoTabContent>
        <fieldset>
          <legend>
            <label  >Detalhes do cupom</label>
          </legend>

          <div class="row">
            <div class="form-group mb-3 col-7">
              <label for="codigo">Código</label>
              <div class="input-group">
                <input id='codigo'  name="codigo" minlength="5"    class="form-control" pattern="^[a-zA-Z0-9]+$" appAutoFocus
                       placeholder="minimo 5 caracteres" required  type="text"  [(ngModel)]="cupom.codigo" #codigo="ngModel">

                <div class="input-group-append" (click)="gereCodigoCupom()" title="gerar aleátorio" kendoTooltip  >
          <span class="input-group-text">
                <i class="fa fa-random cpointer" ></i>
          </span>
                </div>

              </div>

              <div *ngIf="codigo.errors && (codigo.dirty || codigo.touched)" class="text-danger">
                <div *ngIf="codigo.errors.pattern">O código deve conter somente letras e números.</div>
                <div *ngIf="codigo.errors.required">O código é obrigatório.</div>
                <div *ngIf="codigo.touched && codigo.errors.minlength">O código deve ter no mínimo 5 caracteres.</div>
              </div>
            </div>
            <div class="form-group mb-3 col-10">
              <label for="nome">Descrição</label>
              <input type="text" class="form-control" autocomplete="off"
                     id="nome" name="nome" [(ngModel)]="cupom.nome" #nome="ngModel"
                     placeholder="Descrição para o cupom" value="" required/>
              <div class="invalid-feedback">
                <p *ngIf="nome.errors?.required">Descrição do cupom é obrigatório</p>
              </div>
            </div>
            <div class="form-group mb-3 col-10">
              <label for="codigo">Tipo do cupom</label>

              <div class="input-group mt-2 box-escolher">
                <ng-container *ngFor="let tipo of tiposDeCupoms" >
                  <div  >
                    <input id="tipoCupom{{tipo}}" name="tipoCupom" type="radio" class="k-radio" kendoRadioButton
                           [(ngModel)]="cupom.tipo" [value]="tipo" [required]="true"   [disabled]="cupom.jaUtilizado" />

                    <label for="tipoCupom{{tipo}}" class="ml-1 mr-1">

                      {{tipo === 'valor' ? "Reais" : "Percentual"}}
                    </label>
                    <!-- Adiciona ícones para cada tipo -->
                    <ng-container [ngSwitch]="tipo">
                      <img [src]="'/assets/icons/dinheiro.png'" *ngSwitchCase="'valor'" height="40" class="valor">
                      <img [src]="'/assets/icons/percentagem.png'" *ngSwitchCase="'percentual'" height="25">
                    </ng-container>
                  </div>
                </ng-container>

                <div class="invalid-feedback" *ngIf="!cupom.tipo">
                  Informe um tipo
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-3 col-4" *ngIf="cupom.tipo === EnumTipoDeCupom.Valor">
              <label for="valor">Valor do desconto</label>
              <div class="input-group">
                <kendo-numerictextbox [attr.type]="'number'" id="valor"    name="valor"  class="form-control" format="c2"
                                      [(ngModel)]="cupom.valor"
                                      required    >
                </kendo-numerictextbox>
                <div class="invalid-feedback" >
                  Informe um valor para o cupom
                </div>
              </div>
            </div>

            <div class="form-group mb-3 col-4" *ngIf="cupom.tipo === EnumTipoDeCupom.Percentual">
              <label for="percentual">Percentual do desconto</label>
              <div class="input-group">
                <kendo-numerictextbox  [attr.type]="'number'" id="percentual"    name="percentual"  class="form-control"
                                       [(ngModel)]="cupom.percentual" [min]="1" [max]="100"
                                       [format]="obtenhaFormatoPercentual()"
                                       required     >
                </kendo-numerictextbox>
                <div class="invalid-feedback" >
                  Informe um percentual para o cupom
                </div>
              </div>
            </div>

            <div class="form-group col-5 mb-3"  >
              <label  >Valor mínimo <b>do pedido</b> </label>
              <div class="input-group">
                <kendo-numerictextbox [attr.type]="'number'" id="valorMinimo" #valorMinimo  class="form-control"
                                      format="c2"   name="valorMinimo" [min]="0"
                                      [(ngModel)]="cupom.valorMinimo" >
                </kendo-numerictextbox>

              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-3 col-12 "
                 *ngIf="(cupom.produto?.id || cupom.categoria?.id || cupom.produtoTemplateTamanho?.id) && !cupom.brindeResgate">

              <div class="input-group mt-0">
                <input  name="produtoNaoComporMinimo" id='produtoNaoComporMinimo'    class="k-checkbox  " type="checkbox" kendoCheckBox
                        [(ngModel)]="cupom.produtoNaoComporMinimo" [disabled]="cupom.minimoApenasNoValorProduto" />
                <label  class="k-checkbox-label" for="produtoNaoComporMinimo">
                  Produto(s) não compor o <b>valor mínimo do pedido</b></label>

              </div>

            </div>
          </div>

          <div class="row ">

            <div class="form-group mb-2 col-5">

              <div class="input-group mt-2">
                <input  name="primeiraCompra" id='primeiraCompra' kendoCheckBox   class="k-checkbox  " type="checkbox"
                        [(ngModel)]="cupom.primeiraCompra"/>
                <label  class="k-checkbox-label" for="primeiraCompra">Exclusivo para <b> 1° compra</b></label>
              </div>

            </div>
            <div class="col" [hidden]="cupom.brindeResgate">
              <div class="input-group mt-2 mb-2">
                <input  name="selecionavel" id='selecionavel' kendoCheckBox   class="k-checkbox  " type="checkbox"
                        [(ngModel)]="cupom.selecionavel"/>
                <label  class="k-checkbox-label" for="selecionavel">Exibir cupom na loja para ser selecionado</label>
              </div>
            </div>
          </div>

        </fieldset>


        <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
          <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
           {{mensagemErro}}
            </span>
        </div>

          <p [ngClass]="{ 'visible': mensagemSucesso, 'hidden': !mensagemSucesso }" class="text-success mt-2">
            <i class="mdi mdi-check-all mr-1"></i> <b> {{mensagemSucesso}}</b>
          </p>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
          <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
        </div>

    </ng-template>
  </kendo-tabstrip-tab>


  <kendo-tabstrip-tab [title]="'Onde será aplicado?'" >
    <ng-template kendoTabContent>
      <fieldset>
        <legend>
          <label  >Alvo e onde será o desconto</label>
        </legend>
        <div class="row">
          <div class="form-group mb-2 col-12">
            <label for="codigo">O cupom será aplicado em:</label>
            <div class="input-group mt-2 box-escolher sem-imagem">
              <div>
                <input id="alvoProduto" name="alvo" type="radio" class="k-radio" kendoRadioButton
                       (ngModelChange)="alvoSeraProdutos()"
                       [(ngModel)]="cupom.alvo" [value]="'produto'" [required]="true"  [disabled]="cupom.jaUtilizado" />

                <label for="alvoProduto" class="ml-1 mr-1">
                  Produtos
                </label>

              </div>

              <div>
                <input id="alvoTaxaEntrega" name="alvo" type="radio" class="k-radio" kendoRadioButton
                       (ngModelChange)="alvoSeraTaxaEntrega()"  [disabled]="cupom.jaUtilizado"
                       [(ngModel)]="cupom.alvo" [value]="'taxaEntrega'" [required]="true" />

                <label for="alvoTaxaEntrega" class="ml-1 mr-1">
                  Taxa de Entrega
                </label>

              </div>

              <div *ngIf="cupom.tipo === EnumTipoDeCupom.Percentual"  >
                <input id="alvoFidelidade" name="alvo" type="radio" class="k-radio" kendoRadioButton (ngModelChange)="alvoSeraFidelidade()"
                       [(ngModel)]="cupom.alvo" [value]="'fidelidade'" [required]="true"  [disabled]="cupom.jaUtilizado"  />

                <label for="alvoFidelidade" class="ml-1 mr-1">
                  Turbinar Cashback
                </label>
              </div>
            </div>



          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group mb-3">
              <label><b>Onde</b> o cupom é válido?</label>
              <div class="input-group">
                <input id="escopo0" name="escopo" type="radio"  class="k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.escopo" [value]="'livre'" >
                <label for="escopo0" class="k-radio-label">&nbsp;Livre</label>
                <input id="escopo1" name="escopo" type="radio"  class="ml-1 k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.escopo" [value]="'loja'" >
                <label for="escopo1" class="k-radio-label">&nbsp;Apenas no cardápio</label>
                <input id="escopo2" name="escopo" type="radio"  class="ml-1 k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.escopo" [value]="'app'" >
                <label for="escopo2" class="k-radio-label">&nbsp;Apenas no Aplicativo</label>

                <input id="escopo3" name="escopo" type="radio"  class="ml-1 k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.escopo" [value]="'mesas'"  [hidden]="this.cupom.aplicarNaTaxaDeEntrega">
                <label for="escopo3" class="k-radio-label">&nbsp;Apenas em Mesas</label>

              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="cupom.escopo !== 'mesas' && !this.cupom.aplicarNaTaxaDeEntrega">
          <div class="col">
            <div class="form-group mb-3">
              <label>Para qual <b>forma de entrega ou retirada</b> é válido?</label>
              <div class="input-group">
                <input id="forma0" name="formaEntrega" type="radio"  class="k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.formaEntrega" [value]="''" >
                <label for="forma0" class="k-radio-label">&nbsp;Todas</label>

                <input id="forma1" name="formaEntrega" type="radio"  class="k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.formaEntrega" [value]="'delivery'" >
                <label for="forma1" class="k-radio-label">&nbsp;Delivery</label>
                <input id="forma2" name="formaEntrega" type="radio"  class="ml-1 k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.formaEntrega" [value]="'retirada'" >
                <label for="forma2" class="k-radio-label">&nbsp;Retirada</label>
                <input id="forma3" name="formaEntrega" type="radio"  class="ml-1 k-radio"   kendoRadioButton
                       [(ngModel)]="cupom.formaEntrega" [value]="'comerlocal'" >
                <label for="forma3" class="k-radio-label">&nbsp;Comer no local</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" [hidden]="  cupom.aplicarNaFidelidade || cupom.aplicarNaTaxaDeEntrega">
          <div class="form-group mb-2 col-6" >
            <div class="input-group mt-2">
              <input  name="naoPontuarFidelidade" id='naoPontuarFidelidade' [disabled]="cupom.aplicarNaFidelidade" kendoCheckBox  class="k-checkbox  " type="checkbox"
                      [(ngModel)]="cupom.naoPontuarFidelidade"/>
              <label  class="k-checkbox-label" for="naoPontuarFidelidade">Não pontuar fidelidade</label>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
        <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
           {{mensagemErro}}
            </span>
      </div>
      <p [ngClass]="{ 'visible': mensagemSucesso, 'hidden': !mensagemSucesso }" class="text-success mt-2">
        <i class="mdi mdi-check-all mr-1"></i> <b> {{mensagemSucesso}}</b>
      </p>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
        <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab [title]="'Restrições'"  >
    <ng-template kendoTabContent>
      <fieldset>
        <legend>
          <label  >Restringir uso do cupom</label>
        </legend>

        <div class="row">
          <div class="form-group col-3 mb-2  mt-2"  >
            <label  >Quantidade</label>
            <div class="input-group">
              <kendo-numerictextbox [attr.type]="'number'"   #quantidade  class="form-control"
                                    format="n0"   name="quantidade" [min]="0"
                                    [(ngModel)]="cupom.quantidade" >
              </kendo-numerictextbox>

            </div>
          </div>

          <div class="form-group col-4 mb-2  mt-2"  >
            <label  >Máxima por usuário </label>
            <div class="input-group">
              <kendo-numerictextbox [attr.type]="'number'"   #qtdeMaxima class="form-control"
                                    format="n0"   name="qtdeMaxima" [min]="0"
                                    [(ngModel)]="cupom.qtdeMaxima" >
              </kendo-numerictextbox>

            </div>
          </div>



          <div class="form-group mb-2 col-7">

            <div class="input-group mt-2">
              <input  name="restrito" id='restrito' kendoCheckBox   class="k-checkbox  " type="checkbox"
                      [(ngModel)]="cupom.restrito"/>
              <label  class="k-checkbox-label" for="restrito">Restrito a uma lista de contatos</label>


            </div>

          </div>


          <div class="form-group mb-2 col-12 " >
            <div class="input-group mt-2">
              <input  name="restritoContatoPerdido" id='restritoContatoPerdido' kendoCheckBox   class="k-checkbox  " type="checkbox"
                      [(ngModel)]="cupom.restritoContatoPerdido"/>
              <label  class="k-checkbox-label"  for="restritoContatoPerdido">Restringir uso apenas contatos perdidos</label>


            </div>
          </div>

          <div class="form-group mb-2 col-12 " >
            <div class="input-group mt-2">
              <input  name="aniversariantes" id='aniversariantes' kendoCheckBox    class="k-checkbox  " type="checkbox"
                      [(ngModel)]="cupom.restritoAniversariantes"/>
              <label  class="k-checkbox-label"  for="restritoContatoPerdido">Restrito a aniversariantes</label>


            </div>
          </div>

          <div class="input-group mt-2 col-12">
            <input  name="naoAplicarProdutosComDesconto" id='naoAplicarProdutosComDesconto' kendoCheckBox    class="k-checkbox  " type="checkbox"
                    [(ngModel)]="cupom.naoAplicarProdutosComDesconto"/>
            <label  class="k-checkbox-label" for="naoAplicarProdutosComDesconto"> Não aplicar cupom em produtos com descontos acima de: </label>

          </div>

          <div class="input-group mt-2 col-sm-4 col-6"  >
            <div class=" ml-4">
              <kendo-numerictextbox   [attr.type]="'number'" [min]="0.1" [max]="100"  class="form-control" name="valorMaximoDescontoProduto" #valorMaximoDescontoProduto=ngModel
                                      [disabled]="!cupom.naoAplicarProdutosComDesconto"
                                      [format]="obtenhaFormatoPercentual()" [required]="cupom.naoAplicarProdutosComDesconto"
                                      [(ngModel)]="cupom.percentualMaximoDescontoProduto" required>
              </kendo-numerictextbox>

              <div class="invalid-feedback">
                <p *ngIf="valorMaximoDescontoProduto.errors?.required">Informe o percentual de desconto</p>
                <p *ngIf="valorMaximoDescontoProduto.errors?.minError">Valor mínimo é  {{valorMaximoDescontoProduto.errors?.minError?.minValue}}</p>
                <p *ngIf="valorMaximoDescontoProduto.errors?.maxError">Valor máximo é  {{valorMaximoDescontoProduto.errors?.maxError?.maxValue}}</p>

              </div>
            </div>
          </div>

          <div class="input-group mt-2 col-sm-8 col-6  "  >

            <div class="input-group mt-0">
              <input  name="permitirComprarComPromocao" id="permitirComprarComPromocao" kendoCheckBox  class="k-checkbox  " type="checkbox" [disabled]="!cupom.naoAplicarProdutosComDesconto"
                      [(ngModel)]="cupom.permitirComprarComPromocao"/>
              <label  class="k-checkbox-label" for="permitirComprarComPromocao">Permitir que o cupom seja aplicado em   <br> outros produtos do carrinho </label>


            </div>

          </div>

          <div class="form-group  col-12 ">
            <label   >Formas de pagamentos</label>

            <kendo-multiselect  id="formasDePagamento" name="formasDePagamento"
                                [data]="formasDePagamento"   placeholder="Marque um ou mais forma de pagamençto"
                                textField="descricao" valueField="id"
                                [(ngModel)]="cupom.formasDePagamento"  (valueChange)="alterouFormasPagamento()">

              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span>{{ dataItem.descricao }}</span>
              </ng-template>


            </kendo-multiselect>
          </div>


          <div class="form-group mb-3 col-12  mt-2" *ngIf="tamanhosPizzas.length">
            <label  >Aplicar desconto somente em pizzas do tamanho</label><br>
            <kendo-dropdownlist class="form-control" name="tamanhoTemplate"
                                (selectionChange)="alterouComboProduto($event)"
                                [data]="tamanhosPizzas"
                                [filterable]="false"
                                [textField]="'descricao'" [valueField]="'id'"
                                [(ngModel)]="cupom.produtoTemplateTamanho"
            >
            </kendo-dropdownlist>

          </div>

          <div class="form-group mb-3 col-12 "  >
            <label  >Apenas se o produto de uma categoria estiver no carrinho</label><br>

            <kendo-dropdownlist class="form-control" name="categoria"
                                (selectionChange)="alterouComboProduto($event)"
                                [data]="categorias"
                                [filterable]="true"
                                [textField]="'nome'" [valueField]="'id'"
                                [(ngModel)]="cupom.categoria"
                                (filterChange)="searchCategoria($event)"
            >
            </kendo-dropdownlist>

          </div>

          <div class="form-group mb-3 col-12"  >
            <label  >Apenas se o produto estiver no carrinho</label><br>

            <kendo-dropdownlist class="form-control" name="produto"
                                [data]="produtos"   (selectionChange)="alterouComboProduto($event)"
                                [filterable]="true"   [disabled]="cupom.brindeResgate"
                                [textField]="'descricao'" [valueField]="'id'"
                                [(ngModel)]="cupom.produto"
                                (filterChange)="search($event)"
                                (focus)="busquePrimeiraVez()"
            >
            </kendo-dropdownlist>

          </div>


          <ng-container *ngIf="cupom.valorMinimo">
            <div class="form-group mb-3 col-12 " *ngIf="cupom.produtoTemplateTamanho">
              <div class="input-group mt-0" >
                <input  name="minimoApenasMesmoTamanho" id='minimoApenasMesmoTamanho' kendoCheckBox   class="k-checkbox  " type="checkbox"
                        [(ngModel)]="cupom.minimoApenasMesmoTamanho"  />
                <label  class="k-checkbox-label" for="minimoApenasMesmoTamanho">
                  <b>Valor mínimo pedido</b> composto apenas por pizzas mesmo tamanho</label>

              </div>
            </div>


            <div class="form-group mb-3 col-12 "
                 *ngIf="cupom.produto?.id || cupom.categoria?.id || cupom.produtoTemplateTamanho?.id">

              <div class="input-group mt-0">
                <input  name="minimoApenasNoValorProduto" id='minimoApenasNoValorProduto' kendoCheckBox class="k-checkbox  " type="checkbox"
                        [(ngModel)]="cupom.minimoApenasNoValorProduto" [disabled]="cupom.produtoNaoComporMinimo"/>
                <label  class="k-checkbox-label" for="minimoApenasNoValorProduto">
                  <b>Valor mínimo pedido</b> será composto apenas pelos produtos</label>

              </div>

            </div>


            <div class="form-group mb-3 col-12 ">
              <label  >Exibir mensagem erro valor mínimo</label><br>
              <textarea class="form-control" id="mensagemMinimo"
                        [placeholder]="'Padrao: Valor mínimo do pedido para ativar esse cupom é ' +  (cupom.valorMinimo | currency: 'BRL')"
                        rows="3" name="mensagemMinimo" [(ngModel)]="cupom.mensagemMinimo" #mensagemMinimo="ngModel" #textArea
              ></textarea>
            </div>


          </ng-container>


        </div>
      </fieldset>

      <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
        <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
           {{mensagemErro}}
            </span>
      </div>

      <p [ngClass]="{ 'visible': mensagemSucesso, 'hidden': !mensagemSucesso }" class="text-success mt-2">
        <i class="mdi mdi-check-all mr-1"></i> <b> {{mensagemSucesso}}</b>
      </p>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
        <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
      </div>
    </ng-template>

  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'Disponibilidade'"  >
    <ng-template kendoTabContent>

      <fieldset>
        <legend>
          <label  >Disponibilidades do cupom</label>
        </legend>

         <div class="row">
           <div class="form-group mb-3 col-4">
             <label for="validade">Data validade</label>

             <div class="input-group">

               <kendo-datepicker [(ngModel)]="cupom.validade" id="validade"    name="validade" [format]="'dd/MM/yyyy'"
                                 class="form-control   k-display-inline-block"  [min]="hoje" >
                 <kendo-datepicker-messages
                   today="Hoje"
                   toggle="Alterar data"
                 ></kendo-datepicker-messages>

               </kendo-datepicker>

             </div>
           </div>
         </div>



        <div class="row">
          <div class="col">
            <p>Disponibilizar apenas em dias específicos da semana</p>
            <p class="k-block">
              <button type="button" class="mr-1"  kendoButton (selectedChange)="onSelectedChange(ds, $event)" *ngFor="let ds of disponibilidadesSemana"
                      [look]="'outline'"     [toggleable]="true" [selected]="ds.disponivel"  >{{ds.label}}</button>


            </p>
          </div>
        </div>
      </fieldset>


      <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
        <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
           {{mensagemErro}}
            </span>
      </div>

      <p [ngClass]="{ 'visible': mensagemSucesso, 'hidden': !mensagemSucesso }" class="text-success mt-2">
        <i class="mdi mdi-check-all mr-1"></i> <b> {{mensagemSucesso}}</b>
      </p>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
        <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
      </div>
    </ng-template>

  </kendo-tabstrip-tab>
</kendo-tabstrip>

</form>
