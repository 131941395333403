import {Component, Input, OnInit} from '@angular/core';
import {PedidosService} from "../../services/pedidos.service";
import {
  ModalSolicitarCancelamentoDeliveryComponent
} from "../modal-solicitar-cancelamento-delivery/modal-solicitar-cancelamento-delivery.component";

import {ModalUberdirectdetalhesComponent} from "../modal-uberdirectdetalhes/modal-uberdirectdetalhes.component";
import {ModalDeliverydetalhesComponent} from "../modal-deliverydetalhes/modal-deliverydetalhes.component";
import {
  ModalFoodydeliveryDetathesComponent
} from "../modal-foodydelivery-detathes/modal-foodydelivery-detathes.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {ModalIfoodNotificacoesComponent} from "../modal-ifood-notificacoes/modal-ifood-notificacoes.component";
import {ModalCotacaoDeliveryComponent} from "../modal-cotacao-delivery/modal-cotacao-delivery.component";

@Component({
  selector: 'app-acoes-delivery',
  templateUrl: './acoes-delivery.component.html',
  styleUrls: ['./acoes-delivery.component.scss']
})
export class AcoesDeliveryComponent implements OnInit {
  @Input() pedido: any = {};
  @Input() empresa: any = {};
  @Input() exibirDetalhes: boolean ;
  integracaoOpendelivery: any;
  integracaoUber: any;
  restaurante =  false;
  acoesRetorno  = [  { id: 1, descricao: 'Retornar a loja'}, { id: 2, descricao: "Descartar"}];
  acaoRetorno: any;
  integracaoFoodyDelivery: any;
  integracaoIfood: any;
  selecionarParceiro = false;
  parceiros = []
  parceiroSelecionado: any;
  deliveryCancelar: any;
  integracoesIfood: any = []
  constructor(private pedidosService: PedidosService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.integracaoOpendelivery =
      this.empresa.integracaoOpendeliveryLogistica &&  this.empresa.integracaoOpendeliveryLogistica.ativa ?
        this.empresa.integracaoOpendeliveryLogistica : null;

    this.integracaoUber =
      this.empresa.integracaoUberdirect &&  this.empresa.integracaoUberdirect.ativa ? this.empresa.integracaoUberdirect :  null;
    this.integracaoFoodyDelivery  = this.empresa.integracaoFoodyDelivery;


    if(this.empresa.integracoesIfood.length){
      this.integracoesIfood =  this.empresa.integracoesIfood.filter((item: any) => item.shippingApi);

      this.integracoesIfood.forEach((integracao: any) => {
        integracao.ifood = true;
        integracao.descricao = `Ifood ${integracao.nomeLoja || ''}`.trim()
      })

      if(this.integracoesIfood.length === 1)
        this.integracaoIfood =  this.integracoesIfood[0]

    }

    if(this.integracaoFoodyDelivery)
      this.integracaoFoodyDelivery.descricao   = 'Foody Delivery'

    if(this.integracaoUber){
      this.integracaoUber.descricao  = 'Uber';
      this.restaurante =  this.integracaoUber.restaurante;
      this.acaoRetorno = this.acoesRetorno.find((item: any) => item.id === this.integracaoUber.acaoretorno)
    }

    if(this.integracaoOpendelivery )
      this.integracaoOpendelivery.descricao  = 'Parceiro OpenDelivery';

    this.setParceirosSelecionar();
    this.setParceiroSelecionado();

    if(this.pedido.cancelado  || this.pedido.finalizado || !this.temMaisDeUmParceiroAtivo())
      return;

    if(this.pedido.despachar){
      if(this.pedido.delivery && (this.pedido.delivery.foiAceita || this.pedido.delivery.pendente ||
        !this.pedido.delivery.podeSolicitarNovamente)){
        this.selecionarParceiro = false;

      }   else
        this.selecionarParceiro =  true;
    }
  }

  setParceiroSelecionado(){
    if(!this.pedido.delivery) return;

    if(this.integracaoFoodyDelivery && this.pedido.delivery.origem === this.integracaoFoodyDelivery.sistema){
      this.parceiroSelecionado = this.integracaoFoodyDelivery;
    } else if(this.pedido.delivery.origem === 'uber'){
      this.parceiroSelecionado = this.integracaoUber;
    } else if(this.pedido.delivery.origem === 'ifood'){
      this.parceiroSelecionado = this.integracaoIfood
    } else  {
      this.parceiroSelecionado = this.integracaoOpendelivery;
    }
  }

  setParceirosSelecionar(){
    this.parceiros = [];

    if(this.integracaoFoodyDelivery)
      this.parceiros.push(this.integracaoFoodyDelivery)

    if(this.integracaoUber)
      this.parceiros.push(this.integracaoUber)

    if(this.integracaoOpendelivery)
      this.parceiros.push(this.integracaoOpendelivery)

    if(this.integracoesIfood.length)
      this.parceiros.push(...this.integracoesIfood)

    if(this.parceiros.length === 1)
      this.parceiroSelecionado = this.parceiros[0]

  }

  temMaisDeUmParceiroAtivo(){
     return this.parceiros.length  > 1
  }


  verCotacao(){
    if(this.pedido.cancelado || this.pedido.finalizado || this.deliveryCancelar)
      return false;

    return  this.pedido.delivery && this.pedido.delivery.cotacao;
  }
  podeSolicitar(){
    if(this.selecionarParceiro && !this.parceiroSelecionado)
      return false;

    if(this.pedido.delivery && this.pedido.delivery.cotacao) return false;

    if(this.pedidoFechado()) return false;

    if( this.solicitacaoUber() &&  !this.pedido.pago)
      return false;

    return this.pedido.aguardandoColeta;
  }

  pedidoFechado(){
    return this.pedido.cancelado || this.pedido.finalizado;
  }

  solicitacaoUber(){
    if( this.pedido.delivery && !this.deliveryCancelar)
      return this.pedido.delivery.origem === 'uber'

    if(!this.integracaoUber) return false;

    if(!this.temMaisDeUmParceiroAtivo()) return true;

    return   this.parceiroSelecionado && this.parceiroSelecionado.id === this.integracaoUber.id;
  }

  solicitacaoIfood(){
    if(!this.integracoesIfood.length) return false;


    if(this.todosParceiosSaoIfood()) return true;

    if(!this.temMaisDeUmParceiroAtivo()) return true;

    if(!this.integracaoIfood) return false;

    return   this.parceiroSelecionado && this.parceiroSelecionado.id === this.integracaoIfood.id;
  }

  todosParceiosSaoIfood(){
    if(!this.integracoesIfood.length) return false;

    return this.parceiros.length === this.integracoesIfood.length;
  }

  solicitacaoFoody(){
    if( this.pedido.delivery){
      if( this.deliveryCancelar) return false;

      return this.pedido.delivery.origem === 'foodydelivery'
    }

    if(!this.integracaoFoodyDelivery) return false;

    if(!this.temMaisDeUmParceiroAtivo()) return true;

    return this.parceiroSelecionado && this.parceiroSelecionado.id === this.integracaoFoodyDelivery.id;
  }

  foodyDeliveryAguardando(){
    if(!this.pedido.delivery) return false;

    if(this.pedido.delivery.origem !== 'foodydelivery') return false;

    return this.pedido.delivery.podeCancelar;
  }


  exibirCard(){
    if(this.pedido &&  this.pedido.delivery) return true;

    if(this.pagamentoOnlinePendente() || !this.pedido.aceito) return false;

    return this.integracaoAtiva();
  }

  integracaoAtiva(){
    return this.integracaoOpendelivery || this.integracaoUber || this.integracaoFoodyDelivery || this.integracoesIfood.length
  }

  pagamentoOnlinePendente(){
     return this.pedido.aguardandoPagamentoOnline
  }

  exibaAlertaMarcarPago(msg: string) {
    const windowRef: DialogRef = this.dialogService.open({
      title: "Atenção",
      content: msg,
      actions: [
        { text: "OK", primary: true }
      ],
      width: 400
    })

  }


  soliciteParceiro(pedido: any):  any{
    if(this.integracaoUber && this.parceiroSelecionado.id === this.integracaoUber.id)
      return   this.pedidosService.soliciteUberDirect(pedido,   this.acaoRetorno)

    if(this.integracaoFoodyDelivery && this.parceiroSelecionado.id === this.integracaoFoodyDelivery.id)
      return this.pedidosService.soliciteFoodDelivery(pedido)

    if(this.integracaoOpendelivery && this.parceiroSelecionado.id === this.integracaoOpendelivery.id)
      return this.pedidosService.soliciteParceiroOpenDelivery(pedido)


    if(this.integracaoIfood && this.parceiroSelecionado.id === this.integracaoIfood.id){
      let pixManual = pedido.pagamentos.find((item: any) => item.pixManual);

      if(pixManual && !pedido.pago){
         let msg  =
           'Antes de solicitar um entregador do iFood para a forma de pagamento "Pix Manual", o pedido deve estar marcado como pago.';
         this.exibaAlertaMarcarPago(msg);
         return Promise.reject(msg);
      }

      //integracaoIfood.confirmarCotacao
      if(this.integracaoIfood.confirmarCotacao){
        return this.pedidosService.soliciteCotacaoIfood(pedido, this.integracaoIfood.idLoja)
      } else {
        return this.pedidosService.soliciteEntregadorIfood(pedido, this.integracaoIfood.idLoja)
      }
    }


    return Promise.reject('Nenhum integraçao selecionada')
  }

  async solicitarEntregador(pedido: any, evt: any) {
    pedido.solicitando = true;

    if(this.deliveryCancelar){
      let erroCancelar;
      await this.pedidosService.canceleEntrega(this.pedido, {} ).catch((erro) => {
         erroCancelar = erro;
      })

      if(erroCancelar){
        this.setErroSolicitarParceiro(pedido, erroCancelar);
        return;
      }
    }

    this.soliciteParceiro(pedido).then((delivery) => {
      pedido.solicitando = false;
      pedido.delivery = delivery;
      delete pedido.erroExterno;
      pedido.naoNotificadoLogistica = false;
      this.selecionarParceiro = false;
      this.deliveryCancelar = null;

      if(delivery.cotacao)
        this.abraModalCotacaoDelivery(pedido)

    }).catch((erro) => {
      this.setErroSolicitarParceiro(pedido, erro)
    })

    if(evt){
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  setErroSolicitarParceiro(pedido: any, erro){
    pedido.solicitando = false;
    pedido.naoNotificadoLogistica = true;
    pedido.erroExterno = erro;
  }

  abraModalCotacaoDelivery(pedido: any){
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalCotacaoDeliveryComponent,
      minWidth: 400,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.pedido = pedido
    windowRef.result.subscribe( (result: any) => {
      if(result ){
        if(result.removido) delete this.pedido.delivery;
        else if(result.id)  this.pedido.delivery = result;
      }
    } );
    //
  }


  abraModalCancelarDelivery() {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalSolicitarCancelamentoDeliveryComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.pedido = this.pedido
    windowRef.result.subscribe( (result: any) => {
        if(result && result.id)
          this.pedido.delivery = result;
      } );
  }

  usuarioVaiCancelarChamarUber(){
    if(this.integracaoUber){
      this.deliveryCancelar = true;
      this.selecionarParceiro = true;
      this.parceiroSelecionado = this.integracaoUber;
    }

  }

  onParceiroSelecionadoChange(parceiroSelecionado: any){
    if(  this.pedido.delivery &&  this.deliveryCancelar){
      this.deliveryCancelar  = false;
      this.selecionarParceiro = false;
    }

    this.integracaoIfood = parceiroSelecionado.ifood ? parceiroSelecionado : null
  }

  sincronizeAndamentoEntrega(){
    this.pedido.sincronizando  = true;
    this.pedidosService.sincronizeEntrega(this.pedido).then((delivery) => {
      this.pedido.delivery  = delivery;
      this.pedido.sincronizando  = false;
    }).catch((erro) => {
      this.pedido.sincronizando  = false;
      alert(erro)
    })
  }

  verDelivery(){
    this.pedidosService.verDelivery(this.pedido)
  }

  abralModalHistoricoNoticacoes(event){
     let classModal =  this.solicitacaoUber() ?   ModalUberdirectdetalhesComponent :
       ( this.solicitacaoIfood() ? ModalIfoodNotificacoesComponent :
       ( this.solicitacaoFoody() ? ModalFoodydeliveryDetathesComponent :  ModalDeliverydetalhesComponent));

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: classModal,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.pedido = this.pedido

    windowRef.result.subscribe( (result) => {  },
      (a) => {   });

    event.stopPropagation();

    return false;
  }

}
