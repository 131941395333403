<button type="button" class="btn btn-light" (click)="voltar()"  style="position: absolute; margin: 10px;z-index: 99;">
  <i class="fas fa-arrow-left me-2"></i>
</button>

<kendo-splitter #splitter>
  <kendo-splitter-pane>
    <kendo-splitter>
      <kendo-splitter-pane [collapsible]="true" size="100%">
        <div class="pane-content">
          <div style="position: absolute; left: 50%; transform: translateX(-50%); margin: 10px; z-index: 99;background-color: #fff;padding: 5px;">
            Fluxo: <strong>{{nomeFluxo}}</strong>&nbsp;&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-primary" (click)="testarFluxo()">
              <i class="fas fa-play me-2"></i>
              Testar Fluxo
              </button>
          </div>
          <iframe
            [src]="urlFluxo"
            sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-credentials"
      width="100%"
      style="min-height: calc(100vh - 140px);border: none;">
    </iframe>
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </kendo-splitter-pane>
  <kendo-splitter-pane #painel [collapsible]="true" size="30%" style="z-index: 99;" [collapsed]="true">
    <div class="pane-content">
      <button type="button" class="btn btn-light" (click)="painel.collapsed = true" style="position: absolute; right: 10px; top: 10px; z-index: 999;background-color: #fff;">
        <i class="fas fa-times"></i>
      </button>
      <app-testadorchat-bot [idFluxoTypebot]="publicIdTypebot"></app-testadorchat-bot>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>
