<form [ngClass]="{'needs-validation': !frmConfigImpressao.submitted, 'was-validated': frmConfigImpressao.submitted}"
      novalidate #frmConfigImpressao="ngForm" (ngSubmit)="onSubmitConfigImpressao()">
  <div class="form-group mb-2 " *ngIf="empresa?.configImpressao" >
    <label  >Leiaute de Impressão do Pedido</label>
    <div class="form-group">

                    <span class="radio radio-blue mr-2">
                      <input id="leiauteCompleto" name="leiautePedido" type="radio"    kendoRadioButton class="k-radio"
                             [(ngModel)]="empresa.configImpressao.layoutPedido" value="PedidoCompleto" (change)="alterouLeiaute()"/>
                      <label for="leiauteCompleto" class="ml-1">&nbsp;Completo</label>

                    </span>

      <span class="radio radio-blue  mr-2">
                      <input id="leiauteResumido" name="leiautePedido" type="radio"    kendoRadioButton class="k-radio"
                             [(ngModel)]="empresa.configImpressao.layoutPedido" value="PedidoResumido" (change)="alterouLeiaute()"/>
                      <label for="leiauteResumido" class="ml-1">&nbsp;Resumido</label>

                    </span>

      <span class="radio radio-blue">
                      <input id="ambosLeiautes" name="leiautePedido" type="radio"    kendoRadioButton class="k-radio"
                             [(ngModel)]="empresa.configImpressao.layoutPedido" value="Ambos" (change)="alterouLeiaute()"/>
                      <label for="ambosLeiautes" class="ml-1">&nbsp;Ambos</label>

                    </span><br>




    </div>


  </div>
  <hr>

  <div class="form-group mb-2 mt-2"  >
    <label  >Impressão automática ocorre quando? </label>

    <div class="form-group">

                    <span class="radio radio-blue mr-2">
                      <input id="statusPedido" name="momentoImprimirAuto" type="radio"    kendoRadioButton class="k-radio"
                             [(ngModel)]="empresa.configImpressao.momentoImprimirAuto" value="status-pedido"   (change)="marqueRecarregar()" />
                      <label for="statusPedido" class="ml-1">Mudança status do pedido</label>

                    </span>

      <span class="radio radio-blue">
                      <input id="novoPedido" name="momentoImprimirAuto" type="radio"    kendoRadioButton class="k-radio"
                             [(ngModel)]="empresa.configImpressao.momentoImprimirAuto" value="novo-pedido"   (change)="marqueRecarregar()" />
                      <label for="novoPedido" class="ml-1">&nbsp;Quando um novo pedido for feito</label>

                    </span><br>
    </div>
  </div>

  <div class="form-group mb-2 mt-3">

    <div class="form-group">
      <div class="input-group mt-0">
        <input  name="imprimirOnlineNaoPago" id='imprimirOnlineNaoPago'    class="k-checkbox  " type="checkbox" kendoCheckBox
                [(ngModel)]="empresa.configImpressao.imprimirOnlineNaoPago"   (change)="marqueRecarregar()"/>
        <label  class="k-checkbox-label" for="imprimirOnlineNaoPago">
          Imprimir pedidos online não pagos</label>
      </div>
    </div>
  </div>

  <div class="form-group mb-2 mt-2">

    <div class="input-group mt-0">
      <input  name="ocultarCobranca" id='ocultarCobranca'    class="k-checkbox  " type="checkbox" kendoCheckBox
              [(ngModel)]="empresa.configImpressao.ocultarCobranca"   />
      <label  class="k-checkbox-label" for="ocultarCobranca">
        Ocultar dados de cobrança em pedidos nao pagos ( mensagem cobrar do cliente e valor restante a pagar)</label>

    </div>

  </div>



  <hr>
  <ng-container *ngIf="!this.isMobile && !this.conectando && this.qzInstalado && empresa?.configImpressao">
    <div class="form-group mb-4 col-12" >
      <label  >
        Impressão nativa</label>
      <div class="input-group mt-2">
        <input  name="impressaoTXT" id='impressaoTXT' (change)="alterouTipoDeImpressao()"
                class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox" kendoCheckBox
                [(ngModel)]="empresa.configImpressao.imprimirTXT"/>
        <label  class="k-checkbox-label">Realizar impressão em modo nativo</label>
      </div>
      <div class="input-group mt-2" *ngIf="empresa.configImpressao.imprimirTXT">
        <input  name="modoHTML" id='modoHTML'   class="k-checkbox ng-pristine ng-valid" type="checkbox" kendoCheckBox
                [(ngModel)]="empresa.configImpressao.modoHTML"/>
        <label  class="k-checkbox-label">Utilizar o modo gráfico (*necessário driver nativo da impressora)</label>
      </div>
      <div class="input-group mt-2" *ngIf=" empresa.configImpressao.imprimirTXT && empresa.configImpressao.modoHTML">
        <input  name="cortarAutomatico" id='cortarAutomatico'  class="k-checkbox ng-untouched ng-valid" type="checkbox" kendoCheckBox
                (change)="alterouTipoDeImpressao()"                [(ngModel)]="empresa.configImpressao.cortarAutomatico"/>
        <label  class="k-checkbox-label">Enviar comando de corte ao fim da impressão.</label>
      </div>
      <div class="input-group mt-2" *ngIf=" empresa.configImpressao.imprimirTXT">
        <!-- adicionar campo para ocultar número do cliente -->
        <input  name="ocultarNumeroCliente" id='ocultarNumeroCliente' class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox" kendoCheckBox
                [(ngModel)]="empresa.configImpressao.ocultarNumeroCliente"/>
        <label  class="k-checkbox-label">Ocultar número do cliente no cupom impresso.</label>
      </div>

      <div class="input-group mt-2" *ngIf=" empresa.configImpressao.imprimirTXT">
        <input  name="multiplasImpressoras" id='multiplasImpressoras' class="k-checkbox ng-untouched ng-pristine ng-valid" kendoCheckBox
                type="checkbox"
                (change)="alterouTipoDeImpressao()"
                [(ngModel)]="empresa.configImpressao.multiplasImpressoras"/>
        <label  class="k-checkbox-label">Imprimir em mais de uma impressora</label>
      </div>
      <div class="input-group mt-2" *ngIf="empresa.configImpressao.imprimirTXT">
        <input  name="emitirBeep" id='emitirBeep' class="k-checkbox ng-untouched ng-pristine ng-valid" type="checkbox" kendoCheckBox
                [(ngModel)]="empresa.configImpressao.emitirBeep"/>
        <label  class="k-checkbox-label">Emitir um beep após impressão em impressoras compatíveis.</label>
      </div>
      <div class="input-group mt-2" *ngIf="empresa.configImpressao.imprimirTXT && empresa.configImpressao.emitirBeep">
        <label  class="k-checkbox-label">Duração do beep (padrão 500ms).</label>
        <input type="text" class="form-control" autocomplete="off"
               id="duracaoBeep" name="duracaoBeep"
               [(ngModel)]="empresa.configImpressao.duracaoBeep"
               placeholder="500" value="500" >
      </div>

      <div class="input-group mt-2" *ngIf="empresa.configImpressao.imprimirTXT && empresa.configImpressao.emitirBeep">
        <label  class="k-checkbox-label">Quantidade de beeps (padrão 1).</label>
        <input type="text" class="form-control" autocomplete="off"
               id="quantidadeBeeps" name="quantidadeBeeps"
               [(ngModel)]="empresa.configImpressao.quantidadeBeeps"
               placeholder="1" value="1" >
      </div>
    </div>
    <ng-container *ngIf="empresa.configImpressao.imprimirTXT" >
      <ng-container *ngFor="let impressora of empresa.configImpressao.impressoras; let i = index;" >
        <div class="row ml-0 impressora " *ngIf="i==0 || empresa.configImpressao.multiplasImpressoras" [ngClass]="{'removida': impressora.removida}" >
          <div class="form-group   col-2"  *ngIf="empresa.configImpressao.multiplasImpressoras">
            <label >Setor </label>

            <input type="text" class="form-control" autocomplete="off"
                   id="setor{{i}}" name="setor{{i}}"
                   [(ngModel)]="impressora.setor"
                   placeholder="Setor onde será impresso" value="" >
          </div>

          <div class="form-group  col-2"  >
            <label   >Impressora   </label>

            <kendo-dropdownlist id="impressora{{i}}" name="impressora{{i}}" [(ngModel)]="impressora.nome" [data]="impressoras"
                                placeholder="Selecione uma impressora" class="form-control">
            </kendo-dropdownlist>

          </div>



          <div class="form-group   col-2"  *ngIf="empresa.configImpressao.multiplasImpressoras">
            <label  >Layout</label>

            <kendo-dropdownlist id="layout{{i}}" name="layout{{i}}" [(ngModel)]="impressora.layout" [data]="layouts"
                                placeholder="Selecione o layout  do pedido" class="form-control">
            </kendo-dropdownlist>

          </div>



          <div class="form-group "  [ngClass]="{'col-2': !empresa.configImpressao?.multiplasImpressoras,
                                                          'col-1': empresa.configImpressao?.multiplasImpressoras}" style="min-width: 125px;">
            <label  >Papel</label>

            <kendo-dropdownlist id="tamanho{{i}}" name="tamanho{{i}}" [(ngModel)]="impressora.tamanhoPapel" [data]="listaTamanhosPapeis"
                                placeholder="Selecione o tamanho do papel" class="form-control">
            </kendo-dropdownlist>

          </div>


          <div class="   col-2" style="min-width: 200px;">
            <div class="input-group  mt-1 ">
              <input  name="imprimirAutomatico{{i}}"   class="k-checkbox" type="checkbox" kendoCheckBox
                      [(ngModel)]="impressora.imprimirAutomatico"/>
              <label   class="k-checkbox-label">Imprimir auto  </label>

            </div>


            <div class="input-group  mt-1 ">
              <input  name="naoImprimePedidosMesa{{i}}"   class="k-checkbox  " type="checkbox" kendoCheckBox
                      [(ngModel)]="impressora.naoImprimePedidosMesa"/>
              <label class="k-checkbox-label" >Não imprimir mesa  </label>
            </div>

            <div class="input-group mt-1  ">
              <input  name="naoImprimePedidosDelivery{{i}}"   class="k-checkbox " type="checkbox" kendoCheckBox
                      [(ngModel)]="impressora.naoImprimePedidosDelivery"/>
              <label class="k-checkbox-label" >Não imprimir delivery  </label>
            </div>
          </div>

          <div class="form-group   col-1" style="min-width: 150px;"  *ngIf="impressora.tamanhoPapel !== 'LayoutA4'">
            <label  >Cmd fim  </label>
            <input type="text" class="form-control" autocomplete="off"
                   name="comandosFimImpressao{{i}}" [(ngModel)]="impressora.comandosFimImpressao"
                   placeholder="Comandos enviados ao finalizar a impressão" value="" >
          </div>

          <div class="form-group col-1 acao"  >
            <button  type="button" class="btn btn-sm btn-danger " (click)="excluirImpressora(impressora)" *ngIf="!impressora.removida">
              <i class="fe-trash "> </i>
            </button>


            <button type="button" class="btn btn-warning btn-xs  " *ngIf=" impressora.removida" (click)=" impressora.removida = false;">
              <i   class="fa fa-undo"></i>
            </button>

          </div>
        </div>
      </ng-container>


      <div class="row ml-0">
        <div class="col">
          <button class="btn btn-blue mt-2 width-lg" type="button" (click)="adicionarNovaImpressa()" *ngIf="empresa.configImpressao.multiplasImpressoras">
            <i class="fe-plus "></i> Adicionar</button>
        </div>
      </div>


    </ng-container>

  </ng-container>
  <ng-container *ngIf="!this.conectando && !this.qzInstalado && !this.isMobile">
    <div class="form-group mb-4 col-12" >
      <label  >Impressão nativa</label><br>

      <span><strong>QZ Tray não detectado.</strong> É necessário instalar/iniciar o QZ Tray antes de configurar a impressão nativa.</span>
      <button (click)="tenteConectarQZ()" class="ml-2 btn btn-danger waves-effect waves-light width-lg mt-1" [disabled]="salvando">
        <i class="fas fa-sync fa-lg fa-fw"></i>
        Tente novamente</button>
    </div>
  </ng-container>
  <ng-container *ngIf="this.conectando && !this.isMobile">
    <div class="form-group mb-4 col-12" >
      <label  >Impressão nativa</label><br>
      <span>Aguarde enquanto detectamos a instalação do QZ Tray... <i class="mdi mdi-spin mdi-loading mr-1"></i></span>
    </div>
  </ng-container>

  <ng-container *ngIf="this.isMobile">
    <div class="form-group mb-4 col-12" >
      <label  >Impressão nativa</label><br>
      <span>Não é possível configurar impressão nativa em um dispositivo móvel.</span>
    </div>
  </ng-container>

  <ng-container *ngIf="this.conectando">
    <div class="form-group mb-4 col-12" >
      <label  >Impressão nativa</label><br>
      <span>Aguarde enquanto detectamos a instalação do QZ Tray... <i class="mdi mdi-spin mdi-loading mr-1"></i></span>
    </div>
  </ng-container>

  <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible fade show mb-2" role="alert">
    <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="erro">
    <i class="fa fa-times mr-1"></i> {{erro}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <button type="submit" class="btn btn-primary waves-effect waves-light width-lg mt-1" [disabled]="salvando">
    <i class="fa fa-save fa-lg fa-fw"></i>
    Salvar</button>
</form>


