import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CartaoClienteService } from '../../services/cartao-cliente.service';

@Component({
  selector: 'app-selecionar-cartao-cliente',
  templateUrl: './selecionar-cartao-cliente.component.html',
  styleUrls: ['./selecionar-cartao-cliente.component.scss']
})
export class SelecionarCartaoClienteComponent implements OnInit {
  busca = '';
  cartoes: any[] = [];
  cartaoSelecionado: any = null;
  carregando = false;
  cartoesFiltrados: any[] = [];

  constructor(
    private dialogRef: DialogRef,
    private cartaoClienteService: CartaoClienteService
  ) { }

  ngOnInit() {
    this.pesquisar();
  }

  pesquisar() {
    this.carregando = true;
    this.cartaoClienteService.obtenhaCartoes()
      .then(response => {
        this.cartoes = response || [];
        this.atualizeFiltro();
        this.carregando = false;
      })
      .catch(() => {
        this.carregando = false;
      });
  }

  atualizeFiltro() {
    if (!this.busca) {
      this.cartoesFiltrados = this.cartoes;
      this.cartaoSelecionado = null;
      return;
    }
    
    this.cartoesFiltrados = this.cartoes.filter(cartao =>
      cartao.codigo?.toString().includes(this.busca)
    );

    // Seleciona o primeiro cartão se houver resultados
    if (this.cartoesFiltrados.length > 0) {
      this.cartaoSelecionado = this.cartoesFiltrados[0];
    } else {
      this.cartaoSelecionado = null;
    }
  }

  onBuscaChange() {
    this.atualizeFiltro();
  }

  selecionarCartao(cartao: any) {
    this.cartaoSelecionado = cartao;
  }

  confirmar() {
    if (this.cartaoSelecionado) {
      this.dialogRef.close(this.cartaoSelecionado);
    }
  }

  confirmarSeHouverSelecionado() {
    if (this.cartaoSelecionado) {
      this.confirmar();
    }
  }

  fechar() {
    // Fecha o diálogo sem retornar nenhum valor
    this.dialogRef.close();
  }
}
