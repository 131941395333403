import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import { first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracoesMiaService extends ServerService {
  private configuracoesSubject: BehaviorSubject<any>;
  private configuracoesLoaded: boolean = false;

  constructor(private httpCliente: HttpClient) {
    super(httpCliente);

    this.configuracoesSubject = new BehaviorSubject<any>(null);

    window.addEventListener('storage', this.detectarMudancasLocalStorage.bind(this));
  }

  async salveConfiguracoes(configuracoes: any) {
    localStorage.setItem('alterouConfiguracoesMia', new Date().getTime().toString());

    return this.salve('/configuracoesMia', configuracoes);
  }

  async atualizeMudouPlano(configuracoes: any) {
    localStorage.setItem('atualizeMudouPlano', new Date().getTime().toString());

    return this.facaPost('/configuracoesMia/atualizeMudouPlano', configuracoes);
  }

  // Observable para as configurações
  get configuracoes$(): Observable<any> {
    return this.configuracoesSubject.asObservable().pipe(
      tap(() => {
        if (!this.configuracoesLoaded) {
          this.carregarConfiguracoes();
        }
      })
    );
  }

  public  async obtenhaConfiguracoes() {
    return this.obtenha('/configuracoesMia', {});
  }

  public crieFluxosTypebotPadrao() {
    return this.facaPost('/configuracoesMia/crieFluxosTypebotPadrao', {});
  }

  private async carregarConfiguracoes() {
    const configuracoes = await this.obtenha('/configuracoesMia', {});
    this.configuracoesSubject.next(configuracoes);
    this.configuracoesLoaded = true;
  }

  private detectarMudancasLocalStorage(event: StorageEvent) {
    if (event.key === 'alterouConfiguracoesMia') {
      this.carregarConfiguracoes(); // Recarrega as configurações atualizadas
    }
  }

  public publicarFluxo(idFluxo: string) {
    return this.facaPost('/configuracoesMia/publicarFluxo', {idFluxo}).then((response: any) => {
      // After successful publication, update the configurations
      this.carregarConfiguracoes();
      return response;
    });
  }
}
