import {Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {FormasDePagamentoService} from "../../services/formas-de-pagamento.service";
import {NgForm} from "@angular/forms";
import {ConfigFormaDePagamento} from "../../pedidos/objetos/ConfigFormaDePagamento";
import {IntegracaoERPService} from "../../services/integracaoerp.service";
import {DropDownFilterSettings, DropDownListComponent} from "@progress/kendo-angular-dropdowns";
import {TaxaCobranca} from "../../pedidos/objetos/TaxaCobranca";
import {EmpresasService} from "../../superadmin/services/empresas.service";


@Component({
  selector: 'app-nova-forma-de-pagamento',
  templateUrl: './nova-forma-de-pagamento.component.html',
  styleUrls: ['./nova-forma-de-pagamento.component.scss']
})
export class NovaFormaDePagamentoComponent implements OnInit {
  formaDePagamento: FormaDePagamento;
  windowRef: DialogRef;
  mensagemErro = '';
  @ViewChild('frm',  {static: false} ) frm: NgForm;
  @ViewChild('formaOpendelivery',  {static: false} ) formaOpendelivery: DropDownListComponent;
  @ViewChild('bandeiraOpenDelivery',  {static: false} ) bandeiraOpenDelivery: DropDownListComponent;
  enviando = false;
  empresa: any;
  formasDePagamentoIntegradas: any = [];
  formasDePagamentoIntegradasOnline: any = [];
  bandeiraCartaoIntegrada: any;
  complemento: string;
  bandeirasOnlineCartoesIntegrados: any = [];
  carregandoBandeiras = false;
  meioDePagamento: any;
  idMeioPagarmeHub = 'pagarmehub';
  idMeioPagarme = 'pagarme';
  idMeioPagseguro =  'pagseguro'
  idMeioPagBankConnect =  'pagseguroconnect'
  idMeioTuna =  'tunapay'
  meiosPagamentosOnline = [
    {id: this.idMeioPagBankConnect, nome: 'PagBank (Connect)'},
    {id: 'mercadopago', nome: "MercadoPago"},
    {id: 'cielocheckout', nome: 'Cielo Checkout'},
    {id: 'cielo', nome: 'Cielo Superlink'},
    {id: 'erede', nome: 'E.Rede'}
  ]
  bandeiras: any = [];
  bandeirasOpendelivery: any = [];
  habilitarTaxa: boolean;
  habilitarDesconto: boolean;
  erroBandeiras: string;
  gerandolink = false;
  linkAutorizacaoPagBank: string;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  removendoAutorizacao = false;
  confirmarRemocaoAutorizacao = false;
  renovandoToken = false;
  textoCopiado: string;
  constructor(private dialogService: DialogService, private formasDePagamentoService: FormasDePagamentoService,
              private empresasService: EmpresasService,
              private integracaoERPService: IntegracaoERPService) {
    this.formaDePagamento = new FormaDePagamento();
    this.formaDePagamento.exibirCardapio = true;
    this.formaDePagamento.habilitarRetirada = true;
    this.formaDePagamento.habilitarEntrega = true;
  }

  ngOnInit(): void {
    setTimeout(() => {
      if(this.formaOpendelivery)
        this.formaOpendelivery.value = this.formaDePagamento.opendeliveryMethod;

      if(this.bandeiraOpenDelivery)
        this.bandeiraOpenDelivery.value = this.formaDePagamento.opendeliveryBrand

    }, 0);
  }


  gatewayPagarme(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento.startsWith(this.idMeioPagarme);
  }

  gatewayPagseguro(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento.startsWith(this.idMeioPagseguro);
  }

  hubPagarme(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento === this.idMeioPagarmeHub;
  }

  pagseguro(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento === this.idMeioPagseguro;
  }

  pagBankConnect(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento === this.idMeioPagBankConnect;
  }

  gatewayErede(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento === 'erede'
  }

  gatewayMercadoPago(){
    if(!this.formaDePagamento) return false;

    let config: any = this.formaDePagamento.configMeioDePagamento;

    if(!config || !config.meioDePagamento) return false;

    return config.meioDePagamento === 'mercadopago'

  }

  suportePix(){
    if(this.empresa.cadastroPagamentoOnline)
     return this.gatewayErede() || this.gatewayMercadoPago()

    //pix agora so por tuna
    return false;
  }

  tireEspacosNomeFatura(maximo: number = null){
    let descricaoFatura: string =  this.formaDePagamento.configMeioDePagamento.nomeFaturaCartao;

    descricaoFatura = descricaoFatura.replace(/\s/gm, "");

    if(maximo)
      descricaoFatura = descricaoFatura.substring(0, maximo)

    this.formaDePagamento.configMeioDePagamento.nomeFaturaCartao = descricaoFatura;
  }


  novaForma(pagamentoOnline: boolean, empresa: any) {
    this.formaDePagamento.online = pagamentoOnline;
    this.empresa = empresa
    if(this.empresa.integracaoDelivery)
      this.processeFormaIntegrada();

  }

  async editar(forma,  empresa: any) {

    this.formaDePagamento = Object.assign({}, forma);
    this.empresa = empresa;
    this.habilitarTaxa = this.formaDePagamento.taxaCobranca && this.formaDePagamento.taxaCobranca.ativa;

    if( this.formaDePagamento.online ){
      this.formaDePagamento.configMeioDePagamento = forma.configMeioDePagamento;

      if( this.formaDePagamento.configMeioDePagamento.meioDePagamento){

        //jeito antigo do pagarme...so exibir no editar
        if(forma.configMeioDePagamento.meioDePagamento === this.idMeioPagarme)
          this.meiosPagamentosOnline.splice(0, 0 , {id: this.idMeioPagarme, nome: 'Pagar Me'})

        if(forma.configMeioDePagamento.meioDePagamento === this.idMeioPagarmeHub)
          this.meiosPagamentosOnline.splice(0, 0, {id: this.idMeioPagarmeHub, nome: 'Pagar Me (Hub)'})

        //jeito antigo do pagseguro...so exibir no editar
        if(forma.configMeioDePagamento.meioDePagamento === this.idMeioPagseguro)
          this.meiosPagamentosOnline.splice(0, 0 , {id: this.idMeioPagseguro, nome: 'PagSeguro'})

        if(forma.configMeioDePagamento.meioDePagamento === this.idMeioTuna)
          this.meiosPagamentosOnline.push(    {id: this.idMeioTuna, nome: 'Meucardapio Pay'})

        this.meioDePagamento =
          this.meiosPagamentosOnline.find((meio: any) => meio.id ===  this.formaDePagamento.configMeioDePagamento.meioDePagamento )
      }

    }

    if(this.pagBankConnect() && !this.linkAutorizacaoPagBank)
      await this.gereLinkAutorizacaoPagBank()


    if(this.formaDePagamento.bandeirasCartaoIntegrada.length)
      this.bandeiraCartaoIntegrada = this.formaDePagamento.bandeirasCartaoIntegrada[0]
    else if(this.integradoComSaipos()){
      this.bandeiraCartaoIntegrada = {};
    }

    this.processeFormaIntegrada();
  }

  onSalvar() {
    delete this.mensagemErro;
    if (!this.frm.valid) return;

    if(this.ehPagseguroConnectENaoTemAutorizacao()){
      this.mensagemErro = 'Para salvar a forma de pagamento é necessário autorizar Pagseguro'
      return;
    }

    let dados: any = Object.assign({}, this.formaDePagamento)
    dados.bandeirasCartaoIntegrada = [];

    if (this.bandeirasOnlineCartoesIntegrados.length) {
      this.bandeirasOnlineCartoesIntegrados.forEach((dadosBandeira: any) => {
        let bandeiraCartaoIntegrada: any = {
          id: dadosBandeira.id,
          codigoPdvOnline: dadosBandeira.codigoPdvOnline,
          descricaoPdvOnline: dadosBandeira.descricaoPdvOnline
        };

        dados.bandeirasCartaoIntegrada.push(Object.assign(bandeiraCartaoIntegrada, dadosBandeira.bandeiraCartaoIntegrada))
      })
    } else if (this.bandeiraCartaoIntegrada){
      if(this.integradoComSaipos())
        this.bandeiraCartaoIntegrada.nome  = this.bandeiraCartaoIntegrada.codigoPdv;

      dados.bandeirasCartaoIntegrada.push(this.bandeiraCartaoIntegrada)
    }


    this.enviando = true;
    this.formasDePagamentoService.salveFormaDePagamento(dados).then( (resposta) => {
      this.windowRef.close(resposta);
      this.enviando = false;
    }).catch( erro => {
      this.mensagemErro = erro;
      this.enviando = false;
    });
  }

  remova() {

  }

  fechar() {
    this.windowRef.close();
  }

  mudouOnline() {
    if( !this.formaDePagamento.configMeioDePagamento ) {
      this.formaDePagamento.configMeioDePagamento = new ConfigFormaDePagamento();
    }
  }

  bandeiraObrigatoria(){
    if(!this.formaDePagamento.formaIntegrada) return false;

    if(this.bandeirasOnlineCartoesIntegrados.length) return  false;

    let formaPagamentoIntegrada =
      this.empresa.integracaoDelivery.formasDePagamento.find(
        formaIntegrada => formaIntegrada.descricao === this.formaDePagamento.formaIntegrada.descricao)


    return formaPagamentoIntegrada && formaPagamentoIntegrada.tipoBandeira

  }

  mudouFormaIntegrada($event){
   setTimeout( () => { delete this.bandeiraCartaoIntegrada;  this.processeFormaIntegrada()}, 0);
  }


  tipoBandeiraObritatorio(){

    let method: any = this.obtenhaFormaSelecionadaOpenDelivery();

    return method && method.tipoBandeira  != null
  }

  mudouFormaOpenDelivery(forma: any){
    this.formaDePagamento.opendeliveryMethod = forma.codigo;
    this.processeFormaOpendelivery()
  }

  mudouBandeiraOpenDelivery(banderia: any){
    this.formaDePagamento.opendeliveryBrand = banderia.id;
  }

  obtenhaFormaSelecionadaOpenDelivery(){
    let formas: any =  this.obtenhaOpendeliveryMetodos();

   return  formas.find(
      (item: any) => item.codigo === this.formaDePagamento.opendeliveryMethod)
  }

  processeFormaOpendelivery(){
    this.bandeirasOpendelivery = [];

    let forma: any = this.obtenhaFormaSelecionadaOpenDelivery();

    if(forma) {
      let tipoBandeira =  forma.tipoBandeira;

      if(tipoBandeira){
        this.carregandoBandeiras = true;
        this.integracaoERPService.listeBandeirasOpendelivery(tipoBandeira)
          .then( (bandeiras: any) => {
            this.carregandoBandeiras = false;
            this.bandeirasOpendelivery = bandeiras;
            this.setBandeiraSelecionada();
          }).catch((erro: any) => {
          this.carregandoBandeiras = false;
          alert(erro)
        })
      }
    }
  }

  processeFormaIntegrada() {
    this.bandeiras = [];

    if(this.integradoComSaipos() && !this.bandeiraCartaoIntegrada)
      this.bandeiraCartaoIntegrada = {};

    if(this.formaDePagamento.referenciaExterna &&  !this.formaDePagamento.formaIntegrada) //quando retirar referenciaExterna, remover codigo
      this.formaDePagamento.formaIntegrada =   this.empresa.integracaoDelivery.formasDePagamento.find(
        formaIntegrada => formaIntegrada.descricao === this.formaDePagamento.referenciaExterna);

    if( this.formaDePagamento && this.bandeiraCartaoIntegrada)
      this.bandeiras.push( this.bandeiraCartaoIntegrada);

    if(this.formaDePagamento.formaIntegrada && this.empresa.integracaoDelivery) {
      if(this.formaDePagamento.formaIntegrada.tipoBandeira){
        this.carregandoBandeiras = true;
        this.erroBandeiras = null;
        this.integracaoERPService.listeBandeiras( this.formaDePagamento.formaIntegrada.tipoBandeira).then( (bandeiras: any) => {
          this.carregandoBandeiras = false;
          this.bandeiras = bandeiras;
          this.setBandeiraSelecionada();
        }).catch((err) => {
          this.carregandoBandeiras = false;
          this.bandeiras = []
          this.erroBandeiras = err;
        });
      }
    }

    this.processeFormaOpendelivery();

    if(this.empresa.integracaoGatewayPagamento && this.empresa.integracaoGatewayPagamento.dataExpiracao)
      this.empresa.integracaoGatewayPagamento.dataExpiracao = new Date( this.empresa.integracaoGatewayPagamento.dataExpiracao)
  }

  setBandeiraSelecionada(){
    if( this.bandeiraCartaoIntegrada){
      let bandeiraSelecionada =    this.bandeiras.find( bandeira => bandeira.codigoPdv === this.bandeiraCartaoIntegrada.codigoPdv)

      if(bandeiraSelecionada){
        bandeiraSelecionada.id =  this.bandeiraCartaoIntegrada.id;
        this.bandeiraCartaoIntegrada = bandeiraSelecionada;
      }
    }

    if( this.formaDePagamento.online )
      this.setBandeirasOnline();
  }

  setBandeirasOnline(){
    let meio = this.formaDePagamento.configMeioDePagamento.meioDePagamento;

    this.formaDePagamento.bandeirasCartaoIntegrada =   this.formaDePagamento.bandeirasCartaoIntegrada.filter(bandeira => bandeira.id);

    this.bandeirasOnlineCartoesIntegrados = [];
    this.carregandoBandeiras = true;
    this.formasDePagamentoService.listeFormasIntegradas(meio).then( (tiposExterno: any) => {
      this.carregandoBandeiras = false;
      tiposExterno.forEach( (tipoPagamento: any) => {
        let bandeirasCartaoIntegradaExistente: any =
          this.formaDePagamento.bandeirasCartaoIntegrada.find( bandeira => bandeira.codigoPdvOnline  === tipoPagamento.codigo)

        if(!bandeirasCartaoIntegradaExistente){

          let novaBandeiraOnline = { codigoPdvOnline: tipoPagamento.codigo, descricaoPdvOnline: tipoPagamento.descricao};
          this.bandeirasOnlineCartoesIntegrados.push(novaBandeiraOnline);
        } else {
          let bandeiraOnlineExistente: any =
            { id: bandeirasCartaoIntegradaExistente.id,
              codigoPdvOnline: bandeirasCartaoIntegradaExistente.codigoPdvOnline,
              descricaoPdvOnline: bandeirasCartaoIntegradaExistente.descricaoPdvOnline
            };

          let bandeiraSelecionada =
            this.bandeiras.find( bandeira => bandeira.codigoPdv.toString() === bandeirasCartaoIntegradaExistente.codigoPdv)

          bandeiraOnlineExistente.bandeiraCartaoIntegrada = bandeiraSelecionada;

          this.bandeirasOnlineCartoesIntegrados.push(bandeiraOnlineExistente);
        }
      })

    });

  }

  obtenhaFormatoPercentual(){
    return String(`0"%"`);
  }

  aletrouHabilitarTaxa() {
    if(this.habilitarTaxa){
      if(!this.formaDePagamento.taxaCobranca)
        this.formaDePagamento.taxaCobranca = new TaxaCobranca();
      this.formaDePagamento.taxaCobranca.ativa = true;
    } else {
      if(this.formaDePagamento.taxaCobranca && this.formaDePagamento.taxaCobranca.id)
        this.formaDePagamento.taxaCobranca.ativa = false;
       else
         delete    this.formaDePagamento.taxaCobranca;
    }
  }


  integradoComSaipos(){
    if(!this.empresa || !this.empresa.integracaoDelivery) return false;

    return  this.empresa.integracaoDelivery.sistema === 'saipos'
  }

  async selecionouMeioOnline(meioPagamento: any) {
    this.formaDePagamento.configMeioDePagamento.meioDePagamento = meioPagamento.id;
    this.setBandeirasOnline();

    if(this.pagBankConnect() && !this.linkAutorizacaoPagBank)
        await this.gereLinkAutorizacaoPagBank()

    // setTimeout(() => {
    //   if( meioPagamento.id === this.idMeioPagarmeHub)
      //  this.configureHubPargarme();
   //  }, 100)
  }

 async gereLinkAutorizacaoPagBank(){
    this.gerandolink = true;

    this.linkAutorizacaoPagBank = await this.formasDePagamentoService.obtenhaLinkAutorizacaoPagBank().catch((err) => {
      this.mensagemErro = err
    });

    this.gerandolink = false;
  }


  temTaxaRetorno(){
    return this.empresa &&  this.empresa.formasDeEntrega.find((item) => item.taxaExtraRetorno) != null
  }

  configurarOpendelivery(){
    if(!this.empresa) return false;

    let ativapdv = this.empresa.integracaoOpendelivery && this.empresa.integracaoOpendelivery.ativa;
    let ativadelivery = this.empresa.integracaoOpendeliveryLogistica && this.empresa.integracaoOpendeliveryLogistica.ativa;

    return ativapdv || ativadelivery;
  }

  obtenhaOpendeliveryMetodos() {
    if(this.empresa) {
      if(this.empresa.integracaoOpendelivery)
        return this.empresa.integracaoOpendelivery.formasDePagamento;

      if(this.empresa.integracaoOpendeliveryLogistica)
        return this.empresa.integracaoOpendeliveryLogistica.formasDePagamento;
    }

    return  [];
  }

  confirmeRemoverAutorizacao(){
     this.confirmarRemocaoAutorizacao = true;
    return false;
  }

  canceleRemoverAutorizacao(){
    this.confirmarRemocaoAutorizacao = false;
    return false;
  }

  async removaAutorizacaoPagBankConnect(){
    this.removendoAutorizacao = true;

    let erroRemocao;

    await this.empresasService.removaAutorizacaoLojaPagbank().catch((erro) => {
      erroRemocao = erro;
    });

   this.removendoAutorizacao = false

    if(!erroRemocao){
      delete this.empresa.integracaoGatewayPagamento;
      this.formaDePagamento.exibirCardapio = false;
    } else {
      alert(erroRemocao)
    }
  }

 async renoveToken() {
    this.renovandoToken = true;
    let erroRenovacao;
    let dados: any = await this.empresasService.renoveTokenAutorizacaoLojaPagbank().catch((erro) => {
      erroRenovacao = erro;
    });

   this.renovandoToken = false;

    if(dados){
      this.empresa.integracaoGatewayPagamento.dataExpiracao = new Date(dados.dataExpiracao)
    } else {
      alert(erroRenovacao)
    }
    return false;
  }

  ehPagseguroConnectETemAutorizacao() {
    return this.pagBankConnect() && this.empresa.integracaoGatewayPagamento != null
  }

  ehPagseguroConnectENaoTemAutorizacao() {
    return this.pagBankConnect() && !this.empresa.integracaoGatewayPagamento
  }

  podeSalvarComPagseguroConnect(){
    if(!this.pagBankConnect()) return true;

    return this.pagBankConnect() && this.formaDePagamento.id;
  }

  obtenhaBaseUrlWebwookERede(){
    return String(`https://${location.host}/pagamentos/notificacao/erede`)
  }

  obtenhaBaseUrlWookERedeTokenizar(){
    return String(`https://${location.host}/erede/hooks/tokenization`)
  }


  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.textoCopiado = val;
  }

  pagamentoPorFidelidade(){
    let pdv: any = this.formaDePagamento.formaDePagamentoPdv;

    return  pdv && pdv.tipo === 'FIDELIDADE';
  }

}
