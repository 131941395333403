
<div class="modal-content">
  <div class="modal-header" >
    <h4 class="modal-title" id="myModalLabel">
      Alterar Item Da Mesa
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="fecheModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div   >
  <div   class="modal-body">
    <app-tela-adicionar-produto #telaAdicionarProduto  [editandoItemMesa]="true"
                                (onAtualizou)="atualizeProduto($event)"
                                (onCancelouAdicionar)="canceleAdicionar($event)"  >

    </app-tela-adicionar-produto>


    <div class="mt-3 mb-2" *ngIf="atualizou" >
      <button class="btn btn-success btn-block" type="button" [disabled]="alterandoItemPedido"
              (click)="confirmeAlteracaoItemPedido()">
        <i class="k-icon k-i-loading" *ngIf="alterandoItemPedido"></i>Confirmar alteração
      </button>
    </div>


  </div>
</div>
