import {Component, ElementRef, OnInit, QueryList, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';

import {ActivatedRoute} from "@angular/router";
import {Produto} from "../../../../server/domain/Produto";
import {ProdutoService} from "../../services/produto.service";
import {NgForm} from "@angular/forms";
import {EditEvent, PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {CadAdicionalProdutoComponent} from "../cad-adicional-produto/cad-adicional-produto.component";
import {ProdutoPizza} from "../../../../server/domain/ProdutoPizza";
import {ProdutoTamanho} from "../../../../server/domain/templates/ProdutoTamanho";
import {AdicionalDeProdutoEscolhaSimples} from "../../../../server/domain/delivery/AdicionalDeProdutoEscolhaSimples";
import {OpcaoDeAdicionalDeProduto} from "../../../../server/domain/delivery/OpcaoDeAdicionalDeProduto";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {ViewDisponibilidadeComponent} from "../../compartilhado/view-disponibilidade/view-disponibilidade.component";
import {TabStripComponent} from "@progress/kendo-angular-layout";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {ModalKendo} from "../../lib/ModalKendo";
import {AssociarInsumoComponent} from "./associar-insumo/associar-insumo.component";
import {CuponsService} from "../services/cupons.service";


@Component({
  selector: 'app-cad-produto',
  templateUrl: './cad-produto.component.html',
  styleUrls: ['./cad-produto.component.scss']
})
export class CadProdutoComponent extends ModalKendo implements OnInit {
  static DISPONIBILIDADES  = [
    { id: 0, descricao: 'Sempre disponível', disponivel: true},
    { id: 1, descricao: 'Disponível em dias específicos', disponivel: true},
    { id: 2, descricao: 'Não disponível', disponivel: false}];


  @ViewChild('cadAdicionalProduto',  { static: false}) cadAdicionalProduto: CadAdicionalProdutoComponent;
  @ViewChild('frm',  { static: false} ) frm: NgForm;
  @ViewChild('viewDisponibilidades',  { static: false} ) viewDisponibilidades: ViewDisponibilidadeComponent;
  @ViewChild('frmf',  { static: false} ) frmf: NgForm;
  @ViewChild('tabSet') tabSet: TabStripComponent;
  @ViewChild('telaInsumoAssociado') telaInsumoAssociado: AssociarInsumoComponent;
  catalogo: any;
  empresa: any;
  templatesPiza: any = [];
  mensagemSucesso: any;
  mensagemErro: any;
  produto: any = new Produto();
  carregando: boolean;
  abrirDialogAdicional: boolean;
  abraDialogoRemover: boolean;
  adicionalRemover: any;
  adicionalRemoverIndex: number;
  novo: boolean;
  copiarAdicional: boolean;
  tiposDeVenda: any  = ['Unidade', 'Peso'];
  unidades  = [  ];
  disponibilidades  = CadProdutoComponent.DISPONIBILIDADES;
  tipoDeProduto: any ;
  TIPOSDEPRODUTOS = {
    Normal: 'normal',
    Pizza: 'pizza',
    BrindeFidelidade: 'brindefidelidade'
  }
  adicionalMassa: any = {}
  adicionalBorda: any =  {}
  salvando: boolean;
  exibirDescontos: boolean;
  categorias: []
  cupons:  any =  []
  carregandoProduto: boolean;
  salvouProduto: boolean
  usuarioLogado: any;
  temModuloFidelidade: boolean
  temModuloPedidos: boolean

  podeEditarProdutos: boolean;
  classificacao: any = {}
  mapTabsPorId: any = {
    'detalhes': 0,
    'adicionais': 1,
    'disponibilidade': 2,
    'historico': 3,
  }
  modoAdminRede: boolean;
  podeEditarPrecos: boolean;
  tagsAlimentar = [];
  salvou: boolean;
  tipoDeAcumulo: any = 'Pontos';

  constructor( private route: ActivatedRoute, private constantsService: ConstantsService,
              private cuponsService: CuponsService,
              public produtoService: ProdutoService, private autorizacaoService: AutorizacaoService,
              ) {
    super()
    this.produtoService.listeUnidades().then( (unidades: any) => {
      this.unidades = unidades;
      this.setUnidadeProduto();
    });
  }

  ngOnInit() {
      if(!this.produto.id)
        this.setProduto( this.produto)
      else
        this.tipoDeProduto = this.produto.tipo;

      if( this.tipoDeProduto === this.TIPOSDEPRODUTOS.Pizza ) {
        this.mapTabsPorId = {
          'detalhes': 0,
          'massas': 1,
          'bordas': 2,
          'adicionais': 3,
          'disponibilidade': 4,
          'historico': 5
        };
      }

      this.constantsService.moduloFidelidade$.subscribe( data => this.temModuloFidelidade  = data  )
      this.constantsService.moduloPedido$.subscribe( data => this.temModuloPedidos  = data);

      this.categorias = this.catalogo.categorias;
      this.podeEditarProdutos = !this.empresa || !this.empresa.id || !this.catalogo || !this.catalogo.compartilhado

      this.modoAdminRede = !this.empresa || !this.empresa.id;
      this.podeEditarPrecos = this.podeEditarProdutos || (this.catalogo && this.catalogo.precoPorEmpresa
        && RotaGuardClient.alterarPrecoCardapioCompartilhado(this.autorizacaoService.getUser()));

      this.usuarioLogado = this.autorizacaoService.getUser();


  }

  carregueProduto(produtoIncompleto: Produto) {
    this.carregandoProduto = true;
    this.produtoService.obtenhaProduto(produtoIncompleto.id, this.catalogo, this.empresa).then( (produto) => {
      this.carregandoProduto = false;

      this.setProduto(produto);
    }).catch( (erro) => {
      this.carregandoProduto = false;
    });
  }

  setUnidadeProduto(){
    if(this.produto.unidadeMedida){
      this.produto.unidadeMedida = this.unidades.find( unidade => unidade.id === this.produto.unidadeMedida.id );
      this.setValoresPadroes();
    }

  }

  setProduto(produto: Produto){
    this.produto = produto;
    if(this.produto.categoria)
      this.produto.categoria = this.catalogo.categorias.find( categoria => categoria.id === this.produto.categoria.id);

    if(!this.produto.tipoDeVenda)
      this.produto.tipoDeVenda =  this.tiposDeVenda[0];

    if(this.produto.cupom){
      this.produto.gerarCupom = true;
      this.cupons = [ this.produto.cupom]
    }

    if(!this.produto.disponibilidade)
      this.produto.disponibilidade = this.disponibilidades[0].id;

    this.novo  =  !this.produto.id;

    for(let i = 0 ; i < this.produto.disponibilidades.length; i++)
      this.produto.disponibilidades[i] =
        this.catalogo.disponibilidades.find((item: any) => item.id ===  this.produto.disponibilidades[i].id)

    if(this.produto.id){
      this.tipoDeProduto = this.produto.tipo;
      this.tagsAlimentar.forEach((tag: any) => {
        tag.selecionada  =  this.produto.tags.find((item: any) => item.id === tag.id) != null
      })
    }

    this.setUnidadeProduto();

    if(this.templatesPiza.length){
      this.obtenhaTemplateAdicional('Borda').forEach( (adicionalTemplate: any) => {
          this.adicionalBorda = this.produto.camposAdicionais.find(
            adicional => adicional.template && adicional.template.id === adicionalTemplate.id);

          if(!this.adicionalBorda){
            let adicional = new AdicionalDeProdutoEscolhaSimples(adicionalTemplate.descricao, true);

            adicional.template = adicionalTemplate;

            this.adicionalBorda = adicional;
          }

          adicionalTemplate.opcoes.forEach( opcaoTemplate => {
            let opcaoExistente = this.adicionalBorda.opcoesDisponiveis.find( opcao => opcaoTemplate.id === opcao.template.id)

            if(!opcaoExistente){
              let opcaoAdicional = new OpcaoDeAdicionalDeProduto(opcaoTemplate.nome,
                                            opcaoTemplate.valor, false, opcaoTemplate.descricao, opcaoTemplate);

              opcaoAdicional.codigoPdv = opcaoTemplate.codigoPdv;

              this.adicionalBorda.opcoesDisponiveis.push(opcaoAdicional)
            }

          })

        })

      this.obtenhaTemplateAdicional('Massa').forEach( (adicionalTemplate: any) => {
        this.adicionalMassa = this.produto.camposAdicionais.find(
          adicional => adicional.template && adicional.template.id === adicionalTemplate.id);

        if(!this.adicionalMassa){
          let adicional = new AdicionalDeProdutoEscolhaSimples(adicionalTemplate.descricao, true);

          adicional.template = adicionalTemplate;

          this.adicionalMassa = adicional;
        }

        adicionalTemplate.opcoes.forEach( opcaoTemplate => {
          let opcaoExistente = this.adicionalMassa.opcoesDisponiveis.find( opcao => opcaoTemplate.id === opcao.template.id)

          if(!opcaoExistente){
            let opcaoAdicional = new OpcaoDeAdicionalDeProduto(opcaoTemplate.nome,
              opcaoTemplate.valor, false, opcaoTemplate.descricao, opcaoTemplate);

            opcaoAdicional.codigoPdv = opcaoTemplate.codigoPdv;
            this.adicionalMassa.opcoesDisponiveis.push(opcaoAdicional)
          }

        })
      })

      if(this.produto.id && this.tipoDeProduto === this.TIPOSDEPRODUTOS.Pizza){
        let template: any = this.templatesPiza.find( templatePizza => templatePizza.id === this.produto.template.id);

        template.tamanhos.forEach(( tamanhoTamplate: any) => {
          let  tamanhoProdutoExistente = this.produto.tamanhos.find( tamanhoProduto => tamanhoProduto.template.id === tamanhoTamplate.id);

          if(tamanhoProdutoExistente && tamanhoProdutoExistente.novoPreco)
            tamanhoProdutoExistente.exibirDescontos = true;

          if(!tamanhoProdutoExistente)
            this.produto.tamanhos.push(new ProdutoTamanho(null, 0, tamanhoTamplate, false));
        })

      }

    }

    // exibe os descontos se houver novoPreco ou catalogo for preco por empresa e houver novoPrecoNaEmpresa

    this.exibirDescontos = this.produto.novoPreco != null || (this.catalogo.precoPorEmpresa && this.produto.novoPrecoNaEmpresa != null);
  }



  onSalvarProduto() {
    delete this.mensagemErro;
    if(this.frm.valid){
      delete this.mensagemErro;
      this.produto.catalogo = this.catalogo;

      if( this.produto.pesoMinimo && this.produto.valorInicial < this.produto.pesoMinimo ) {
        this.mensagemErro = 'Valor inicial tem que ser maior ou igual ao peso mínimo do produto';
        return;
      }

      if( this.produto.pesoMinimo && this.produto.valorInicial > this.produto.pesoMaximo ) {
        this.mensagemErro = 'Valor inicial tem que ser menor ou igual ao peso máximo do produto';
        return;
      }

      if(!this.empresa || !this.empresa.id)
        delete this.produto.precoNaEmpresa

      this.produtoService.salveProduto(this.produto).then( resposta => {
        this.salvou = true;
        if(!this.produto.id) {
          this.produto.id = resposta.id;
          this.produto.ordem = resposta.ordem;
          this.produto.tamanhos = resposta.tamanhos;
          setTimeout( () => {
            if(this.produto.tipo === this.TIPOSDEPRODUTOS.Pizza){
              this.selecioneTabMassas();
            } else {
              this.selecioneTabAdicionais();
            }
          }, 0);
        }

        this.frm.form.markAsPristine();
        this.frm.form.markAsUntouched();
        this.mensagemSucesso = 'Produto salvo com sucesso!'
        setTimeout ( () => {
          delete this.mensagemSucesso ;
        }, 3000);
      }).catch( erro => {
        this.mensagemErro   = erro;
      })
    }
  }

  salveImagensProduto(fnRetorno: Function) {
    this.produtoService.salveImagensProduto(this.catalogo, this.produto).then( resposta => {
      this.salvou = true;
      fnRetorno( );
    }).catch( erro => {
      fnRetorno(erro);
    })
  }

  onSalvarPontosFidelidade(){
    delete this.mensagemErro;
    if(this.frmf.valid && !this.salvando){
      this.salvando = true;
      this.produtoService.salvePontosProduto(this.produto).then( resposta => {
        this.salvou = true;
        this.mensagemSucesso = 'Pontos fidelidade salvo com sucesso!'
        this.salvando = false;
        this.frmf.form.markAsPristine();
        this.frmf.form.markAsUntouched();
        setTimeout ( () => {
          delete this.mensagemSucesso ;
        }, 3000);
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro   = erro;
      })
    }
  }

  onSalvarDisponibilidade(){
    delete this.mensagemErro;
    if(!this.empresa || !this.empresa.id)
      delete this.produto.disponibilidadeNaEmpresa

    if(!this.salvando){
      this.produto.disponibilidades = this.viewDisponibilidades.disponibilidades;
      this.salvando = true;

      if(this.telaInsumoAssociado)
        this.telaInsumoAssociado.setInsumoDesvinculado();

      this.produtoService.salveDisponibilidadeProduto(this.catalogo, this.produto).then( resposta => {
        this.salvou = true;
        this.mensagemSucesso = 'Produto salvo com sucesso!'
        this.salvando = false;
        this.telaInsumoAssociado.salvou();

        setTimeout ( () => {
          delete this.mensagemSucesso ;
        }, 3000);
      }).catch( erro => {
        this.salvando = false;
        this.mensagemErro   = erro;
      })
    }
  }

  salveClassificacao(){
    delete this.mensagemErro;

    let tags = this.tagsAlimentar.filter((item: any) => item.selecionada);

    this.salvando = true;
    this.produtoService.salveTagsProdutos(this.catalogo, this.produto,  tags).then(resposta => {
      this.salvou = true;
      this.mensagemSucesso = 'Produto salvo com sucesso!'
      this.salvando = false;
      setTimeout ( () => {
        delete this.mensagemSucesso ;
      }, 3000);
    }).catch( erro => {
      this.salvando = false;
      this.mensagemErro   = erro;
    })

  }

  novoAdicional() {
    this.abrirDialogAdicional = true;
  }

  editarAdicional($event: EditEvent) {
    this.abrirDialogAdicional  = true;
    setTimeout( () => {
      this.cadAdicionalProduto.exibaAdicional($event.dataItem)
    })
  }

  carregue($event: PageChangeEvent) {   }

  encerrouEdicaoDeAdicional(adicionalDeProduto: any) {
    this.abrirDialogAdicional = false;
  }

  removerAdicional($event: RemoveEvent) {
    this.adicionalRemover = $event.dataItem
    this.adicionalRemoverIndex = $event.rowIndex
    this.abraDialogoRemover = true;
  }

  exibaCopiarAdicionalProduto() {
    this.copiarAdicional = true;
  }

  copiouAdicionais(novosAdicionais: any) {
    this.copiarAdicional = null;
    if(novosAdicionais && novosAdicionais.length)
      novosAdicionais.forEach( adicional => this.produto.camposAdicionais.push(adicional))
  }

  obtenhaFormato() {
    if(!this.produto.unidadeMedida) return '0.00'

    return String(`0.00 ${this.produto.unidadeMedida.sigla}`);
  }

  obtenhaFormatoPreco(){
    if(!this.produto.tipoDeVenda || this.produto.tipoDeVenda !== 'Peso')
      return '$0.00'

    return String(`$ 0.00/ kg`);
  }

  obtenhaFormatoPercentual(){
    return String(`0.0"%"`);
  }

  alterouUnidade(){  }

  alterouValorUnidade(){
    this.setValoresPadroes();
  }

  setValoresPadroes(){
    if(this.produto.unidadeMedida){
      this.produto.valorInicial = this.produto.unidadeMedida.valorInicialPadrao;
      this.produto.incremento =  this.produto.unidadeMedida.incrementoPadrao;
    }
  }

  fecheModal() {

    super.fecheModal( { recarregar: this.salvou} )
  }

  escolheuTemplate(tipoTemplate, template = null) {
    this.tipoDeProduto = tipoTemplate;
    if(this.tipoDeProduto === this.TIPOSDEPRODUTOS.Pizza){
      let pizza = new ProdutoPizza();

      pizza.template = template

      pizza.template.tamanhos.forEach( (tamanhoTamplate) => {
        pizza.tamanhos.push(new ProdutoTamanho(null, 0, tamanhoTamplate));
      })

      this.setProduto(pizza)

    }
  }

  obtenhaClassTamanho(tamanho: any) {
    if(tamanho.qtdePedacos  <= 6) return 'x1'
    if(tamanho.qtdePedacos  <= 8) return 'x2'
    if(tamanho.qtdePedacos  <= 10) return 'x3'

    return 'x4'

  }

  obtenhaImagemTamanho(tamanho: any){
    if(tamanho.qtdePedacos  <= 6) return 'pizza-pequena.png'
    if(tamanho.qtdePedacos  <= 8) return 'pizza-media.png'
    if(tamanho.qtdePedacos  <= 10) return 'pizza-grande.png'

    return 'pizza-familia.png'
  }

  obtenhaClassCor(tamanho: any){
    if(!tamanho || !tamanho.template.disponivel) return 'light'

    if(tamanho.qtdePedacos  <= 6) return 'info '
    if(tamanho.qtdePedacos  <= 8) return 'success'
    if(tamanho.qtdePedacos  <= 10) return 'warning'

    return 'primary'
  }

  obtenhaTemplateAdicional(descricao: string) {
    if(!this.produto.template) return [];

    let template: any = this.templatesPiza.find( templatePizza => templatePizza.id === this.produto.template.id);

    return template ? template.adicionais.filter(
      adicional =>   adicional.descricao.toLowerCase() === descricao.toLowerCase())
       : []
  }

  limpeMensagens() {
    this.mensagemErro = null;
    this.mensagemSucesso = null;
  }

  selecioneTabAdicionais(){
    const index = this.mapTabsPorId['adicionais'];

    this.tabSet.selectTab(index);
  }

  selecioneTabMassas(){
      const index = this.mapTabsPorId['massas'];

    this.tabSet.selectTab(index)
  }

  selecioneTabDisponibilidade(){
    const index = this.mapTabsPorId['disponibilidade'];

    this.tabSet.selectTab(index);
  }

  fecheMensagemErro() {
    delete this.mensagemErro;
  }

  fecheMensagemSucesso() {
    delete this.mensagemSucesso;
  }

  handleFilter(value) {

    this.categorias = this.catalogo.categorias.filter((categoria) => categoria.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  handleFilterCupom(value) {

  }

  salvarAdicional(adicional: any, frm: NgForm) {
    this.limpeMensagens();

    if(!frm.valid ||  adicional.salvando)
      return;

    adicional.salvando = true;

    this.produtoService.salveAdicional(this.catalogo, this.produto, adicional, this.empresa.id ? null : true).then( (resp) => {
      this.salvou = true;
      if(!adicional.id)  adicional.id = resp.id;
      adicional.opcoesDisponiveis = resp.opcoesDisponiveis;
      adicional.salvando = false;
      this.mensagemSucesso = String(`${adicional.nome}s salvo com sucesso!`);
      frm.form.markAsPristine();
      frm.form.markAsUntouched();
    }).catch((erro) => {
      adicional.salvando = false;
      this.mensagemErro = erro;
    })


  }

  exibaDescontos(){
    this.exibirDescontos = true;
  }

  removerDesconto(){
    this.exibirDescontos = false;
    this.frm.form.markAsDirty();

    if(this.podeEditarProdutos) {
      delete  this.produto.percentualDesconto;
      delete   this.produto.novoPreco;
    }
    else if(this.podeEditarPrecos) {
      delete this.produto.percentualDescontoNaEmpresa;
      delete this.produto.novoPrecoNaEmpresa;

    }
    this.produto.destaque = false;
  }

  alterouNovoPreco() {
    this.produto.percentualDesconto =  Number((  100 - (this.produto.novoPreco / this.produto.preco ) * 100 ).toFixed(2))
  }

  alterouNovoPrecoNaEmpresa() {
    this.produto.percentualDescontoNaEmpresa =  Number((  100 - (this.produto.novoPrecoNaEmpresa /
      this.produto.precoNaEmpresa ) * 100 ).toFixed(2))
  }


  alterouPercentualDesconto() {
    let desconto =  this.produto.preco *   (this.produto.percentualDesconto / 100 );
    this.produto.novoPreco = Number(this.produto.preco - desconto).toFixed(2);
  }

  alterouPercentualDescontoNaEmpresa() {
    let desconto =  this.produto.precoNaEmpresa *   (this.produto.percentualDescontoNaEmpresa / 100 );
    this.produto.novoPrecoNaEmpresa = Number(this.produto.precoNaEmpresa - desconto).toFixed(2);
  }

  alterouNovoPrecoTamanho(tamanho: any){
    tamanho.percentualDesconto =  Number((  100 - (tamanho.novoPreco / tamanho.preco ) * 100 ).toFixed(2))
  }

  alterouPercentualDescontoTamanho(tamanho: any){
    let desconto =  tamanho.preco *   (tamanho.percentualDesconto / 100 );
    tamanho.novoPreco = Number(tamanho.preco - desconto).toFixed(2);

  }

  removerDescontoTamanho(tamanho: any){
    tamanho.exibirDescontos = false;
    delete  tamanho.percentualDesconto;
    delete tamanho.novoPreco
   tamanho.destaque = false;
    this.frm.form.markAsDirty();
  }

  adicionarImagem() {
    if(!this.produto.imagensAdicionais)
      this.produto.imagensAdicionais = []

    this.produto.imagensAdicionais.push({indice: this.produto.imagensAdicionais.length + 2})
  }

  salveImagemAdicional($event: any, imagemAdicional: any) { }


  excluirAdicional(turno: any) {
    if(turno.id){
      turno.removido = true;
    } else {
      this.produto.turnos.splice( this.produto.turnos.indexOf(turno), 1);
    }
  }

  exibirNoSite(){
    return this.temModuloFidelidade && !this.temModuloPedidos
  }

  integradoComEcletica() {
    return this.empresa.integracaoDelivery && this.empresa.integracaoDelivery.sistema === 'ecletica';
  }
  integradoComEcleticaOuGCOM() {
    return this.empresa.integracaoDelivery && (this.empresa.integracaoDelivery.sistema === 'ecletica' ||
      this.empresa.integracaoDelivery.sistema === 'gcom');
  }

  trendsFoods(){
    return this.empresa && this.empresa.rede === 'chinainbox'
  }

  pontuarFidelidade() {
    if(!this.empresa || !this.empresa.integracaoPedidoFidelidade)
       return false;

    let tipoAcumulo = this.empresa.integracaoPedidoFidelidade.plano.tipoDePontuacao.tipo;

    return tipoAcumulo === 'qtde-variavel-por-atividade' || tipoAcumulo === 'cashback'
  }

  acumulaReais(){
    if(!this.empresa || !this.empresa.integracaoPedidoFidelidade)
      return false;

    return this.empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo === 'Reais';
  }

  getCashbackExibir(atividade){
    if(atividade.cashback)
      return String(`${(atividade.cashback * 100).toFixed(1)}%`);

  }

  exibirDisponibilidadeEmpresa() {
    if(!this.catalogo ||  !this.empresa) return false;

    return this.catalogo.disponibilidadePorEmpresa  && this.empresa.id
  }

  getIconTag(nome: string){
    return nome.toLowerCase().replace(' ', '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  setProdutoBrinde(){
   this.tipoDeProduto =  this.produto.tipo = this.TIPOSDEPRODUTOS.BrindeFidelidade;

  }

  ehBrindeFidelidade(){
    return  this.produto.tipo === this.TIPOSDEPRODUTOS.BrindeFidelidade;
  }

  // Otimiza detecção de mudanças em listas
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  carregueCupons() {
    if(!this.produto.gerarCupom)
      this.produto.cupom = null

    if(!this.cupons.length){
      this.cuponsService.listeCupons({}).then((cupons: any) => {
        this.cupons = cupons;
      })
    }
  }
}
