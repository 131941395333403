import {Component, Input, OnInit} from '@angular/core';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {
  ModalTrocarcartaoComponent
} from "../../fidelidade/assinatura-trocarcartao/modal-trocarcartao/modal-trocarcartao.component";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";

@Component({
  selector: 'app-fatura-aberta-pagar',
  templateUrl: './fatura-aberta-pagar.component.html',
  styleUrls: ['./fatura-aberta-pagar.component.scss']
})
export class FaturaAbertaPagarComponent implements OnInit {
  @Input() fatura: any = {}
  fechouAlerta = false;
  @Input() emModal = false;
  mensagemCopiada: string;
  constructor(          private dialogService: DialogService) { }

  ngOnInit(): void {
  }


  abraModalPagarCartao(){
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalTrocarcartaoComponent,
      minWidth: 250,
      width: window.innerWidth > 650 ? 650 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    //windowRef.content.instance.contrato = contrato;
    windowRef.content.instance.reload =  false;
    windowRef.content.instance.exibirFechar =  true;
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.mensagemCopiada = val;

    setTimeout( () => {
      delete this.mensagemCopiada
    }, 5000);
  }

}
