<h4 class="page-title mb-3 d-flex align-items-center">
  <i class="fa fa-users me-2"></i>
  <span class="ml-1">Garçons</span>
</h4>

<kendo-grid [pageSize]="paginacao.size"
            [skip]="paginacao.skip"
            [style]=""
            [kendoGridBinding]="garcons"
            [loading]="loading" style="min-height: 300px;"
            [scrollable]="'none'"
            [pageable]="{
              buttonCount: paginacao.buttonCount,
              info: paginacao.info,
              type: 'numeric',
              pageSizes: paginacao.pageSizes,
              previousNext: paginacao.previousNext
            }"
            (edit)="editarUsuario($event)"
            (pageChange)="mudouPaginacao($event)">
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <button class="  btn btn-blue"  (click)="novoGarcom()">
      <i class="fe-plus mr-1"></i>
      Adicionar garçom
    </button>
  </ng-template>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="nenhum usuário cadastrado"
    loading="Carregando"
    pagerItemsPerPage="itens por página"
  >
  </kendo-grid-messages>


  <kendo-grid-column field="id" title="ID"   [width]="70">  </kendo-grid-column>

  <kendo-grid-column title="Nome"  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span><b>{{dataItem.nome}}</b></span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Email" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="text-primary">{{dataItem.email || '-'}}</span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Ultimo login" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="text-primary" *ngIf="dataItem.ultimoLogin">{{dataItem.ultimoLogin | date:'dd/MM/yyyy HH:mm:ss'}}</span>
      <span class="text-primary" *ngIf="!dataItem.ultimoLogin">-</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Padrão" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <i class="fa fa-check text-success" *ngIf="dataItem.garcomPadrao"></i>
      <i class="fa fa-times text-danger" *ngIf="!dataItem.garcomPadrao"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Ativo" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <i class="fa fa-check text-success" *ngIf="dataItem.ativo"></i>
      <i class="fa fa-times text-danger" *ngIf="!dataItem.ativo"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="100">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editarUsuario($event)">Editar</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
