<div  style="max-width: 500px" class="card" *ngIf="!integracaoIfood.nova && !integracaoIfood.editar ">
  <div class="card-body"  >
    <div    class="text-center" [hidden]="integracaoIfood.editar">

        <img src="/assets/icons/logo-ifood.png" height="75px" class="logo-ifood" >

        <p class="text-black-50 mb-1">
          Nome da Loja:  <span class="text-primary"><b>{{integracaoIfood.nomeLoja}}</b></span>
          <br>
          Merchant id:  <span class="text-primary">  {{integracaoIfood.idLoja}} </span>

        </p>


      <ng-container *ngIf="!integracaoIfood.desativado">
        <p class="mb-1">Integração
          <b>{{integracaoIfood.codigoAutorizacao ? 'distribuida' : 'centralizada'}}</b>
          ativa desde de <b>{{integracaoIfood.dataAtivacao | date: 'dd/MMM/yyyy'}}</b></p>

        <p     class="text-muted" mb-1>
          Validade token: <b>{{integracaoIfood.validadeToken | date: 'dd/MMM/yyyy HH:mm'}}</b></p>
      </ng-container>

      <div class="row mb-2 caixa">
        <div class="col">
          <p class="text-black  mb-1">
            <b>  Order Api: </b> <span class="text-success" *ngIf="integracaoIfood.orderApi"><b>Sim</b></span>
            <span class="text-danger"  *ngIf="!integracaoIfood.orderApi">Não </span>

          </p>
        </div>
        <div class="col">
          <p class="text-black  mb-1">
            <b> Shipping Api:</b>  <span class="text-success" *ngIf="integracaoIfood.shippingApi"><b>Sim</b></span>
            <span class="text-danger"  *ngIf="!integracaoIfood.shippingApi">Não </span>

            <ng-container *ngIf="integracaoIfood.shippingApi">
              <br>
              Solicitar automatico: <span class="text-success" *ngIf="integracaoIfood.solicitarAutomatico"><b>Sim</b></span>
              <span class="text-danger"  *ngIf="!integracaoIfood.solicitarAutomatico">Não </span>
              <br>
              Confirmar cotação: <span class="text-success" *ngIf="integracaoIfood.confirmarCotacao"><b>Sim</b></span>
              <span class="text-danger"  *ngIf="!integracaoIfood.confirmarCotacao">Não </span>
            </ng-container>
          </p>
        </div>
      </div>

      <ng-container *ngIf="integracaoIfood.desativado">
         <p class="text-danger">Integração desativada</p>
         <br>
        <button class="btn btn-success" (click)="ativeIntegracao()" [disabled]="ativando">
          Ativar</button>

      </ng-container>

      <ng-container *ngIf="!integracaoIfood.desativado">
        <span [hidden]="confirmarDesativar || confirmarRemover">
            <button class="btn btn-blue mr-2" (click)="editarIntegracao(integracaoIfood)" > Editar</button>


            <button class="btn btn-warning mr-2" (click)="confirmeDesativar()"  >Desativar</button>


            <button class="btn btn-danger" (click)="confirmeRemover()"  >Remover</button>
        </span>

        <h4 class="mt-2 " *ngIf="confirmarRemover">
          Confirma a remoção da integração  ?

          <div class="mt-1">
            <button class="btn btn-success mr-1"  (click)="removerIntegracao()" [disabled]="removendo">Sim</button>
            <button class="btn btn-light" (click)="canceleRemover()">Cancelar</button>
          </div>

        </h4>


        <h4 class="mt-2 " *ngIf="confirmarDesativar">
          Confirma a destativar da integração  ?

          <div class="mt-1">
            <button class="btn btn-success mr-1"  (click)="desativarIntegracao()" [disabled]="removendo">Sim</button>
            <button class="btn btn-light" (click)="canceleRemover()">Cancelar</button>
          </div>

        </h4>
      </ng-container>



      </div>
  </div>
</div>



<div  style="max-width: 700px" class="card" *ngIf="integracaoIfood.nova || integracaoIfood.editar">
  <div class="card-body"  >
    <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
          novalidate #frm="ngForm" (ngSubmit)="onSubmit()"    >
      <h5>Integração com pedidos do <b>Ifood</b>  </h5>


      <div class="row" *ngIf="integracaoIfood.nova">
        <div class="col" style="    max-width: 175px;">
          <button class="btn btn-blue mt-1" type="button" (click)="gereLinkAutorizacao()" [disabled]="gerandoLink || ifooodUserCode.id">
            <i class="k-icon k-i-loading " *ngIf="gerandoLink" ></i> Gerar Link</button>
        </div>

        <div class="col">

          <div class="form-group mt-1">
            <input kendoTextBox   name="verificationUrl"  placeholder="" readonly
                   class="form-control"   #verificationUrl="ngModel"
                   [(ngModel)]="ifooodUserCode.verificationUrl" required/>
          </div>

          <a target="_blank" href="{{ifooodUserCode.verificationUrl}}" *ngIf="ifooodUserCode.verificationUrl">abrir link</a>
        </div>

      </div>
      <div class="row mt-2"    *ngIf="integracaoIfood.nova">
        <div class="col">

          <div class="form-group  ">
            <label>Codigo Autorizacao</label>
            <input kendoTextBox   name="codigoAutorizacao"  placeholder="Codigo autorização gerado pela loja do cliente"
                   class="form-control"   #codigoAutorizacao="ngModel"
                   [(ngModel)]="ifooodUserCode.codigoAutorizacao" required/>

            <div class="invalid-feedback">
              <p  >Campo é obrigatório</p>
            </div>
          </div>
        </div>
        <div class="col">

          <div class="form-group  ">
            <label>Id da Loja </label>
            <input kendoTextBox   name="grupo"  placeholder="Id da loja no Ifood"
                   class="form-control"   #idLoja="ngModel"
                   [(ngModel)]="ifooodUserCode.idLoja"  />


          </div>
        </div>
      </div>

      <div class="row">
        <div class="col"  >
          <div class="form-group mb-2 ">
            <div class="input-group mt-2">
              <input  name="orderApi" id='orderApi'    class="k-checkbox  " type="checkbox" kendoCheckBox
                      [(ngModel)]="integracaoIfood.orderApi"/>
              <label  class="k-checkbox-label">Integrado API Pedidos</label>
            </div>

            <small class="text-muted  mt-1">importar pedidos do ifood para o promokit</small>
          </div>
        </div>
        <div class="col"  >
          <div class="form-group mb-2 ">
            <div class="input-group mt-2">
              <input  name="shippingApi" id='shippingApi'    class="k-checkbox  " type="checkbox" kendoCheckBox
                      [(ngModel)]="integracaoIfood.shippingApi"/>
              <label  class="k-checkbox-label">Integrado API Delivery</label>
            </div>
            <small class="text-muted mt-1">solicitar entregadores do ifood</small>
          </div>
        </div>
      </div>

      <ng-container *ngIf="integracaoIfood.shippingApi">
        <div class="row">
          <div class="col">
            <div class="input-group mt-1 mb-2">
              <input  name="solicitarAutomatico" id='solicitarAutomatico'    class="k-checkbox  " type="checkbox" kendoCheckBox
                      [(ngModel)]="integracaoIfood.solicitarAutomatico"/>
              <label  class="k-checkbox-label" for="solicitarAutomatico">
                Solicitar entregador automaticamente quando pedido entrar <b>"Em Preparação"</b> </label>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!integracaoIfood.solicitarAutomatico">
          <div class="col" >
            <div class="input-group mt-1 mb-2">
              <input  name="confirmarCotacao" id='confirmarCotacao'    class="k-checkbox  " type="checkbox" kendoCheckBox
                      [(ngModel)]="integracaoIfood.confirmarCotacao"/>
              <label  class="k-checkbox-label" for="confirmarCotacao">
                Exibir tela confirmação com a cotação antes de solicitar entregador  </label>
            </div>
          </div>
        </div>


      </ng-container>

      <div class="row">
        <div class="col">
          <button class="btn btn-success mt-2" type="submit" *ngIf="integracaoIfood.nova" [disabled]="salvando || !ifooodUserCode.codigoAutorizacao">
            <i class="k-icon k-i-loading " *ngIf="salvando"></i>  Autorizar
          </button>

          <button class="btn btn-success mt-2" type="submit"  *ngIf="integracaoIfood.editar"  [disabled]="salvando ">
            <i class="k-icon k-i-loading " *ngIf="salvando"></i>  Salvar
          </button>

          <button class="btn btn-secondary mt-2 ml-1" type="button" (click)="integracaoIfood.editar = false" [hidden]="!integracaoIfood.id" >
            Voltar
          </button>

          <div class="alert alert-danger mt-2" role="alert" *ngIf="mensagemErro">
            {{mensagemErro}}
            <button type="button" class="close" aria-label="Close" (click)="fecharAlerta()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>

