<div class="page-title-right d-none d-md-block">
  <ol class="breadcrumb m-0">
    <li class="breadcrumb-item active">Fidelidade</li>
  </ol>
</div>

<h4 class="page-title"><i class="fab fa-whatsapp"></i> Central de Notificações</h4>

<div class="row g-0">
  <div class="col-12">
    <div class="card">
      <div class="card-body p-0">
        <div class="loading-overlay" *ngIf="loading">
          <div class="k-i-loading"></div>
        </div>

        <div class="row g-0" *ngIf="!loading">
          <!-- Menu Lateral -->
          <div class="col-md-3 notifications-sidebar" [class.d-none]="exibindoFormMobile" [class.d-md-block]="exibindoFormMobile">
            <!-- Busca -->
            <div class="search-box">
              <i class="fas fa-search"></i>
              <input type="text" class="form-control" placeholder="Buscar notificação..." [(ngModel)]="termoBusca">
            </div>

            <!-- Navegação -->
            <div class="notifications-nav">
              <!-- Fidelidade -->
              <div class="nav-section" *ngIf="temModuloFidelidade">
                <div class="nav-section-header">
                  <i class="fas fa-crown"></i>
                  <span>Fidelidade</span>
                </div>
                <div class="nav-items">
                  <a class="nav-item" *ngFor="let notificacao of notificacoesFidelidade"
                     [class.active]="notificacaoSelecionada === notificacao"
                     (click)="selecionarNotificacao(notificacao)">
                    <i [class]="getIconeNotificacao(notificacao.tipoDeNotificacao)"></i>
                    <span>{{notificacao.tipoDeNotificacao}}</span>
                  </a>
                </div>
              </div>

              <!-- Pedidos -->
              <div class="nav-section" *ngIf="temModuloPedidos">
                <div class="nav-section-header">
                  <i class="fas fa-shopping-bag"></i>
                  <span>Pedidos</span>
                </div>
                <div class="nav-items">
                  <a class="nav-item" *ngFor="let notificacao of notificacoesPedidos"
                     [class.active]="notificacaoSelecionada === notificacao"
                     (click)="selecionarNotificacao(notificacao)">
                    <i [class]="getIconeNotificacao(notificacao.tipoDeNotificacao)"></i>
                    <span>{{notificacao.tipoDeNotificacao}}</span>
                  </a>
                </div>
              </div>

              <!-- SDR -->
              <div class="nav-section" *ngIf="notificacoesSDR.length > 0">
                <div class="nav-section-header">
                  <i class="fas fa-chart-line"></i>
                  <span>SDR</span>
                </div>
                <div class="nav-items">
                  <a class="nav-item" *ngFor="let notificacao of notificacoesSDR"
                     [class.active]="notificacaoSelecionada === notificacao"
                     (click)="selecionarNotificacao(notificacao)">
                    <i [class]="getIconeNotificacao(notificacao.tipoDeNotificacao)"></i>
                    <span>{{notificacao.tipoDeNotificacao}}</span>
                  </a>
                </div>
              </div>

              <!-- Entregas -->
              <div class="nav-section">
                <div class="nav-section-header">
                  <i class="fas fa-truck-fast"></i>
                  <span>Entregas</span>
                </div>
                <div class="nav-items">
                  <a class="nav-item" *ngFor="let notificacao of notificacoesEntregas"
                     [class.active]="notificacaoSelecionada === notificacao"
                     (click)="selecionarNotificacao(notificacao)">
                    <i [class]="getIconeNotificacao(notificacao.tipoDeNotificacao)"></i>
                    <span>{{notificacao.tipoDeNotificacao}}</span>
                  </a>
                </div>
              </div>

              <!-- Templates -->
              <div class="nav-section">
                <div class="nav-section-header">
                  <i class="fas fa-layer-group"></i>
                  <span>Templates</span>
                </div>
                <div class="nav-items">
                  <a class="nav-item" [class.active]="mostrandoTemplates"
                     (click)="mostrarTemplates()">
                    <i class="fas fa-file-alt"></i>
                    <span>Templates de Mensagem</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Área de Conteúdo -->
          <div class="col-md-9 notifications-content p-0" [class.d-none]="!exibindoFormMobile && isMobile" [class.d-md-block]="true">
            <!-- Botão Voltar Mobile -->
            <div class="d-md-none" *ngIf="exibindoFormMobile">
              <button class="btn btn-light" (click)="voltarParaLista()">
                <i class="fas fa-arrow-left"></i> Voltar
              </button>
            </div>

            <!-- Conteúdo de Notificação -->
            <div *ngIf="!mostrandoTemplates" class="notification-form" #formContent>
              <!-- Título do Form -->


              <app-form-notificacao *ngIf="notificacaoSelecionada"
                [notificacao]="notificacaoSelecionada"
                [icone]="getIconeNotificacao(notificacaoSelecionada.tipoDeNotificacao)">
              </app-form-notificacao>
            </div>

            <!-- Conteúdo de Templates -->
            <div *ngIf="mostrandoTemplates" class="templates-content">
              <div class="notification-header">
                <div class="notification-title">
                  <i class="fas fa-layer-group"></i>
                  <h4>Templates de Mensagem</h4>
                </div>
                <div class="notification-actions">
                  <button class="btn btn-primary">
                    <i class="fas fa-plus"></i>
                    Novo Template
                  </button>
                </div>
              </div>
              <app-templates-de-mensagem></app-templates-de-mensagem>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
