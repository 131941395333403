<div class="integration-container" style="max-width: 900px; position: relative">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

    <div class="bloqueio" *ngIf="integracao && !integracao.ativa"></div>

    <!-- Seção: Nossas Credenciais -->
    <div class="card mb-4">
      <div class="card-header bg-light">
        <h4 class="mb-0"><i class="fas fa-key mr-2"></i>Nossas Credenciais</h4>
        <small class="text-muted">Informações que devem ser configuradas no sistema do parceiro</small>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-link mr-1"></i>
                URL Webhook (Tracking Event Webhook)
                <small class="text-muted d-block">Endereço para receber notificações de rastreamento</small>
              </label>
              <div class="input-group">
                <input kendoTextBox name="urlTracking" class="form-control"
                       [value]="obtenhaBaseUrl()" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button"
                          (click)="copyMessage(obtenhaBaseUrl())"
                          [ngClass]="{'btn-success': textoCopiado === obtenhaBaseUrl()}"
                          title="Copiar URL webhook">
                    <i class="fe-copy" [ngClass]="{'text-white': textoCopiado === obtenhaBaseUrl()}"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-id-card mr-1"></i>
                Merchant ID
                <small class="text-muted d-block">Identificador único da sua loja</small>
              </label>
              <div class="input-group">
                <input kendoTextBox name="merchantId" class="form-control" required
                       placeholder="merchantId da Loja"
                       [(ngModel)]="integracao.merchantId"
                       [readonly]="integracaoOpendelivery?.id"/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button"
                          (click)="copyMessage(integracao.merchantId)"
                          [ngClass]="{'btn-success': textoCopiado === integracao.merchantId}"
                          title="Copiar Merchant ID">
                    <i class="fe-copy" [ngClass]="{'text-white': textoCopiado === integracao.merchantId}"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seção: Credenciais do Parceiro -->
    <div class="card mb-4">
      <div class="card-header bg-light">
        <h4 class="mb-0"><i class="fas fa-shield-alt mr-2"></i>Credenciais do Parceiro</h4>
        <small class="text-muted">Credenciais fornecidas pelo serviço de logística parceiro</small>
      </div>
      <div class="card-body">
        <div class="alert alert-info mb-4">
          <i class="fas fa-info-circle mr-2"></i>
          <strong>Observação:</strong> Em alguns serviços de logística, a <b>URL Base de Autenticação</b>  e a <b>URL Base API</b> podem ser iguais.
        </div>

        <!-- URLs -->
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-lock mr-1"></i>
                URL Base Autenticação
                <small class="text-muted d-block">Endpoint para obter auth/token </small>
              </label>
              <input kendoTextBox name="logisticAuthUrl"
                     placeholder="Ex.: https://api.logistaca.com.br"
                     class="form-control" #logisticAuthUrl="ngModel"
                     [(ngModel)]="integracao.authUrl" required/>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-globe mr-1"></i>
                URL Base API
                <small class="text-muted d-block">Endpoint para logistics/delivery </small>
              </label>
              <input kendoTextBox name="logisticBaseUrl"
                     placeholder="Ex.: https://api.logistaca.com.br/v1"
                     class="form-control" #logisticBaseUrl="ngModel"
                     [(ngModel)]="integracao.baseUrl" required/>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
        </div>

        <!-- Credenciais -->
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-user mr-1"></i>
                Client ID
              </label>
              <input kendoTextBox name="logisticClientId" class="form-control"
                     placeholder="clientId para gerar o token"
                     [(ngModel)]="integracao.clientId" required/>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-key mr-1"></i>
                Client Secret
              </label>
              <input kendoTextBox name="logisticClientSecret" class="form-control"
                     placeholder="clientSecret para gerar o token"
                     [(ngModel)]="integracao.clientSecret" required/>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>
                <i class="fas fa-fingerprint mr-1"></i>
                App ID
              </label>
              <input kendoTextBox name="appid" class="form-control"
                     placeholder="App id único"
                     [(ngModel)]="integracao.appid" required/>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seção: Configurações Específicas do Cliente -->
    <div class="card mb-4">
      <div class="card-header bg-light">
        <h4 class="mb-0">
          <i class="fas fa-user-cog mr-2"></i>Configurações Específicas do Cliente
          <small class="text-muted d-block">Personalize as configurações de acordo com sua operação</small>
        </h4>
      </div>
      <div class="card-body">
        <!-- Tempos de Operação -->
        <div class="card mb-3">
          <div class="card-header bg-light">
            <h5 class="mb-0">
              <i class="fas fa-clock mr-2"></i>Tempos de Operação
              <small class="text-muted d-block">Configure os tempos máximos para sua operação</small>
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    <i class="fas fa-store mr-1"></i>
                    Tempo Máximo para Retirada
                    <small class="text-muted d-block">Quanto tempo o entregador tem para retirar na loja</small>
                  </label>
                  <kendo-numerictextbox
                    class="form-control"
                    [min]="0"
                    name="tempoLimiteRetirada"
                    [format]="'0 min'"
                    required
                    [(ngModel)]="integracao.tempoLimiteRetirada"
                    placeholder="Ex.: 15 minutos">
                  </kendo-numerictextbox>
                  <div class="invalid-feedback">Defina o tempo máximo para retirada</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    <i class="fas fa-shipping-fast mr-1"></i>
                    Tempo Máximo para Entrega
                    <small class="text-muted d-block">Quanto tempo o entregador tem para entregar ao cliente</small>
                  </label>
                  <kendo-numerictextbox
                    class="form-control"
                    [min]="0"
                    name="tempoLimiteEntrega"
                    [format]="'0 min'"
                    required
                    [(ngModel)]="integracao.tempoLimiteEntrega"
                    placeholder="Ex.: 45 minutos">
                  </kendo-numerictextbox>
                  <div class="invalid-feedback">Defina o tempo máximo para entrega</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Veículo e Equipamentos -->
        <div class="card mb-3">
          <div class="card-header bg-light">
            <h5 class="mb-0">
              <i class="fas fa-truck mr-2"></i>Veículo e Equipamentos
              <small class="text-muted d-block">Configure os requisitos do veículo para entrega</small>
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    <i class="fas fa-motorcycle mr-1"></i>
                    Tipos de Veículo Aceitos
                    <small class="text-muted d-block">Quais veículos podem fazer suas entregas</small>
                  </label>
                  <kendo-multiselect
                    name="tiposVeiculo"
                    [(ngModel)]="integracao.veiculoPadrao.type"
                    [data]="veiculosTipos"
                    placeholder="Selecione os tipos permitidos"
                    class="form-control"
                    required>
                  </kendo-multiselect>
                  <div class="invalid-feedback">Selecione pelo menos um tipo de veículo</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>
                    <i class="fas fa-box mr-1"></i>
                    Tipo de Container
                    <small class="text-muted d-block">Container para transporte</small>
                  </label>
                  <kendo-combobox
                    name="container"
                    [(ngModel)]="integracao.veiculoPadrao.container"
                    [data]="conteiners"
                    textField="nome"
                    placeholder="Selecione o container"
                    class="form-control"
                    required>
                  </kendo-combobox>
                  <div class="invalid-feedback">Selecione o tipo de container</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>
                    <i class="fas fa-ruler mr-1"></i>
                    Tamanho do Container
                    <small class="text-muted d-block">Capacidade necessária</small>
                  </label>
                  <kendo-combobox
                    name="containerSize"
                    [(ngModel)]="integracao.veiculoPadrao.containerSize"
                    [data]="conteinersTamanhos"
                    textField="nome"
                    placeholder="Selecione o tamanho"
                    class="form-control"
                    required>
                  </kendo-combobox>
                  <div class="invalid-feedback">Selecione o tamanho do container</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Automação e Comportamento -->
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="mb-0">
              <i class="fas fa-robot mr-2"></i>Automação e Comportamento
              <small class="text-muted d-block">Configure o comportamento automático do sistema</small>
            </h5>
          </div>
          <div class="card-body">
            <div class="alert alert-info mb-4">
              <i class="fas fa-info-circle mr-2"></i>
              Estas configurações determinam como o sistema se comporta automaticamente
            </div>

            <div class="custom-control custom-switch mb-4">
              <input type="checkbox"
                     class="custom-control-input"
                     id="automatico"
                     name="automatico"
                     [(ngModel)]="integracao.automatico">
              <label class="custom-control-label" for="automatico">
                <strong>Solicitar Entregador Automaticamente</strong>
                <small class="text-muted d-block">
                  O sistema solicitará um entregador assim que o pedido entrar em "Em Preparação"
                </small>
              </label>
            </div>

            <div class="custom-control custom-switch">
              <input type="checkbox"
                     class="custom-control-input"
                     id="naoEnviarLocalizacao"
                     name="naoEnviarLocalizacao"
                     [(ngModel)]="integracao.naoEnviarLocalizacao">
              <label class="custom-control-label" for="naoEnviarLocalizacao">
                <strong>Privacidade de Localização</strong>
                <small class="text-muted d-block">
                  Não compartilhar coordenadas GPS (latitude/longitude) do endereço de entrega
                </small>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Botões de Ação -->
    <div class="card-footer bg-light text-right">
      <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mb-3" role="alert">
        <i class="fas fa-exclamation-triangle mr-2"></i>{{mensagemErro}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <button class="btn btn-warning" type="button" [disabled]="processando"
              (click)="ativeIntegracao()" *ngIf="this.integracao?.id && !this.integracao.ativa">
        <i class="fas fa-power-off mr-2"></i>Ativar
        <i class="k-i-loading k-icon ml-2" *ngIf="processando"></i>
      </button>

      <ng-container *ngIf="!this.integracao?.id || this.integracao.ativa">
        <button class="btn btn-success" type="submit" [disabled]="salvando || processando">
          <i class="fas fa-save mr-2"></i>Salvar
          <i class="k-i-loading k-icon ml-2" *ngIf="salvando"></i>
        </button>

        <button class="btn btn-danger ml-2" type="button" [disabled]="salvando || processando"
                *ngIf="integracao.id" (click)="desativeIntegracao()">
          <i class="fas fa-power-off mr-2"></i>Desativar
          <i class="k-i-loading k-icon ml-2" *ngIf="processando"></i>
        </button>
      </ng-container>
    </div>
  </form>
</div>



