<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}" novalidate #frm="ngForm" (ngSubmit)="onSubmit()">
  <div #pnl class="form-container">
    <!-- Título do Form -->
    <div class="form-header">
      <i [class]="icone"></i>
      <h4>{{notificacao.tipoDeNotificacao}}</h4>
    </div>

    <!-- Mensagem de Sucesso -->
    <div class="alert alert-success alert-dismissible fade show" *ngIf="notificacao.mensagemSucesso" role="alert">
      <i class="mdi mdi-check-all mr-2"></i> {{notificacao.mensagemSucesso}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- Configurações Principais -->
    <div class="card config-card mb-4">
      <div class="card-body">
        <h5 class="card-title mb-3">Configurações Principais</h5>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="d-flex justify-content-between align-items-center">
                <span>Status da Notificação</span>
                <kendo-switch [(ngModel)]="notificacao.ativada" name="ativada" id="ativada"
                            [disabled]="!notificacao.podeDesativar"></kendo-switch>
              </label>
              <small class="form-text text-muted">Ative ou desative o envio desta notificação</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="d-flex justify-content-between align-items-center">
                <span>Preview dos Links</span>
                <kendo-switch [(ngModel)]="notificacao.fazerPreview" name="fazerPreview" id="fazerPreview"></kendo-switch>
              </label>
              <small class="form-text text-muted">Habilite para mostrar preview dos links no WhatsApp</small>
            </div>
          </div>
        </div>

        <!-- Configurações específicas para Mensagem de Saudação -->
        <div class="row" *ngIf="notificacao.tipoDeNotificacao === NOTIFICACAO_MENSAGEM_SAUDACAO">
          <div class="col-md-6">
            <div class="form-group">
              <label class="d-flex justify-content-between align-items-center">
                <span>Marcar como Lida</span>
                <kendo-switch [(ngModel)]="notificacao.marcarComoLida" name="marcarComoLida" id="marcarComoLida"></kendo-switch>
              </label>
              <small class="form-text text-muted">Marcar mensagem como lida automaticamente</small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tempo para Nova Mensagem</label>
              <div class="input-group">
                <input type="text" class="form-control" currencyMask
                       [options]="{ prefix: '', suffix: '', precision: 0, thousands: '', decimal: ',', align: 'left', allowNegative: false }"
                       [(ngModel)]="tempoMensagemWhatsapp" name="tempoMensagemWhatsapp"/>
                <div class="input-group-append">
                  <span class="input-group-text">hora(s)</span>
                </div>
              </div>
              <small class="form-text text-muted">Intervalo para envio de nova mensagem</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Editor de Mensagem -->
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="card-title mb-3">Mensagem</h5>
        <div class="form-group message-editor">
          <div class="editor-actions">
            <kendo-dropdownbutton *ngIf="templates && templates.length > 0"
                                [data]="templates"
                                textField="nome"
                                valueField="nome"
                                (itemClick)="adicioneCampo(textArea, notificacao, $event)"
                                class="btn-sm mr-2">
              <i class="fas fa-file-alt mr-1"></i> Templates
            </kendo-dropdownbutton>

            <kendo-dropdownbutton [data]="campos"
                                textField="nome"
                                valueField="nome"
                                (itemClick)="adicioneCampo(textArea, notificacao, $event)"
                                class="btn-sm">
              <i class="fas fa-plus mr-1"></i> Campos
            </kendo-dropdownbutton>
          </div>

          <textarea class="form-control"
                    id="txtMensagem"
                    rows="8"
                    name="txtMensagem"
                    [(ngModel)]="notificacao.mensagem"
                    #txtMensagem="ngModel"
                    #textArea
                    required
                    appAutoFocus
                    (ngModelChange)="alterouMensagem(notificacao)"
                    [maxlength]="QTDE_MAXIMA_CARACTERES"
                    placeholder="Digite sua mensagem aqui..."></textarea>

          <div class="message-footer">
            <span [ngClass]="{
              'text-success': notificacao.qtdeCaracteresRestantes >= 50,
              'text-warning': notificacao.qtdeCaracteresRestantes < 50 && notificacao.qtdeCaracteresRestantes >= 20,
              'text-danger': notificacao.qtdeCaracteresRestantes < 20
            }">
              <i class="fas fa-info-circle mr-1"></i>
              {{notificacao.qtdeCaracteresRestantes}} caracteres restantes
            </span>
          </div>

          <div *ngIf="txtMensagem.invalid && (txtMensagem.dirty || txtMensagem.touched || frm.submitted)"
               class="invalid-feedback">
            Informe uma mensagem que será enviada ao cliente.
          </div>
        </div>
      </div>
    </div>

    <!-- Configurações de Intervalo -->
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="card-title mb-3">Configurações de Intervalo</h5>

        <div class="form-group" *ngIf="informarQtdeDiasAtiva(notificacao)">
          <label>Intervalo de Envio</label>
          <div class="input-group" style="max-width: 200px;">
            <input type="number"
                   class="form-control"
                   [required]="informarQtdeDiasAtiva(notificacao)"
                   name="qtdeDiasAtiva"
                   #qtdeDiasAtiva="ngModel"
                   [(ngModel)]="notificacao.qtdeDiasAtiva"
                   autocomplete="off">
            <div class="input-group-append">
              <span class="input-group-text">dia(s)</span>
            </div>
          </div>
          <small class="form-text text-muted">Intervalo para disparar o envio da notificação</small>

          <div *ngIf="qtdeDiasAtiva.invalid && (qtdeDiasAtiva.dirty || qtdeDiasAtiva.touched || frm.submitted)"
               class="invalid-feedback">
            Informe a quantidade de dias que irá disparar o envio da notificação
          </div>
        </div>

        <div class="form-group" *ngIf="!informarQtdeDiasAtiva(notificacao)">
          <label>Intervalo entre Notificações</label>
          <div class="input-group" style="max-width: 200px;">
            <input type="number"
                   class="form-control"
                   required
                   name="qtdeDias"
                   #txtQtdeDeDias="ngModel"
                   [(ngModel)]="notificacao.qtdeDeDiasNovaNotificacao"
                   [minimo]="-1"
                   [maximo]="180"
                   autocomplete="off">
            <div class="input-group-append">
              <span class="input-group-text">dia(s)</span>
            </div>
          </div>
          <small class="form-text text-muted">Intervalo mínimo para enviar nova notificação</small>

          <div *ngIf="txtQtdeDeDias.invalid && (txtQtdeDeDias.dirty || txtQtdeDeDias.touched || frm.submitted)"
               class="invalid-feedback">
            <div *ngIf="txtQtdeDeDias.errors?.required">
              Informe a quantidade de dias mínimo para enviar nova notificação.
            </div>
            <div *ngIf="txtQtdeDeDias.errors?.minimo">
              A quantidade de dias para enviar outra mensagem deve estar entre menor que 365 dias.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Menu WhatsApp -->
    <div class="card mb-4">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h5 class="menu-title">Opções de Menu</h5>
          <div class="toggle-wrapper">
            <kendo-switch [(ngModel)]="notificacao.menuAtivo" name="menuAtivo"></kendo-switch>
            <span class="toggle-label">Enviar opções de menu</span>
          </div>
        </div>

        <div *ngIf="notificacao.menuAtivo">
          <!-- Título do Menu -->
          <div class="form-group menu-title-input">
            <input type="text" class="form-control"
                   [(ngModel)]="notificacao.tituloMenu"
                   name="tituloMenu"
                   placeholder="Digite o título do menu e pressione Enter"
                   (keyup.enter)="focarNovaOpcao()"
                   required
                   #tituloInput>
            <div class="invalid-feedback" *ngIf="!notificacao.tituloMenu?.trim() && frm.submitted">
              O título do menu é obrigatório
            </div>
          </div>

          <!-- Lista de Opções -->
          <div class="menu-options">
            <!-- Opções Existentes -->
            <div class="option-item" *ngFor="let opcao of notificacao.opcoes; let i = index">
              <div class="option-header" (click)="toggleExpand(i)">
                <div class="option-number">{{i + 1}}</div>
                <input type="text"
                       class="option-text"
                       [(ngModel)]="opcao.texto"
                       [name]="'opcaoTexto' + i"
                       required
                       [class.is-invalid]="!opcao.texto?.trim() && frm.submitted"
                       (click)="$event.stopPropagation()"
                       (keyup.enter)="focarResposta(i)">
                <div class="option-status" *ngIf="!opcao.valor?.trim()">
                  <i class="fas fa-exclamation-circle" title="Resposta não preenchida"></i>
                </div>
                <div class="option-actions" (click)="$event.stopPropagation()">
                  <button type="button" class="btn btn-link btn-sm" (click)="toggleExpand(i)">
                    <i class="fas fa-chevron-up" [class.expanded]="opcao.expanded"></i>
                  </button>
                  <button type="button" class="btn btn-link btn-sm text-danger" (click)="removerOpcao(i)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>

              <!-- Campo de Resposta -->
              <div class="option-response" [class.expanded]="opcao.expanded">
                <div class="response-editor">
                  <div class="editor-header">
                    <kendo-dropdownbutton [data]="campos"
                                        textField="nome"
                                        valueField="nome"
                                        (itemClick)="adicioneCampo(respostaTextarea, opcao, $event)"
                                        class="btn-sm">
                      <i class="fas fa-plus mr-1"></i> Campos
                    </kendo-dropdownbutton>
                  </div>
                  <textarea class="form-control"
                            [(ngModel)]="opcao.valor"
                            [name]="'opcaoValor' + i"
                            required
                            [class.is-invalid]="!opcao.valor?.trim() && frm.submitted"
                            #respostaTextarea
                            (ngModelChange)="opcao.valor = $event?.trim()"
                            placeholder="Digite a mensagem de resposta..."></textarea>
                  <div class="invalid-feedback" *ngIf="!opcao.valor?.trim() && frm.submitted">
                    A resposta é obrigatória
                  </div>
                </div>
              </div>
            </div>

            <!-- Nova Opção -->
            <div class="option-item new-option">
              <div class="option-header">
                <div class="option-number">{{(notificacao.opcoes?.length || 0) + 1}}</div>
                <input type="text" class="form-control"
                       [(ngModel)]="novaOpcao.texto"
                       name="novaOpcaoTexto"
                       placeholder="Digite a opção e pressione Enter"
                       (keyup.enter)="adicionarOpcaoRapida(novaOpcao.texto); novaOpcao.texto = ''"
                       #novaOpcaoInput>
                <div class="new-option-hint">
                  <i class="fas fa-keyboard"></i>
                  Pressione Enter para criar a opção
                </div>
              </div>
            </div>
          </div>

          <!-- Templates Rápidos -->
          <div class="template-chips">
            <button type="button" class="template-chip" (click)="aplicarTemplate('cardapio')">
              <i class="fas fa-utensils"></i> Cardápio
            </button>
            <button type="button" class="template-chip" (click)="aplicarTemplate('fidelidade')">
              <i class="fas fa-star"></i> Fidelidade
            </button>
            <button type="button" class="template-chip" (click)="aplicarTemplate('pedido')">
              <i class="fas fa-shopping-bag"></i> Status Pedido
            </button>
            <button type="button" class="template-chip" (click)="aplicarTemplate('atendimento')">
              <i class="fas fa-headset"></i> Atendimento
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mensagens de Erro/Sucesso -->
    <div class="alert alert-danger" *ngIf="erro">
      <i class="fas fa-exclamation-circle mr-1"></i> {{erro}}
    </div>

    <div class="alert alert-success" *ngIf="notificacao.mensagemSucesso" (close)="fecheMensagemSucesso()">
      <i class="mdi mdi-check-all mr-2"></i> {{notificacao.mensagemSucesso}}
    </div>

    <!-- Botões de Ação -->
    <div class="form-actions">
      <button type="submit" class="btn btn-primary btn-lg">
        <i class="fas fa-save mr-1"></i> Salvar
      </button>
    </div>
  </div>
</form>
