<div class="container-fluid">
  <div class="row mt-2 mb-3">
    <div class="col">
      <div class="page-title">
        <i class="fas fa-chart-line"></i>
        <h2>CRM de Contatos</h2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card filtro">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row filtro">
                <div class="col-12 col-sm-7">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend" (click)="onBuscaChange(termoBusca)">
                        <span class="input-group-text" id="basic-addon1"><i class="fe-search"></i></span>
                      </div>
                      <input type="text" class="form-control" placeholder="Buscar contato"
                             [(ngModel)]="termoBusca"
                             (keyup.enter)="onBuscaChange(termoBusca)">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary waves-effect waves-light d-none d-sm-block"
                                type="button"
                                (click)="exibaFiltroAvancado()">
                          <i class="fe-filter"></i>
                          Avançado
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-5">
                  <div class="d-flex align-items-center justify-content-end">
                    <kendo-combobox name="filtro" [(ngModel)]="filtro" [data]="filtros"
                                  [clearButton]="Object.keys(filtro.dados).length > 0"
                                  class="form-control combo-avancado filtro-grande"
                                  [placeholder]="'Todos os contatos'"
                                  [textField]="'nome'"
                                  (valueChange)="onBuscaChange(termoBusca)">
                    </kendo-combobox>
                    <button class="btn btn-success"
                            [disabled]="!filtro?.salvar"
                            (click)="abraModalSalvarFiltro()"
                            ngbTooltip="salvar filtro">
                      <i class="fe-save"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row d-sm-none mt-2">
                <div class="col-12">
                  <button class="btn btn-outline-secondary btn-lg waves-effect waves-light"
                          type="button"
                          (click)="exibaFiltroAvancado()"
                          style="width: 100%;">
                    <i class="fe-filter"></i>
                    Avançado
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Filtros ativos -->
  <div class="row filtro-aplicado" *ngIf="Object.keys(filtro.dados).length > 0">
    <div class="col-12">
      <div class="filtros-wrapper">
        <div class="filtros-header">
          <i class="fe-filter"></i>
          <span>Filtros aplicados</span>
          <button type="button" class="btn-limpar" (click)="limpeFiltros()">
            <i class="fe-trash-2"></i>
            <span>Limpar todos</span>
          </button>
        </div>
        <div class="filtros-content">
          <div class="filtro-tag" *ngFor="let item of filtro.dados | keyvalue" (click)="removerFiltro(item.key)">
            <span class="filtro-texto">{{getDescricaoFiltro(item.value)}}</span>
            <i class="fe-x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Componente de Filtro Avançado -->
  <div [hidden]="!filtro || !filtro.exibir">
    <filtro-avancado #filtroComponent
      [exibir]="filtro?.exibir"
      (onVoltar)="fecharFiltroAvancado()"
      (onFechar)="fecharFiltroAvancado()"
      (onFiltrar)="onFiltrarAvancado($event)">
    </filtro-avancado>
  </div>

  <!-- Modal Salvar Filtro -->
  <kendo-dialog *ngIf="modalSalvarFiltro" (close)="fecheModalFiltro()" [width]="300">
    <kendo-dialog-titlebar>
      Salvar Filtro
    </kendo-dialog-titlebar>
    <div class="p-3">
      <div class="form-group">
        <label>Nome do Filtro</label>
        <input type="text" class="form-control" [(ngModel)]="filtro.nome">
      </div>
      <div class="text-danger" *ngIf="erroSalvarFiltro">
        {{erroSalvarFiltro}}
      </div>
    </div>
    <kendo-dialog-actions>
      <button class="btn btn-secondary" (click)="fecheModalFiltro()">Cancelar</button>
      <button class="btn btn-primary" (click)="salveFiltro()">Salvar</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <div class="row">
    <div class="col">
      <div class="kanban-board">
        <div class="kanban-columns">
          <div class="kanban-column" *ngFor="let coluna of colunas"
               [attr.data-status]="coluna.id"
               [class.empty-column]="getContatosPorStatus(coluna.id).length === 0"
               [class.expanded]="coluna.expanded"
               (click)="coluna.expanded = !coluna.expanded">
            <div class="column-header">
              <div class="header-content">
                <i [class]="coluna.icone"></i>
                <h3>{{coluna.titulo}}</h3>
              </div>
              <span class="badge">
                <span class="total-text" *ngIf="getContatosPorStatus(coluna.id).length === 0">0/0</span>
                <span class="total-text" *ngIf="getContatosPorStatus(coluna.id).length > 0">{{getExibindoTotal(coluna.id)}}</span>
              </span>
            </div>

            <div class="column-content"
                 #columnContent
                 infiniteScroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 [infiniteScrollContainer]="columnContent"
                 (scrolled)="onScrollColuna(coluna.id)">

              <div class="card-contato" *ngFor="let contato of getContatosPorStatus(coluna.id)"
                   [attr.data-status]="coluna.id">
                <div class="card-header">
                  <h5>{{contato.nome}}</h5>
                </div>
                <div class="card-body">
                  <p>
                    <i class="fas fa-phone"></i>
                    <span>{{contato.telefone | telefone}}</span>
                  </p>
                  <p>
                    <i class="fas fa-clock"></i>
                    <span>Última visita: {{contato.ultimaVisita | date:'dd/MM/yyyy'}}</span>
                  </p>
                  <p *ngIf="contato.qtdePedidos > 0">
                    <i class="fas fa-shopping-cart"></i>
                    <span>{{contato.qtdePedidos}} pedidos</span>
                  </p>
                </div>
                <div class="card-footer">
                  <button class="btn btn-outline-primary"
                          (click)="verContato(contato)">
                    <i class="fas fa-eye"></i>
                    <span>Detalhes</span>
                  </button>
                  <button class="btn btn-outline-success"
                          (click)="abrirWhatsApp(contato.telefone)">
                    <i class="fab fa-whatsapp"></i>
                    <span>WhatsApp</span>
                  </button>
                </div>
              </div>

              <div class="empty-column" *ngIf="getContatosPorStatus(coluna.id).length === 0 && coluna.expanded">
                <i class="fe-inbox"></i>
                <p class="mb-0">Nenhum contato</p>
              </div>

              <div class="loading-more" *ngIf="carregandoPorStatus[coluna.id]">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Carregando...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading-overlay" *ngIf="carregando">
  <div class="spinner-border" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>
