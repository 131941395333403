import { Injectable } from '@angular/core';
import {ServerService} from "./ServerService";
import {HttpClient} from "@angular/common/http";
import {NumeroWhatsapp} from "../pedidos/objetos/NumeroWhatsapp";
import {Mesa} from "../pedidos/objetos/Mesa";
import {FormaDePagamento} from "../pedidos/objetos/FormaDePagamento";
import { ConstantsService } from '../fidelidade/ConstantsService';

@Injectable({
  providedIn: 'root'
})
export class MesaService extends ServerService {
  empresa: any;

  constructor(http: HttpClient, private constantsService: ConstantsService) {
    super(http);
    this.constantsService.empresa$.subscribe(empresa => {
      this.empresa = empresa;
    });
  }

  salveMesa(mesa: Mesa) {
    return this.salve('/mesas', mesa);
  }

  removaMesa(objetoApagar: Mesa) {
    return this.remova( "/mesas/" + objetoApagar.id, {})
  }

  obtenhaMesas(empresa: any) {
    return this.obtenha('/mesas/status', {});
  }

  obtenhaMesasIntegradas() {
    return this.obtenha('/mesas/me/integradas'  , {});
  }

  async obtenhaComandas(idMesa: number): Promise<any[]> {
      return this.obtenha(`/mesas/${idMesa}/comandas`, {});
  }

  crieNovaComanda(mesaId: string) {
    return this.salve('/mesas/comanda/nova', { mesaId });
  }
}
