<div class="card-box">
  <kendo-tabstrip #tabstrip="kendoTabStrip" class="nav-bordered">
    <kendo-tabstrip-tab  [selected]="true">
      <ng-template kendoTabTitle>
        <i class="fas fa-store mr-2"></i>
        Pagamentos pela Loja
      </ng-template>

      <ng-template kendoTabContent>
        <div class="k-display-inline-flex">
          <div class="flex-row">
            <h5>Ative ou desative as opções de pagamento aceitas pela loja.</h5>

          </div>
          <div class="flex-row">

            <button class="btn-migrar ml-2" *ngIf="usuario.admin && !empresa.integracoesIfood.length" (click)="abraModalMigrarFormasBandeira()">

                 <span class="d-flex align-items-center">
                    <i class="fas fa-exchange-alt mr-2"></i>
                    <span>
                      <strong>Migrar para formas antiga sem bandeiras</strong>
                    </span>
                  </span>

              </button>
          </div>
        </div>


        <kendo-tabstrip class="nav-bordered mt-3">
          <kendo-tabstrip-tab [selected]="true">
            <ng-template kendoTabTitle>
              <i class="fas fa-credit-card mr-2"></i>
              Cartão de Crédito
            </ng-template>
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento [empresa]="empresa" [formasDePagamento]="obtenhaFormasPagamentoMetodo('CREDITO')" [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab>
            <ng-template kendoTabTitle>
              <i class="fas fa-money-check mr-2"></i>
              Cartão de Débito
            </ng-template>
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento  [empresa]="empresa" [formasDePagamento]="obtenhaFormasPagamentoMetodo('DEBITO')"  [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab>
            <ng-template kendoTabTitle>
              <i class="fas fa-money-bill-wave mr-2"></i>
              Dinheiro
            </ng-template>
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento  [empresa]="empresa" [formasDePagamento]="obtenhaFormasPagamentoMetodo('DINHEIRO')"  [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>


          <kendo-tabstrip-tab [title]="'Vales'"  >
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento  [empresa]="empresa" [formasDePagamento]="obtenhaFormasPagamentoMetodo(null,'VALE_')"  [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab>
            <ng-template kendoTabTitle>
              <i class="fas fa-qrcode mr-2"></i>
              Pix
            </ng-template>
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento  [empresa]="empresa" [formasDePagamento]="obtenhaFormasPagamentoMetodo('PIX')"  [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>


          <kendo-tabstrip-tab [title]="'Ifood Loja'"  *ngIf="empresa?.integracoesIfood?.length">
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento  [empresa]="empresa" [ocultarAtivar] = true
                                             [formasDePagamento]="obtenhaFormasPagamentoIfoodPdv()" [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>


          <kendo-tabstrip-tab [title]="'Fidelidade'"   *ngIf="empresa.integracaoFidelidade || empresa.integracaoPedidoFidelidade">
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento [ocultarAtivar]="true"  [empresa]="empresa"
                                            [formasDePagamento]="obtenhaFormasPagamentoTipo('FIDELIDADE')"  [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>

        </kendo-tabstrip>

      </ng-template>
    </kendo-tabstrip-tab>

    <!-- Aba: Pagamento Online -->
    <kendo-tabstrip-tab [title]="nomeTabOnline" >
      <ng-template kendoTabTitle>
        <i class="fas fa-globe mr-2"></i>
        {{nomeTabOnline}}
      </ng-template>
      <ng-template kendoTabContent>
        <h5>Cadastre e ative as formas de pagamentos online loja vai aceitar</h5>

        <kendo-tabstrip class="nav-bordered mt-3" #tabstriponline>
          <kendo-tabstrip-tab [title]="'Meu Cardapio Pay'" [selected]="true">
            <ng-template kendoTabTitle>
              <i class="fas fa-wallet mr-2"></i>
              Meu Cardápio Pay
            </ng-template>
            <ng-template kendoTabContent>
              <div class="mt-1" *ngIf="!loading">
                <app-meucardapio-pay-pagamento [empresa]="empresa" [formasDePagamentoOnline]="formasDePagamentoOnline">

                </app-meucardapio-pay-pagamento>
              </div>

            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab [title]="'Integrações Pagamento Online'"  >

            <ng-template kendoTabTitle>
              <i class="fas fa-plug mr-2"></i>
              Integrações Pagamento Online
            </ng-template>
            <ng-template kendoTabContent>
              <div class="row mb-2"  >
                <div class="col" >
                  <app-pagarmehub-acoes [empresa]="empresa" [nomeTabOnline]="nomeTabOnline">

                  </app-pagarmehub-acoes>
                </div>
              </div>

              <div class="row mb-2"  >
                <div class="col" style="max-width: 210px;">

                  <kendo-button class="btn-nova-forma  mt-1" (click)="novoOnline()">
                      <span class="d-flex align-items-center">
                                <i class="fas fa-plus-circle mr-2"></i>
                                <span>
                                  <strong>Nova Integração Online</strong>
                                </span>
                       </span>
                  </kendo-button>
                </div>
              </div>

              <app-grid-formas-de-pagamento  [empresa]="empresa" [online]="true"
                                             [formasDePagamento]="formasDePagamentoOnlineExterna" [loading]="loading">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab [title]="'Ifood Online'"  *ngIf="empresa?.integracoesIfood?.length" >
            <ng-template kendoTabContent>
              <app-grid-formas-de-pagamento  [empresa]="empresa" [formasDePagamento]="obtenhaFormasPagamentoIfoodOnline()"  [ocultarBotoes]="true">

              </app-grid-formas-de-pagamento>
            </ng-template>
          </kendo-tabstrip-tab>

        </kendo-tabstrip>


      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</div>



<kendo-dialog *ngIf="confirmarMigrar" (close)="confirmarMigrar = false" >
  <kendo-dialog-titlebar>
    Confirmação
  </kendo-dialog-titlebar>
  <p>
    Você tem certeza que deseja migrar para formas de pagamentos sem bandeiras?
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="k-primary" (click)="confirmarMigrarBanderias()" [disabled]="migrandoFormasPagamento">
      <i class="k-i-loading k-icon" *ngIf="migrandoFormasPagamento"></i>
      Confirmar</button>
    <button kendoButton  (click)="confirmarMigrar = false" [disabled]="migrandoFormasPagamento">Cancelar</button>
  </kendo-dialog-actions>
</kendo-dialog>
