<kendo-grid
  [data]="formasDePagamento"
  [loading]="loading" style="min-height: 100px; max-width: 1200px"

  (edit)="editar($event)" >
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Nenhuma forma de pagamento"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column title="ID"    [width]="70">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span class="text-blue"><b>{{dataItem.id || '-'}}</b></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Descrição" [width]="250">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <ng-container *ngIf=" dataItem.bandeira">
        <app-bandeira-logo [bandeira]=" dataItem.bandeira" [descricao]="dataItem.descricao"></app-bandeira-logo>
      </ng-container>

      <span *ngIf="!dataItem.bandeira"><b>{{dataItem.descricao}}</b></span>

      <ng-container *ngIf="informarChavePix(dataItem)">
        <input type="text" class="form-control" autocomplete="off" (change)="mudouChavePix(dataItem)"
                name="chavePix" [(ngModel)]="dataItem.chavePix" #chavePix="ngModel"  [disabled]="dataItem.salvando"
               placeholder="Informe a chave pix" value="" required appAutoFocus [autoFocus]="!dataItem.chavePix">
      </ng-container>


      <ng-container *ngIf="dataItem.formaDePagamentoPdv?.metodo === 'PIX'">
        <br>
        <div class="badge badge-blue" *ngIf="dataItem.formaDePagamentoPdv.tipo !=='MANUAL' ">
          QR Code na máquina </div>
        <div class="badge badge-info" *ngIf="dataItem.formaDePagamentoPdv.tipo === 'MANUAL'">
          Minha chave pix
        </div>

      </ng-container>


    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Gateway"    [width]="100" *ngIf="online">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

         <span [ngClass]="{'pagarme': ehPagarme(dataItem), 'mci text-blue': ehMeucardapio(dataItem)}">
          <b>  {{dataItem.configMeioDePagamento?.meioDePagamento}}</b>
         </span>

    </ng-template>
  </kendo-grid-column>



  <kendo-grid-column title=" {{'Referência (' +empresa?.integracaoDelivery?.sistema + ')'}}"   *ngIf="empresa.integracaoDelivery" [width]="150" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <ng-container *ngIf="dataItem.referenciaExterna">
        {{dataItem.referenciaExterna}}
        <ng-container *ngIf="dataItem.bandeirasCartaoIntegrada.length">
          (
          <span *ngFor="let bandeira of dataItem.bandeirasCartaoIntegrada; let ultimo = last; ">
                                        {{bandeira.nome}} <span *ngIf="!ultimo">, </span>
                                   </span>
          )</ng-container>
      </ng-container>

      <span *ngIf="!dataItem.referenciaExterna" class="text-danger">
                              <i class="fa fa-times "></i> nao configurada
                            </span>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="OpenDelivery"    [width]="130"
                     *ngIf="empresa.integracaoOpendelivery?.ativa || empresa.integracaoOpendeliveryLogistica?.ativa" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <ng-container *ngIf="dataItem.opendeliveryMethod">
        {{dataItem.opendeliveryMethod}}
        <ng-container *ngIf="dataItem.opendeliveryBrand">
          ({{dataItem.opendeliveryBrand}})
        </ng-container>
      </ng-container>
      <span *ngIf="!dataItem.opendeliveryMethod" class="text-danger">
                              <i class="k-icon k-i-error"></i> nao configurada
                            </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Ativado"    [width]="90" *ngIf="!ocultarBotoes && !ocultarAtivar">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-switch name="ativo" [(ngModel)]="dataItem.exibirCardapio"  [hidden]="dataItem.soAdminAtiva && !usuario.admin"
                    [onLabel]="'Sim'" (ngModelChange)="alterouAtivarPagamento(dataItem, $event)"
                    [offLabel]="'Não'"></kendo-switch>

      <span *ngIf="dataItem.soAdminAtiva && !usuario.admin">
        <b>{{dataItem.exibirCardapio ? 'Ativado' : 'Desativado'}}</b>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="80" *ngIf="!ocultarBotoes"  >
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-formaPagamento >
      <button kendoGridEditCommand [primary]="true" (selectedChange)="editar(formaPagamento)" *ngIf="formaPagamento.id">
        <i class="fa fa-edit" kendoTooltip title="editar"></i>
      </button>

    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>
