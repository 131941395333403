import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ImpressaoService} from "../../services/impressao.service";
import {PedidosService} from "../../services/pedidos.service";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {LayoutPedido} from "../../services/impressao/LayoutPedido";
import {ProcessadorReciboTexto} from "../../services/impressao/ProcessadorReciboTexto";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'info-pedido',
  templateUrl: './info-pedido.component.html',
  styleUrls: ['./info-pedido.component.scss']
})
export class InfoPedidoComponent implements OnInit {
  @Input()
  public pedido: any;
  @Input()
  public contato: any;
  public empresa: any = {}
  @Input()
  public permiteAlterarStatus: boolean;
  @Input()
  public permiteEditarPedido = false;
  @Input() modal: any = false;
  @Input() modoGrupoLojas = false;
  imprimindo: boolean;
  configImpressao: any;

  constructor(private router: Router, private impressao: ImpressaoService,
              private constantsService: ConstantsService,
              private pedidosService: PedidosService) { }

  ngOnInit() {
    this.constantsService.empresa$.subscribe((empresa) => {
      if(empresa && !this.modoGrupoLojas){
        this.empresa = empresa;
        this.configImpressao = this.empresa.configImpressao;
      }
    })

  }

  mudarStatus(pedido: any) {
    this.router.navigateByUrl('/admin/pedidos/mudar-status/' + pedido.codigo + '?urlRetorno=' + location.pathname, {
      state: {pedido:  pedido, contato: this.contato, empresa: this.empresa}
    } );
  }

  verPedido(pedido: any){
    this.router.navigateByUrl(  String(`/admin/pedidos/detalhes/${pedido.guid}?urlRetorno=${location.pathname}`),
      { state: pedido });
  }


  imprimaPedidoResumido(){
    this.imprimaPedido('PedidoResumido')
  }

  imprimaPedidoDoLayout(){
    this.imprimaPedido(this.configImpressao ? this.configImpressao.layoutPedido : null  )
  }

  imprimaPedido(layoutPedido: string = 'PedidoCompleto') {
    if(!this.configImpressao  || !this.configImpressao.imprimirTXT)
      window.open('/imprimir/pedido/' + this.pedido.guid + '?l=' + layoutPedido);
    else
      this.imprimaNativamente(null, layoutPedido)
  }

  imprimaNativamente(impressora: any = null, layoutPedido: string  = null ) {
    if(this.configImpressao.multiplasImpressoras){
      this.imprimaAutomaticamente(impressora);
    } else {
      let impressoraPadrao = this.configImpressao.impressoras[0];

      impressoraPadrao.layout =  layoutPedido || this.configImpressao.layoutPedido;

      this.imprimaAutomaticamente(impressoraPadrao);
    }
  }

  imprimaAutomaticamente(impressora: any) {
    this.imprimindo = true;

    if(this.pedido.itens && this.pedido.itens.length > 0 )
      this.imprimaPedidoNaImpressora(this.pedido, impressora);
    else this.pedidosService.obtenhaPorCodigo(this.pedido.guid).then( (pedidoCompleto) => {
      this.imprimaPedidoNaImpressora(pedidoCompleto, impressora);
    });

  }

  private async imprimaPedidoNaImpressora(pedidoCompleto, impressora: any) {
    try {
      await this.impressao.imprimaPedidoNaImpressora(pedidoCompleto, impressora)
      this.imprimindo = false;
    } catch (erro) {
      this.imprimindo = false;
      alert("Houve um erro ao imprimir: " + erro)
    }
  }

  editar(pedido: any, event = null) {
    if(this.permiteEditarPedido)
      this.router.navigateByUrl('/pedidos/' + pedido.codigo);

    if(event){
      event.stopPropagation();
      event.preventDefault();
    }

    return false;
  }


  enviarMensagemLinkPagamento(pedido) {
    pedido.gerandoMsgLink = true;
    window['root'].envieMensagemLinkPagamento(pedido.guid);
    setTimeout( () => {
      pedido.gerandoMsgLink = false;
    }, 30000)
  }
}
