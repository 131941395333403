import { Component, OnInit } from '@angular/core';
import { ClassificacaoContatoService } from '../../services/classificacao-contato.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';

@Component({
  selector: 'app-classificacao-contato',
  templateUrl: './classificacao-contato.component.html',
  styleUrls: ['./classificacao-contato.component.scss']
})
export class ClassificacaoContatoComponent implements OnInit {
  contatos: any[] = [];
  classificacoes = [
    'Novo',
    'NovoImportado',
    'Regular',
    'RegularEmRisco',
    'Recorrente',
    'RecorrenteEmRisco',
    'VIP',
    'VIPEmRisco',
    'EmRisco',
    'Perdido'
  ];
  classificacoesSelecionadas: string[] = ['Regular'];
  carregando = false;
  processando = false;

  // Parâmetros de paginação
  pageSize = 10;
  skip = 0;
  total = 0;

  constructor(private classificacaoService: ClassificacaoContatoService)
  {

  }

  ngOnInit() {
    this.carregarContatos();
  }

  async carregarContatos() {
    this.carregando = true;
    try {
      // TODO: Pegar o idEmpresa do serviço de autenticação
      const idEmpresa = 1;
      const resultado = await this.classificacaoService
        .obterContatosPorClassificacao(idEmpresa, this.classificacoesSelecionadas[0], this.skip, this.pageSize)
        .toPromise();

      this.contatos = resultado.contatos;
      this.total = resultado.total;
    } catch (erro) {
      console.error('Erro ao carregar contatos:', erro);
    } finally {
      this.carregando = false;
    }
  }

  onClassificacaoChange() {
    this.skip = 0; // Volta para primeira página ao mudar classificação
    if (this.classificacoesSelecionadas.length > 0) {
      this.carregarContatos();
    }
  }

  async processarClassificacao() {
    if (!this.classificacoesSelecionadas.length) {
      alert('Selecione pelo menos uma classificação');
      return;
    }

    this.processando = true;
    try {
      // TODO: Pegar o idEmpresa do serviço de autenticação
      const idEmpresa = 1;
      const mes = moment();
      //volta 1 mês
      //mes.subtract(1, 'month');
      const dataInicio = mes.startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const dataFim = mes.endOf('month').format('YYYY-MM-DD HH:mm:ss');

      debugger;

      await this.classificacaoService.atualizarClassificacoes(idEmpresa, dataInicio, dataFim).toPromise();
      alert('Classificação de clientes processada com sucesso!');
      this.carregarContatos();
    } catch (erro) {
      console.error('Erro ao processar classificação:', erro);
      alert('Erro ao processar classificação de clientes');
    } finally {
      this.processando = false;
    }
  }

  onPageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.carregarContatos();
  }

  getClassificacaoBadge(classificacao: string): string {
    switch (classificacao) {
      case 'Novo':
        return 'info';
      case 'NovoImportado':
        return 'secondary';
      case 'Regular':
        return 'success';
      case 'RegularEmRisco':
        return 'warning';
      case 'Recorrente':
        return 'primary';
      case 'RecorrenteEmRisco':
        return 'warning';
      case 'VIP':
        return 'dark';
      case 'VIPEmRisco':
        return 'warning';
      case 'EmRisco':
        return 'warning';
      case 'Perdido':
        return 'danger';
      default:
        return 'light';
    }
  }
}
