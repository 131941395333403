<div class="modal-container">
  <div class="modal-content">
    <form #frm="ngForm" novalidate [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}">
      <div class="form-group">
        <label for="codigo">Código do Cartão</label>
        <input type="text" 
               class="form-control" 
               id="codigo" 
               name="codigo"
               [(ngModel)]="cartao.codigo"
               #codigo="ngModel"
               required
               minlength="1"
               maxlength="20"
               pattern="[A-Za-z0-9]+"
               appAutoFocus>
        
        <div class="invalid-feedback" *ngIf="codigo.errors && (codigo.dirty || codigo.touched || frm.submitted)">
          <span *ngIf="codigo.errors?.required">O código do cartão é obrigatório</span>
          <span *ngIf="codigo.errors?.minlength">O código deve ter no mínimo 1 caractere</span>
          <span *ngIf="codigo.errors?.maxlength">O código deve ter no máximo 20 caracteres</span>
          <span *ngIf="codigo.errors?.pattern">O código deve conter apenas letras e números</span>
        </div>
      </div>

      <!-- Mensagem de Erro Geral -->
      <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-3" role="alert">
        <i class="fas fa-exclamation-circle me-2"></i>
        {{mensagemErro}}
        <button type="button" class="btn btn-sm btn-close-custom" (click)="mensagemErro = ''">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="switch-container">
        <div class="switch-label">
          <span>Ativo</span>
          <span class="switch-description">O cartão estará disponível para uso</span>
        </div>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="cartao.ativo" name="ativo" [checked]="true">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="qr-preview-container" *ngIf="cartao.codigo">
        <h3>Preview do QR Code</h3>
        <p class="preview-description">Este é o QR Code que será exibido no cartão</p>
        <div class="qr-image">
          <img [src]="'/qrcode/' + cartao.codigo" alt="QR Code Cartão">
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <kendo-dialog-actions>
      <button kendoButton (click)="fechar()" class="k-button-solid-base">
        <i class="fas fa-times"></i>
        Cancelar
      </button>
      <button kendoButton (click)="salvar()" class="k-button-solid-success" [primary]="true" [disabled]="loading">
        <i class="fas fa-save"></i>
        <span class="spinner-border spinner-border-sm me-2" *ngIf="loading"></span>
        Salvar Cartão
      </button>
    </kendo-dialog-actions>
  </div>
</div>
