import {Component, Input, OnInit} from '@angular/core';
import {SortDescriptor} from "@progress/kendo-data-query";
import {ProdutoService} from "../services/produto.service";
import {PageChangeEvent} from "@progress/kendo-angular-pager";
import {
  PayloadJsonViewComponent
} from "../pedidos/tela-pedidos-erros-importar/payload-json-view/payload-json-view.component";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../lib/KendoPopupUtils";


@Component({
  selector: 'app-historico-produto',
  templateUrl: './historico-produto.component.html',
  styleUrls: ['./historico-produto.component.scss']
})
export class HistoricoProdutoComponent implements OnInit {
  @Input()
  public produto;
  historicoResultSet: any[];
  public pageSizes = false;
  public previousNext = true;
  tamanhoPagina = 5;
  total: any;
  loading = true;
  page = 0;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  filtro: any = {};
  erroBloqueio: boolean;
  erroRemover: string;
  sort: SortDescriptor[] = [{
    field: 'nome'
  }];
  private filtroAtual: any;
  operacoes: any = [
    {
      id: 'CRIAR_PRODUTO',
      texto: 'Criou o produto'
    },
    {
      id: 'EDITAR_PRODUTO',
      texto: 'Editou o produto'
    },

    {
      id: 'MUDOU_PRECO',
      texto: 'Alterou preço produto'
    },

    {
      id: 'INSERIU_ADICIONAL_PRODUTO',
      texto: 'Inseriu adicional'
    },
    {
      id: 'ALTEROU_ADICIONAL_PRODUTO',
      texto: 'Alterou adicional'
    },
    {
      id: 'REMOVEU_ADICIONAL_PRODUTO',
      texto: 'Removeu adicional'
    },
    {
      id: 'REORDENOU_ADICIONAIS_PRODUTO',
      texto: 'Reordenou adicionais'
    }
  ];

  private timeoutBusca: any;
  inicio = 0;
  constructor(private produtoService: ProdutoService,
              private dialogService: DialogService) { }

  ngOnInit(): void {

    this.produtoService.historicoProduto(this.produto, {i: this.inicio, t: this.tamanhoPagina}).then((resposta: any) => {

      this.historicoResultSet = resposta.registros
      this.total = resposta.quantidade
      this.loading = false;
    })
  }

  sortChange($event: Array<SortDescriptor>) {

  }

  onFilter(event: Event) {
    if(this.timeoutBusca) clearTimeout(this.timeoutBusca);

    this.timeoutBusca = setTimeout(() => {
      this.filtreHistorico(this.filtro)
    }, 500)
  }

  filtreHistorico(filtro) {
    this.inicio = 0

    filtro.i = 0
    filtro.t = this.tamanhoPagina

  }

  carregueHistorico(dados) {
    this.produtoService.historicoProduto(this.produto, dados).then((resposta: any) => {
      this.historicoResultSet = resposta.registros
      this.total = resposta.quantidade
      this.loading = false;
    })
  }

  onPageChange($event: PageChangeEvent) {
    this.inicio = $event.skip;
    if(!this.filtro) this.filtro = {}
    this.filtro.i = this.inicio;
    this.filtro.t = this.tamanhoPagina
    window.scrollTo(0, 0);
    if(this.timeoutBusca) clearTimeout(this.timeoutBusca);
    this.carregueHistorico(this.filtro)
  }

  verJsonHistorico(dataItem: any){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: PayloadJsonViewComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.payload = dataItem.valorNovo;
    windowRef.content.instance.titulo =  dataItem.descricao;

    windowRef.result.subscribe( (result) => {  },
      (a) => { });

    return false;
  }
}
