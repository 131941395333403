import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule, Injectable, APP_INITIALIZER} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClickOutsideModule} from 'ng-click-outside';
import {UsuarioService} from './services/usuario.service';
import {AppComponent} from "./app.component";
import { AppRoutingModule } from './app.routing';
import {LoginComponent} from "./login/login.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthGuardService} from "./guards/auth-guard.service";
import localPT from '@angular/common/locales/pt';
import {registerLocaleData} from "@angular/common";
import { SignupComponent } from './signup/signup.component';
import {InlineSVGModule} from "ng-inline-svg";
// @ts-ignore
import {NgxMaskModule} from "ngx-mask";
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import {CldrIntlService, IntlModule, IntlService} from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/pt/all';
import {InputsModule, MaskedTextBoxModule} from '@progress/kendo-angular-inputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import {CampoTelefoneValidator} from "./directives/validador-telefone.directive";
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import {ComprovanteTrocaComponent} from "./comprovante-troca/comprovante-troca.component";
import { ComprovantePontuacaoComponent } from './comprovante-pontuacao/comprovante-pontuacao.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {HttpRequestInterceptor} from "./HttpRequestInterceptor";
import { ComprovantePedidoComponent } from './pedidos/comprovante-pedido/comprovante-pedido.component';
import {TelPipe} from "./pipes/tel.pipe";
import {ApplicationPipesModule} from "./pipes/application-pipes.module";
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import {ConstantsService} from "./fidelidade/ConstantsService";
import { CadastroNumeroWhatsappComponent } from './numero-whatsapp/cadastro-numero-whatsapp/cadastro-numero-whatsapp.component';
import { NovoNumeroWhatsappComponent } from './numero-whatsapp/novo-numero-whatsapp/novo-numero-whatsapp.component';
import {CompartilhadoModule} from "./compartilhado/compartilhado.module";
import { TelaFecharLojaComponent } from './tela-fechar-loja/tela-fechar-loja.component';
import { AdminRecuperarComponent } from './login/admin-recuperar/admin-recuperar.component';
import { PagerModule } from '@progress/kendo-angular-pager';
import { ComprovanteComandaComponent } from './pedidos/comprovante-comanda/comprovante-comanda.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { ImpressaoRelatorioVendasComponent } from './pedidos/impressao-relatorio-vendas/impressao-relatorio-vendas.component';
import { ListaDeNotificacoesAppComponent } from './marketing/app/lista-notificacoes-app/lista-de-notificacoes-app.component';
import { NovaCampanhaAppComponent } from './marketing/app/nova-campanha-app/nova-campanha-app.component';
import {NotificationModule} from "@progress/kendo-angular-notification";
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LoginRedeComponent } from './admin-rede/login-rede/login-rede.component';
import {PainelRedeModule} from "./admin-rede/painel-rede/painel-rede.module";
import { SincronizarCatalogoRedeComponent } from './admin-rede/sincronizar-catalogo-rede/sincronizar-catalogo-rede.component';
import { TelaEscolherCupomCampanhaComponent } from './marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component';
import { LoginGrupoComponent } from './admin-grupo/login-grupo/login-grupo.component';
import { TelaEscolherDominioEmpresaComponent } from './marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component';
import { LoginInstaComponent } from './instagram-bot/login-insta/login-insta.component';
import { ConfigInstagramComponent } from './instagram-bot/config-instagram/config-instagram.component';
import {appInitializer} from "./fidelidade/app.initializer";
import {FBAccountService} from "./services/fbaccount.service";
import { ImpressaoRecebimentosEcleticaComponent } from './impressao-recebimentos-ecletica/impressao-recebimentos-ecletica.component';
import { FormCatalogoComponent } from './catalogo/form-catalogo/form-catalogo.component';
import { CatalogosComponent } from './catalogo/catalogos/catalogos.component';
import { EmpresasUsandoTemplateComponent } from './admin-rede/empresas-usando-template/empresas-usando-template.component';
import {TemplatesMensagensComponent} from "./admin-rede/templates-mensagens/templates-mensagens.component";
import {AdminSdrModule} from "./admin-sdr/admin-sdr.module";
import { ListViewModule } from '@progress/kendo-angular-listview';
import { SincronizarCatalogosModelosComponent } from './admin-rede/sincronizar-catalogos-modelos/sincronizar-catalogos-modelos.component';
import { DynamicChangelogComponent } from './dynamic-changelog/dynamic-changelog.component';
import {MarkdownModule} from "ngx-markdown";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular-ivy';
import {Router} from "@angular/router";
import { AdminAutenticarComponent } from './login/admin-autenticar/admin-autenticar.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';


registerLocaleData(localPT, 'pt');

@Injectable()
export class MyErrorHandler implements ErrorHandler {
  handleError(error) {
    console.log(error);
  }
}

// @ts-ignore
export const options: Partial | (() => Partial) = null;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ComprovanteTrocaComponent, ComprovantePontuacaoComponent,
        SignupComponent,
        CampoTelefoneValidator,
        ComprovantePedidoComponent,
        CadastroNumeroWhatsappComponent,
        NovoNumeroWhatsappComponent,
        TelaFecharLojaComponent,
        AdminRecuperarComponent,
        ComprovanteComandaComponent,
        ImpressaoRelatorioVendasComponent,
        ImpressaoRecebimentosEcleticaComponent,
        ListaDeNotificacoesAppComponent,
        NovaCampanhaAppComponent,
        LoginRedeComponent,
        SincronizarCatalogoRedeComponent,
        TelaEscolherCupomCampanhaComponent,
        LoginGrupoComponent,
        TelaEscolherDominioEmpresaComponent,
        LoginInstaComponent,
        ConfigInstagramComponent,
        FormCatalogoComponent,
        CatalogosComponent,
        TemplatesMensagensComponent,
        EmpresasUsandoTemplateComponent,
        SincronizarCatalogosModelosComponent,
        DynamicChangelogComponent,
        AdminAutenticarComponent
    ],
    imports: [
        ApplicationPipesModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        InlineSVGModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MarkdownModule.forRoot(),
        NgxMaskModule.forRoot(options),
        ReactiveFormsModule,
        ClickOutsideModule,
        AppRoutingModule,
        DropDownsModule,
        ButtonsModule,
        DropDownButtonModule,
        DateInputsModule,
        IntlModule,
        InputsModule,
        MaskedTextBoxModule,
        DialogsModule,
        GridModule,
        TooltipModule,
        ProgressBarModule,
        NotificationModule,
        ChartsModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production, registrationStrategy: 'registerImmediately' /*environment.production*/
        }), ScrollViewModule, CompartilhadoModule, PagerModule, TreeViewModule, LabelModule, LayoutModule, PainelRedeModule,
        AdminSdrModule, ListViewModule, MarkdownModule, NgbModule, UploadsModule, ChatModule
    ],
    providers: [
        AuthGuardService, TelPipe, ConstantsService,
        UsuarioService,
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          })
        },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [Sentry.TraceService, FBAccountService] },
        CldrIntlService,
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
    ],
    exports: [
        CampoTelefoneValidator
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
