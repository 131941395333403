<div class="cartao-cliente-container">
  <!-- Cabeçalho com Busca -->
  <div class="search-header">
    <div class="search-box">
      <i class="fas fa-search"></i>
      <input 
        type="text" 
        class="form-control" 
        [(ngModel)]="busca"
        (ngModelChange)="onBuscaChange()"
        placeholder="Digite o código do cartão..."
        appAutoFocus
        (keyup.enter)="confirmarSeHouverSelecionado()"
      >
    </div>
  </div>

  <!-- Loading State -->
  <div class="loading-state" *ngIf="carregando">
    <div class="spinner-border text-primary"></div>
    <p>Carregando cartões...</p>
  </div>

  <!-- Lista de Cartões -->
  <div class="cartoes-container" *ngIf="!carregando">
    <!-- Empty State -->
    <div class="empty-state" *ngIf="cartoesFiltrados.length === 0">
      <i class="fas fa-file-invoice"></i>
      <h3>Nenhum cartão encontrado</h3>
      <p>Digite o código do cartão para continuar</p>
    </div>

    <!-- Grid de Cartões -->
    <div class="cartoes-grid" *ngIf="cartoesFiltrados.length > 0">
      <div 
        class="cartao-item" 
        *ngFor="let cartao of cartoesFiltrados"
        [class.selected]="cartao === cartaoSelecionado"
        (click)="selecionarCartao(cartao)"
      >
        <div class="cartao-codigo">
          <i class="fas fa-file-invoice"></i>
          <span>{{cartao.codigo}}</span>
        </div>
        <div class="cartao-status" [class.ativo]="cartao.ativo">
          {{ cartao.ativo ? 'Ativo' : 'Inativo' }}
        </div>
      </div>
    </div>
  </div>
</div>

<kendo-dialog-actions>
  <button class="btn btn-success btn-primary" (click)="confirmar()" [disabled]="!cartaoSelecionado">
    <i class="fas fa-check mr-1"></i>
    Abrir Comanda
  </button>
  <button class="btn btn-light" (click)="fechar()">
    <i class="fas fa-times mr-1"></i>
    Cancelar
  </button>
</kendo-dialog-actions> 