import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CartaoClienteService } from '../../services/cartao-cliente.service';
import { CartaoCliente } from '../cadastro-cartao-cliente/cadastro-cartao-cliente.component';

@Component({
  selector: 'app-novo-cartao-cliente',
  templateUrl: './novo-cartao-cliente.component.html',
  styleUrls: ['./novo-cartao-cliente.component.scss']
})
export class NovoCartaoClienteComponent implements OnInit {
  @ViewChild('frm') form: NgForm;
  
  cartao: CartaoCliente;
  empresa: any;
  windowRef: DialogRef;
  loading = false;
  mensagemErro: string;

  constructor(private cartaoClienteService: CartaoClienteService) {
    this.cartao = new CartaoCliente();
  }

  ngOnInit() {
  }

  salvar() {
    debugger;
    
    if (!this.form.valid) {
      this.mensagemErro = 'Por favor, preencha todos os campos obrigatórios corretamente.';
      return;
    }

    this.loading = true;
    this.mensagemErro = '';
    this.cartao.empresa = this.empresa;

    const promise = this.cartao.id ?
      this.cartaoClienteService.atualizeCartao(this.cartao) :
      this.cartaoClienteService.insiraCartao(this.cartao);

    promise
      .then(() => {
        this.loading = false;
        this.windowRef.close();
      })
      .catch((erro) => {
        this.loading = false;
        console.log(erro);
        this.mensagemErro = erro.error?.message || 'Erro ao salvar o cartão. Tente novamente.';
      });
  }

  fechar() {
    this.windowRef.close();
  }

  criarCartao() {
    if (!this.cartao.codigo || !this.empresa) {
      return;
    }

    // Preparar apenas os dados necessários para envio
    const dadosCartao = {
      codigo: this.cartao.codigo,
      empresa: {
        id: this.empresa.id
      },
      ativo: true
    };

    this.cartaoClienteService.crieCartao(dadosCartao)
      .then((response) => {
        this.windowRef.close();
        // Emitir evento de sucesso se necessário
      })
      .catch((erro) => {
        this.mensagemErro = 'Erro ao criar cartão';
        console.error(erro);
      });
  }
}
