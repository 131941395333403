<div class="input-group mb-2" *ngIf="formasDePagamento.length > 1">
  <input name="multiplasFormasPagamentos" id='multiplasFormasPagamentos' kendoCheckBox class="k-checkbox" type="checkbox"
         [(ngModel)]="multiplasFormasPagamentos" (ngModelChange)="mudouMultiplasFormas()"/>
  <label class="k-checkbox-label" for="multiplasFormasPagamentos">
    Usar mais de uma forma de pagamento</label>
</div>

<div *ngIf="!multiplasFormasPagamentos">
  <div class="card mb-3">
    <div class="card-body" style="        padding: 1rem 15px;">
      <!-- Pagamentos Online -->
      <div class="mb-4" *ngIf="formasDePagamento[0]?.online">
        <h5 class="d-flex align-items-center text-primary mb-3">
          <i class="fas fa-globe mr-2"></i>
          <span>Pagamento Online</span>
          <span class="badge badge-primary ml-2">Processado imediatamente</span>
        </h5>

        <ng-container *ngFor="let formaDePagamento of formasDePagamento">
          <div class="radio radio-blue" *ngIf="formaDePagamento.online">
            <input id="formaPagamento{{formaDePagamento.id}}"
                   name="formaDePagamento"
                   type="radio"
                   [(ngModel)]="formaDePagamentoEscolhida"
                   [value]="formaDePagamento"
                   class="k-radio"
                   kendoRadioButton
                   (ngModelChange)="adicionePagamentoUnico()"
                   [required]="true"/>
            <label for="formaPagamento{{formaDePagamento.id}}">
              <div class="forma-pagamento-item">
                <div class="forma-pagamento-icon">
                  <img src="https://user-images.githubusercontent.com/33992396/99478353-00e4d600-2933-11eb-8228-4bafe8571507.png"
                       style="width: 32px;"/>
                </div>
                <div class="forma-pagamento-content">
                  <strong>PIX</strong>
                  <div class="text-muted small">
                    QR Code gerado automaticamente para pagamento
                    <div class="forma-pagamento-badges">
                      <span *ngIf="formaDePagamento.taxaCobranca" class="badge badge-danger mr-1">
                        Taxa: {{formaDePagamento.taxaCobranca.percentual}}%
                      </span>
                      <span *ngIf="formaDePagamento.desconto" class="badge badge-success">
                        Desconto: {{formaDePagamento.desconto}}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </ng-container>
      </div>

      <!-- Pagamentos na Entrega -->
      <div style="    max-width: 700px;">
        <h5 class="d-flex align-items-center text-success mb-3" [hidden]="pagamentoComanda">
          <i class="fas fa-truck mr-2"></i>
          <span>Pagamento na Entrega</span>
          <span class="badge badge-success ml-2">Pague ao receber</span>
        </h5>

        <h4 class="d-flex align-items-center  text-success  mb-3" *ngIf="pagamentoComanda">
          <i class="fas fa-wallet mr-2"></i>
          <span>Pagamento</span>
        </h4>

        <!-- Opção Dinheiro com Troco -->
        <ng-container *ngFor="let formaDePagamento of formasDePagamento">
          <div class="row" *ngIf="!formaDePagamento.online && formaDePagamento.nome?.toLowerCase().includes('dinheiro')">
            <div class="col-md-6">
              <div class="radio radio-blue">
                <input id="formaPagamento{{formaDePagamento.id}}"
                       name="formaDePagamento"
                       type="radio"
                       [(ngModel)]="formaDePagamentoEscolhida"
                       [value]="formaDePagamento"
                       class="k-radio"
                       kendoRadioButton
                       (ngModelChange)="adicionePagamentoUnico()"
                       [required]="true"/>
                <label for="formaPagamento{{formaDePagamento.id}}">
                  <div class="forma-pagamento-item">
                    <div class="forma-pagamento-icon">
                      <i class="fas fa-money-bill-wave fa-2x"></i>
                    </div>
                    <div class="forma-pagamento-content">
                      <strong>{{formaDePagamento.descricao}}</strong>
                      <div class="text-muted small">
                        <div class="forma-pagamento-badges">
                          <span *ngIf="formaDePagamento.taxaCobranca" class="badge badge-info mr-1">
                            Taxa: {{formaDePagamento.taxaCobranca.percentual}}%
                          </span>
                          <span *ngIf="formaDePagamento.desconto" class="badge badge-success">
                            Desconto: {{formaDePagamento.desconto}}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="row mt-1" *ngIf="pagarEmDinheiro()">
          <!-- Opção de Troco -->
          <div class="col " >
            <div class="form-group mb-0">
              <label class="mr-3">Vai precisar de troco?</label>
              <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label class="btn" [ngClass]="{'btn-primary': temTroco==='sim', 'btn-outline-primary': temTroco!=='sim'}" (click)="mudouOpcaoComTroco()">
                  <input name="temTroco" type="radio" kendoRadioButton
                         [(ngModel)]="temTroco" value="sim" required/>
                  <i class="fas fa-check mr-1"></i> Sim
                </label>
                <label class="btn" [ngClass]="{'btn-primary': temTroco==='nao', 'btn-outline-primary': temTroco!=='nao'}" (click)="mudouOpcaoParaSemTroco()">
                  <input name="temTroco" type="radio" kendoRadioButton
                         [(ngModel)]="temTroco" value="nao" required/>
                  <i class="fas fa-times mr-1"></i> Não
                </label>
              </div>
            </div>


            <!-- Campo de Troco -->
            <div class="card mt-1" *ngIf="pagarEmDinheiro() && temTroco==='sim'" style="    margin: 0;padding: 0;">
              <div class="card-body">
                <div class="form-group">
                  <label>Levar Troco Para:</label>
                  <div class="input-group">
                    <kendo-numerictextbox name="trocoPara" #trocoParatxt="ngModel"
                                          format="c2" appRemovaWheel class="form-control"
                                          [(ngModel)]="trocoPara" [min]="totalPagar"
                                          (ngModelChange)="calculeTroco()">
                    </kendo-numerictextbox>
                    <div class="input-group-append">
            <span class="input-group-text">
              <i class="fas fa-calculator"></i>
            </span>
                    </div>
                  </div>
                  <div class="invalid-feedback" *ngIf="trocoParatxt.errors?.minError">
                    A nota tem que ser maior que {{totalPagar | currency: "R$"}}
                  </div>
                </div>

                <div class="alert alert-info mt-3" *ngIf="troco > 0">
                  <i class="fas fa-info-circle mr-2"></i>
                  Troco a ser devolvido: <strong>{{troco | currency: 'BRL'}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Outras Formas de Pagamento -->
        <div class="row mt-3">
          <ng-container *ngFor="let formaDePagamento of formasDePagamento">
            <div class="col-md-6" *ngIf="!formaDePagamento.online && !formaDePagamento.nome?.toLowerCase().includes('dinheiro')">
              <div class="radio radio-blue mb-3">
                <input id="formaPagamento{{formaDePagamento.id}}"
                       name="formaDePagamento"
                       type="radio"
                       [(ngModel)]="formaDePagamentoEscolhida"
                       [value]="formaDePagamento"
                       class="k-radio"
                       kendoRadioButton
                       (ngModelChange)="adicionePagamentoUnico()"
                       [required]="true"/>
                <label for="formaPagamento{{formaDePagamento.id}}">
                  <div class="forma-pagamento-item">
                    <div class="forma-pagamento-icon" *ngIf="formaDePagamento.nome?.toLowerCase().includes('dinheiro')">
                      <i class="fas fa-money-bill-wave fa-lg" ></i>

                    </div>
                    <div class="forma-pagamento-content">
                      <strong>{{formaDePagamento.descricao}}</strong>
                      <div class="text-muted small">
                        <div class="forma-pagamento-badges">
                          <span *ngIf="formaDePagamento.taxaCobranca" class="badge badge-info mr-1">
                            Taxa: {{formaDePagamento.taxaCobranca.percentual}}%
                          </span>
                          <span *ngIf="formaDePagamento.desconto" class="badge badge-success">
                            Desconto: {{formaDePagamento.desconto}}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>


</div>

<div class="" *ngIf="multiplasFormasPagamentos">
  <div class="card-header">
    <b>Escolha a opção de pagamento que o cliente irá utilizar</b>
  </div>
  <div class="form-group mb-2 escolher">
    <kendo-grid
      #grdFormasDePagamento
      [data]="formasDePagamentoNaEntrega()"
      [selectable]="selectableSettings"
      [navigable]="true"
      [hideHeader]="true"
      [kendoGridSelectBy]="'id'"
      [(selectedKeys)]="mySelection"
      [height]="150"
      (selectionChange)="selecionouForma($event)"
    >
      <kendo-grid-column class="forma_de_pagamento"
                         title="Descricao">

        <ng-template kendoGridCellTemplate let-formaDePagamento let-rowIndex="rowIndex">
          {{formaDePagamento.descricao}}
          <span *ngIf="formaDePagamento.taxaCobranca">
             (<i>+{{formaDePagamento.taxaCobranca.percentual}}% taxa </i>)
           </span>

        </ng-template>

      </kendo-grid-column>
    </kendo-grid>

    <div class="mt-3">
      <div class="" *ngIf="formaDePagamentoEscolhida.id">
        Valor Pago: <b>{{formaDePagamentoEscolhida.descricao}}</b>
      </div>
      <div class="mt-2">
        <kendo-numerictextbox name="valorFormaDePagamento"   format="c2" appRemovaWheel style="width: 12em" class="form-control"
                              [(ngModel)]="formaDePagamentoEscolhida.valor"  (valueChange)="calculeTroco()"
                              [min]="0">
        </kendo-numerictextbox>
        <button class="btn btn-primary ml-2" type="button"
                (click)="adicioneFormaDePagamento()">Adicionar</button>

        <label class="ml-2">Faltam: </label>
        <span class="ml-1 mt-0 mb-1 font-24 valor" *ngIf="obtenhaValorRestante() > 0">
                        {{obtenhaValorRestante() | currency: 'BRL' }}</span>
        <span class="ml-1 mt-0 mb-1 font-24 valor" *ngIf="obtenhaValorRestante() === 0"><i class="fas fa-check-circle"></i></span>

        <label class="ml-2">Troco: </label>
        <span class="ml-1 mt-0 mb-1 font-24 valor">{{troco | currency: 'BRL' }}</span>
      </div>
      <div>
        <h6 class="alert alert-danger d-block" style="padding: 5px;" *ngIf="msgErroAdicionar">
          <i class="fas fa-exclamation-triangle fal-lg ml-1 mr-1 "></i>
          {{msgErroAdicionar}}
        </h6>
      </div>
    </div>
    <div class="invalid-feedback" *ngIf="!pagamentos.length">
      <p>Escolha a forma de pagamento do seu pedido</p>
    </div>
  </div>

  <h4>Pagamentos Escolhidos</h4>
  <kendo-grid
    [data]="pagamentos"
    [navigable]="true"
  >
    <kendo-grid-column  title="Descricao"  >
      <ng-template kendoGridCellTemplate let-pagamento >
        <span [ngClass]="{'riscado text-muted': pagamento.removido}">{{pagamento.formaDePagamento.descricao}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="forma_de_pagamento"
                       field="valor"
                       title="Valor">
      <ng-template kendoGridCellTemplate let-pagamento >
        <span [ngClass]="{'riscado text-muted': pagamento.removido}">

          {{pagamento.obtenhaValorSemTaxa() | currency: "BRL"}}</span>

        <span *ngIf="pagamento.taxa"> <i> +{{pagamento.taxa | currency: "BRL"}}  taxa </i></span>
        <span *ngIf="pagamento.desconto"> <i> -{{pagamento.desconto | currency: "BRL"}}  desconto </i></span>


        <span *ngIf="pagamento.trocoPara && pagamento.valor != pagamento.trocoPara" class="ml-1" [hidden]="!pagamento.valor">
          troco para {{pagamento.trocoPara | currency: "BRL"}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column    [width]="140">
      <ng-template kendoGridCellTemplate let-pagamento let-rowIndex="rowIndex">
        <button type="button" class="btn btn-sm btn-secondary"
                [hidden]="pagamento.removido || (pagamento.id && pagamento.formaDePagamento.nome === 'cashback')"
                (click)="editarPagamento(pagamento)">Editar</button>

        <button type="button" class="btn btn-sm btn-danger ml-1"  [hidden]="pagamento.removido"
                (click)="removaPagamento(pagamento)"><i class="fas fa-trash"></i></button>

        <button   type="button" class="btn btn-sm btn-warning ml-1"
                  (click)="desremoverPagamento(pagamento)" *ngIf="pagamento.removido">
          <i class="fas fa-undo"></i>
        </button>
      </ng-template>

    </kendo-grid-column>
  </kendo-grid>
</div>
