export class CupomPedido{
  public erro: string
  constructor(public id: number = null, public desconto: number = null,
              public  codigo: string = null, public aplicarNaTaxaDeEntrega: boolean = false,
              public naoPontuarFidelidade: boolean = false, public aplicarAuto: boolean = false,
              public cashback: boolean = false, public brindeResgate: boolean = false) {


    if(brindeResgate) this.aplicarAuto = true;
  }

}
