<h4 _ngcontent-mki-c5="" class="page-title"><i class="fas fa-inbox"></i> Mensagens</h4>

<div class="card">
  <div class="card-body">
    <div>
      <h4 class="card-title">
        Filtro de Mensagens

        <button class="text-right btn btn-sm btn-micro btn-secondary ml-1" (click)="monteFiltro()" [disabled]="loading">
          <i class="fas fa-redo-alt"></i>
        </button>
      </h4>
    </div>
  <form class="needs-validation" novalidate  #frm="ngForm" [ngClass]="{'was-validated':frm.submitted }" >
      <div class="row">
        <div class="col">
          <div class="form-group mb-0">
            <label for="statusMensagem">Status</label>
            <kendo-dropdownlist
              name="statusMensagem"
              id="statusMensagem"
              class="form-control"
              [(ngModel)]="statusFiltro"
              valueField="id"
              [defaultItem]="statusNaoSelecionado"
              textField="nome"
              (ngModelChange)="monteFiltro()"
              [data]="statusPossiveisMensagens">
            </kendo-dropdownlist>
            <div class="invalid-feedback">

            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group mb-0">
            <label for="periodoMensagem">Período</label>
            <kendo-dropdownlist
              name="periodoMensagem"
              id="periodoMensagem"
              class="form-control"
              [(ngModel)]="periodoFiltro"
              valueField="id"
              textField="nome"
              (ngModelChange)="monteFiltro()"
              [data]="opcoesPeriodo">
            </kendo-dropdownlist>
            <div class="invalid-feedback">

            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group mb-0">
            <label for="nome">Nome do Contato</label>
            <input type="text" class="form-control" autocomplete="off"
            kendoTextBox id="nome" name="nome" [(ngModel)]="filtroNome"
                   (keyup)='keyUp.next($event)'
                   placeholder="Filtre Por Nome"
                   value="">
            <div class="invalid-feedback">

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div>
  <div class="row resumo">
    <div class="col-2" *ngIf="total != null">
      <div class="card-box com-borda">
        <div>
          <i class="far fa-comment-alt"></i>
          <span>&nbsp;{{total | number}}</span>
        </div>
        <label>Total de Mensagens</label>
      </div>
    </div>

    <div class="col-2" *ngFor="let itemResumo of resumo">
      <div class="card-box com-borda">
        <div>
          <i class="far fa-check-circle" *ngIf="itemResumo.status === 'ENVIADA'" style="color: green" kendoTooltip [title]="itemResumo.status"></i>
          <i class="fas fa-clock" *ngIf="itemResumo.status === 'ENVIANDO'" style="color: #333" kendoTooltip [title]="itemResumo.status"></i>
          <i class="fas fa-paper-plane" *ngIf="itemResumo.status === 'NOVA'" style="" kendoTooltip [title]="itemResumo.status"></i>
          <i class="fas fa-exclamation-circle" *ngIf="itemResumo.status !== 'ENVIADA' && itemResumo.status !== 'ENVIANDO' && itemResumo.status !== 'NOVA'" style="color: red" kendoTooltip [title]="itemResumo.status"></i>
          <span>&nbsp;{{itemResumo.qtde}}</span>
        </div>
        <label>{{itemResumo.status}}</label>
      </div>
    </div>
  </div>
</div>

<kendo-grid #grid [data]="gridDataResult" [pageSize]="this.tamanhoPagina"
            [skip]="page"
            [style]=""
            [loading]="loading" style="min-height: 300px;"
            [scrollable]="'none'"
            footerStyle="font-size: 11px;"
            [pageable]="{
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext
            }"
            (pageChange)="carregue($event)">
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Sem contatos"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column field="status" title="Status" class="coluna_menor text-center" [width]="70">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <i class="far fa-check-circle" *ngIf="dataItem.status === 'ENVIADA'" style="font-size: 18px;color: green" kendoTooltip [title]="dataItem.status"></i>
      <i class="fas fa-clock" *ngIf="dataItem.status === 'ENVIANDO'" style="font-size: 18px;color: #333" kendoTooltip [title]="dataItem.status"></i>
      <i class="fas fa-paper-plane" *ngIf="dataItem.status === 'NOVA'" style="font-size: 18px;" kendoTooltip [title]="dataItem.status"></i>
      <i class="fas fa-exclamation-circle" *ngIf="dataItem.status !== 'ENVIADA' && dataItem.status !== 'ENVIANDO' && dataItem.status !== 'NOVA'" style="font-size: 18px;color: red" kendoTooltip [title]="dataItem.status"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="id" title="ID" class="coluna_menor text-center" [width]="85">
  </kendo-grid-column>
  <kendo-grid-column field="contato.nome" title="Nome" class="coluna_menor"  [width]="220">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a routerLink="/admin/contatos/{{dataItem.contato.codigoPais + dataItem.contato.telefone}}" *ngIf="dataItem.contato">
        <span>{{dataItem.contato.nome}}</span>
        <p class="mt-1 mb-0 text-muted">
          <span>{{dataItem.telefone | tel }}</span>
        </p>
      </a>
      <p class="mt-1 mb-0 text-muted" *ngIf="!dataItem.contato">
        <span>{{dataItem.telefone | tel }}</span>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Whatsapp Operador" media="lg" class="coluna_menor" [width]="180">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>{{dataItem.numeroWhatsapp?.whatsapp | mask: '(99) 9-9999-9999' }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="tipoDeNotificacao" title="Tipo" media="lg" class="coluna_menor" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column field="horario" title="Horário" media="lg" class="coluna_menor" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.horario | date: 'dd/MM/yyyy HH:mm:ss'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="mensagem" title="Mensagem" media="sm" class="coluna_menor">
  </kendo-grid-column>
  <ng-template kendoGridDetailTemplate let-dataItem>
    <section>
      <h4>Dados da Mensagem: </h4>
      <p><strong>Telefone:</strong> {{dataItem.telefone | mask: '(99) 9-9999-9999' }}</p>
      <p><strong>Mensagem:</strong> {{dataItem.mensagem}}</p>
      <p><strong>Mensagem:</strong> {{dataItem.horario | date: 'dd/MM/yyyy HH:mm:ss'}}</p>
      <p><strong>Status:</strong> {{dataItem.status}}</p>

      <button class="btn btn-primary" [disabled]="dataItem.enviando" *ngIf="dataItem.status === 'NAO_ENVIADA'" (click)="envieNovamente(dataItem)">Tentar novamente</button>
    </section>
  </ng-template>
</kendo-grid>
