<!-- Cabeçalho e navegação -->
<div class="page-header d-flex justify-content-between align-items-center mb-4">
  <div>
    <h4 class="page-title mb-1">
      <i class="fas fa-paint-roller mr-2"></i>Criar Tema Personalizado
    </h4>
    <ol class="breadcrumb m-0 p-0 bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/admin/temas">Temas</a></li>
    <li class="breadcrumb-item active">Criar Tema Personalizado</li>
  </ol>
</div>
  <button class="btn btn-outline-primary btn-rounded" (click)="voltar()">
    <i class="fas fa-chevron-left mr-1"></i> Voltar
  </button>
</div>

<form [formGroup]="temaForm" id="temaForm" (ngSubmit)="salvarTema()">
  <div class="row">
    <!-- Coluna de configurações -->
    <div class="col-12 col-md-12 col-lg-7 mb-4">
      <!-- Card de Geração de Paleta -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-palette mr-2"></i>Geração de Paleta de Cores</h5>
        </div>
        <div class="card-body">
          <h5 class="mb-3"><i class="fas fa-palette mr-2 text-primary"></i>Escolha como gerar sua paleta de cores</h5>
          <p class="text-muted mb-4">Selecione uma das opções abaixo para criar automaticamente uma paleta de cores harmoniosa para seu tema.</p>

          <div class="paleta-options-grid">
            <!-- Opção de Paleta Automática -->
            <div class="paleta-option-card" [class.active]="mostrarGeracaoPaleta" (click)="toggleGeracaoPaleta()">
              <div class="option-icon">
                <i class="fas" [ngClass]="mostrarGeracaoPaleta ? 'fa-times' : 'fa-magic'"></i>
              </div>
              <div class="option-content">
                <h6>{{ mostrarGeracaoPaleta ? 'Ocultar Paleta' : 'Paleta Automática' }}</h6>
                <p class="option-description">Gere uma paleta de cores usando IA</p>
              </div>
              <div class="option-indicator" *ngIf="mostrarGeracaoPaleta">
                <i class="fas fa-arrow-down"></i>
              </div>
            </div>

            <!-- Opção de Paleta de Imagem -->
            <div class="paleta-option-card" [class.active]="mostrarUploadImagem" (click)="toggleUploadImagem()">
              <div class="option-icon">
                <i class="fas" [ngClass]="mostrarUploadImagem ? 'fa-times' : 'fa-image'"></i>
              </div>
              <div class="option-content">
                <h6>{{ mostrarUploadImagem ? 'Ocultar Upload' : 'Paleta de Imagem' }}</h6>
                <p class="option-description">Extraia cores de uma imagem</p>
              </div>
              <div class="option-indicator" *ngIf="mostrarUploadImagem">
                <i class="fas fa-arrow-down"></i>
              </div>
            </div>

            <!-- Opção de Paleta do Logo -->
            <div class="paleta-option-card" [class.active]="mostrarPaletaLogo" (click)="togglePaletaLogo()" *ngIf="logoEmpresa">
              <div class="option-icon">
                <i class="fas" [ngClass]="mostrarPaletaLogo ? 'fa-times' : 'fa-building'"></i>
              </div>
              <div class="option-content">
                <h6>{{ mostrarPaletaLogo ? 'Ocultar Paleta' : 'Paleta do Logo' }}</h6>
                <p class="option-description">Use as cores do seu logo</p>
              </div>
              <div class="option-indicator" *ngIf="mostrarPaletaLogo">
                <i class="fas fa-arrow-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Geração de Paleta -->
      <div id="cardGeracaoPaleta" class="card mb-4 shadow-sm paleta-card" [@fadeInOut] *ngIf="mostrarGeracaoPaleta">
        <div class="card-header bg-light">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0"><i class="fas fa-magic mr-2"></i>Geração Automática de Paleta</h5>
            <button type="button" class="btn-close-card btn-primary" (click)="toggleGeracaoPaleta()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <p class="text-muted mb-3">Gere automaticamente uma paleta de cores harmoniosa usando Inteligência Artificial.</p>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="descricaoPaleta" class="d-flex align-items-center">
                <i class="fas fa-brain mr-2 ai-icon"></i>
                Instruções para a IA
              </label>
              <div class="input-ai-wrapper">
                <textarea class="form-control" id="descricaoPaleta" [(ngModel)]="descricaoPaleta" [ngModelOptions]="{standalone: true}"
                  placeholder="Ex: Quero cores vibrantes para uma hamburgueria jovem com estilo retrô" rows="3"></textarea>
                <div class="ai-indicator">
                  <i class="fas fa-robot"></i>
                </div>
              </div>
              <small class="form-text text-muted mt-2">
                <i class="fas fa-lightbulb mr-1"></i>
                Descreva o estilo, sentimento ou tema que você deseja para sua paleta de cores
              </small>
            </div>
          </div>

          <div class="mt-4">
            <button type="button" class="btn btn-primary btn-lg btn-block btn-action-paleta" (click)="gerarPaletaDeCores()" [disabled]="gerandoPaleta">
              <i class="fas" [ngClass]="gerandoPaleta ? 'fa-spinner fa-spin' : 'fa-palette'"></i>
              {{ gerandoPaleta ? 'Gerando paleta...' : 'Gerar Paleta de Cores' }}
            </button>
            <small class="d-block form-text text-muted mt-2 text-center">A IA irá gerar uma paleta harmoniosa baseada na descrição fornecida</small>
          </div>
        </div>
      </div>

      <!-- Card de Upload de Imagem -->
      <div id="cardUploadImagem" class="card mb-4 shadow-sm paleta-card" [@fadeInOut] *ngIf="mostrarUploadImagem">
        <div class="card-header bg-light">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0"><i class="fas fa-image mr-2"></i>Gerar Paleta a partir de Imagem</h5>
            <button type="button" class="btn-close-card btn-primary" (click)="toggleUploadImagem()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <p class="text-muted mb-3">Extraia uma paleta de cores harmoniosa a partir de uma imagem de referência usando IA.</p>

          <div class="row">
            <div class="col-12 mb-3">
              <label class="d-flex align-items-center mb-2">
                <i class="fas fa-upload mr-2"></i>
                Selecione uma imagem
              </label>

              <!-- Área de upload de imagem -->
              <div class="image-upload-container">
                <div class="image-upload-area" [class.has-image]="previewImagemUrl" (click)="fileInput.click()">
                  <input #fileInput type="file" accept="image/*" class="d-none" (change)="onImagemSelecionada($event)">

                  <ng-container *ngIf="!previewImagemUrl">
                    <div class="upload-placeholder">
                      <i class="fas fa-cloud-upload-alt fa-3x mb-3"></i>
                      <p>Clique para selecionar uma imagem ou arraste e solte aqui</p>
                      <small class="text-muted">JPG, PNG ou GIF (máx. 5MB)</small>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="previewImagemUrl">
                    <div class="image-preview-container">
                      <img [src]="previewImagemUrl" alt="Preview da imagem" class="img-preview">
                      <button type="button" class="btn btn-sm btn-danger remove-image" (click)="$event.stopPropagation(); limparImagem()">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>

              <!-- Descrição adicional para a IA -->
              <div class="mt-3">
                <label for="descricaoPaletaImagem" class="d-flex align-items-center">
                  <i class="fas fa-brain mr-2 ai-icon"></i>
                  Instruções adicionais para a IA (opcional)
                </label>
                <div class="input-ai-wrapper">
                  <textarea class="form-control" id="descricaoPaletaImagem" [(ngModel)]="descricaoPaleta" [ngModelOptions]="{standalone: true}"
                    placeholder="Ex: Prefiro cores mais suaves da imagem, estilo minimalista" rows="2"></textarea>
                  <div class="ai-indicator">
                    <i class="fas fa-robot"></i>
                  </div>
                </div>
                <small class="form-text text-muted mt-2">
                  <i class="fas fa-lightbulb mr-1"></i>
                  Você pode orientar a IA sobre quais aspectos da imagem priorizar
                </small>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button type="button" class="btn btn-primary btn-lg btn-block btn-action-paleta" (click)="gerarPaletaDeImagem()"
                    [disabled]="uploadandoImagem || !imagemSelecionada">
              <i class="fas" [ngClass]="uploadandoImagem ? 'fa-spinner fa-spin' : 'fa-palette'"></i>
              {{ uploadandoImagem ? 'Processando imagem...' : 'Extrair Paleta da Imagem' }}
            </button>
            <small class="d-block form-text text-muted mt-2 text-center">
              A IA analisará a imagem e extrairá uma paleta de cores harmoniosa
            </small>
          </div>
        </div>
      </div>

      <!-- Indicador de rolagem para o card do logo -->
      <div class="scroll-indicator" *ngIf="mostrarPaletaLogo && logoEmpresa">
        <div class="scroll-indicator-content">
          <i class="fas fa-arrow-down"></i>
          <span>Role para ver o botão</span>
        </div>
      </div>

      <!-- Card de Paleta a partir do Logo -->
      <div id="cardPaletaLogo" class="card mb-4 shadow-sm paleta-card" [@fadeInOut] *ngIf="mostrarPaletaLogo && logoEmpresa">
        <div class="card-header bg-light">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0"><i class="fas fa-building mr-2"></i>Gerar Paleta a partir do Logo</h5>
            <button type="button" class="btn-close-card btn-primary" (click)="togglePaletaLogo()">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <p class="text-muted mb-3">Extraia uma paleta de cores harmoniosa a partir do logo da sua empresa usando IA.</p>

          <div class="row">
            <div class="col-12 mb-3">
              <!-- Preview do logo -->
              <div class="logo-preview-container text-center mb-4">
                <div class="logo-preview-wrapper">
                  <img [src]="logoUrl" alt="Logo da empresa" class="logo-preview">
                </div>
                <p class="mt-2 text-muted">Logo atual da empresa</p>
              </div>

              <!-- Descrição adicional para a IA -->
              <div class="mt-3">
                <label for="descricaoPaletaLogo" class="d-flex align-items-center">
                  <i class="fas fa-brain mr-2 ai-icon"></i>
                  Instruções adicionais para a IA (opcional)
                </label>
                <div class="input-ai-wrapper">
                  <textarea class="form-control" id="descricaoPaletaLogo" [(ngModel)]="descricaoPaleta" [ngModelOptions]="{standalone: true}"
                    placeholder="Ex: Quero um tema que combine com as cores do meu logo, mas com tons mais suaves" rows="2"></textarea>
                  <div class="ai-indicator">
                    <i class="fas fa-robot"></i>
                  </div>
                </div>
                <small class="form-text text-muted mt-2">
                  <i class="fas fa-lightbulb mr-1"></i>
                  Você pode orientar a IA sobre como deseja que as cores do logo sejam utilizadas
                </small>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <!-- Indicador visual para chamar atenção para o botão -->
            <div class="btn-attention-indicator">
              <i class="fas fa-arrow-down"></i>
              <span>Clique aqui</span>
            </div>

            <button type="button" class="btn btn-primary btn-lg btn-block btn-action-paleta btn-logo-paleta" (click)="gerarPaletaDoLogo()"
                    [disabled]="gerandoPaleta">
              <i class="fas" [ngClass]="gerandoPaleta ? 'fa-spinner fa-spin' : 'fa-palette'"></i>
              {{ gerandoPaleta ? 'Processando logo...' : 'Extrair Paleta do Logo' }}
            </button>
            <small class="d-block form-text text-muted mt-2 text-center">
              A IA analisará o logo da sua empresa e extrairá uma paleta de cores harmoniosa
            </small>
          </div>
        </div>
      </div>

      <!-- Card de informações básicas -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-info-circle mr-2"></i>Informações Básicas</h5>
        </div>
        <div class="card-body">
          <!-- Alerta de erro de validação -->
          <div class="alert alert-danger" *ngIf="formSubmitted && temaForm.get('nome').invalid">
            <i class="fas fa-exclamation-triangle mr-2"></i>
            Por favor, preencha o nome do tema antes de salvar.
          </div>

          <!-- Nome do Tema -->
          <div class="form-group mb-4">
            <label for="nome">Nome do Tema <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="nome" formControlName="nome" required
                  placeholder="Digite um nome para seu tema personalizado"
                  [class.is-invalid]="temaForm.get('nome').invalid && (temaForm.get('nome').dirty || temaForm.get('nome').touched || formSubmitted)">
            <div class="invalid-feedback" *ngIf="temaForm.get('nome').invalid && (temaForm.get('nome').dirty || temaForm.get('nome').touched || formSubmitted)">
              O nome do tema é obrigatório
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Geral -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-palette mr-2"></i>Geral</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corFundoDoSite">Cor de Fundo do Site</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" name="corFundoDoSite" formControlName="corFundoDoSite"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor principal do fundo do site</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corFundoElementos">Cor de Fundo de Elementos</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" name="corFundoElementos" formControlName="corFundoElementos"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para cards e elementos</small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corBorda">Cor de Bordas</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corBorda"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para bordas e separadores</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Topo e Menu -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-window-maximize mr-2"></i>Topo e Menu</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corFundo">Cor de Fundo do Topo</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corFundo"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do cabeçalho e menu</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoTopo">Cor do Texto do Topo</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoTopo"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto no topo e menu</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Conteúdo -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-align-left mr-2"></i>Conteúdo</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corTextoPrimaria">Cor do Texto Principal</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoPrimaria"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor dos títulos e textos principais</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoSecundaria">Cor do Texto Secundário</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoSecundaria"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para descrições e textos secundários</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Produtos e Preços -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-shopping-cart mr-2"></i>Produtos e Preços</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corDestaque">Cor de Destaque</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corDestaque"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para elementos de destaque</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corPreco">Cor dos Preços</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corPreco"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para preços dos produtos</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corPrecoAdicional">Cor das Tags de Preço</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corPrecoAdicional"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para preços de adicionais</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoPrecoAdicional">Cor do Texto das Tags de Preço</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoPrecoAdicional"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto nas tags de preço</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Elementos Interativos -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-mouse-pointer mr-2"></i>Elementos Interativos</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corBotao">Cor dos Botões</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corBotao"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor de fundo dos botões</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoBotao">Cor do Texto dos Botões</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoBotao"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto dentro dos botões</small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corHover">Cor de Hover</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corHover"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor ao passar o mouse sobre elementos</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Rodapé -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-shoe-prints mr-2"></i>Rodapé</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corFundoRodape">Cor de Fundo do Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corFundoRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor de fundo do rodapé</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoRodape">Cor do Texto do Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto do rodapé</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corBordaRodape">Cor da Borda do Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corBordaRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor da borda do rodapé</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corItemAtivoRodape">Cor do Item Ativo no Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corItemAtivoRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor de destaque para itens ativos no rodapé</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Coluna de visualização -->
    <div class="col-12 col-md-12 col-lg-5">
      <div class="card shadow-sm sticky-preview">

        <div class="card-header bg-light">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0"><i class="fas fa-eye mr-2"></i>Visualização em Tempo Real</h5>
          </div>
        </div>

        <!-- Abas de navegação para diferentes visualizações -->
        <div class="preview-tabs">
          <div class="nav nav-tabs" role="tablist">
            <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#preview-home" type="button" role="tab" aria-controls="preview-home" aria-selected="true">
              <i class="fas fa-home"></i> Início
            </button>
          </div>
        </div>

        <div class="tab-content">
          <!-- Visualização da tela inicial -->
          <div class="tab-pane fade show active" id="preview-home" role="tabpanel" aria-labelledby="home-tab">
            <div class="card-body p-2">
              <div class="d-flex justify-content-center">
                <div class="device-wrapper">
                  <div class="device-frame" style="border-radius: 36px; border: 12px solid #222; overflow: hidden; position: relative;">
                    <iframe id="mobile-content-home" [src]="urlSegura" width="375px" height="667px"
                            style="border: none;display: block;" frameborder="0" allowfullscreen></iframe>



                    <!-- Overlay de carregamento -->
                    <div *ngIf="carregandoIframe" class="iframe-loading-overlay">
                      <div class="loading-content">
                        <i class="fas fa-palette fa-spin fa-3x"></i>
                        <p class="mt-3">Gerando paleta de cores...</p>
                      </div>
                    </div>
                  </div>
                  <div class="device-stripe"></div>
                  <div class="device-header"></div>
                  <div class="device-sensors"></div>
                  <div class="device-btns"></div>
                  <div class="device-power"></div>
                </div>
              </div>
            </div>

            <!-- Botão de geração rápida de paleta (abaixo da visualização) -->
            <div class="generate-btn-container text-center mt-3 mb-2" *ngIf="mostrarGeracaoPaleta || mostrarUploadImagem || (mostrarPaletaLogo && logoEmpresa)">
              <button type="button"
                      class="btn btn-primary generate-btn"
                      [disabled]="gerandoPaleta || uploadandoImagem"
                      (click)="gerarPaletaRapida()">
                <i class="fas" [ngClass]="gerandoPaleta ? 'fa-spinner fa-spin' : 'fa-magic'"></i>
                {{ gerandoPaleta ? 'Gerando paleta...' : 'Gerar Paleta de Cores' }}
              </button>
              <small class="d-block text-muted mt-2" *ngIf="!gerandoPaleta">Clique para gerar a paleta com o método selecionado</small>
            </div>
          </div>
        </div>
        <!-- Alternativa: Visualização de elementos específicos -->
        <div class="card-body border-top">
          <h6 class="mb-3"><i class="fas fa-puzzle-piece mr-2"></i>Elementos Específicos</h6>
          <div class="preview-elements">
            <div class="row">
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Botão</div>
                  <div class="preview-element-content">
                    <button class="btn preview-button" [style.background-color]="temaForm.get('corBotao')?.value" [style.color]="temaForm.get('corTextoBotao')?.value">Comprar</button>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Preço</div>
                  <div class="preview-element-content">
                    <span class="preview-price" [style.color]="temaForm.get('corPreco')?.value">R$ 29,90</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Adicional Individual</div>
                  <div class="preview-element-content">
                    <div class="preview-adicional">
                      <span>Queijo extra</span>
                      <span class="badge badge-success" [style.background-color]="temaForm.get('corPrecoAdicional')?.value" [style.color]="temaForm.get('corTextoPrecoAdicional')?.value">+ R$ 5,00</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Destaque</div>
                  <div class="preview-element-content">
                    <span class="preview-destaque" [style.color]="temaForm.get('corDestaque')?.value">Promoção!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Footer Fixo -->
<div class="card-footer">
  <div class="d-flex">
    <button type="submit" form="temaForm" class="btn btn-primary" [disabled]="salvando">
      <i class="fas" [ngClass]="salvando ? 'fa-spinner fa-spin' : 'fa-save'"></i>
      <span class="ml-2">{{ salvando ? 'Salvando...' : 'Salvar Tema' }}</span>
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-times mr-2"></i>Cancelar
    </button>
  </div>
</div>

<!-- Mensagens de feedback -->
<div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 1050">
  <div *ngIf="mensagemSucesso" class="toast show bg-success text-white" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-success text-white">
      <i class="fas fa-check-circle mr-2"></i>
      <strong class="me-auto">Sucesso</strong>
      <button type="button" class="btn-close" (click)="mensagemSucesso = null"></button>
    </div>
    <div class="toast-body">
      {{ mensagemSucesso }}
    </div>
  </div>

  <!-- Mensagem de erro global -->
  <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show position-fixed"
       style="top: 20px; right: 20px; z-index: 9999; max-width: 400px; box-shadow: 0 4px 8px rgba(0,0,0,0.2);"
       role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex align-items-center">
      <i class="fas fa-exclamation-circle mr-2 fa-lg"></i>
      <div>
        <strong>Erro</strong>
        <p class="mb-0">{{ mensagemErro }}</p>
      </div>
    </div>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="mensagemErro = null">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
