import { Router } from '@angular/router';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {EmpresasService} from "../services/empresas.service";
declare var _;
@Component({
  selector: 'app-form-formasdeentrega',
  templateUrl: './form-formasdeentrega.component.html',
  styleUrls: ['./form-formasdeentrega.component.scss']
})
export class FormFormasEntregaComponent implements OnInit {
  @ViewChild('frmFormasDeEntrega')  frmFormasDeEntrega: NgForm;
  @ViewChild('tableScroll') private tableScroll: ElementRef;
  @Output() onSalvou = new EventEmitter();
  @Input() empresa;

  taxaFreteGratis = { valor: 'FRETE_GRATIS', descricao: 'Frete Grátis'};
  taxaValorFixo = { valor: 'VALOR_FIXO', descricao: 'Valor Fixo'};
  taxaValorPorDistancia = { valor: 'VALOR_POR_DISTANCIA', descricao: 'Valor por Distância' }
  taxaValorManual = { valor: 'VALOR_MANUAL', descricao: 'Valor Manual' }
  mensagemSucesso
  salvando
  entregaEmCasa: any = {
    formaDeEntrega: { id: 2 },
    nome: 'Receber em casa',
    valorMinimoPedido: 0,
    valorMinimoFreteGratis: 0,
    maximoKm: 0,
    exibirTelaBusca: true,
    raiosDeCobranca: [],
    zonasDeEntrega: []
  };

  entregaRetirada: any = {
    formaDeEntrega: { id: 1  }  ,
    nome: 'Retirar',
    valorMinimoPedido: 0
  }

  entregaRetiradaComerLocal: any = {
    formaDeEntrega: { id: 1  }  ,
    nome: 'Comer no local',
    comberNoLocal: true,
    valorMinimoPedido: 0
  }


  erro: any;
  tiposDeTaxas: any = [this.taxaFreteGratis, this.taxaValorFixo, this.taxaValorPorDistancia, this.taxaValorManual];
  formasDeEntrega: any = [ ];

  constructor(private empresaService: EmpresasService, private router: Router) { }

  ngOnInit() {
    this.exibaFormasEmpresa();
  }

  exibaFormasEmpresa() {
    this.formasDeEntrega = [ this.entregaRetirada, this.entregaEmCasa  ];

    if(!this.empresa || !this.empresa.formasDeEntrega) return;

    let formaDeEntregaEmpresaRetirada: any =
      this.empresa.formasDeEntrega.find( forma  => forma.formaDeEntrega.id === this.entregaRetirada.formaDeEntrega.id)

    if(formaDeEntregaEmpresaRetirada)
        Object.assign(this.entregaRetirada , formaDeEntregaEmpresaRetirada);

    let formaDeEntregaCasa: any =
      this.empresa.formasDeEntrega.find( forma  => forma.formaDeEntrega.id === this.entregaEmCasa.formaDeEntrega.id)

    if(formaDeEntregaCasa){
      Object.assign(this.entregaEmCasa , formaDeEntregaCasa);

      this.setRadiosOrdenados();

    }

    if(this.entregaRetirada && this.entregaRetirada.permiteAgendamento)
      this.selecionouPermiteAgendamento(this.entregaRetirada)

    if(this.entregaEmCasa && this.entregaEmCasa.permiteAgendamento)
      this.selecionouPermiteAgendamento(this.entregaEmCasa)
  }

  setRadiosOrdenados(){
    this.entregaEmCasa.raiosDeCobranca =  this.entregaEmCasa.raiosDeCobranca.filter( radio => !radio.removido)
    this.entregaEmCasa.raiosDeCobranca = _.sortBy( this.entregaEmCasa.raiosDeCobranca, radio => radio.alcance);

    this.entregaEmCasa.zonasDeEntrega = this.entregaEmCasa.zonasDeEntrega.filter( zona => !zona.removido)
    //this.entregaEmCasa.zonasDeEntrega = _.sortBy( this.entregaEmCasa.zonasDeEntrega, zona => zona.nome);


    this.entregaEmCasa.raiosDeCobranca.forEach( (raioCobranca => {
      raioCobranca.tipoDaTaxa = this.tiposDeTaxas.find( tipo => tipo.valor === raioCobranca.tipo);
    }))
  }

  onSubmitFormasRetiradas() {
    this.mensagemSucesso = '';
    delete this.erro;
    if( !this.frmFormasDeEntrega.valid || this.salvando) {
      this.erro = 'Verifique os erros no preenchimento'
      return;
    }


    this.salvando = true;
    this.empresaService.salveFormasDeEntrega(this.empresa, this.formasDeEntrega).then( (formasServer) => {
      this.salvando = false;
      this.empresa.formasDeEntrega = formasServer;

      let formaDeEntregaCasa: any =
        this.empresa.formasDeEntrega.find( forma  => forma.formaDeEntrega.id === this.entregaEmCasa.formaDeEntrega.id)
      if( formaDeEntregaCasa ) this.entregaEmCasa.zonasDeEntrega = formaDeEntregaCasa.zonasDeEntrega;

      this.setRadiosOrdenados();
      if(this.onSalvou.observers.length){
        this.onSalvou.emit({})
      } else {
        this.mensagemSucesso = "Formas de entrega atualizadas";
      }
    }).catch( (erro) => {
      this.salvando = false;
      this.erro = erro;
    });
  }

  ehManual(raioCobranca) {
    return raioCobranca.tipo === this.taxaValorManual.valor;
  }

  ehFreteGratis(raioCobranca){
    return raioCobranca.tipo === this.taxaFreteGratis.valor;
  }

  alterouTipo(raioCobranca, item) {
    raioCobranca.tipo = item.valor;

    if(this.ehFreteGratis(raioCobranca) || this.ehManual(raioCobranca) ){
      raioCobranca.valorFixo = 0;
      raioCobranca.valorKmTaxa = 0;
    }
  }

  novoRadioDeCobranca() {
    let novo = { permiteFreteGratis: true};
    this.entregaEmCasa.raiosDeCobranca.push(novo)
  }

  obtenhaAlcanceMinimo(i: number) {
    let anterior = i  - 1;

    if(anterior  >= 0 ){
      let raioAnterior = this.entregaEmCasa.raiosDeCobranca[anterior].alcance;

      if(raioAnterior.removido)
        return this.obtenhaAlcanceMinimo(anterior);

      return  raioAnterior.alcance + 1;
    }


    return 0;
  }

  removaRaioCobranca(radioDeCobranca, indice: number){
    if(radioDeCobranca.id){
      radioDeCobranca.removido = true;
    } else {
      this.entregaEmCasa.raiosDeCobranca.splice(indice, 1);
    }
  }

  pauseRaioCobranca(radioDeCobranca: any){
    radioDeCobranca.desativado = true;
  }

  ativeRaioCobranca(radioDeCobranca: any){
    radioDeCobranca.desativado = false;
  }

  novaZonaDeEntrega() {
    let novo = {};
    this.entregaEmCasa.zonasDeEntrega.push(novo)
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.tableScroll.nativeElement.scrollTop = this.tableScroll.nativeElement.scrollHeight;
    } catch(err) { }
  }

  removaZonaDeEntrega(zonaDeEntrega: any, indice: number) {
    if(zonaDeEntrega.id){
      zonaDeEntrega.removido = true;
    } else {
      this.entregaEmCasa.zonasDeEntrega.splice(indice, 1);
    }
  }

  abraTelaArquivoKML() {
    this.router.navigateByUrl('/admin/areas-entrega').then( () => {} ).catch( () => {});
  }

  selecionouPermiteAgendamento(forma: any) {
    if(forma.permiteAgendamento)
      if(!forma.intervaloAgendamento) forma.intervaloAgendamento = 30
  }
}
