<kendo-grid [data]="historicoResultSet" [pageSize]="this.tamanhoPagina"
            [skip]="page"
            [style]=""
            [loading]="loading" style="min-height: 300px;"
            [scrollable]="'none'"
            (sortChange)="sortChange($event)"
            footerStyle="font-size: 11px;"
  >
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Não há nada para exibir no histórico."
    loading="Carregando"
    pagerItemsPerPage="itens por página"
  >
  </kendo-grid-messages>
  <ng-template kendoGridToolbarTemplate [position]="'top'">
    <div style="display: flex; flex-direction: row;gap: 15px;">
      <div style="flex: 6">
        <label for="buscaPorConteudo">Filtro por texto</label> <br>
        <input id="buscaPorConteudo" kendoTextBox placeholder="Busque" class="form-control busca" [(ngModel)]="filtro.texto"
               appAutoFocus kendoTextBox (input)="onFilter($event)"/>
      </div>
      <div style="flex: 4">
        <label for="buscaPorOperacao">Operação</label>
        <kendo-combobox id="buscaPorOperacao" name="buscaPorOperacao" [(ngModel)]="filtro.operacao" [data]="operacoes" [filterable]="true"
                        [allowCustom]="false" placeholder="Todas" class="form-control"
                        (selectionChange)="onFilter($event)"

                        textField="texto"
                        [valueField]="'id'">
          <ng-template kendoComboBoxItemTemplate let-operacao>
            {{operacao.texto}}
          </ng-template>
        </kendo-combobox>
      </div>
    </div>
  </ng-template>
  <kendo-grid-column title="Horário" [width]="100" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.horario | date: 'dd/MM/yyyy HH:mm:ss'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Descrição" [sortable]="true" field="descricao" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        {{dataItem.descricao}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <!--
  <kendo-grid-column title="Operação" class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.operacao.split('_').join(' ')}}
    </ng-template>
  </kendo-grid-column>
  -->
  <kendo-grid-column title="Operador"  class="coluna_menor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span *ngIf="dataItem.usuario">
              {{dataItem.usuario.nome}} ({{dataItem.usuario.email}}) - {{dataItem.ip}}
      </span>

      <span *ngIf="dataItem.clienteApi">
               {{dataItem.clienteApi.nome}} - {{dataItem.ip}}
      </span>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Novo valor">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a href="#" class="text-blue" (click)="verJsonHistorico(dataItem)" *ngIf="dataItem.valorNovo">
        ver detalhes</a>
      <span *ngIf="!dataItem.valorNovo"> - </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
<kendo-datapager
  [style.width.%]="100"
  [pageSize]="tamanhoPagina"
  [skip]="inicio"
  [total]="total"
  (pageChange)="onPageChange($event)" *ngIf="total > 0">
</kendo-datapager>
