<div class="container-fluid">
  <!-- Loading State -->
  <div class="loading-container" *ngIf="loading">
    <div class="k-i-loading"></div>
  </div>

  <div class="dashboard-container" *ngIf="empresa && !loading">
    <!-- Mensagem de Sucesso -->
    <div class="alert alert-success fade show" role="alert" *ngIf="msgSucesso">
      <i class="mdi mdi-check-all mr-2"></i> {{msgSucesso}}
    </div>

    <!-- Header com Ações -->
    <div class="dashboard-header">
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <div class="d-flex align-items-center gap-3">
          <h4 class="page-title mb-0 mr-1">
            <i class="fa fa-table me-2"></i>
            Mesas
          </h4>
          <button class="btn btn-primary d-flex align-items-center" (click)="novo()">
            <i class="fe-plus mr-2"></i>
            Nova Mesa
          </button>
        </div>

        <div class="search-container">
          <kendo-textbox
            name="filtro"
            [(ngModel)]="filtro"
            [showSuccessIcon]="false"
            placeholder="Pesquisar mesas...">
            <ng-template kendoTextBoxSuffixTemplate>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton [look]="'clear'" [icon]="'search'"></button>
            </ng-template>
          </kendo-textbox>
        </div>
      </div>
    </div>

    <!-- Grid de Mesas -->
    <div class="mesas-grid">
      <div class="mesa-item" *ngFor="let mesa of objetos | filtroObjetosNome: filtro">
        <!-- Mensagem quando nenhuma mesa é encontrada -->
        <div class="alert alert-warning" *ngIf="mesa === -1">
          <div class="d-flex align-items-center">
            <i class="fas fa-exclamation-triangle mr-2"></i>
            <span>Nenhuma mesa encontrada para <strong>{{filtro}}</strong></span>
          </div>
        </div>

        <!-- Card da Mesa -->
        <div class="mesa-card" *ngIf="mesa !== -1">
          <div class="mesa-card-header">
            <h4 class="mesa-title">
              <i class="fas fa-utensils mr-2"></i>
              {{mesa.nome}}
            </h4>
            <div class="mesa-actions">
              <button (click)="editar(mesa)" class="btn-action edit" kendoTooltip title="Editar">
                <i class="fa fa-edit"></i>
              </button>
              <button class="btn-action delete" kendoTooltip title="Remover" (click)="abraDialogRemover(mesa)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>

          <div class="mesa-card-body">
            <div class="qr-container">
              <div class="qr-image">
                <img [src]="'/qrcode/mesa/mesa_' + mesa.nome" alt="QR Code Mesa"/>
              </div>
              <div class="qr-info">
                <h5>
                  <i class="fas fa-utensils mr-2"></i>
                  Mesa {{mesa.nome}}
                </h5>
                <div class="link-container">
                  <i class="fas fa-external-link-alt text-success"></i>
                  <div class="link-wrapper">
                    <a [href]="empresa.linkLoja + '/mesa/' + mesa.id" target="_blank" class="mesa-link">
                      {{empresa.linkLoja}}/mesa/{{mesa.id}}
                    </a>
                  </div>
                </div>
                <a [href]="'/qrcode/mesa/mesa_' + mesa.nome" class="btn btn-success btn-sm mt-2 download-qr" download>
                  <i class="fas fa-download mr-1"></i>
                  Download QR Code
                </a>
              </div>
            </div>

            <div class="mesa-details">
              <div class="detail-item">
                <i class="fas fa-receipt"></i>
                <span class="status-badge" [ngClass]="{
                  'status-success': !mesa.naoGerarComanda,
                  'status-danger': mesa.naoGerarComanda
                }">
                  {{mesa.naoGerarComanda ? 'Não gera comanda' : 'Gera comanda'}}
                </span>
              </div>
              <div class="detail-item">
                <i class="fas fa-qrcode"></i>
                <span class="status-badge" [ngClass]="{
                  'status-danger': mesa.somenteLeitura,
                  'status-success': !mesa.somenteLeitura
                }">
                  {{mesa.somenteLeitura ? 'QRCode apenas visualização' : 'QRCode permite pedidos'}}
                </span>
              </div>
              <div class="detail-item" *ngIf="mesa.codigoPdv">
                <i class="fas fa-barcode"></i>
                <span class="status-badge status-info">
                  Cod. Pdv: {{mesa.codigoPdv}}
                </span>
                <span class="pdv-status" *ngIf="mesa.mesaIntegrada?.status">
                  <span class="status-badge"
                        [ngClass]="{
                          'status-free': mesa.mesaIntegrada.status === 'Livre',
                          'status-busy': mesa.mesaIntegrada.status === 'EmUso',
                          'status-closing': mesa.mesaIntegrada.status === 'Fechando'
                        }">
                    Status Pdv: {{mesa.mesaIntegrada.status}}
                  </span>
                </span>
              </div>
              <div class="detail-item" *ngIf="!mesa.codigoPdv">
                <i class="fas fa-barcode text-muted"></i>
                <span class="status-badge status-muted">
                  Sem código PDV cadastrado
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mensagem quando não há mesas -->
      <div class="empty-state" *ngIf="objetos.length === 0">
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle mr-2"></i>
          <span>Você ainda não tem mesas criadas</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Confirmação -->
<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p class="confirmation-message">
    Você tem certeza que deseja apagar a mesa <strong>{{objetoApagar.nome}}</strong>?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" [primary]="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
