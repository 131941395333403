import {Injectable} from "@angular/core";
import {ServerService} from "./ServerService";

@Injectable({
  providedIn: 'root'
})
export class EstoqueService extends ServerService {

  public listeGrupos(inicio = 0, total = 50){
    return this.obtenha('/estoque/grupos', { i: inicio, t: total})
  }

  public listeInsumos(inicio = 0, total = 50, qs: any = { }){
    let params: any = { i: inicio, t: total};

    Object.keys(qs).forEach((key) => {
      params[key] = qs[key]
    })
    return this.obtenha('/estoque/insumos', params)
  }

  public listeAlertasMinimo( ){
    return this.obtenha('/estoque/insumos/alerta/minino', {  })
  }

  public listeUnidades(inicio = 0, total = 50){
    return this.obtenha('/estoque/unidades', { i: inicio, t: total})
  }

  public listeMotivosMotimentacoes( ){
    let params: any = {};


    return this.obtenha('/estoque/movimentacoes/motivos', params)
  }


  public listeMovimentacoes(inicio = 0, total = 25, dataInicio: number, dataFim: number,
                            termo: string = null, grupo: any = null) {
    let params: any = { i: inicio, t: total};

    if(dataInicio) params.di = dataInicio;
    if(dataFim) params.df = dataFim;
    if(termo) params.termo = termo;
    if(grupo) params.gid = grupo.id;

    return this.obtenha(`/estoque/movimentacoes`, params)
  }

  public listeMovimentacoesInsumo(insumo: any, inicio = 0, total = 25) {
    return this.obtenha(`/estoque/insumos/${insumo.id}/movimentacoes`, { i: inicio, t: total})
  }

  public listeMovimentacoesProduto(produto: any, inicio = 0, total = 25) {
    return this.obtenha(`/estoque/produtos/${produto.id}/movimentacoes`, { i: inicio, t: total})
  }

  salveGrupo(grupo: any) {
    return this.salve('/estoque/grupo', grupo)
  }

  salveInsumo(insumo: any) {
    return this.salve('/estoque/insumos', insumo)
  }

  removaInsumo(insumo: any){
    return this.facaPut(`/estoque/insumos/${insumo.id}/remova`, insumo)
  }


  removaGrupo(grupo: any){
    return this.facaPut(`/estoque/grupo/${grupo.id}/remova`, grupo)
  }

  vinculeProdutosNoInsumo(insumo: any, produtos: any[]) {
    return this.facaPost(String(`/estoque/insumos/${insumo.id}/produtos/add`), {insumo: insumo, produtos: produtos});
  }

  async removaVinculoProduto(insumo: any, produtoAssociado: any) {
    return this.facaPost(String(`/estoque/insumos/${insumo.id}/produtos/del`), {insumo: insumo, produto: produtoAssociado});
  }


  vinculeOpcoesNoInsumo(insumo: any, opcoes: number[]) {
    return this.facaPost(String(`/estoque/insumos/${insumo.id}/opcoes/add`), {insumo: insumo, opcoes: opcoes});
  }

  async removaVinculoOpcao(insumo: any, opcao: any) {
    return this.facaPost(String(`/estoque/insumos/${insumo.id}/opcoes/del`), {insumo: insumo, opcao: opcao});
  }

  registreMovimentacaoManual(novaMovimentacao: any) {
    return this.facaPost(String(`/estoque/movimentacoes/manual/insumos/${novaMovimentacao.insumo.id}`),
      novaMovimentacao);
  }


  registreMovimentacoesManual(novaMovimentacao: any) {
    return this.facaPost(String(`/estoque/movimentacoes/manual`),
      novaMovimentacao);
  }


  async listeComposicaoDo(insumo: any) {
      return this.obtenha(`/estoque/insumos/${insumo.id}/composicoes`, {});
  }

  listeHistoricoInsumo(insumo: any, filtro: any = null) {
    let parametros = []
    if(filtro) {


      if(filtro.texto) parametros.push('texto=' + filtro.texto)
      if(filtro.operacao) parametros.push('operacao=' + filtro.operacao.id)
      if(filtro.t) {
        parametros.push('i=' + (filtro.i ? filtro.i : '0'))
        parametros.push('t=' + filtro.t)
      }


    }

    return this.obtenha(`/estoque/insumos/${insumo.id}/historico`, parametros );
  }
}
