import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {EnumStatusContato} from "../../../../server/lib/emun/EnumStatusContato";
import {EmpresasService} from "../../superadmin/services/empresas.service";
import {FiltroTelaUI} from "../../../../projects/loja/src/objeto/FiltroTelaUI";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'filtro-avancado',
  templateUrl: './filtro-avancado.component.html',
  styleUrls: ['./filtro-avancado.component.scss'],
  host: {
    '[class.visible]': 'exibir'
  }
})
export class FiltroAvancadoComponent implements OnInit, OnDestroy {
  @Output() onVoltar = new EventEmitter();
  @Output() onFiltrar = new EventEmitter();
  @Output() onFechar = new EventEmitter();

  @Input() set exibir(value: boolean) {
    this._exibir = value;
    if (value) {
      document.body.classList.add('filtro-aberto');
    } else {
      document.body.classList.remove('filtro-aberto');
    }
  }
  get exibir(): boolean {
    return this._exibir;
  }

  private _exibir: boolean;
  tags = []
  filtro: any = {};
  sexos = [ { value: 'Homem', label: 'Homem'}, { value: 'Mulher', label: 'Mulher' } ];
  planos = [  ];
  valores: any = [];
  unidades = FiltroTelaUI.getUnidades();
  operadores = FiltroTelaUI.getOperadores();
  operadoresMais = FiltroTelaUI.getOperadoresMais();
  tiposClientes: any = [];
  tiposPets: any  = [];
  generosPets: any = ['Macho', "Fêmea"];
  classificacoes: any = [
    { value: 'Novo', label: 'Novo' },
    { value: 'NovoImportado', label: 'Novo Importado' },
    { value: 'Regular', label: 'Regular' },
    { value: 'RegularEmRisco', label: 'Regular em Risco' },
    { value: 'Recorrente', label: 'Recorrente' },
    { value: 'RecorrenteEmRisco', label: 'Recorrente em Risco' },
    { value: 'VIP', label: 'VIP' },
    { value: 'VIPEmRisco', label: 'VIP em Risco' },
    { value: 'EmRisco', label: 'Em Risco' },
    { value: 'Perdido', label: 'Perdido' }
  ];
  constructor(private empresaService: EmpresasService) {
    this.setNovoFiltro();
  }

  ngOnInit() {
    this.tiposClientes = [];
    for (let item in EnumStatusContato){
      if ( isNaN(Number(item)) ) {
        this.tiposClientes.push( { label: item, value: EnumStatusContato[item] });
      }
    }
    for(let i = 1; i <= 90; i++)
      this.valores.push( { value: i, label: i + ''} );

    this.empresaService.listeTiposPet( ).then( tipos => this.tiposPets = tipos);

    this.empresaService.listeTags().then( tags =>  this.setTags(tags) )

  }

  ngOnDestroy() {
    document.body.classList.remove('filtro-aberto');
  }

  setNovoFiltro() {
    this.filtro = {
      ultimas: FiltroTelaUI.ultimaCompra(),
      pontuacao: FiltroTelaUI.pontuacao(),
      ticketMedio: FiltroTelaUI.ticketMedio(),
      totalComprado: FiltroTelaUI.totalComprado(),
      qtdComprado: FiltroTelaUI.qtdeCompras(),
      naoNotificado: FiltroTelaUI.naoNotificado(),
      aniversario: FiltroTelaUI.aniversario(),
      status: FiltroTelaUI.status(),
      plano: FiltroTelaUI.plano(),
      tag: FiltroTelaUI.tag(),
      jaTrocou: FiltroTelaUI.jaTrocou(),
      podeTrocar: FiltroTelaUI.podeTrocar(),
      sexo: FiltroTelaUI.sexo(),
      tipoPet: FiltroTelaUI.tipoPet(),
      generoPet: FiltroTelaUI.generoPet(),
      apenasDddLocal: FiltroTelaUI.apenasDddLocal()
    }


    this.filtro.naoNotificado.operador = this.operadoresMais[0];
  }

  voltar() {
    this.onVoltar.emit({})
  }

  vaiUsarFiltro(filtroAvancado, valores, unidades) {
    if(filtroAvancado.operador && valores && unidades){
      filtroAvancado.valor = valores[0];
      filtroAvancado.unidade = unidades[0];
    }
  }

  setFiltroAvancado(dados) {
    this.setNovoFiltro();

    for(let chave in dados){
      if(dados[chave] && dados[chave].id)
         this.setFiltroExistente(dados[chave])
      else  {
        this.filtro[chave] = dados[chave];
      }
    }
  }

  setFiltroExistente(filtro){
    for(let chave in this.filtro){
      if(this.filtro[chave].id === filtro.id) {
        this.filtro[chave].valor = filtro.valor;
        this.filtro[chave].operador = filtro.operador;
        this.filtro[chave].unidade = filtro.unidade;
      }
    }
  }

  filtre(){
    let dados = {};

    // tslint:disable-next-line:forin
    for ( let key in this.filtro ) {
      let filtro = this.filtro[key];

      if( filtro.id ) {
        let valor =  FiltroTelaUI.getValor(filtro)

        if(valor != null) dados[key] = filtro;
      } else {
        dados[key] = filtro;
      }
    }

    this.onFiltrar.emit(dados);
  }

  setTags(tags){
    tags.forEach(  (tag) => {
      this.tags.push( { value: tag.id, label: tag.nome } )
    })
  }

  setPlanos(planos: any[]) {
    this.planos = [ { value: 0, label: 'Todos' } ];

    planos.forEach(  (plano) => {
      this.planos.push( { value: plano.id, label: plano.nome } )
    })
  }
}
