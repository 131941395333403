import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ConstantsService } from '../../fidelidade/ConstantsService';
import { CartaoClienteService } from '../../services/cartao-cliente.service';
import { NovoCartaoClienteComponent } from '../novo-cartao-cliente/novo-cartao-cliente.component';

export class CartaoCliente {
  id: number;
  codigo: string;
  empresa: any;
  ativo: boolean;
  dataCriacao: Date;

  constructor() {
    this.ativo = true;
    this.dataCriacao = new Date();
  }
}

@Component({
  selector: 'app-cadastro-cartao-cliente',
  templateUrl: './cadastro-cartao-cliente.component.html',
  styleUrls: ['./cadastro-cartao-cliente.component.scss']
})
export class CadastroCartaoClienteComponent implements OnInit {
  loading: boolean = true;
  empresa: any;
  objetos: Array<CartaoCliente> = [];
  modalDeletar = false;
  objetoApagar: CartaoCliente;
  filtro = '';
  msgSucesso = '';

  constructor(
    private router: Router,
    private constantsService: ConstantsService,
    private cartaoClienteService: CartaoClienteService,
    private dialogService: DialogService
  ) {
    this.constantsService.empresa$.subscribe((empresa) => {
      if (!empresa) return;
      this.empresa = empresa;
      this.carregueCartoes();
    });
  }

  ngOnInit() {}

  editar(cartao: CartaoCliente) {
    const altura = window.innerHeight - 100;
    const windowRef = this.dialogService.open({
      title: 'Editar Cartão Cliente',
      content: NovoCartaoClienteComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovoCartao = windowRef.content.instance;
    telaNovoCartao.empresa = this.empresa;
    telaNovoCartao.windowRef = windowRef;
    telaNovoCartao.cartao = Object.assign({}, cartao);

    windowRef.result.subscribe(() => {
      this.carregueCartoes();
    });
  }

  abraDialogRemover(cartao: CartaoCliente) {
    this.objetoApagar = cartao;
    this.modalDeletar = true;
  }

  onFilter($event: Event) {
    // Implementar filtro se necessário
  }

  novo() {
    const altura = window.innerHeight - 100;
    const windowRef = this.dialogService.open({
      title: 'Novo Cartão Cliente',
      content: NovoCartaoClienteComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovoCartao = windowRef.content.instance;
    telaNovoCartao.empresa = this.empresa;
    telaNovoCartao.windowRef = windowRef;

    windowRef.result.subscribe(() => {
      this.carregueCartoes();
    });
  }

  close(status: string) {
    if (status === 'sim') {
      this.cartaoClienteService.removaCartao(this.objetoApagar.id).then(() => {
        this.objetoApagar = null;
        this.modalDeletar = false;
        this.carregueCartoes();
      });
    } else {
      this.objetoApagar = null;
      this.modalDeletar = false;
    }
  }

  voltar() {
    this.router.navigate(['/admin/pedidos-mesas'])
      .then(() => {})
      .catch(err => console.error('Erro ao navegar:', err));
  }

  private carregueCartoes() {
    this.cartaoClienteService.obtenhaCartoes().then((cartoes: any) => {
      this.objetos = cartoes;
      this.loading = false;
    }).catch((erro) => {
      this.loading = false;
    });
  }
}
