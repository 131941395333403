import {Component, Input, OnInit} from '@angular/core';
import {FormasDePagamentoService} from "../../services/formas-de-pagamento.service";
import {EditEvent} from "@progress/kendo-angular-grid";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {NovaFormaDePagamentoComponent} from "../nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {AutorizacaoService} from "../../services/autorizacao.service";

@Component({
  selector: 'app-grid-formas-de-pagamento',
  templateUrl: './grid-formas-de-pagamento.component.html',
  styleUrls: ['./grid-formas-de-pagamento.component.scss']
})
export class GridFormasDePagamentoComponent implements OnInit {
  @Input() formasDePagamento: any = [];
  @Input() empresa: any = {};
  @Input() loading ;

  @Input() online = false ;
  @Input() ocultarBotoes = false ;
  @Input() ocultarAtivar = false ;
  usuario: any = {}
  constructor(private formasDePagamentoService: FormasDePagamentoService, private dialogService: DialogService,
              private autorizacaoService: AutorizacaoService) { }

  ngOnInit(): void {
    this.usuario = this.autorizacaoService.getUser() || {};
  }

  alterouAtivarPagamento(forma: any, ativar: boolean){
     if(ativar){
       this.formasDePagamentoService.ativeFormaPagamentoNaEmpresa(forma).then((formaDePagamento) => {
         if(!forma.id)
            forma.id = formaDePagamento.id;

       }).catch((erro) => {
         alert(erro)
         forma.exibirCardapio = !ativar;
       })
     } else {
       this.formasDePagamentoService.desativeFormaPagamentoNaEmpresa(forma).then(() => {

       }).catch((erro) => {
         alert(erro)
         forma.exibirCardapio = !ativar;
       })
     }

  }

  mudouChavePix(forma: any){
    if(forma.id){
      forma.salvando = true;
      this.formasDePagamentoService.salveChavePix(forma).then(() => {
        forma.salvando = false;
      }).catch((erro) => {
        alert(erro)
        forma.salvando = false;
      })
    }
  }

  editar($event: EditEvent) {
    const forma: FormaDePagamento = $event.dataItem;

    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro de Forma de Pagamento',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;

    telaNovaFormaDePagamento.windowRef = windowRef;
    telaNovaFormaDePagamento.editar(forma,  this.empresa);

    windowRef.result.subscribe((result: any) => {
      //this.constantsService.recarregueEmpresa();
    });
  }

  informarChavePix(forma: any){
    if(!forma || !forma.formaDePagamentoPdv) return false;

    return forma.formaDePagamentoPdv.tipo === 'MANUAL' && forma.formaDePagamentoPdv.metodo === 'PIX'

  }

  ehPagarme(forma: any){
    if(!forma.configMeioDePagamento) return false;

    return forma.configMeioDePagamento.meioDePagamento.startsWith('pagarme')
  }

  ehMeucardapio(forma: any){
    if(!forma.configMeioDePagamento) return false;

    return forma.configMeioDePagamento.meioDePagamento.startsWith('tuna');
  }
}
