import {CalculadoraPrecoSabor} from "./SaborPizzaLoja";
import {ObjetoComAdicionais} from "./ObjetoComAdicionais";

const PESO = 'Peso'
export class ItemPedido extends ObjetoComAdicionais {
  total: number;
  valorUnitario: number;
  produtoTamanho: any;
  sabores = [];
  valorResgatado: number;
  constructor(public produto: any, public qtde: number,
              public observacao: string) {
    super();
    this.valorUnitario = produto.preco
    this.atualizeTotal();
  }

  atualize(qtde, observacao: string, adicionais: any){
    this.qtde = qtde;

    if(observacao){
      if(this.observacao)
        this.observacao += " " + observacao;
      else
        this.observacao = observacao;
    }

    this.adicionais = adicionais
    this.atualizeTotal()
  }


  atualizeTotal() {
    if(this.produto && this.produto.brinde){
      this.valorUnitario = 0;
      this.total = 0;
      this.valorResgatado = this.produto.valorResgate * this.qtde;
    } else {
      this.valorUnitario = this.obtenhaValorUnitario();
      this.total = this.obtenhaValor()
    }
  }


  obtenhaValor(): number {

    if(this.produto.tipoDeVenda  && this.produto.tipoDeVenda === PESO)
      return this.obtenhaValorPorPeso();

    //quando for por unidade adicionais e calculado junto
    return this.obtenhaValorUnitario() * this.qtde;
  }

  obtenhaValorUnitario(): number {
    if(this.produto.tipoDeVenda  && this.produto.tipoDeVenda === PESO)
      return this.obtenhaValorPorPeso();

    return this.obtenhaPreco() + this.obtenhaValorAdicionais();
  }


  private obtenhaPreco(){
    if(this.sabores && this.sabores.length)
      return  CalculadoraPrecoSabor.calculeValorSabores(this.produto.template, this.sabores);

    if(this.produtoTamanho)
      return this.produtoTamanho.preco;

    if(this.produto.novoPreco) return this.produto.novoPreco;

    return this.produto.preco;
  }

  private obtenhaValorAdicionais() {
    let valorAdicionais = 0;

    for (let prop in this.adicionais) {
      if(prop.startsWith('campo') && this.adicionais[prop] != null)
        valorAdicionais += this.adicionais[prop].valor;
      else if(prop.startsWith('lista')) {
        let opcoes = this.adicionais[prop]

        valorAdicionais += this.obtenhaValorAdicional(opcoes)
      }
    }

    return valorAdicionais;
  }


  obtenhaValorPorPeso(): number{
    let qtdePorPeso = this.qtde;

    if(this.produto.unidadeMedida && this.produto.unidadeMedida.sigla === 'g'  )
      qtdePorPeso = this.qtde / 1000;

    let valorDoPeso = Number((this.produto.preco * qtdePorPeso ).toFixed(2));

    //quando for por peso adicionais e calculado a parte
    return valorDoPeso + this.obtenhaValorAdicionais();
  }



  obtenhaUnidade(){
    if(this.produto.tipoDeVenda && this.produto.tipoDeVenda === PESO)
      return this.produto.unidadeMedida.sigla.toLowerCase()

    return 'x'
  }


  private obtenhaValorAdicional(opcoes: any) {
    let valorAdicional = 0

    let tipoDeCobranca = opcoes.tipoDeCobranca

    switch(tipoDeCobranca) {
      case 'SOMA':
        for(let propInterna in opcoes) {
          if (opcoes[propInterna].selecionada) {
            valorAdicional += opcoes[propInterna].valorTotal
          }
        }
        break;
      case 'MEDIA':
        let tot = 0;

        for(let propInterna in opcoes) {
          if (opcoes[propInterna].selecionada) {
            tot += opcoes[propInterna].qtde;
            valorAdicional += opcoes[propInterna].valorTotal

          }
        }

        if(tot > 0)
          valorAdicional =  +((valorAdicional / tot).toFixed(2));
        break;
      case 'MAIOR':
        for(let propInterna in opcoes) {
          if (opcoes[propInterna].selecionada) {
            if(opcoes[propInterna].valorTotal > valorAdicional)
              valorAdicional = opcoes[propInterna].valorTotal
          }
        }
        break;
    }

    return valorAdicional
  }

  obtenhaDescricao(){
    let descricao = this.produto.nome;

    if(this.produtoTamanho)
      descricao = String(`${this.produto.nome} ${this.produtoTamanho.descricao}`);

    if(this.sabores && this.sabores.length){
      let listaSabores = this.sabores.map( (sabor: any) => sabor.nome);

      if(listaSabores.length   === 1)
        descricao = String(`${this.produto.template.identificador} ${this.produtoTamanho.descricao}  - ${listaSabores[0]}`);
      else {
        for(let i = 0 ; i < listaSabores.length; i++){
          listaSabores[i] = String(`${(i + 1)}°: ${listaSabores[i]}`)
        }

        descricao = String(`${this.produto.template.identificador} ${this.produtoTamanho.descricao} ${this.sabores.length} Sabores ( ${listaSabores.join('; ') } )`);
      }

    }

    return descricao;
  }

  obtenhaPontosFidelidade(atividade: any){
    if(atividade.cashback != null){
      let preco = this.total, cashback = atividade.cashback;

      if(this.produtoTamanho && this.produtoTamanho.template){
        if(this.produtoTamanho.template.cashback != null)
          cashback = (this.produtoTamanho.template.cashback / 100)
      } else  if(this.produto.cashback != null){
        cashback = (this.produto.cashback / 100)
      }

      let pontos = cashback  * preco;

      return Number(pontos.toFixed(2))
    }

    let pontosPorProduto =    ( atividade.pontosGanhos || 0);

    if(this.produtoTamanho &&  this.produtoTamanho.template){
      if(this.produtoTamanho.template.pontosGanhos != null)
        pontosPorProduto =  this.produtoTamanho.template.pontosGanhos
    } else if(this.produto.pontosGanhos != null)
      pontosPorProduto = this.produto.pontosGanhos

    return pontosPorProduto * this.qtde;
  }

}
