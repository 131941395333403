
<h4 class="page-title" [hidden]="modal">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()"   style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fe-market "></i> Detalhes do pedido </h4>



<div class="row" *ngIf="_pedido && _pedido.empresa">
  <div class="col-12 col-xl-5 col-sm-6" [ngClass]="{'col-xl-6': modal}" >
    <div class="card-box ribbon-box"  >
      <h4 class="header-title mb-3 float-left">Pedido <b>#{{_pedido?.codigo}}</b>
        <span class="badge badge-primary ml-2 mr-2" *ngIf="_pedido.multipedido">multipedido </span>
        <ng-container *ngIf="_pedido.aceito">
          <button   type="button" kendoButton   class="k-button k-primary ml-2 mr-1"  *ngIf="_pedido.podeEditar"
                    (click)="editarPedido(_pedido)" title="editar" kendoTooltip>
            <i class="fa fa-edit"></i>
          </button>

          <ng-container *ngIf="(!configImpressao || !configImpressao?.multiplasImpressoras) &&  !_pedido.aguardandoPagamentoOnline">

            <button  *ngIf="!configImpressao ||  (configImpressao.layoutPedido !== 'Ambos')" title="imprimir" kendoTooltip
                     type="button" kendoButton   class="k-button   "  (click)="imprimaPedidoDoLayout( )" [hidden]="!empresa.id">
              <i class="fa fa-print"></i></button>

            <ng-container *ngIf="configImpressao?.layoutPedido === 'Ambos'">
              <button type="button"  kendoButton  class="k-button   "   (click)="imprimaPedido('PedidoCompleto')" title="imprimir" kendoTooltip>
                <i class="fa fa-print"></i>&nbsp;Completo</button>
              <button type="button" kendoButton   class="k-button  ml-1"   (click)="imprimaPedido('PedidoResumido')" title="imprimir" kendoTooltip>
                <i class="fa fa-print"></i>&nbsp;Resumido</button>

            </ng-container>
          </ng-container>

          <kendo-dropdownbutton   [data]="configImpressao.impressoras" [popupSettings]="{animate: true}"
                                  (itemClick)="imprimaNaImpressora($event)" [textField]="'setor'"
                                  *ngIf="configImpressao?.multiplasImpressoras  &&  !_pedido.aguardandoPagamentoOnline"
                                  [icon]="'k-icon k-i-printer'" >

            Imprimir
          </kendo-dropdownbutton >
        </ng-container>

        <app-pedido-origem [pedido]="_pedido"></app-pedido-origem>

        <p class=" text-muted font11 mb-0" *ngIf="_pedido.referenciaExterna && (!_pedido.doIfood  )"
           [hidden]="_pedido.delivery?.origem === 'ifood'" >
          <br>
          Ref. Externa:

          <a target="_blank"  href="{{host}}/integracaoerp/pedido/{{_pedido.guid}}"  >
            {{_pedido.referenciaExterna}}
          </a>

        </p>

        <p *ngIf="_pedido.requests && usuario.admin" class="mt-0" >
          <a  class="text-primary font11" href="#"  (click)="abraModalRequestsParceiro()">  ver requests</a>
        </p>

      </h4>

      <div class="float-right  " *ngIf="empresa.integracaoOpendelivery?.ativa"  >
         <button type="button" class="btn btn-warning btn-sm  " (click)="abraModalEventosOpenDelivery()">
           eventos <b>Open Delivery</b></button>
      </div>

      <button   type="button"   class="btn btn-danger btn-sm mb-3 float-right"
                [hidden]="_pedido.aguardandoPagamentoOnline ||  _pedido.multipedido"
                [disabled]="processando" *ngIf="_pedido.aceito && !_pedido.referenciaExterna && integracao"
                (click)="notifiqueSistemaIntegrado()" title="enviar pedido sistema integrado" kendoTooltip >

        <i class="k-i-loading k-icon mr-1" *ngIf="processando"></i>
        Enviar Pedido {{integracao.sistema}}</button>

      <div class="ribbon-content">
        <div  class="fundo" >
          <div class="d-flex justify-content-center align-items-center" style="height: 400px" *ngIf="!_pedido && carregou">
            <div class="align-self-center text-center" style="color: #F1556C;">
              <i class="fas fa-exclamation-triangle" style="font-size: 48px"></i>
              <p>
                Não encontrado!
              </p>
            </div>
          </div>

          <div *ngIf="_pedido.naoNotificado" class="alert alert-danger alert-dismissible fade show mt-1" role="alert">
            <p>{{_pedido.erroExterno}}</p>

            <a *ngIf="_pedido.requests && usuario.admin" href="#" (click)="abraModalRequestsParceiro()">
              ver requests
            </a>


            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>


          <div *ngIf="_pedido.id">
            <div class="media mb-2" *ngIf="_pedido.ifood" style="border: 1px dashed #999;padding: 20px 15px;">
              <div class="media-body">
                <div class="mb-2">
                  <i class="badge badge-danger text-left mr-1">Ifood</i>

                  <span  class="mr-1">
                   <a target="_blank"  href="{{host}}/ifood/{{_pedido.idLojaExterna}}/order/{{_pedido.referenciaExterna}}"  >
                     <b> #{{_pedido.ifood.displayId}}</b>
                  </a>
                  </span>
                  <span class="  text-blue mb-1 " *ngIf="_pedido.ifood?.codigoRetirada"   style="float: right">
                    Codigo coleta: <b>{{_pedido.ifood?.codigoRetirada}}</b>
                  </span>

                </div>

                <a *ngIf="_pedido.ifood?.localizadorPedido" class="mb-0"
                   href="https://confirmacao-entrega-propria.ifood.com.br" target="_blank">
                  Localizador do pedido: <b>{{_pedido.ifood.localizadorPedido}}</b>
                </a>

                <p *ngIf="_pedido.ifood?.entregadorPor"  class="mb-0">
                    <b>{{_pedido.ifood.entregadorPor}}</b>
                </p>

                <p class="mb-0">
                  Contato por Telefone Ifood:

                  <i class="fa fa-phone mr-1"></i>
                  <b> {{_pedido.ifood.numeroCliente}}</b>
                </p>

                <p class="mb-0" *ngIf="_pedido.ifood.cpfNota">
                  Incluir cpf na nota:
                  <b> {{_pedido.ifood.cpfNota}}</b>
                </p>

              </div>
            </div>

            <div class="desktop">
              <div class="produto   pb-0"    *ngIf="_pedido.mesa">
                <div class="media mt-2">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1"> <i class="fa fa-table fa-lg"></i><span class="ml-1">{{_pedido.empresa.identificadorMesa}}</span></h5>
                  </div>
                  <h5 class="mt-0 text-right">
                    <strong>  {{_pedido.mesa.nome}}</strong>
                  </h5>
                </div>

              </div>


              <div class="produto   pb-0"   *ngIf="!_pedido.balcao" >
                <div class="media mt-2">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1"> <i class="fe-user fa-lg"></i><span>Cliente</span></h5>
                  </div>
                  <h5 class="mt-0 text-right">
                    <a [routerLink]="'/admin/contatos/' + _pedido.cliente.codigoPais + _pedido.cliente.telefone">
                      <strong>{{_pedido.cliente.nome}}</strong> <br>
                      <label class="text-muted mt-1">{{_pedido.cliente.telefone | tel: _pedido.cliente.codigoPais}}</label>
                    </a>

                  </h5>
                </div>

              </div>

              <div class="produto pt-2 pb-1" *ngIf="_pedido.formaDeEntrega">
                <div class="media mt-1">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1"><span> <i class="fe-map-pin fa-lg"></i> Forma de Entrega</span></h5>
                  </div>
                  <h5 class="mt-0"><strong>{{_pedido.formaDeEntrega}}</strong></h5>

                </div>
                <div class="media mt-1" *ngIf="_pedido.horarioEntregaAgendada">
                  <div class="media-body">
                    <h5 class="mt-0 mb-1"><span> <i class="fe-clock fa-lg"></i> Agendado para</span></h5>
                  </div>
                  <h5 class="mt-0"><strong>{{_pedido.horarioEntregaAgendada | date: "dd/MM/yyyy 'às' HH:mm"}}</strong></h5>
                </div>

                <div class="pt-0 pb-0 ml-2" *ngIf="_pedido.formaDeEntrega === FormaDeEntrega.RECEBER_EM_CASA">
                  <h5 class="text-muted"><i class="fas fa-map-marker-alt"></i> Endereço Escolhido</h5>
                  <h5>
                    {{_pedido.enderecoCompleto}}
                  </h5>




                  <app-acoes-delivery [pedido]="_pedido" [empresa]="empresa" [exibirDetalhes]="true"
                                        *ngIf="!_pedido.retirar"  ></app-acoes-delivery>


                </div>
              </div>
              <h5 class="mt-2 mb-1"><span>
             <i class="fe-shopping-bag fa-lg"></i>
            Produtos</span></h5>

              <div *ngFor="let empresa of _pedido.empresas">

                <h5 class="font-weight-bold mb-0 mt-2 text-uppercase" *ngIf="_pedido.origem === 'multiloja'">
                  <i class="fe-home fa-lg "></i>   {{empresa.nome}}
                      <span class="font-12 text-muted ml-2">{{empresa.totalProdutos}} {{empresa.totalProdutos > 1 ? 'produtos': 'produto'}}</span>
                </h5>

                <div *ngFor="let item of obtenhaItens(empresa)" class="produto pt-2 pb-2 ml-1 item_produto dashed">
                  <div class="media">
                    <div class="media-body">
                      <h5 class="mt-0 mb-1">
                        {{item.qtde}}{{item.unidadeTxt}}   {{item.descricao}}
                      </h5>
                      {{item.produto.descricao}}

                      <div *ngIf="item.adicionaisImprirmir?.length" >
                       <span  *ngFor="let last = last;let adicional of item.adicionaisImprirmir " class="d-block ml-2 font-weight-bold">
                         {{adicional.descricao}}{{last ? '' : ', '}}
                       </span>
                      </div>
                      <span *ngIf="item.observacao"  class="mt-1 d-block"> Obs.:<i>"{{item.observacao}}"</i></span>
                    </div>

                    <h5 class="preco mt-0" *ngIf="!item.valorResgatado">
                      {{item.total | currency: 'BRL'}}
                    </h5>
                    <h5 class="mt-0" *ngIf="item.valorResgatado">
                      -{{item.valorResgatado}} {{_pedido.acumulo}}
                    </h5>
                  </div>
                </div>

              </div>


            </div>

            <div class="produto pt-2 pb-1" *ngIf="_pedido.observacoes">
              <div class="media mt-1">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Observação</span></h5>
                </div>
                <h5 class="mt-0">
                  <i>
                    {{_pedido.observacoes}}
                  </i>
                </h5>
              </div>
            </div>

            <div class="linha mt-0 mb-0" *ngIf="_pedido.itens.length > 3"></div>

            <div class="  totais  pt-2 pb-0">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0 mb-1 text-muted"><span>Subtotal</span></h5>
                </div>
                <h5 class="mt-0 text-muted font-16"> {{_pedido.subvalor | currency: 'BRL'}}</h5>
              </div>

              <div class="media" *ngIf=" _pedido.desconto">
                <div class="media-body">
                  <h5 class="mt-0 mb-1">
                    <span>Desconto</span>
                    <span style="font-size: 10px"   *ngIf="_pedido.cupom && !_pedido.descontoTaxaEntrega"><i>({{ _pedido.cupom  }})</i></span>
                    <span *ngIf="_pedido.patrocinadoresDesconto">(<i>{{_pedido.patrocinadoresDesconto}}</i>)</span>

                    <br>
                    </h5>
                </div>
                <h5 class="mt-0">-{{_pedido.desconto | currency: 'BRL'}}</h5>
              </div>

              <div class="media" *ngIf=" _pedido.descontoFormaDePagamento">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>{{_pedido.descricaoDescontoFormaDePagamento}}</span><br>
                  </h5>
                </div>
                <h5 class="mt-0">-{{_pedido.descontoFormaDePagamento | currency: 'BRL'}}</h5>
              </div>

              <div class="media" *ngIf=" _pedido.taxaEntrega || _pedido.descontoTaxaEntrega">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Taxa de entrega</span>
                    <ng-container *ngIf="_pedido.descontoTaxaEntrega">
                      <span class="lh20"> <br>Desconto {{_pedido.cupom ? "(" + _pedido.cupom + ")" : ""}}</span>
                      <span class="lh20"> <br>Total taxa de entrega</span>
                    </ng-container>
                  </h5>

                </div>

                <h5 class="mt-0">
                  <ng-container *ngIf="_pedido.descontoTaxaEntrega">
                    <span class="riscado lh20">
                      +{{_pedido.taxaEntrega + _pedido.descontoTaxaEntrega | currency: 'BRL'}}
                      <br>
                    </span>
                    <span class="lh20">
                      -{{_pedido.descontoTaxaEntrega | currency: 'BRL'}}
                      <br>
                    </span>
                  </ng-container>

                  +{{_pedido.taxaEntrega | currency: 'BRL'}}</h5>

              </div>

              <div class="media" *ngIf=" _pedido.ifood?.taxaServico">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Taxa de serviço Ifood</span>
                  </h5>

                </div>

                <h5 class="mt-0">  +{{_pedido.ifood?.taxaServico | currency: 'BRL'}}</h5>
              </div>

              <div class="media" *ngIf="_pedido.pagamento && _pedido.pagamento.formaDePagamento === 'Dinheiro'">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Troco</span></h5>
                </div>
                <h5 class="mt-0 preco">{{_pedido.troco | currency: 'BRL'}}</h5>
              </div>

              <div class="media" *ngIf=" _pedido.taxaFormaDePagamento">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Taxa forma de pagamento</span></h5>
                </div>
                <h5 class="mt-0">+{{_pedido.taxaFormaDePagamento | currency: 'BRL'}}</h5>
              </div>


              <div class="media mt-0">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Total Pago</span></h5>
                </div>
                <h5 class="mt-0 preco font-18"><strong>{{_pedido.total | currency: 'BRL'}}</strong></h5>
              </div>

              <div class="media mt-0" *ngIf="_pedido.totalResgatado">
                <div class="media-body">
                  <h5 class="mt-0 mb-1"><span>Total Resgatado</span></h5>
                </div>
                <h5 class="mt-0 resgate font-18"><strong>{{_pedido.totalResgatado  }} {{_pedido.acumulo}}</strong></h5>
              </div>

            </div>

            <div class="pamento "  *ngFor="let pagamento of _pedido.pagamentos; let primeiro = first;">

              <div class="media">
                <div class="media-body" *ngIf="primeiro && _pedido.pagamentos.length === 1">
                  <h5 class="mt-0 mb-1"><span>  Pagamento em:</span>
                  </h5>

                </div>
                <h5 class="mt-0  ">
                  {{pagamento.descricao.toUpperCase()  }}
                  <ng-container *ngIf="_pedido.pagamentos.length > 1">
                    <span   [hidden]="pagamento.resgate"> ({{pagamento.valor | currency: "BRL" }})</span>
                    <span   [hidden]="!pagamento.resgate"> (-{{pagamento.valor}} {{_pedido.acumulo}})</span>
                  </ng-container>

                  <span *ngIf="pagamento.online" class="badge badge-primary">Online</span>
                  <span *ngIf="pagamento.autenticado" class="badge badge-success ml-1">Autenticado</span>
                </h5>
              </div>

              <ng-container *ngIf="pagamento.levarTroco" >
                 <div class="media" >
                    <div class="media-body"  >
                      <h5 class="mt-0 mb-1"><span>  Troco para:</span>
                      </h5>

                    </div>
                     <h5 class="mt-0  ">
                        {{pagamento.trocoPara | currency: "BRL"}}
                      </h5>

                </div>
                 <div class="media" >
                  <div class="media-body"  >
                    <h5 class="mt-0 mb-1"><span> Valor do troco:</span>
                    </h5>
                  </div>
                  <h5 class="mt-0  ">
                    {{pagamento.valorTroco | currency: "BRL"}}
                  </h5>

                </div>
               </ng-container>

            </div>
            <div class="media" *ngIf=" _pedido.entregador">
              <div class="media-body">
                <h5 class="mt-0 mb-1"><span>Entregador</span>
                </h5>

              </div>

              <h5 class="mt-0">
                {{_pedido.entregador}}</h5>

            </div>

            <ng-container *ngIf="_pedido.pontosGanhos" >
              <div class="linha mt-0 mb-0"  ></div>
              <div class="media mt-2" >
                <div class="media-body"  >
                  <h5 class="mt-0 mb-1 text-muted">{{ _pedido.temCashback ? 'Cashback a confirmar' : ' Pontos a confirmar:' }}
                  </h5>

                </div>
                <h5 class="mt-0  ">
                  {{_pedido.pontosGanhosDescricao }}
                </h5>

              </div>
            </ng-container>

            <h5  *ngIf="_pedido.observacoes"><span class="text-muted">Observações:</span>  "{{_pedido.observacoes}}"</h5>

            <div style="height: 40px" class="mobile"></div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-4 col-sm-6"  [ngClass]="{'col-xl-6': modal}">

      <app-acao-pedido-disputa  *ngIf="_pedido.disputa " [pedido]="_pedido"></app-acao-pedido-disputa>

      <div class="card-box ribbon-box">

        <app-pedido-ribbon [pedido]="_pedido"></app-pedido-ribbon>

        <h4 class="header-title mb-3 mt-1">Acompanhamento do pedido

          <button   type="button"   class="k-button k-primary"  [hidden]="_pedido.multipedido" kendoButton
                    (click)="mudarStatusPedido(_pedido)" title="alterar status" kendoTooltip *ngIf="!_pedido.cancelado && _pedido.aceito">
            <i class="fa fa-edit"></i></button>

        </h4>

        <div class="ribbon-content">
          <div class="track-order-list">

            <ul class="list-unstyled">
              <li *ngIf="!_pedido.aguardandoPagamentoOnline" [ngClass]=" {'completed': _pedido.statusOrdem > 0  ,
                              'actived': _pedido.statusOrdem ==  0   }">
                <span class="active-dot dot" *ngIf="_pedido.statusOrdem ==  0   "></span>
                <h5 class="mt-0 mb-1">Pedido Realizado</h5>
                <p class="text-muted">{{ _pedido.horarioDescricao }}
                  <span class="badge badge-light">{{_pedido.duracaoDescricao}}</span>
                </p>

                <h5 class="text-success" *ngIf="!_pedido.aceito && !_pedido.cancelado">
                  <b>Aguardando confirmação do pedido</b>
                </h5>
              </li>

              <ng-container *ngIf="!_pedido.aguardandoPagamentoOnline && !_pedido.cancelado && _pedido.aceito">
                <li [ngClass]=" {'completed':  _pedido.statusOrdem > 1 }">
                  <span class="active-dot dot" *ngIf="_pedido.statusOrdem ==  1">      </span>
                  <h5 class="mt-0 mb-1">Em preparação</h5>
                  <p class="text-muted"> &nbsp;
                    <span class="text-muted" *ngIf="_pedido.statusOrdem == 1">{{_pedido.horarioAtualizacaoDescricao}}</span>
                  </p>
                </li>

                <li [ngClass]=" {'completed': _pedido.statusOrdem > 2  }">
                  <h5 class="mt-0 mb-1">Pronto</h5>
                  <span class="active-dot dot" *ngIf="_pedido.statusOrdem == 2">                </span>
                  <p class="text-muted"> &nbsp;
                    <span class="text-muted" *ngIf="_pedido.statusOrdem ==  2">{{_pedido.horarioAtualizacaoDescricao}}</span>
                  </p>
                </li>
                <ng-container *ngIf="!_pedido.mesa">
                  <li [ngClass]="{'completed': _pedido.statusOrdem > 3  }">
                    <span class="active-dot dot" *ngIf="_pedido.statusOrdem===3"></span>
                    <h5 class="mt-0 mb-1"> Saiu para entrega</h5>
                    <p class="text-muted"> &nbsp;
                      <span   *ngIf="_pedido.statusOrdem === 3">{{_pedido.horarioAtualizacaoDescricao}}</span>
                    </p>

                  </li>

                  <li [ngClass]="{'completed': _pedido.statusOrdem == 4  }">

                    <h5 class="mt-0 mb-1">Entregue</h5>
                    <p class="text-muted"> &nbsp;
                      <span   *ngIf="_pedido.statusOrdem === 4">{{_pedido.horarioAtualizacaoDescricao}}</span>
                    </p>
                  </li>
                </ng-container>
              </ng-container>

              <li class="actived" *ngIf="_pedido.aguardandoPagamentoOnline">
                <span class="active-dot dot"  ></span>
                <h5 class="mt-0 mb-1 text0 "><b>
                  {{_pedido.aguardandoEstorno ? 'Reembolso solicitado' : 'Aguardando confirmação pagamento online'}}

                </b></h5>
                <p class="text-muted">{{ _pedido.horarioDescricao }}
                  <span class="badge badge-light">{{_pedido.duracaoDescricao}}</span>
                </p>

                <app-sincronizar-pagamento-online [pedido]="_pedido" >

                </app-sincronizar-pagamento-online>
              </li>

              <li class="completed"  *ngIf="_pedido.cancelado">

                <h5 class="mt-0 mb-1">{{_pedido.status}}</h5>
                <p class="text-muted">
                  <span class="text-muted"  >{{_pedido.horarioAtualizacaoDescricao}}</span>
                </p>
              </li>


            </ul>

          </div>
          <div *ngIf="_pedido.motivoReprovacao" class="alert alert-danger alert-dismissible fade show mt-1" role="alert">
            Pagamento não aprovado: {{_pedido.motivoReprovacao}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div *ngIf="erroAceitar" class="alert alert-danger alert-dismissible fade show mb-2 mt-2" role="alert">
            {{erroAceitar}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>



      <div *ngIf="!_pedido.aceito && !_pedido.cancelado" class="mb-3 mt-2"  class="alert alert-info">
        <div [hidden]="_pedido.confirmarRejeitar">
          <h5>Você recebeu um novo Pedido</h5>
          <div class="btn-group " >
            <button   type="button"   class="btn  btn-success " [disabled]="processando"
                      (click)="aceitarPedido()" title="aceitar pedido" kendoTooltip >
              <i class="fa fa-check" *ngIf="!processando"></i>
              <i class="k-i-loading k-icon " *ngIf="processando"></i>
              Aceitar Pedido</button>


            <button type="button" class="btn  btn-danger ml-2"
                    title="cancelar pedido" kendoTooltip (click)="_pedido.confirmarRejeitar = true">

              <i class="fa fa-times-circle"  ></i>
              Rejeitar Pedido
            </button>
          </div>
        </div>
        <ng-container *ngIf="_pedido.confirmarRejeitar">
          <h5>Confirma que não quer aceitar esse pedido?  </h5>
          <div class="btn-group"  >
            <button class="btn btn-danger   " (click)="rejeitarPedido()" [disabled]="processando">
              <i class="k-i-loading k-icon " *ngIf="processando"></i> Rejeitar Pedido
            </button>
            <button class="btn  btn-secondary   ml-1" (click)="_pedido.confirmarRejeitar = false" [disabled]="processando">
              Voltar
            </button>
          </div>
        </ng-container>
      </div>
  </div>
</div>

<div class="row">
   <div class="col-12 col-xl-9 col-sm-12">
     <div class="card-box ribbon-box">
       <div class=" ribbon ribbon-dark float-right">
         <span>Historico</span>
       </div>

       <h4 class="header-title mb-3 mt-1">
         Historico do pedido
       </h4>

       <div class="ribbon-content">
         <kendo-grid [data]="historico"
                     [loading]="carregandoHistorico" style="min-height: 150px;">
           <kendo-grid-messages
             pagerPage="Página"
             pagerOf="de"
             pagerItems="itens"
             noRecords="Sem historico"
             loading="Carregando"
             pagerItemsPerPage="ítems por página"
           >
           </kendo-grid-messages>
           <kendo-grid-column title="Alteração"   >
             <ng-template kendoGridCellTemplate let-dataItem  >
               <span class="text-blue"><b>{{dataItem.descricao}}</b></span>
             </ng-template>
           </kendo-grid-column>

           <kendo-grid-column title="Horário"  [width]="180"  >
             <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span class="text-muted"><b>{{dataItem.horario | date: 'dd/MM/YYYY HH:mm:ss'}}</b></span>
             </ng-template>
           </kendo-grid-column>

           <kendo-grid-column title="Operador" [width]="180" >
             <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span>
                 <b>{{dataItem.operador ? dataItem.operador.nome : (dataItem.clienteApi ? dataItem.clienteApi.nome : '-')}}</b>
               </span>
             </ng-template>
           </kendo-grid-column>

           <kendo-grid-column title="Api"   [width]="100">
             <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span class="badge badge-success" *ngIf="dataItem.clienteApi">Sim</span>
               <span class="badge badge-danger" *ngIf="!dataItem.clienteApi">Não

          </span>
             </ng-template>
           </kendo-grid-column>

         </kendo-grid>
       </div>
   </div>
  </div>
</div>
