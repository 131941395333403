<h4 class="page-title d-flex align-items-center">
  <i class="fas fa-utensils"></i>
  <span>&nbsp;Mesas</span>
</h4>

<div *ngIf="this.mesasBloqueadas" class="card mt-2">
  <div class="card-body p-2">
    <div class="alert alert-warning mb-0">
      <h4 class="d-flex align-items-center">
        <i class="fas fa-exclamation-triangle me-2"></i>
        Sistema parceiro "<b>{{empresa.integracaoDelivery.sistema}}</b>" não tem suporte a pedidos mesa
      </h4>
    </div>
  </div>
</div>

<div class="card" [hidden]="mesasBloqueadas">
  <div class="card-body p-0">
    <ng-container *ngIf="modoGarcom">
      <app-pedidos-mesa></app-pedidos-mesa>
    </ng-container>

    <div class="mobile-tabs" *ngIf="!modoGarcom">
      <!-- Menu de navegação mobile -->
      <div class="nav-container">
        <div class="nav-pills-mobile">
          <button class="nav-pill" [class.active]="selectedTab === 'erro'" (click)="selectedTab = 'erro'" *ngIf="comErros">
            <i class="fas fa-exclamation-circle"></i>
            <span>Com erro</span>
          </button>

          <ng-container *ngIf="!comErros">
            <button class="nav-pill" [class.active]="selectedTab === 'aberto'" (click)="selectedTab = 'aberto'">
              <i class="fas fa-door-open"></i>
              <span>Em aberto</span>
            </button>

            <button class="nav-pill" [class.active]="selectedTab === 'finalizados'" (click)="selectedTab = 'finalizados'">
              <i class="fas fa-check-circle"></i>
              <span>Finalizados</span>
            </button>

            <button class="nav-pill" [class.active]="selectedTab === 'cancelados'" (click)="selectedTab = 'cancelados'">
              <i class="fas fa-times-circle"></i>
              <span>Cancelados</span>
            </button>
          </ng-container>
        </div>

        <kendo-dropdownbutton [data]="opcoesConfiguracao"
                             [icon]="'gear'"
                             (itemClick)="onConfiguracaoClick($event)"
                             class="btn-configuracao">
          Cadastros / Configurações
        </kendo-dropdownbutton>
      </div>

      <!-- Conteúdo das abas -->
      <div class="tab-content p-0" [ngSwitch]="selectedTab">
        <app-pedidos-mesas-com-erro *ngSwitchCase="'erro'"></app-pedidos-mesas-com-erro>

        <ng-container *ngSwitchCase="'aberto'">
          <!-- Barra de ações -->
          <div class="acoes-container">
            <button class="btn-abrir-mesa" (click)="abrirNovaMesa()">
              <i class="fas fa-plus-circle"></i>
              Abrir Mesa
            </button>

            <div class="search-container">
              <kendo-textbox
                class="busca"
                name="filtro"
                placeholder="Buscar mesa por número ou nome"
                [(ngModel)]="filtro"
                [showSuccessIcon]="false">
                <ng-template kendoTextBoxSuffixTemplate>
                  <kendo-textbox-separator></kendo-textbox-separator>
                  <button kendoButton [look]="'clear'" [icon]="'search'"></button>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>

          <div class="mesas-grid">
            <!-- Mensagem quando não há mesas em uso -->
            <div class="alert alert-warning mt-2" *ngIf="!mesas || mesas.length === 0">
              <h4>
                <i class="fas fa-exclamation-triangle"></i>
                Nenhuma {{empresa?.identificadorMesa || 'Mesa'}} em uso no momento
              </h4>
              <button class="btn btn-primary mt-3" (click)="abrirNovaMesa()">
                <i class="fas fa-plus"></i>
                Abrir Mesa
              </button>
            </div>

            <!-- Mensagem quando a busca não encontra resultados -->
            <div class="empty-search-container" *ngIf="(mesas | filtroObjetosNome: filtro).length === 0 && mesas.length > 0">
              <div class="empty-search-content">
                <div class="empty-search-icon">
                  <i class="fas fa-search"></i>
                </div>
                <h3>Nenhuma mesa encontrada</h3>
                <p>Não encontramos mesas com o termo "<strong>{{filtro}}</strong>"</p>
                <button class="btn-limpar-busca" (click)="limparFiltro()">
                  <i class="fas fa-times"></i>
                  Limpar busca
                </button>
              </div>
            </div>

            <!-- Lista de mesas filtradas -->
            <div class="mesa-card" *ngFor="let mesa of mesas | filtroObjetosNome: filtro">
              <div class="card mesa-item">
                <div class="card-header">
                  <div class="mesa-info">
                    <h4 class="mesa-numero">{{mesa.nome}}</h4>
                    <div class="mesa-status">
                      <span class="status-badge nao-gera-comanda" *ngIf="mesa.naoGerarComanda">
                        Não gera comanda
                      </span>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <div class="mesa-ocupada">
                    <div class="info-principal">
                      <div class="valor-total">
                        <span class="label">TOTAL DA MESA</span>
                        <span class="valor">R$ {{calcularValorTotalMesa(mesa) | number:'1.2-2'}}</span>
                      </div>
                      <div class="info-secundaria">
                        <div class="tempo">
                          <i class="far fa-clock"></i>
                          <span>{{mesa.tempo | number:'1.0-0'}}h em uso</span>
                        </div>
                        <div class="comandas-count" *ngIf="mesa.comandas?.length">
                          <i class="fas fa-receipt"></i>
                          <span>{{mesa.comandas.length}} {{mesa.comandas.length === 1 ? 'Comanda' : 'Comandas'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="acoes-mesa">
                    <!-- Quando permite múltiplas comandas -->
                    <button class="btn-selecionar" (click)="verComandas(mesa)" *ngIf="empresa.permitirMultiplasComandasMesa">
                      <i class="fas fa-check"></i>
                      Selecionar Mesa
                    </button>

                    <!-- Quando não permite múltiplas comandas -->
                    <div class="botoes-container" *ngIf="!empresa.permitirMultiplasComandasMesa && mesa.comandas && mesa.comandas.length > 0">
                      <button class="btn-detalhes" (click)="verDetalhesComanda(mesa)">
                        <i class="fas fa-receipt"></i>
                        Detalhes
                      </button>
                      <button class="btn-novo-pedido" (click)="novoPedidoComanda(mesa)">
                        <i class="fas fa-utensils"></i>
                        Novo Pedido
                      </button>
                    </div>

                    <!-- Botão selecionar quando não tem comandas -->
                    <button class="btn-selecionar" (click)="verComandas(mesa)"
                            *ngIf="!empresa.permitirMultiplasComandasMesa && (!mesa.comandas || mesa.comandas.length === 0)">
                      <i class="fas fa-check"></i>
                      Selecionar Mesa
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'finalizados'">
          <app-tela-pedidos-pagos [mesa]="true"></app-tela-pedidos-pagos>
        </ng-container>

        <ng-container *ngSwitchCase="'cancelados'">
          <app-tela-pedidos-cancelados [mesa]="true"></app-tela-pedidos-cancelados>
        </ng-container>

        <ng-container *ngSwitchCase="'garcons'">
          <app-garcons-empresa [empresa]="empresa" [moduloGarcom]="possuiModuloGarcom"></app-garcons-empresa>
        </ng-container>

        <ng-container *ngSwitchCase="'cartao'">
          <app-cadastro-cartao-cliente></app-cadastro-cartao-cliente>
        </ng-container>

        <ng-container *ngSwitchCase="'mesas'">
          <app-cadastro-mesa></app-cadastro-mesa>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Comandas -->
<app-lista-comandas
  *ngIf="modalComandaAberto"
  [mesa]="mesaSelecionada"
  [comandas]="comandasAtuais"
  [mostrarBotaoNovo]="true"
  (selecionarComanda)="onSelecionarComanda($event)"
  (criarNovaComanda)="onCriarNovaComanda()"
  (fechar)="onFecharModal()">
</app-lista-comandas>



