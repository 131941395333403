<h4 class="mb-3"><b>{{insumo.totalVinculos}}</b> Itens associados ao Insumo "<b>{{insumo.nome}}</b>"</h4>


<kendo-tabstrip class="nav-bordered vertical-tabstrip" id="tabs" name="tabs" (tabSelect)="selecionouTab($event)">
  <kendo-tabstrip-tab [id]="'produtos'" [title]="'Produtos  (' + totalProdutos + ')'"   [selected]="true"  >
    <ng-template kendoTabContent>

      <ng-container *ngIf="!adicionar">
        <h5>Lista de produtos que estão vinculados ao insumo</h5>
        <kendo-grid
          [kendoGridBinding]="insumo.produtos"   (remove)="desvinculeProdutoInsumo($event)"
          [loading]="carregando" [height]="500"
          footerStyle="font-size: 11px;">
          <ng-template kendoGridToolbarTemplate [position]="'top'">
            <div class="row">
              <div class="col">
                <button class="btn btn-blue float-right" (click)="associarInsumoProdutos()">
                  <i class="fe-plus mr-1"></i>
                  Adicionar Produto
                </button>
              </div>

            </div>

          </ng-template>
          <kendo-grid-messages
            pagerPage="Página"
            pagerOf="de"
            pagerItems="itens"
            noRecords="Nenhum produto vinculado ao insumo"
            loading="Carregando"
            pagerItemsPerPage="ítems por página"
          >
          </kendo-grid-messages>

          <kendo-grid-column title="Imagem"  [width]="110">
            <ng-template kendoGridCellTemplate let-produto let-rowIndex="rowIndex">

              <img [src]="'/images/empresa/'+ produto.imagens[0].linkImagem" style="width: 80px;"
                   *ngIf="produto.imagens && produto.imagens.length > 0">

              <i class="fe-image fa-2x" *ngIf="!produto.imagens || produto.imagens.length == 0"></i>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Codigo"   [width]="100" >
            <ng-template kendoGridCellTemplate let-produto   >
              <b>{{produto.codigoPdv || '-'}}</b>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Nome"   >
            <ng-template kendoGridCellTemplate let-produto   >
              <span class="text-blue" [ngClass]="{'removido': produto.removido}" ><b>{{produto.nome}}</b></span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Categoria"   >
            <ng-template kendoGridCellTemplate let-produto  >
              <span [ngClass]="{'removido': produto.removido}"> {{produto.categoria ? produto.categoria.nome : '-'}} </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-command-column title="" [width]="75">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-produto>

              <button kendoGridRemoveCommand kendoTooltip title="remover" [disabled]="produto.removendo"
                      *ngIf="!produto.removido"  ><i class="fa fa-trash text-danger"></i></button>



              <button class="btn btn-xs  btn-warning" *ngIf="produto.removido" [disabled]="produto.vinculando"
                      (click)="vinculeNovamenteProduto(produto)" kendoTooltip title="desfazer remoção">
                <i class="k-i-undo k-icon"></i>
              </button>

              <i class="k-i-loading k-icon" *ngIf="produto.removendo || produto.vinculando"></i>



            </ng-template>
          </kendo-grid-command-column>

        </kendo-grid>
      </ng-container>

      <ng-container *ngIf="adicionar">

        <ng-container *ngIf="totalAdicionado > 0">
          <div class="alert alert-success mt-2" role="alert">
            <i class="mdi mdi-check-all mr-2"></i> <b>{{totalAdicionado}}</b> itens(s) adicionados com sucesso!
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="mt-2">
              <button class="btn btn-blue" (click)="adicionarMaisProdutos()">
                <i class="fe-plus mr-1"></i>
                Adicionar mais
              </button>

              <button class="btn btn-secondary ml-1" (click)="volteAdicionarProdutos(true)" [disabled]="salvando">
                <i class="fe-chevron-left mr-1"></i>
                Voltar
              </button>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="!totalAdicionado">
          <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="erro">
            <i class="mdi mdi-check-all mr-2"></i> {{erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <kendo-grid #gridNovos
                      [kendoGridBinding]="produtosAdicionar" [selectable]="selectableSettings"
                      [(selectedKeys)]="idsProdutosSelecionados" kendoGridSelectBy="id" (selectedKeysChange)="onSelectedKeysChange($event)"
                      [loading]="carregando" [height]="500"

                      footerStyle="font-size: 11px;">
            <ng-template kendoGridToolbarTemplate [position]="'top'">
              <div class="row">
                <div class="col">
                  <button class="btn btn-success" (click)="salveNovosProdutos()" [disabled]="salvando || !this.idsProdutosSelecionados.length">
                    <i class="fe-save mr-1"></i>
                    Salvar ({{this.idsProdutosSelecionados.length}}) <i class="k-icon k-i-loading" *ngIf="salvando"></i>
                  </button>

                  <button class="btn btn-secondary ml-2" (click)="volteAdicionarProdutos(false)" [disabled]="salvando">
                    <i class="fe-chevron-left "></i>
                    Voltar
                  </button>

                </div>
              </div>
              <div  >
                <div style="width: 220px" >
                  <input style="width: 90%" kendoTextBox placeholder="Busque por nome do produto" class="form-control busca" [(ngModel)]="filtro.texto"
                         appAutoFocus   (input)="onFilterProdutos($event)"/>
                </div>

                <div   style="width: 190px">
                  <div class="form-group mb-0">

                    <kendo-combobox id="cboCategorias" class="form-control" [data]="empresa.catalogo.categorias" style="width: 95%"
                                    textField="nome" valueField="id" [(ngModel)]="filtro.categoria" [allowCustom]="false"
                                    (valueChange)="carregueProdutosDisponiveis()">
                    </kendo-combobox>
                  </div>

                </div>

              </div>


            </ng-template>
            <kendo-grid-messages
              pagerPage="Página"
              pagerOf="de"
              pagerItems="itens"
              noRecords="Nenhum produto disponível "
              loading="Carregando"
              pagerItemsPerPage="ítems por página"
            >
            </kendo-grid-messages>
            <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true"></kendo-grid-checkbox-column>
            <kendo-grid-column title="Imagem" class="coluna_menor" [width]="110">
              <ng-template kendoGridCellTemplate let-produto let-rowIndex="rowIndex">

                <img [src]="'/images/empresa/'+produto.imagens[0].linkImagem" style="width: 80px;" *ngIf="produto.imagens && produto.imagens.length > 0">

                <i class="fe-image fa-2x" *ngIf="!produto.imagens || produto.imagens.length == 0"></i>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Codigo"   [width]="100" >
              <ng-template kendoGridCellTemplate let-produto   >
                <b>{{produto.codigoPdv || '-'}}</b>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Nome"   >
              <ng-template kendoGridCellTemplate let-produto  >
                <span class="text-blue"><b>{{produto.nome}}</b></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Categoria"   >
              <ng-template kendoGridCellTemplate let-produto  >
                <span > {{produto.categoria ? produto.categoria.nome : '-'}} </span>
              </ng-template>
            </kendo-grid-column>


          </kendo-grid>
        </ng-container>
      </ng-container>


    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [id]="'complementos'" [title]="'Adicionais  (' + totalComplementos + ')'"      >
    <ng-template kendoTabContent>
      <ng-container *ngIf="!adicionar">
        <h5>Lista de opção de adicinais que estão vinculados ao insumo</h5>
        <kendo-grid
          [kendoGridBinding]="insumo.opcoes"   (remove)="desvinculeOpcaoInsumo($event)"
          [loading]="carregando" [height]="500"
          footerStyle="font-size: 11px;">
          <ng-template kendoGridToolbarTemplate [position]="'top'">
            <div class="row">
              <div class="col">
                <button class="btn btn-blue float-right" (click)="associarInsumoOpcoes()">
                  <i class="fe-plus mr-1"></i>
                  Adicionar Complemento
                </button>
              </div>

            </div>

          </ng-template>
          <kendo-grid-messages
            pagerPage="Página"
            pagerOf="de"
            pagerItems="itens"
            noRecords="Nenhum complemento vinculado ao insumo"
            loading="Carregando"
            pagerItemsPerPage="ítems por página"
          >
          </kendo-grid-messages>


          <kendo-grid-column title="Id"   [width]="80"  >
            <ng-template kendoGridCellTemplate let-opcao  >
              <span class="text-blue" > {{opcao.id}} </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Codigo"   [width]="100" >
            <ng-template kendoGridCellTemplate let-opcao   >
              <b>{{opcao.codigoPdv || '-'}}</b>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Nome"   >
            <ng-template kendoGridCellTemplate let-opcao   >
              <span class="text-blue" [ngClass]="{'removido': opcao.removido}" ><b>{{opcao.nome}}</b></span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Adicional"   >
            <ng-template kendoGridCellTemplate let-opcao  >
              <span [ngClass]="{'removido': opcao.removido}"> {{opcao.adicional ? opcao.adicional.nome : '-'}} </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-command-column title="" [width]="75">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-opcao>

              <button kendoGridRemoveCommand kendoTooltip title="remover" [disabled]="opcao.removendo"
                      *ngIf="!opcao.removido"  ><i class="fa fa-trash text-danger"></i></button>


              <button class="btn btn-xs btn-warning" *ngIf="opcao.removido"  (click)="vinculeNovamenteOpcao(opcao)"
                      kendoTooltip title="desfazer remoção">
                 <i class="k-i-undo k-icon"></i>
              </button>

              <i class="k-i-loading k-icon" *ngIf="opcao.removendo || opcao.vinculando"></i>
            </ng-template>
          </kendo-grid-command-column>

        </kendo-grid>
      </ng-container>

      <ng-container *ngIf="adicionar">
        <ng-container *ngIf="totalAdicionado > 0">
          <div class="alert alert-success mt-2" role="alert">
            <i class="mdi mdi-check-all mr-2"></i> <b>{{totalAdicionado}}</b> itens(s) adicionados com sucesso!
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="mt-2">
              <button class="btn btn-blue" (click)="adicionarMaisOpcoes()">
                <i class="fe-plus mr-1"></i>
                Adicionar mais
              </button>

              <button class="btn btn-secondary ml-1" (click)="volteAdicionarOpcoes(true)" [disabled]="salvando">
                <i class="fe-chevron-left mr-1"></i>
                Voltar
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!totalAdicionado">
          <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="erro">
            <i class="mdi mdi-check-all mr-2"></i> {{erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="!totalAdicionado">
          <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" *ngIf="erro">
            <i class="mdi mdi-check-all mr-2"></i> {{erro}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <kendo-grid #gridNovos
                      [kendoGridBinding]="opcoesAdicionar" [selectable]="selectableSettings"
                      [(selectedKeys)]="idsOpcoesSelecionadas"  kendoGridSelectBy="id"   (selectedKeysChange)="onSelectedKeysChangeOpcoes($event)"
                      [loading]="carregando" [height]="500"  [scrollable]="'virtual'">
            <ng-template kendoGridToolbarTemplate [position]="'top'">
              <div class=" ">
                <div >
                  <button class="btn btn-success" (click)="salveNovasOpcoes()" [disabled]="salvando || !idsOpcoesSelecionadas.length">
                    <i class="fe-save mr-1"></i>
                    Salvar ({{idsOpcoesSelecionadas.length}}) <i class="k-icon k-i-loading" *ngIf="salvando"></i>
                  </button>

                  <button class="btn btn-secondary ml-2" (click)="volteAdicionarOpcoes(false)" [disabled]="salvando">
                    <i class="fe-chevron-left "></i>
                    Voltar
                  </button>

                </div>
              </div>

              <div  >
                <div style="width: 230px" >
                  <input style="    width: 90%;" kendoTextBox placeholder="Busque por nome do complemento ou codigo" class="form-control busca" [(ngModel)]="filtro.texto"
                         appAutoFocus   (input)="onFilterOpcoes($event)"/>
                </div>

                <div  style="width: 190px">
                  <div class="input-group mt-2">
                    <input  name="porCodigo" id='porCodigo'    class="k-checkbox  " type="checkbox"
                            [(ngModel)]="filtro.porCodigo"   (ngModelChange)="alterouPorCodigo()"/>
                    <label  class="k-checkbox-label" for="porCodigo">
                      Buscar pelo <b> codigo  </b>   </label>

                  </div>
                </div>


              </div>

            </ng-template>


            <kendo-grid-messages
              pagerPage="Página"
              pagerOf="de"
              pagerItems="itens"
              noRecords="Nenhuma opçao disponível "
              loading="Carregando"
              pagerItemsPerPage="ítems por página"
            >
            </kendo-grid-messages>
            <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true"></kendo-grid-checkbox-column>


            <kendo-grid-column title="Codigo"   [width]="80"  >
              <ng-template kendoGridCellTemplate let-opcao  >
                <span > {{opcao.codigo ||  '-'}} </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Nome"   >
              <ng-template kendoGridCellTemplate let-opcao  >
                <span class="text-blue"><b>{{opcao.nome}}</b></span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Adicional"   >
              <ng-template kendoGridCellTemplate let-opcao  >
                <span class="text-blue"><b>{{opcao.nomeAdicional}}</b></span>
              </ng-template>
            </kendo-grid-column>




          </kendo-grid>
        </ng-container>
       </ng-container>

    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [id]="'compoe'" [title]="'Composições (' + totalComposicoes + ')'"      >
    <ng-template kendoTabContent>
      <h5>Lista de fichas tecnicas que o insumo compõe </h5>

      <kendo-grid [kendoGridBinding]="insumo.composicoes"
        [loading]="carregando" [height]="500">
        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="Nenhuma composição faz uso do insumo"
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>
        <kendo-grid-column title="Id"     [width]="90" >
          <ng-template kendoGridCellTemplate let-insumo let-rowIndex="rowIndex">
                   <b>{{insumo.id}}</b>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Codigo"   [width]="130" >
          <ng-template kendoGridCellTemplate let-insumo   >
            <b>{{insumo.codigo  || '-'}}</b>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Nome"   >
          <ng-template kendoGridCellTemplate let-insumo   >
            <span class="text-blue"  ><b>{{insumo.nome}}</b></span>
            <span class="badge badge-info ml-1" *ngIf="insumo.beneficiado">beneficiado</span>
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>


