import {Component, Input, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, OnChanges, SimpleChanges} from '@angular/core';
import {NgForm} from "@angular/forms";
import {TipoDeNotificacaoEnum} from "../../../../server/domain/TipoDeNotificacaoEnum";
import {NotificacoesService} from "../../services/notificacoes.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {ConstantsService} from "../ConstantsService";
import {TelaEscolherCupomCampanhaComponent} from "../../marketing/tela-escolher-cupom-campanha/tela-escolher-cupom-campanha.component";
import {DialogService} from "@progress/kendo-angular-dialog";
import {TelaEscolherDominioEmpresaComponent} from "../../marketing/tela-escolher-dominio-empresa/tela-escolher-dominio-empresa.component";
import {MensagemTextAreaUtils} from "../../lib/MensagemTextAreaUtils";
import {TemplatesService} from "../../services/templates.service";

@Component({
  selector: 'app-form-notificacao',
  templateUrl: './form-notificacao.component.html',
  styleUrls: ['./form-notificacao.component.scss']
})
export class FormNotificacaoComponent implements OnInit, OnChanges {
  NOTIFICACAO_MENSAGEM_SAUDACAO = 'Mensagem Saudação Whatsapp Pedido';

  @Input() notificacao: any = {
    menu: {
      opcoes: []
    },
    opcoes: [],
    menuAtivo: false,
    novaOpcao: {}
  };
  @Input() icone: string;
  mensagem: any = '';
  mensagemSucesso: any = '';
  erro;
  campos = MensagemTextAreaUtils.campos
  templates: any = []
  items: any;

  QTDE_MAXIMA_CARACTERES = 160;
  empresa: any;
  tempoMensagemWhatsapp = 12;

  msgOpcao = '';
  erroOpcao = '';

  @ViewChild('tituloInput') tituloInput: ElementRef;
  @ViewChild('novaOpcaoInput') novaOpcaoInput: ElementRef;
  @ViewChildren('respostaTextarea') respostaTextareas: QueryList<ElementRef>;

  editandoOpcao = false;
  opcaoEditando: number = null;
  novaOpcao = {
    texto: ''
  };

  constructor(private notificacoesService: NotificacoesService, private constantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService, private dialogService: DialogService,
              private templatesService: TemplatesService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notificacao && !changes.notificacao.firstChange) {
      this.inicializaMenu();
    }
  }

  ngOnInit() {
    this.items = [
      {label: 'Nome Contato', icon: 'pi pi-fw pi-plus'},
      {label: 'Nome Empresa', icon: 'pi pi-fw pi-download'},
      {label: 'Undo', icon: 'pi pi-fw pi-refresh'}
    ];

    this.inicializaMenu();

    this.empresa = this.autorizacaoService.getUser().empresa;

    if (this.empresa.meioDeEnvio === 'Whatsapp' || this.empresa.meioDeEnvio === 'Mock')
      this.QTDE_MAXIMA_CARACTERES = 1000;


    this.constantsService.empresa$.subscribe((empresa) => {
      if (!empresa)     return;
      this.empresa = empresa;

      if (this.empresa.configWhatsapp)
        this.tempoMensagemWhatsapp = this.empresa.configWhatsapp.tempoMsgSaudacao / (60 * 60 * 1000);


      if (this.empresa.integracaoOpendeliveryLogistica || this.empresa.integracaoUberdirect) {
        if (!this.campos.find((item: any) => item.texto === '[LinkRastreamento]')) {
          this.campos.splice(10, 0, {
                nome: 'Link Rastreamento Entrega',
                texto: '[LinkRastreamento]',
              },
          )
        }
      }
    });

    this.templatesService.daEmpresaOuDaRede().then((templates) => {
      this.templates = templates
    })

    this.alterouMensagem(this.notificacao);
  }

  private inicializaMenu() {
    // Inicializa as opções do menu se existirem
    if (this.notificacao?.menu?.opcoes?.length > 0) {
      this.notificacao.menuAtivo = true;
      this.notificacao.tituloMenu = this.notificacao.menu.titulo;
      this.notificacao.opcoes = [...this.notificacao.menu.opcoes].map(opcao => ({
        texto: opcao.texto,
        valor: opcao.valor,
        acao: opcao.acao || 'resposta',
        expanded: false
      }));
    } else {
      // Inicializa arrays vazios para evitar undefined
      this.notificacao.opcoes = [];
      this.notificacao.menu = {
        opcoes: []
      };
    }
  }

  adicioneCampo(textArea: any, notificacao: any, campo: any) {
    const valor = campo.texto || campo.nome;

    // Se for o textarea principal da mensagem
    if (!notificacao.mensagem) notificacao.mensagem = '';
    notificacao.mensagem = notificacao.mensagem + valor;

    // Força atualização do textarea e posiciona o cursor no final
    setTimeout(() => {
      if (textArea && textArea.nativeElement) {
        textArea.nativeElement.focus();
        const length = textArea.nativeElement.value.length;
        textArea.nativeElement.setSelectionRange(length, length);
      }
    });

    // Atualiza contagem de caracteres
    this.alterouMensagem(notificacao);
  }

  adicioneNomeEmpresa(notificacao: any) {
    notificacao.mensagem = '[Empresa]';
  }

  validarOpcoes(): { valido: boolean, mensagem?: string } {
    if (!this.notificacao.menuAtivo) {
      return { valido: true };
    }

    if (!this.notificacao.tituloMenu?.trim()) {
      return {
        valido: false,
        mensagem: 'O título do menu é obrigatório'
      };
    }

    if (!this.notificacao.opcoes?.length) {
      return {
        valido: false,
        mensagem: 'Adicione pelo menos uma opção ao menu'
      };
    }

    for (let i = 0; i < this.notificacao.opcoes.length; i++) {
      const opcao = this.notificacao.opcoes[i];

      if (!opcao.texto?.trim()) {
        return {
          valido: false,
          mensagem: `O texto da opção ${i + 1} é obrigatório`
        };
      }

      if (!opcao.valor?.trim()) {
        // Expande a opção com erro e força manter expandido
        this.toggleExpand(i, true);

        return {
          valido: false,
          mensagem: `A resposta da opção ${i + 1} é obrigatória`
        };
      }
    }

    return { valido: true };
  }

  async onSubmit() {
    // Limpa mensagens anteriores
    this.erro = '';
    this.notificacao.mensagemSucesso = '';

    const validacao = this.validarOpcoes();
    if (!validacao.valido) {
      this.erro = validacao.mensagem;
      return;
    }

    try {
      this.notificacao.tempoMensagemWhatsapp = this.tempoMensagemWhatsapp;

      // Garante que as opções estão no formato correto e dentro do menu
      if (this.notificacao.menuAtivo && this.notificacao.opcoes) {
        this.notificacao.menu = {
          titulo: this.notificacao.tituloMenu?.trim(),
          opcoes: this.notificacao.opcoes.map(opcao => ({
            texto: opcao.texto?.trim(),
            valor: opcao.valor?.trim(),
            acao: 'resposta'
          }))
        };
      } else {
        this.notificacao.menu = null;
      }

      await this.notificacoesService.salveNotificacao(this.notificacao);
      this.notificacao.mensagemSucesso = 'Notificação salva com sucesso!';
    } catch (error) {
      this.erro = error.message;
    }
  }

  fecheMensagemSucesso() {
    this.mensagemSucesso = '';
  }

  informarQtdeDiasAtiva(notificacao: any) {
    return notificacao.tipoDeNotificacao === TipoDeNotificacaoEnum.SentimosSuaFalta ||
        notificacao.tipoDeNotificacao === TipoDeNotificacaoEnum.PontosExpirar ||
        notificacao.tipoDeNotificacao === TipoDeNotificacaoEnum.ClientePerdido
  }

  alterouMensagem(notificacao: any) {
    // tslint:disable-next-line:max-line-length
    const expressaoRegularURL = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&z\/\/=]*)/g;

    let TAMANHO_URL_ENCURTADA = 20;
    let qtdARemover = 0;
    let qtdAAdicionar = 0;

    //tratando links detectados
    const urls = notificacao.mensagem.match(expressaoRegularURL);

    if (urls) {
      for (let i = 0; i < urls.length; i++)
        qtdARemover += urls[i].length;

      qtdAAdicionar = urls.length * TAMANHO_URL_ENCURTADA;

      console.log("Qtd a remover: " + qtdARemover);
      console.log("Qtd a adicionar: " + qtdAAdicionar);
    }

    //tratando campos detectados
    const reg = /\[(.*?)\]/g;

    let result;
    while ((result = reg.exec(notificacao.mensagem)) !== null) {
      const campo: string = result[0];

      if (campo === '[NomeContato]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if (campo === '[LinkCartao]') {
        qtdARemover += campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if (campo === '[LinkPedido]') {
        qtdARemover += campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      } else if (campo === '[Empresa]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if (campo === '[CODIGO]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 15;
      } else if (campo === '[SALDO]') {
        qtdARemover += campo.length;
        qtdAAdicionar += 10;
      } else if (campo === '[LinkAtivarCartao]') {
        qtdARemover += campo.length;
        qtdAAdicionar += TAMANHO_URL_ENCURTADA;
      }
    }

    notificacao.qtdeCaracteresRestantes = this.QTDE_MAXIMA_CARACTERES - notificacao.mensagem.length + qtdARemover - qtdAAdicionar;
  }

  adicionarOpcaoRapida(texto: string) {
    if (!texto?.trim()) {
      return; // Não adiciona opção vazia
    }

    if (!this.notificacao.opcoes) {
      this.notificacao.opcoes = [];
    }

    const index = this.notificacao.opcoes.length;

    this.notificacao.opcoes.push({
      texto,
      acao: 'resposta',
      valor: '',
      expanded: true
    });

    // Foca na resposta da nova opção
    this.focarResposta(index);
  }

  removerOpcao(index: number) {
    this.notificacao.opcoes.splice(index, 1);
  }

  focarNovaOpcao() {
    setTimeout(() => {
      this.novaOpcaoInput?.nativeElement?.focus();
    });
  }

  editarOpcao(index: number) {
    this.editandoOpcao = true;
    this.opcaoEditando = index;
  }

  fecharEditor() {
    this.editandoOpcao = false;
    this.opcaoEditando = null;
    this.focarNovaOpcao();
  }

  getPlaceholder(acao: string) {
    switch (acao) {
      case 'link': return 'https://...';
      case 'resposta': return 'Mensagem de resposta...';
      case 'telefone': return '+55...';
      default: return '';
    }
  }

  toggleExpand(index: number, forceExpand?: boolean) {
    // Fecha todos os outros
    this.notificacao.opcoes.forEach((opcao: any, i: number) => {
      if (i !== index) {
        opcao.expanded = false;
      }
    });

    // Se forceExpand for true, sempre expande
    if (forceExpand) {
      this.notificacao.opcoes[index].expanded = true;
      return;
    }

    // Toggle atual
    this.notificacao.opcoes[index].expanded = !this.notificacao.opcoes[index].expanded;
  }

  expandUltimaOpcao() {
    if (this.notificacao.opcoes?.length) {
      const ultimoIndex = this.notificacao.opcoes.length - 1;
      // Expande só o último
      this.notificacao.opcoes.forEach((opcao: any, i: number) => {
        opcao.expanded = (i === ultimoIndex);
      });
    }
  }

  aplicarTemplate(tipo: string) {
    if (!this.notificacao.opcoes) {
      this.notificacao.opcoes = [];
    }

    switch (tipo) {
      case 'cardapio':
        this.notificacao.tituloMenu = 'Como posso ajudar?';
        this.adicionarOpcaoRapida('Ver Cardápio');
        this.adicionarOpcaoRapida('Fazer Pedido');
        this.adicionarOpcaoRapida('Falar com Atendente');
        break;

      case 'fidelidade':
        this.notificacao.tituloMenu = 'Programa de Fidelidade';
        this.adicionarOpcaoRapida('Ver Meus Pontos');
        this.adicionarOpcaoRapida('Resgatar Prêmio');
        this.adicionarOpcaoRapida('Ver Regulamento');
        break;

      case 'pedido':
        this.notificacao.tituloMenu = 'Acompanhe seu Pedido';
        this.adicionarOpcaoRapida('Rastrear Pedido');
        this.adicionarOpcaoRapida('Ver Status');
        this.adicionarOpcaoRapida('Falar com Suporte');
        break;

      case 'atendimento':
        this.notificacao.tituloMenu = 'Como podemos ajudar?';
        this.adicionarOpcaoRapida('Dúvidas Frequentes');
        this.adicionarOpcaoRapida('Fazer Sugestão');
        this.adicionarOpcaoRapida('Fazer Reclamação');
        break;
    }
  }

  focarResposta(index: number) {
    // Expande a opção
    this.toggleExpand(index);

    // Aguarda a expansão e o DOM atualizar
    setTimeout(() => {
      // Pega todos os textareas
      const textareas = this.respostaTextareas.toArray();
      // Foca no textarea correspondente ao índice
      if (textareas[index]) {
        textareas[index].nativeElement.focus();
        // Posiciona o cursor no final do texto
        const textarea = textareas[index].nativeElement;
        const length = textarea.value.length;
        textarea.setSelectionRange(length, length);
      }
    }, 150); // Aumentei um pouco mais o timeout
  }

}
