import {Component, Input, OnInit} from '@angular/core';
import {UsuarioService} from "../../services/usuario.service";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {CaixaService} from "../services/caixa.service";
import {ProcessadorReciboTexto} from "../../services/impressao/ProcessadorReciboTexto";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {ProcessadorCaixaTexto} from "../../services/impressao/ProcessadorCaixaTexto";
import {ImpressaoService} from "../../services/impressao.service";

@Component({
  selector: 'app-gestao-de-caixa',
  templateUrl: './gestao-de-caixa.component.html',
  styleUrls: ['./gestao-de-caixa.component.scss']
})
export class GestaoDeCaixaComponent implements OnInit {
  abrirDialog = false;
  usuarioLogado: any;
  hora: Date;
  saldoInicial: number;
  totalEntradasDinheiro = 100;
  totalEntradas = 100;
  totalSaidasDinheiro = 50
  ;
  saldoFinalDinheiro = 50;
  saldoFinal = 50;
  caixa: any;
  resumo: any;
  mensagemErro: string;
   carregando: boolean;
  fecharDialog: any;
  saldoConferido: number;
  mensagemErroFechamento: any;
  sangriaDialog: any;
  valorSangria: any;
  justificativaSangria: any;
  mensagemErroSangria: any;
  mensagemAdvertenciaFechamento: any;
  empresa: any;
  configImpressao: any;

  @Input() online: boolean
  abrindoCaixa: any;
   adicionarSaldoDialog: any;
  valorAdicaoSaldo: any;
  mensagemErroAdicaoSaldo: any;


  constructor(private autorizacaoService: AutorizacaoService, private constantsService: ConstantsService,
              private impressaoService: ImpressaoService, private caixaService: CaixaService) {
    this.usuarioLogado = this.autorizacaoService.getUser();
    this.carregando = true;

    this.constantsService.empresa$.subscribe( (empresa: any) => {
      if(empresa) {
        this.empresa = empresa
        this.configImpressao = this.empresa.configImpressao;
      }
    })
  }

  ngOnInit(): void {
    this.caixaService.obtenhaCaixaAtual(this.online).then((resposta: any) => {
      this.carregando = false;
      this.caixa = resposta.caixa;
      this.resumo = resposta.resumo;
      this.resumo.horaAbertura = new Date(this.resumo.horaAbertura)
      if(this.resumo.horaFechamento)
        this.resumo.horaFechamento = new Date(this.resumo.horaFechamento)

    });
  }

  abrirCaixa() {
    this.hora = new Date();
    this.abrirDialog = true;

  }

  cancelar() {
    this.abrirDialog = false;
  }

  confirmar() {
    this.abrindoCaixa = true
    this.caixaService.abraCaixa(this.saldoInicial).then((resposta: any) => {
      this.abrindoCaixa = false
      this.abrirDialog = false;
      this.caixa = resposta.caixa;
      this.resumo = resposta.resumo
      console.log(this.caixa)
    }).catch((erro: any) => {
      this.abrindoCaixa = false
      console.log(erro)
      this.mensagemErro = erro
    })
  }



  fecharCaixa() {

    this.hora = new Date()
    this.caixaService.podeFecharCaixa().then((resposta: any) => {
      if(resposta.temErro) {
        this.mensagemErroFechamento = resposta.mensagem
      }

      if(resposta.temAdvertencia) {
        this.mensagemAdvertenciaFechamento = resposta.mensagem
      }
      this.fecharDialog = true;
    })

  }

  imprimirResumo() {
    this.imprimaNativamente(this.configImpressao.impressoras[0])
  }

  private async imprimaNativamente(impressora: any) {
    if(!impressora) impressora = this.configImpressao.impressoras[0]

    let relatorio: any = new ProcessadorCaixaTexto(impressora).obtenhaResumoCaixa(this.resumo)
    console.log(relatorio)
    this.impressaoService.imprimaTexto(relatorio, impressora)
  }


  cancelarFechamento() {

  }

  confirmarFechamento() {
    //fecha o caixa no servidor
    this.caixaService.fecheCaixa(this.caixa, this.saldoConferido).then((resposta: any) => {
      this.fecharDialog = false;
      this.caixa = null
      this.resumo = null
    }).catch(
      (erro: any) => {
        this.mensagemErroFechamento = erro
      }
    )
  }

  confirmarEImprimirRelatorio() {
    this.caixaService.fecheCaixa(this.caixa, this.saldoConferido).then((resumo: any) => {
      if(resumo.status = 'FECHADO') {
        this.resumo = resumo
        this.resumo.horaAbertura = new Date(this.resumo.horaAbertura)
        if(this.resumo.horaFechamento)
          this.resumo.horaFechamento = new Date(this.resumo.horaFechamento)

        this.imprimaNativamente(this.configImpressao.impressoras[0])
        this.fecharDialog = false;
        this.caixa = null
        this.resumo = null

      }
    }).catch(
      (erro: any) => {
        this.mensagemErroFechamento = erro
      }
    )
  }

  adicionarSaldo() {
    this.adicionarSaldoDialog = true;
  }


  realizarSangria() {
    this.sangriaDialog = true;
  }

  cancelarSangria() {
    this.sangriaDialog = false;
  }

  confirmarSangria() {
    this.caixaService.sangreCaixa(this.valorSangria, this.justificativaSangria).then((resposta: any) => {
      this.sangriaDialog = false;
      this.caixa = resposta.caixa;
      this.resumo = resposta.resumo;
    }).catch((erro: any) => {
      this.mensagemErroSangria = erro
    })
  }

  confirmarEImprimirSangria() {

  }

  cancelarAdicaoSaldo() {
    this.adicionarSaldoDialog = false;

  }

  confirmarAdicaoSaldo() {
    this.caixaService.adicioneDinheiro(this.valorAdicaoSaldo).then((resposta: any) => {
      this.adicionarSaldoDialog = false;
      this.caixa = resposta.caixa;
      this.resumo = resposta.resumo;
    }).catch((erro: any) => {
      this.mensagemErroAdicaoSaldo = erro
    })
  }
}
