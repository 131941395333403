import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemaPersonalizadoService } from '../../services/tema-personalizado.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from '../../fidelidade/ConstantsService';
import { AutorizacaoService } from '../../services/autorizacao.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { PaletaCoresService } from '../../services/paleta-cores.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-tela-criar-tema-personalizado',
  templateUrl: './tela-criar-tema-personalizado.component.html',
  styleUrls: ['./tela-criar-tema-personalizado.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)', boxShadow: '0 0 0 rgba(33, 150, 243, 0)' }),
        animate('200ms ease-out',
          style({ opacity: 1, transform: 'translateY(0)', boxShadow: '0 8px 24px rgba(33, 150, 243, 0.25)' }))
      ]),
      transition(':leave', [
        animate('150ms ease-in',
          style({ opacity: 0, transform: 'translateY(-10px)', boxShadow: '0 0 0 rgba(33, 150, 243, 0)' }))
      ])
    ])
  ]
})
export class TelaCriarTemaPersonalizadoComponent implements OnInit, AfterViewInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  temaForm: FormGroup;
  salvando = false;
  mensagemSucesso: string;
  mensagemErro: string;
  idTema = '';
  gerandoPaleta = false;
  formSubmitted = false;

  // Propriedades para upload de imagem
  imagemSelecionada: File = null;
  previewImagemUrl: SafeUrl = null;
  uploadandoImagem = false;
  mostrarUploadImagem = false;

  // Propriedades para paleta do logo
  mostrarPaletaLogo = false;
  logoEmpresa: string = null;
  logoUrl: SafeUrl = null;

  corDoSite = '#FFFFFF';
  logoPath = '';
  empresa: any;
  // Referências para os cards de paleta
  @ViewChild('cardGeracaoPaleta') cardGeracaoPaleta: ElementRef;
  @ViewChild('cardUploadImagem') cardUploadImagem: ElementRef;
  @ViewChild('cardPaletaLogo') cardPaletaLogo: ElementRef;

  constructor(
    private fb: FormBuilder,
    private temaService: TemaPersonalizadoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private constantsService: ConstantsService,
    private autorizacaoService: AutorizacaoService,
    private sanitizer: DomSanitizer,
    private paletaCoresService: PaletaCoresService,
    private renderer: Renderer2
  ) {
    this.constantsService.empresa$.subscribe( (empresa ) => {
      if( !empresa ) {
        return;
      }

      this.empresa = empresa;
    });
  }

  corFundoDoSite = '#FFFFFF';
  dominioEmpresa: string;
  urlSegura: SafeResourceUrl;
  isDevelopment = !environment.production;
  mostrarGeracaoPaleta = false;
  descricaoPaleta = ''; // Descrição para orientar a geração da paleta
  carregandoIframe = false; // Controla o efeito de carregamento sobre o iframe

  ngOnInit(): void {
    if (this.isDevelopment) {
      console.log('Executando em modo de desenvolvimento - usando URL: https://localhost:8443');
    }

    this.temaForm = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      corFundoDoSite: ['#FFFFFF', Validators.required],
      corFundoElementos: ['#F8F9FA', Validators.required],
      corFundo: ['#FFFFFF', Validators.required],
      corTextoFundo: ['#000000', Validators.required],
      corTextoTopo: ['#000000', Validators.required],
      corTextoPrimaria: ['#000000', Validators.required],
      corTextoSecundaria: ['#6C757D', Validators.required],
      corBotao: ['#4a81d4', Validators.required],
      corTextoBotao: ['#FFFFFF', Validators.required],
      corPrecoAdicional: ['#28a745', Validators.required],
      corTextoPrecoAdicional: ['#FFFFFF', Validators.required],
      corDestaque: ['#FF9800', Validators.required],
      corHover: ['#007BFF', Validators.required],
      corBorda: ['#DEE2E6', Validators.required],
      corPreco: ['#28a745', Validators.required],
      corTexto: ['#FFFFFF', Validators.required],
      corTextoRodape: ['#FFFFFF', Validators.required],
      corFundoRodape: ['#FFFFFF', Validators.required],
      corBordaRodape: ['#DEE2E6', Validators.required],
      corItemAtivoRodape: ['#FF9800', Validators.required]
    });

    this.activatedRoute.params.subscribe(params => {
      this.idTema = params['id'];
      if (this.idTema) {
        this.carregarTema(this.idTema);
      }
    });

    // Observar mudanças nas cores para atualizar as visualizações
    this.observarMudancasCores();

    // Verificar se o usuário está autenticado
    this.autorizacaoService.carregueUsuario().subscribe(usuario => {
      if (usuario) {
        this.constantsService.empresa$.subscribe(empresa => {
          if (empresa) {
            this.dominioEmpresa = this.getBaseUrl(empresa.dominio);
            this.urlSegura = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.dominioEmpresa}/loja?mobile=true`);

            // Obter o logo da empresa
            if (empresa.logo) {
              this.logoEmpresa = empresa.logo;
              this.logoPath = `${this.getBaseUrl(empresa.dominio, true)}/images/empresa/${empresa.logo}`;
              this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(this.logoPath);
            }
          }
        });
      }

    });
  }

  observarMudancasCores(): void {
    // Lista de propriedades de cores para observar
    const propriedadesCores = [
      'corFundoDoSite', 'corFundoElementos', 'corFundo', 'corTextoFundo',
      'corTextoPrimaria', 'corTextoSecundaria', 'corBotao', 'corTextoBotao',
      'corPrecoAdicional', 'corDestaque', 'corHover', 'corBorda', 'corPreco',
      'corTextoRodape', 'corFundoRodape', 'corBordaRodape', 'corTextoTopo', 'corItemAtivoRodape'
    ];

    // Observar cada propriedade
    propriedadesCores.forEach(prop => {
      this.temaForm.get(prop)?.valueChanges.subscribe(valor => {
        // Processamento de mudanças específicas
        this.processarMudancaDeCor(prop, valor);
      });
    });
  }

  // Método para processar mudanças de cores com tratamento de erros
  processarMudancaDeCor(propriedade: string, valor: string): void {
    setTimeout(() => {
      // Aplicar diretamente em todos os iframes
      const iframeIds = ['mobile-content-home'];

      iframeIds.forEach(id => {
        const iframe = document.getElementById(id) as HTMLIFrameElement;
        if (!iframe) return;

        try {
          // Preparar mensagem com todos os valores atuais do tema
          const mensagem = {
            tipo: 'atualizacao-tema',
            propriedade: propriedade,
            valor: valor,
            todosValores: this.temaForm.value
          };

          // Enviar mensagem ao iframe
          const origemIframe = new URL(iframe.src).origin;
          iframe.contentWindow?.postMessage(mensagem, origemIframe);

        } catch (e) {
          console.log(`Erro ao enviar mensagem para iframe ${id}:`, e);
        }
      });
    }, 100);
  }

  carregarTema(id: string) {
    this.temaService.obtenha(id).then(tema => {
      if (tema) {
        this.temaForm.patchValue(tema);
      }
    }).catch(erro => {
      console.error('Erro ao carregar tema:', erro);
      this.mensagemErro = 'Erro ao carregar tema. Por favor, tente novamente.';
    });
  }

  /**
   * Valida o formulário e exibe mensagens de erro específicas
   */
  validarFormulario(): boolean {
    this.mensagemErro = '';

    // Verificar campo nome
    if (!this.temaForm.get('nome')?.value) {
      this.mensagemErro = 'Por favor, informe um nome para o tema.';
      return false;
    }

    // Verificar se todas as cores foram preenchidas
    const camposCores = [
      'corFundoDoSite', 'corFundoElementos', 'corFundo', 'corTextoFundo',
      'corTextoTopo', 'corTextoPrimaria', 'corTextoSecundaria', 'corBotao',
      'corTextoBotao', 'corPrecoAdicional', 'corTextoPrecoAdicional', 'corDestaque', 'corHover',
      'corBorda', 'corPreco', 'corTextoRodape', 'corFundoRodape', 'corBordaRodape', 'corItemAtivoRodape'
    ];

    for (const campo of camposCores) {
      if (!this.temaForm.get(campo)?.value) {
        this.mensagemErro = `Por favor, preencha a cor "${campo.replace('cor', '')}".`;
        return false;
      }
    }

    return true;
  }

  /**
   * Salva o tema personalizado
   */
  salvarTema() {
    this.formSubmitted = true;

    // Verificar se o formulário é válido
    if (this.temaForm.invalid) {
      // Rolar para o topo para mostrar os erros
      window.scrollTo(0, 0);
      this.mensagemErro = 'Por favor, corrija os erros no formulário antes de salvar.';
      return;
    }

    // Validar o formulário antes de salvar
    if (!this.validarFormulario()) {
      // Exibir mensagem de erro e não prosseguir
      return;
    }

    this.salvando = true;
    this.mensagemSucesso = '';
    this.mensagemErro = '';

    this.temaService.crieTema(this.temaForm.value)
      .then(() => {
        ///this.salvando = false;
        if (this.idTema) {
          this.mensagemSucesso = 'Tema personalizado atualizado com sucesso!';
        } else {
          this.mensagemSucesso = 'Tema personalizado criado com sucesso!';
        }
        setTimeout(() => {
          this.router.navigate(['/admin/temas']);
        }, 2000);
      })
      .catch((erro) => {
        this.salvando = false;
        this.mensagemErro = 'Erro ao criar tema personalizado: ' + erro;
      });
  }

  cancelar() {
    this.router.navigate(['/admin/temas']);
  }

  ngAfterViewInit(): void {
  }

  voltar() {
    this.router.navigateByUrl('/admin/temas');
  }

  /**
   * Alterna a visibilidade da seção de geração de paleta
   */
  toggleGeracaoPaleta() {
    this.mostrarGeracaoPaleta = !this.mostrarGeracaoPaleta;
    this.mostrarUploadImagem = false;
    this.mostrarPaletaLogo = false;

    // Se estiver mostrando o card, fazer scroll até ele após um pequeno delay
    // para garantir que o DOM foi atualizado
    if (this.mostrarGeracaoPaleta) {
      setTimeout(() => {
        this.scrollToElement('cardGeracaoPaleta');
      }, 100);
    }
  }

  /**
   * Alterna a visibilidade da seção de upload de imagem
   */
  toggleUploadImagem() {
    this.mostrarUploadImagem = !this.mostrarUploadImagem;
    this.mostrarGeracaoPaleta = false;
    this.mostrarPaletaLogo = false;

    // Se estiver mostrando o card, fazer scroll até ele após um pequeno delay
    if (this.mostrarUploadImagem) {
      setTimeout(() => {
        this.scrollToElement('cardUploadImagem');
      }, 100);
    }
  }

  /**
   * Alterna a visibilidade da seção de paleta do logo
   */
  togglePaletaLogo() {
    this.mostrarPaletaLogo = !this.mostrarPaletaLogo;
    this.mostrarGeracaoPaleta = false;
    this.mostrarUploadImagem = false;

    // Se estiver mostrando o card, fazer scroll até ele
    if (this.mostrarPaletaLogo && this.logoEmpresa) {
      // Chamar diretamente sem setTimeout adicional, pois já temos um na função scrollToElement
      this.scrollToElement('cardPaletaLogo');
    }
  }

  /**
   * Manipula a seleção de arquivo de imagem
   */
  onImagemSelecionada(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.imagemSelecionada = input.files[0];

      // Criar URL para preview da imagem
      const reader = new FileReader();
      reader.onload = () => {
        this.previewImagemUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      };
      reader.readAsDataURL(this.imagemSelecionada);
    }
  }

  /**
   * Limpa a imagem selecionada
   */
  limparImagem() {
    this.imagemSelecionada = null;
    this.previewImagemUrl = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * Gera uma paleta de cores a partir da imagem selecionada
   */
  gerarPaletaDeImagem() {
    if (!this.imagemSelecionada) {
      this.mensagemErro = 'Por favor, selecione uma imagem para gerar a paleta de cores.';
      return;
    }

    this.uploadandoImagem = true;
    this.gerandoPaleta = true;
    this.carregandoIframe = true;
    this.mensagemErro = '';
    this.mensagemSucesso = '';

    console.log('Gerando paleta a partir da imagem:', this.imagemSelecionada.name);
    console.log('Descrição para orientação:', this.descricaoPaleta || 'Nenhuma descrição fornecida');

    this.paletaCoresService.gerePaletaDeImagem(this.imagemSelecionada, this.descricaoPaleta)
      .then((paleta) => {
        // Atualizar o formulário com as cores geradas
        this.temaForm.patchValue(paleta);

        this.mensagemSucesso = 'Paleta de cores gerada com sucesso a partir da imagem!';
        this.uploadandoImagem = false;
        this.gerandoPaleta = false;
        this.carregandoIframe = false;
      })
      .catch((erro) => {
        console.error('Erro ao gerar paleta de cores da imagem:', erro);
        this.mensagemErro = 'Erro ao gerar paleta de cores: ' + erro;
        this.uploadandoImagem = false;
        this.gerandoPaleta = false;
        this.carregandoIframe = false;
      });
  }



  /**
   * Gera uma paleta de cores a partir do logo da empresa
   */
  gerarPaletaDoLogo() {
    if (!this.logoEmpresa) {
      this.mensagemErro = 'Logo da empresa não encontrado.';
      return;
    }

    this.gerandoPaleta = true;
    this.carregandoIframe = true;
    this.mensagemErro = '';
    this.mensagemSucesso = '';

    console.log('Descrição para orientação:', this.descricaoPaleta || 'Nenhuma descrição fornecida');

    // Enviar a URL do logo diretamente para o serviço de paleta de cores
    this.paletaCoresService.gerePaletaDeImagemUrl(this.logoPath, this.descricaoPaleta)
      .then((paleta) => {
        // Atualizar o formulário com as cores geradas
        this.temaForm.patchValue(paleta);

        this.mensagemSucesso = 'Paleta de cores gerada com sucesso a partir do logo!';
        this.gerandoPaleta = false;
        this.carregandoIframe = false;
      })
      .catch((erro) => {
        console.error('Erro ao gerar paleta de cores do logo:', erro);
        this.mensagemErro = 'Erro ao gerar paleta de cores: ' + erro;
        this.gerandoPaleta = false;
        this.carregandoIframe = false;
      });
  }

  /**
   * Gera uma paleta de cores baseada na cor selecionada usando IA
   */
  gerarPaletaDeCores() {
    // Usar a cor do tema como cor base padrão
    const corBase = this.temaForm.get('corFundo')?.value || '#1976D2';

    this.gerandoPaleta = true;
    this.carregandoIframe = true; // Ativa o efeito de carregamento sobre o iframe
    this.mensagemErro = '';
    this.mensagemSucesso = '';

    // Usar a descrição fornecida pelo usuário para orientar a geração da paleta
    console.log('Gerando paleta com cor base:', corBase);
    console.log('Descrição para orientação:', this.descricaoPaleta || 'Nenhuma descrição fornecida');

    this.paletaCoresService.gerePaletaDeCores(corBase, this.descricaoPaleta)
      .then((paleta) => {
        // Atualizar o formulário com as cores geradas
        this.temaForm.patchValue(paleta);

        this.mensagemSucesso = 'Paleta de cores gerada com sucesso!';
        this.gerandoPaleta = false;
        this.carregandoIframe = false; // Desativa o efeito de carregamento
      })
      .catch((erro) => {
        console.error('Erro ao gerar paleta de cores:', erro);
        this.mensagemErro = 'Erro ao gerar paleta de cores: ' + erro;
        this.gerandoPaleta = false;
        this.carregandoIframe = false; // Desativa o efeito de carregamento mesmo em caso de erro
      });
  }

  /**
   * Gera uma paleta rápida com base no método ativo (paleta automática, imagem ou logo)
   */
  gerarPaletaRapida() {
    // Verificar qual método de geração está ativo e chamar o método correspondente
    if (this.mostrarGeracaoPaleta) {
      // Gerar paleta automática
      this.gerarPaletaDeCores();
    } else if (this.mostrarUploadImagem && this.imagemSelecionada) {
      // Gerar paleta a partir da imagem
      this.gerarPaletaDeImagem();
    } else if (this.mostrarPaletaLogo && this.logoEmpresa) {
      // Gerar paleta a partir do logo
      this.gerarPaletaDoLogo();
    } else {
      // Caso nenhum método esteja ativo ou pronto para uso
      this.mensagemErro = 'Selecione um método de geração de paleta primeiro';
    }
  }

  /**
   * Retorna a URL base de acordo com o ambiente (desenvolvimento ou produção)
   * @param dominio Domínio da empresa (opcional)
   */
  getBaseUrl(dominio?: string, server = false): string {
    if (this.isDevelopment && !server) {
      return 'https://localhost:8443';
    } else {
      // Se um domínio for fornecido, use-o; caso contrário, use uma string vazia
      const empresaDominio = dominio || '';
      return `https://${empresaDominio}.meucardapio.ai`;
    }
  }

  /**
   * Faz scroll até o elemento especificado
   * @param elementId ID do elemento para scroll
   */
  scrollToElement(elementId: string): void {
    // Usar um setTimeout maior para garantir que o elemento esteja renderizado
    setTimeout(() => {
      // Obter o elemento pelo ID
      const element = document.getElementById(elementId);
      if (element) {
        console.log(`Scrolling to element: ${elementId}`);
        // Calcular a posição do elemento
        const rect = element.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // Calcular a posição de destino (um pouco acima do elemento para melhor visualização)
        const scrollTo = rect.top + scrollTop - 100; // 100px acima do elemento

        // Fazer o scroll suave
        window.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        });

        // Adicionar um destaque temporário para chamar atenção
        this.highlightElement(element);
      } else {
        console.warn(`Element with ID ${elementId} not found`);
      }
    }, 300); // Aumentar o delay para 300ms
  }

  /**
   * Adiciona um efeito de destaque temporário ao elemento
   * @param element Elemento a ser destacado
   */
  highlightElement(element: HTMLElement): void {
    // Adicionar classe de destaque
    this.renderer.addClass(element, 'highlight-card');

    // Remover a classe após 1.5 segundos
    setTimeout(() => {
      this.renderer.removeClass(element, 'highlight-card');
    }, 1500);
  }
}
