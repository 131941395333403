<kendo-dialog-titlebar (close)="fecheModal()"  >
  <h4 class="modal-title">
    <img src="assets/fidelidade/icones/novoselo.svg" style="height: 32px;" loading="lazy"/>
    <ng-container *ngIf="produto.id"> Produto {{produto.id}} - {{produto.nome}}</ng-container>
    <ng-container *ngIf="!produto.id"> Novo Produto </ng-container>
  </h4>
</kendo-dialog-titlebar>
<div   class="modal-body">
  <div style="text-align: center;" *ngIf="carregandoProduto">
    <i class="k-icon k-i-loading mr-1 font-22"></i>
    <p class="mt-2">
      Carregando dados do Produto
    </p>
  </div>

  <kendo-tabstrip class="nav-bordered mobile-tabs" (tabSelect)="limpeMensagens()" #tabSet
                  *ngIf="!carregandoProduto" [scrollable]="true">
    <kendo-tabstrip-tab [title]="'Template'" *ngIf="templatesPiza.length && !tipoDeProduto" [selected]="true">
      <ng-template kendoTabContent>
        <h4 class="mobile-title">Qual tipo de produto: </h4>
        <div class="row mobile-buttons">
          <div class="col-12 col-sm-6 mt-2">
            <button class="btn btn-outline-blue btn-block" (click)="escolheuTemplate(TIPOSDEPRODUTOS.Normal)">
              <i class="fas fa-box mr-2"></i>Produto normal
            </button>
          </div>
          <div class="col-12 col-sm-6 mt-2" *ngFor="let template of templatesPiza; trackBy: trackByFn">
            <button class="btn btn-outline-primary btn-block" (click)="escolheuTemplate(TIPOSDEPRODUTOS.Pizza, template)">
              <i class="fas fa-pizza-slice mr-2"></i>Sabor de {{template.nome}}
            </button>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <ng-container *ngIf="!templatesPiza.length || tipoDeProduto">
      <kendo-tabstrip-tab [title]="'Detalhes'" [selected]="true">
        <ng-template kendoTabContent>
          <ng-container *ngIf="tipoDeProduto !== TIPOSDEPRODUTOS.Pizza">
            <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                  novalidate #frm="ngForm" (ngSubmit)="onSalvarProduto()" >

              <div class="row mobile-form">
                <div class="col-12 foto-produto">
                  <h5 class="upload-title">Imagens do Produto</h5>
                  <app-upload-multiplas-imagens
                    [objeto]="produto"
                    [sm]="true"
                    [maxsize]="800"
                    prop="imagens"
                    (onSalvar)="salveImagensProduto($event)">
                  </app-upload-multiplas-imagens>
                </div>
                <div class="col-12 produto-info">
                  <div class="row">
                    <div class="form-group mb-3 col-12 col-sm-8">
                      <label for="nome">Nome do Produto</label>
                      <input type="text" class="form-control" autocomplete="off" [disabled]="!podeEditarProdutos"
                             id="nome" name="nome" [(ngModel)]="produto.nome" #nome="ngModel"
                             placeholder="Nome do Produto" value="" required appAutoFocus [autoFocus]="true">
                      <div class="invalid-feedback">
                        <p *ngIf="nome.errors?.required">Nome é obrigatório</p>
                      </div>
                    </div>

                    <div class="form-group mb-3 col-12 col-sm-4">
                      <label>Código
                        <ng-container *ngIf="empresa?.integracaoDelivery">PDV</ng-container>
                      </label>
                      <input type="text" class="form-control" autocomplete="off" [disabled]="!podeEditarProdutos"
                             name="codigoPdv" [(ngModel)]="produto.codigoPdv" #codigoPdv="ngModel"
                             placeholder="Cod. PDV" [required]="integradoComEcletica()">
                    </div>
                  </div>

                  <div class="row"  *ngIf="ehBrindeFidelidade()" >
                    <div class="form-group col-4  mb-3">
                      <label for="valorEmPontos">Valor em <b>{{tipoDeAcumulo}}</b> para o resgate</label>
                      <input type="text"  id="valorEmPontos" name="valorEmPontos"  class="form-control" autocomplete="off"
                             [(ngModel)]="produto.valorResgate"       placeholder="Valor em pontos"   required    >
                      <div class="invalid-feedback">
                        <p *ngIf="nome.errors?.required">Valor é obrigatório</p>
                      </div>
                    </div>

                    <div class="form-group  col-4 "   >
                      <label class="k-checkbox-label mt-3">
                        <input type="checkbox"   [(ngModel)]="produto.gerarCupom"    name="gerarCupom" class="k-checkbox " kendoCheckBox
                           (change)="carregueCupons()"/>
                          Aplicar um cupom de desconto
                      </label>
                    </div>

                    <div class="form-group col-4" *ngIf="produto.gerarCupom">
                      <label for="categoria">Cupom que será aplicado</label>

                      <kendo-dropdownlist  name="cupom" [(ngModel)]="produto.cupom" [data]="cupons" [filterable]="true"
                                           placeholder="Selecione um cupom" class="form-control" [textField]="'nome'"
                                           (filterChange)="handleFilterCupom($event)" required>
                      </kendo-dropdownlist>

                    </div>

                  </div>
                  <div class="row"  *ngIf="!ehBrindeFidelidade()" >
                    <div class="form-group  col-6 col-sm-4" *ngIf="(catalogo && !catalogo.precoPorEmpresa) || (!empresa || !empresa.id) ">
                      <label for="valorPorPonto">Preço</label>
                      <kendo-numerictextbox id="valorPorPonto" [min]="0"  class="form-control" name="preco" #preco=ngModel
                                            [disabled]="!podeEditarProdutos"
                                            [format]="obtenhaFormatoPreco()"   [(ngModel)]="produto.preco" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="preco.errors?.required">Preço é obrigatório</p>
                      </div>
                    </div>

                    <div class="form-group mb-3 col-4 ">
                      <label>Vendido por </label>

                      <kendo-dropdownlist  name="grandeza" [(ngModel)]="produto.tipoDeVenda" [data]="tiposDeVenda"
                                           [disabled]="!podeEditarProdutos"
                                           class="form-control">
                      </kendo-dropdownlist>

                    </div>


                    <div class="form-group  col-6 col-sm-4"  *ngIf="catalogo && catalogo.precoPorEmpresa && empresa && empresa.id">
                      <label for="valorPorEmpresa">Preço</label>
                      <kendo-numerictextbox id="valorPorEmpresa"
                                            [disabled]="!podeEditarPrecos"

                                            [min]="0"  class="form-control" name="precoPorEmpresa" #precoPorEmpresa=ngModel
                                            [format]="obtenhaFormatoPreco()"   [(ngModel)]="produto.precoNaEmpresa" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="catalogo && catalogo.precoPorEmpresa && precoPorEmpresa.errors?.required">Preço é obrigatório</p>
                      </div>
                    </div>

                    <div class="form-group col-6 col-sm-4"  *ngIf="integradoComEcletica() && false">
                      <label  >SKU</label>
                      <input type="text" class="form-control" autocomplete="off"
                             name="sku" [(ngModel)]="produto.sku" #sku="ngModel"
                             [disabled]="!podeEditarProdutos"
                             placeholder="SKU produto"   >
                    </div>

                    <div class="form-group  col-6 col-sm-4"  *ngIf="produto.tipoDeVenda == tiposDeVenda[0]">
                      <label>Incremento</label>

                      <kendo-numerictextbox  name="incremento" [(ngModel)]="produto.incremento" [min]="1" class="form-control"
                                             [disabled]="!podeEditarProdutos"
                                             #incremento="ngModel"  [format]="'n0'" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p  >Incremento mínimo tem ser 1</p>
                      </div>

                    </div>

                    <div class="form-group  col-12 "  *ngIf="produto.preco === 0" >
                      <label class="k-checkbox-label">
                        <input type="checkbox"   name="destaque" class="k-checkbox " kendoCheckBox
                               [disabled]="!podeEditarProdutos"  [(ngModel)]="produto.ehBrinde" />

                          <ng-container *ngIf="integradoComEcletica()">
                            sempre enviar na integração e promoções  mesmo com valor zero
                          </ng-container>
                          <ng-container *ngIf="!integradoComEcletica()">
                            É brinde?
                          </ng-container>
                         </label>
                    </div>

                    <div class="form-group  col-12" *ngIf="!exibirDescontos">
                      <button type="button" class="btn btn-outline-blue btn-xs" (click)="exibaDescontos()" [disabled]="!podeEditarPrecos"  >Inserir desconto</button>
                    </div>

                  </div>

                  <div class="row" *ngIf="exibirDescontos">
                    <div class="form-group col-4"   *ngIf="!produto.precoNaEmpresa || (!this.empresa || !this.empresa.id)">
                      <label  >Novo Preço</label>
                      <kendo-numerictextbox    [max]="produto.preco - 0.10"  class="form-control" name="novoPreco" #novoPreco=ngModel
                                               [disabled]="!podeEditarProdutos"
                                               [format]="obtenhaFormatoPreco()" (change)="alterouNovoPreco()"   [(ngModel)]="produto.novoPreco" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="novoPreco.errors?.required">Novo Preço é obrigatório</p>
                        <p *ngIf="novoPreco.errors?.maxError">Valor máximo é  {{novoPreco.errors?.maxError?.maxValue}}</p>
                      </div>
                    </div>

                    <div class="form-group col-4"   *ngIf="produto.precoNaEmpresa && this.empresa && this.empresa.id">
                      <label  >Novo Preço</label>
                      <kendo-numerictextbox    [max]="produto.precoNaEmpresa - 0.10"  class="form-control" name="novoPreco" #novoPreco=ngModel
                                               [format]="obtenhaFormatoPreco()" (change)="alterouNovoPrecoNaEmpresa()"   [(ngModel)]="produto.novoPrecoNaEmpresa" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="novoPreco.errors?.required">Novo Preço é obrigatório</p>
                        <p *ngIf="novoPreco.errors?.maxError">Valor máximo é  {{novoPreco.errors?.maxError?.maxValue}}</p>
                      </div>
                    </div>


                    <div class="form-group  col-4"  *ngIf="modoAdminRede || !produto.precoNaEmpresa">

                      <label  >Desconto em %</label>
                      <kendo-numerictextbox   [min]="1" [max]="100"  class="form-control" name="percentualDesconto" #percentualDesconto=ngModel
                                              [disabled]="!podeEditarProdutos"
                                              [format]="obtenhaFormatoPercentual()" (change)="alterouPercentualDesconto()"     [(ngModel)]="produto.percentualDesconto" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="percentualDesconto.errors?.required">Desconto é obrigatório</p>
                        <p *ngIf="percentualDesconto.errors?.minError">Valor mínimo é  {{percentualDesconto.errors?.minError?.minValue}}</p>
                        <p *ngIf="percentualDesconto.errors?.maxError">Valor máximo é  {{percentualDesconto.errors?.maxError?.maxValue}}</p>

                      </div>

                    </div>

                    <div class="form-group  col-4"  *ngIf="!modoAdminRede && produto.precoNaEmpresa">

                      <label  >Desconto em %</label>
                      <kendo-numerictextbox   [min]="1" [max]="100"  class="form-control" name="percentualDescontoNaEmpresa" #percentualDescontoNaEmpresa=ngModel
                                              [format]="obtenhaFormatoPercentual()" (change)="alterouPercentualDescontoNaEmpresa()"     [(ngModel)]="produto.percentualDescontoNaEmpresa" required>
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="percentualDescontoNaEmpresa.errors?.required">Desconto é obrigatório</p>
                        <p *ngIf="percentualDescontoNaEmpresa.errors?.minError">Valor mínimo é  {{percentualDescontoNaEmpresa.errors?.minError?.minValue}}</p>
                        <p *ngIf="percentualDescontoNaEmpresa.errors?.maxError">Valor máximo é  {{percentualDescontoNaEmpresa.errors?.maxError?.maxValue}}</p>

                      </div>

                    </div>


                    <div class="form-group  col-4"  >
                      <div class="form-group mt-4">
                        <label class="k-checkbox-label">
                          <input type="checkbox" *ngIf="modoAdminRede || !produto.precoNaEmpresa"  name="destaque" class="k-checkbox " kendoCheckBox
                                 [disabled]="!podeEditarProdutos"  [(ngModel)]="produto.destaque" />
                          <input type="checkbox" *ngIf="!modoAdminRede && produto.precoNaEmpresa"  name="destaque" class="k-checkbox " kendoCheckBox
                                 [(ngModel)]="produto.destaqueNaEmpresa" />
                          Destaque{{produto.precoNaEmpresa ? " na empresa" : ""}}</label>
                      </div>
                    </div>

                    <div class="form-group  col-12"  >

                      <button class="btn btn-outline-danger btn-xs  "  (click)="removerDesconto()"  >Remover desconto</button>
                    </div>


                  </div>

                  <div class="row " *ngIf="produto.tipoDeVenda != tiposDeVenda[0]">
                    <div class="form-group  col-4">
                      <label  >Unidade</label>

                      <kendo-dropdownlist   name="unidade" [(ngModel)]="produto.unidadeMedida" [data]="unidades" (ngModelChange)="alterouValorUnidade()"
                                            class="form-control"  [textField]="'nome'" required  #unidade="ngModel"
                                            [disabled]="!podeEditarProdutos">
                      </kendo-dropdownlist>

                      <div class="invalid-feedback">
                        <p *ngIf="unidade.errors?.required">Unidade é obrigatório</p>
                      </div>

                    </div>

                    <div class="form-group  col-4">
                      <label >Valor inicial</label>

                      <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0" #valorInicial="ngModel"
                                            [format]="obtenhaFormato()" name="valorInicial" required
                                            [disabled]="!podeEditarProdutos"
                                            [(ngModel)]="produto.valorInicial" placeholder="Valor inicial">     </kendo-numerictextbox>


                      <div class="invalid-feedback">
                        <p *ngIf="valorInicial.errors?.required">Valor inicial é obrigatório</p>
                      </div>
                    </div>

                    <div class="form-group  col-4">
                      <label for="incremento">Incremento</label>
                      <kendo-numerictextbox id="incremento" [min]="0"   [format]="obtenhaFormato()"
                                            class="form-control" name="incremento" #incremento=ngModel
                                            [disabled]="!podeEditarProdutos"
                                            [(ngModel)]="produto.incremento" required placeholder="Incremento">
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">
                        <p *ngIf="incremento.errors?.required">Incremento é obrigatório</p>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="form-group  col-12 ">
                      <label for="categoria">Categoria</label>

                      <kendo-dropdownlist  name="categoria" [(ngModel)]="produto.categoria" [data]="categorias" [filterable]="true"
                                           [disabled]="!podeEditarProdutos"
                                           placeholder="Selecione uma categoria" class="form-control" [textField]="'nome'"
                                           (filterChange)="handleFilter($event)">
                      </kendo-dropdownlist>

                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="nome">Descrição do Produto</label>
                    <textarea rows="3" type="text" class="form-control" autocomplete="off"
                              [disabled]="!podeEditarProdutos"
                              id="descricao" name="descricao" [(ngModel)]="produto.descricao" #descricao="ngModel"
                              placeholder="Descricao do Produto"       > </textarea>
                    <div class="invalid-feedback">
                      <p *ngIf="descricao.errors?.required">Descrição é obrigatório</p>
                    </div>
                  </div>

                  <div class="row" *ngIf="produto.tipoDeVenda == tiposDeVenda[1]">
                    <div class="form-group  col-4">
                      <label>Peso Mínimo
                        <i class="fas fa-question" kendoTooltip  title="Na loja, o cliente só poderá comprar esse produto com esse peso mínimo."></i>
                      </label>

                      <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0" #pesoMinimo="ngModel"
                                            [disabled]="!podeEditarProdutos"
                                            [format]="obtenhaFormato()" name="pesoMinimo"
                                            [(ngModel)]="produto.pesoMinimo" placeholder="Peso Mínimo">     </kendo-numerictextbox>

                    </div>

                    <div class="form-group  col-4  ">
                      <label for="qtdMaxima"  >Peso Máximo
                        <i class="fas fa-question" kendoTooltip  title="Na loja, o peso máximo que o cliente poderá comprar por pedido."></i>
                      </label>
                      <kendo-numerictextbox class="form-control" autocomplete="off"  [min]="0" #pesoMaximo="ngModel"
                                            [disabled]="!podeEditarProdutos"
                                            [format]="obtenhaFormato()" name="pesoMaximo"
                                            [(ngModel)]="produto.pesoMaximo" placeholder="Peso Máximo">     </kendo-numerictextbox>

                    </div>
                  </div>

                  <div class="row" *ngIf="produto.tipoDeVenda == tiposDeVenda[0]">
                    <div class="form-group  col-6">
                      <label>Quantidade Mínima
                        <i class="fas fa-question" kendoTooltip  title="Na loja, o cliente só poderá comprar esse produto com essa quantidade mínima."></i>
                      </label>

                      <kendo-numerictextbox  name="qtdeMinima" [(ngModel)]="produto.qtdeMinima" class="form-control"
                                             [disabled]="!podeEditarProdutos" [min]="0"
                                             [format]="'n0'">
                      </kendo-numerictextbox>

                    </div>

                    <div class="form-group  col-6  ">
                      <label for="qtdMaxima"  >Quantidade Máxima
                        <i class="fas fa-question" kendoTooltip  title="Na loja, o limite máximo que o cliente poderá comprar por pedido"></i>
                      </label>
                      <kendo-numerictextbox id="qtdMaxima" class="form-control" autocomplete="off"    name="qtdMaxima"
                                            [disabled]="!podeEditarProdutos"
                                            [(ngModel)]="produto.qtdMaxima">
                      </kendo-numerictextbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group  col" *ngIf="catalogo.compartilhado">
                      <label class="k-checkbox-label">
                        <input type="checkbox" name="sincronizarModelos" [disabled]="!podeEditarProdutos"
                               kendoCheckBox class="k-checkbox " [(ngModel)]="produto.sincronizarModelos" />
                        Sincronizar em todos catalogos</label>
                    </div>

                    <div class="form-group  col" [hidden]="ehBrindeFidelidade()">
                      <label class="k-checkbox-label">
                        <input type="checkbox" name="dipsonivelParaDelivery" [disabled]="!podeEditarProdutos"
                               kendoCheckBox class="k-checkbox " [(ngModel)]="produto.naoAceitaCupom" />
                        Não aplicar cupom nesse produto.</label>
                    </div>

                  </div>


                </div>
              </div>

              <div class="modal-footer mobile-footer">
                <div class="messages">
                  <p *ngIf="mensagemSucesso" class="text-success">
                    <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
                  </p>
                  <p *ngIf="mensagemErro" class="text-danger">
                    <i class="mdi mdi-alert mr-1"></i> {{mensagemErro}}
                  </p>
                </div>
                <div class="buttons">
                  <button type="submit" class="btn btn-primary" [disabled]="!frm.dirty">
                    <i class="fas fa-save mr-1"></i>Salvar
                  </button>
                  <button type="button" class="btn btn-light" (click)="fecheModal()">
                    <i class="fas fa-times mr-1"></i>Fechar
                  </button>
                </div>
              </div>
            </form>
          </ng-container>

          <ng-container  *ngIf="tipoDeProduto === TIPOSDEPRODUTOS.Pizza">
            <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                  novalidate #frm="ngForm" (ngSubmit)="onSalvarProduto()" *ngIf="tipoDeProduto === TIPOSDEPRODUTOS.Pizza">

              <div class="row">
                <div class="col foto-produto">
                  <app-upload-multiplas-imagens [objeto]="produto" [sm]="true" [maxsize]="800" prop="imagens" (onSalvar)="salveImagensProduto($event)"></app-upload-multiplas-imagens>
                </div>

                <div class="col">
                  <div class="row">
                    <div class="form-group mb-3 col-12">
                      <label for="nome">Sabor</label>
                      <input type="text" class="form-control" autocomplete="off"
                             name="nome" [(ngModel)]="produto.nome" #nome="ngModel"
                             placeholder="Sabor" value="" required appAutoFocus [autoFocus]="true">
                      <div class="invalid-feedback">
                        <p *ngIf="nome.errors?.required">Sabor é obrigatório</p>
                      </div>
                    </div>


                    <div class="form-group mb-3 col-6"   >
                      <label for="categoria">Categoria</label>

                      <kendo-dropdownlist id="categoria" name="categoria" [(ngModel)]="produto.categoria" [data]="categorias" [filterable]="true"
                                          placeholder="Selecione uma categoria" class="form-control" [textField]="'nome'"
                                          (filterChange)="handleFilter($event)">
                      </kendo-dropdownlist>

                    </div>

                    <div class="form-group  mb-3 col-6 mt-3">
                      <label class="k-checkbox-label">
                        <input type="checkbox" name="dipsonivelParaDelivery" class="k-checkbox " kendoCheckBox
                               [(ngModel)]="produto.naoAceitaCupom" />
                        Não aplicar cupom nesse produto.</label>
                    </div>

                    <div class="form-group mb-3 col-12">
                      <label for="nome">Descrição do Produto</label>
                      <textarea rows="4" type="text" class="form-control" autocomplete="off" required
                                name="descricao" [(ngModel)]="produto.descricao" #descricao="ngModel"
                                placeholder="Descricao do Produto"       > </textarea>
                      <div class="invalid-feedback">
                        <p *ngIf="descricao.errors?.required">Descrição é obrigatório</p>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-12">

                  <fieldset>
                    <legend> <b> {{produto.template.nome}} </b> - Preços / Tamanhos</legend>

                    <div class="row">
                      <div *ngFor="let tamanho of produto.tamanhos; let i =index;" class="col-3 mb-2"
                           [ngClass]="{'desativado':  !tamanho.template.disponivel}">

                        <div class="card-box ribbon-box">
                          <div class="ribbon  float-left ribbon-{{obtenhaClassCor(tamanho)}}" >
                            <span> {{tamanho.descricao}}</span>
                          </div>

                          <h5 class="text-info float-right mt-0" *ngIf="!tamanho.id && produto.id">
                            <label class="badge badge-danger">Novo</label>
                          </h5>

                          <div class="form-group mb-3 text-center" >

                            <label class="k-checkbox-label"  *ngIf="tamanho.template.disponivel">
                              <input  name="disponivel{{tamanho.descricao}}" class="k-checkbox  checkboxlg  mr-2 mt-1" kendoCheckBox
                                      type="checkbox"
                                      [(ngModel)]="tamanho.disponivel"   />

                            </label>

                          </div>

                          <div class="ribbon-content">

                            <div class=" tamanho-pizza {{obtenhaClassTamanho(tamanho)}}">

                              <small class="text-muted mt-1 d-block  text-center  " *ngIf="!tamanho.template.disponivel"><b>Indisponível</b></small>

                              <img [src]="'/assets/icons/' + obtenhaImagemTamanho(tamanho)"   [hidden]="!tamanho.template.disponivel"  >
                            </div>

                            <div  >
                              <label  >Codigo
                                <ng-container *ngIf="empresa?.integracaoDelivery">PDV</ng-container></label>
                              <input type="text" class="form-control" autocomplete="off"
                                     name="codigoPdv-{{i}}" [(ngModel)]="tamanho.codigoPdv" #codigoPdv="ngModel"
                                     placeholder="Cod. PDV"     [required]="integradoComEcletica() && tamanho.template.disponivel"     >
                              <div class="invalid-feedback">
                                <p *ngIf="codigoPdv.errors?.required">Código
                                  <ng-container *ngIf="empresa?.integracaoDelivery">do pdv </ng-container> é obrigatório</p>
                              </div>
                            </div>

                            <div class="mt-2" [class.preco-antigo]="tamanho.novoPreco > 0">
                              <label  >Preço</label>
                              <kendo-numerictextbox   [min]="1"  class="form-control" name="preco-{{i}}" #preco=ngModel
                                                      [format]="'c2'"   [(ngModel)]="tamanho.preco" required [disabled]="!tamanho.template.disponivel">
                              </kendo-numerictextbox>

                              <div class="invalid-feedback">
                                <p *ngIf="preco.errors?.required">Preço é obrigatório</p>
                                <p *ngIf="preco.errors?.minError">Valor mínimo é  {{preco.errors?.minError.minValue | currency: "R$"}}</p>
                              </div>
                            </div>

                            <button type="button" class="btn btn-outline-blue btn-xs mt-2 btn-block" (click)="tamanho.exibirDescontos = true"
                                    *ngIf="!tamanho.exibirDescontos">Inserir desconto</button>


                            <ng-container  *ngIf="tamanho.exibirDescontos">

                              <div class="form-group   mt-1"  >

                                <label  >Desconto em %</label>
                                <kendo-numerictextbox   [min]="1" [max]="100"  class="form-control" name="percentualDesconto-{{i}}" #percentualDesconto=ngModel
                                                        [format]="obtenhaFormatoPercentual()" (change)="alterouPercentualDescontoTamanho(tamanho)"     [(ngModel)]="tamanho.percentualDesconto" required>
                                </kendo-numerictextbox>

                                <div class="invalid-feedback">
                                  <p *ngIf="percentualDesconto.errors?.required">Desconto é obrigatório</p>
                                  <p *ngIf="percentualDesconto.errors?.minError">Valor mínimo é  {{percentualDesconto.errors?.minError?.minValue}}</p>
                                  <p *ngIf="percentualDesconto.errors?.maxError">Valor máximo é  {{percentualDesconto.errors?.maxError?.maxValue}}</p>

                                </div>

                              </div>

                              <div class="form-group  mt-1 novo-preco"  >
                                <label  ><b>Novo Preço</b></label>
                                <kendo-numerictextbox    [max]="tamanho.preco - 0.1"  class="form-control" name="novoPreco-{{i}}" #novoPreco=ngModel
                                                         [format]="obtenhaFormatoPreco()" (change)="alterouNovoPrecoTamanho(tamanho)"   [(ngModel)]="tamanho.novoPreco" required>
                                </kendo-numerictextbox>

                                <div class="invalid-feedback">
                                  <p *ngIf="novoPreco.errors?.required">Novo Preço é obrigatório</p>
                                  <p *ngIf="novoPreco.errors?.maxError">Valor máximo é  {{novoPreco.errors?.maxError?.maxValue}}</p>
                                </div>
                              </div>


                              <div class="form-group mt-2">
                                <label class="k-checkbox-label">
                                  <input type="checkbox"   name="destaque-{{i}}" class="k-checkbox " kendoCheckBox
                                         [(ngModel)]="tamanho.destaque" />
                                  Destaque</label>
                              </div>

                              <button class="btn btn-outline-danger btn-xs mt-1"  (click)="removerDescontoTamanho(tamanho)"  >Remover desconto</button>

                            </ng-container>


                          </div>

                        </div>
                      </div>
                    </div>
                  </fieldset>

                </div>
              </div>

              <div class="modal-footer mobile-footer">
                <div class="messages">
                  <p *ngIf="mensagemSucesso" class="text-success">
                    <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
                  </p>
                  <p *ngIf="mensagemErro" class="text-danger">
                    <i class="mdi mdi-alert mr-1"></i> {{mensagemErro}}
                  </p>
                </div>
                <div class="buttons">
                  <button type="submit" class="btn btn-primary" [disabled]="!frm.dirty">
                    <i class="fas fa-save mr-1"></i>Salvar
                  </button>
                  <button type="button" class="btn btn-light" (click)="fecheModal()">
                    <i class="fas fa-times mr-1"></i>Fechar
                  </button>
                </div>
              </div>

            </form>


          </ng-container>

        </ng-template>
      </kendo-tabstrip-tab>


      <ng-container *ngIf="tipoDeProduto === TIPOSDEPRODUTOS.Pizza">
        <kendo-tabstrip-tab id="massas" [title]="'Massas'"   [disabled]="!produto?.id" >
          <ng-template kendoTabContent>
            <div class="container-adicionais">
              <form [ngClass]="{'needs-validation': !frmmb.submitted, 'was-validated': frmmb.submitted}"
                    novalidate #frmmb="ngForm" (ngSubmit)="salvarAdicional(adicionalMassa, frmmb)"  >

                <h5 class="titulo-adicional">Massas</h5>

                <div class="row">
                  <div class="col-3">Nome</div>
                  <div class="col-2">Preço</div>
                  <div class="col-2">Cod. Pdv</div>
                  <div class="col-3">Tamanho</div>
                  <div class="col">Disponível</div>
                </div>

                <div class="row mt-2" *ngFor="let opcaoMassa of adicionalMassa.opcoesDisponiveis; let i= index; trackBy: trackByFn"
                     [ngClass]="{'desativado': opcaoMassa.template && !opcaoMassa.template.disponivel}" >
                  <div class="col-3">
                    <input type="text" class="form-control" autocomplete="off"
                           name="nome{{opcaoMassa.nome}}" [(ngModel)]="opcaoMassa.nome" disabled
                           placeholder="Nome da massa" value="" required   >
                  </div>

                  <div class="col-2">
                    <kendo-numerictextbox   [min]="0"  class="form-control" name="valor-{{opcaoMassa.nome}}"
                                            [format]="'c2'"   [(ngModel)]="opcaoMassa.valor" required [disabled]="true">
                    </kendo-numerictextbox>

                  </div>



                  <div class="col-2" >
                    {{opcaoMassa.codigoPdv || '-'}}
                  </div>

                  <div class="col-3 "  >
                        <span class="mt-1 d-block">
                          {{opcaoMassa.template && opcaoMassa.template.tamanho ? opcaoMassa.template.tamanho.descricao : 'Todos'}}</span>
                  </div>

                  <div class="" >
                    <input  name="disponivel{{opcaoMassa.nome}}" class="k-checkbox checkboxlg mt-1" type="checkbox" kendoCheckBox
                            [(ngModel)]="opcaoMassa.disponivel"  *ngIf="opcaoMassa.template?.disponivel"/>

                    <small *ngIf="opcaoMassa.template && !opcaoMassa.template.disponivel">*indisponível</small>


                    <label class="badge badge-danger ml-1"  *ngIf="!opcaoMassa.id && adicionalMassa.id">Novo</label>

                  </div>
                </div>

                <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible fade show mt-2">
                  <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2">
                  <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-footer mt-2">

                  <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="!frmmb.dirty || adicionalMassa.salvando"  >

                    Salvar  <i class="k-icon k-i-loading"  *ngIf="adicionalMassa.salvando"></i>
                  </button>


                  <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                          (click)="fecheModal()">Fechar</button>
                </div>

              </form>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab id="bordas" [title]="'Bordas'"   [disabled]="!produto?.id" >
          <ng-template kendoTabContent>
            <div class="container-adicionais">
              <form [ngClass]="{'needs-validation': !frmmb.submitted, 'was-validated': frmmb.submitted}"
                    novalidate #frmmb="ngForm" (ngSubmit)="salvarAdicional(adicionalBorda, frmmb)"  >
                <h5 class="titulo-adicional  ">Bordas</h5>

                <div class="row">
                  <div class="col-3">Nome</div>
                  <div class="col-2">Preço</div>
                  <div class="col-2">Cod. Pdv</div>
                  <div class="col-3">Tamanho</div>
                  <div class="col">Disponível</div>
                  <div class="col"></div>
                </div>

                <div class="row  mt-2" *ngFor="let opcaoBorda of adicionalBorda.opcoesDisponiveis;  let i= index;"
                     [ngClass]="{'desativado': opcaoBorda.template && !opcaoBorda.template.disponivel}" >
                  <div class="col-3">
                    <input type="text" class="form-control" autocomplete="off"
                           name="nome-{{i}}" [(ngModel)]="opcaoBorda.nome" disabled
                           placeholder="Nome da Borda" value="" required   >
                  </div>

                  <div class="col-2">
                    <kendo-numerictextbox   [min]="0"  class="form-control" name="valor-{{opcaoBorda.id}}"
                                            [format]="'c2'"   [(ngModel)]="opcaoBorda.valor" required [disabled]="true">
                    </kendo-numerictextbox>

                  </div>

                  <div class="col-2" >
                    {{opcaoBorda.codigoPdv || '-'}}
                  </div>


                  <div class="col-3 "  >
                      <span class="mt-1 d-block">
                        {{opcaoBorda.template && opcaoBorda.template.tamanho ? opcaoBorda.template.tamanho.descricao : 'Todos'}}</span>
                  </div>



                  <div class="col"  >
                    <input  name="disponivel-{{i}}"   class="k-checkbox checkboxlg mt-1" type="checkbox" kendoCheckBox
                            [(ngModel)]="opcaoBorda.disponivel" *ngIf="opcaoBorda.template?.disponivel"/>

                    <small *ngIf="opcaoBorda.template && !opcaoBorda.template.disponivel">*indisponível</small>
                    <label class="badge badge-danger ml-1"  *ngIf="!opcaoBorda.id && adicionalMassa.id">Novo</label>


                  </div>


                </div>


                <div *ngIf="mensagemSucesso" class="alert alert-success alert-dismissible fade show mt-2" role="alert">
                  <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <!-- Mensagem de Erro -->
                <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mt-2" role="alert">
                  <i class="mdi mdi-check-all mr-2"></i> {{mensagemErro}}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemErro()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-footer mt-2">

                  <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="!frmmb.dirty || adicionalMassa.salvando"  >

                    Salvar  <i class="k-icon k-i-loading"  *ngIf="adicionalMassa.salvando"></i>
                  </button>

                  <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                          (click)="fecheModal()">Fechar</button>
                </div>

              </form>

            </div>

          </ng-template>

        </kendo-tabstrip-tab>


      </ng-container>


      <kendo-tabstrip-tab id="adicionais" [title]="'Adicionais'"   [disabled]="!produto?.id">
        <ng-template kendoTabContent>

          <app-lista-adicionais [catalogo]="catalogo" [empresa]="empresa"  [templatesPiza]="templatesPiza"
                                [objetoComAdicionais]="produto" [tela]="this"
                                [servicoSalvarAdicional]="produtoService"></app-lista-adicionais>

        </ng-template>
      </kendo-tabstrip-tab>


      <kendo-tabstrip-tab id="classificacao" [title]="'Classificação'" [disabled]="!produto?.id">
        <ng-template kendoTabContent>

          <fieldset>
            <legend> Restrições alimentares</legend>
            <div class="row ml-2 mt-2">
              <div class="form-group mb-1 col-12 " *ngFor="let tag of tagsAlimentar">
                <div class="input-group mt-0">
                  <input  name="{{tag.nome}}" id='{{tag.id}}'    class="k-checkbox  " type="checkbox" kendoCheckBox
                          [(ngModel)]="tag.selecionada"  />

                  <i class="icon-restricao-alimentar {{getIconTag(tag.nome)}} "></i>
                  <label  class="k-checkbox-label ml-1" for="{{tag.nome}}">
                    <b>{{tag.nome}}</b></label>
                </div>

                <p class="text-muted ml-3 mt-1">{{tag.descricao}}</p>

              </div>

            </div>
          </fieldset>

          <div class="modal-footer">

            <p *ngIf="mensagemSucesso" class="text-success ">
              <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
            </p>

            <p  *ngIf="mensagemErro" class="text-danger ">
              <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
            </p>

            <button type="button" class="btn btn-primary waves-effect waves-light"
                (click)="salveClassificacao()"     [disabled]="salvando || !podeEditarProdutos" >
              Salvar</button>

            <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                    (click)="fecheModal()">
              Fechar</button>
          </div>


        </ng-template>

      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab id="disponibilidade" [title]="'Disponibilidade'" [disabled]="!produto?.id">
        <ng-template kendoTabContent>

          <form [ngClass]="{'needs-validation': !frmd.submitted, 'was-validated': frmd.submitted}"
                novalidate #frmd="ngForm" (ngSubmit)="onSalvarDisponibilidade()" style="padding: 15px 15px;">

            <fieldset>
              <legend> Disponibilidade no cardápio <span *ngIf="exibirDisponibilidadeEmpresa()"> <b>da empresa</b></span> </legend>

              <div class="row disponibilidade" >
                <div class="form-group mb-3 col-12">

                  <div class="input-group mt-1" *ngFor="let disponibilidade of disponibilidades">
                    <ng-container *ngIf="!exibirDisponibilidadeEmpresa()">
                      <input id="{{disponibilidade.id}}" name="disponibilidade" type="radio"  class="k-radio"   kendoRadioButton
                             [(ngModel)]="produto.disponibilidade" [value]="disponibilidade.id"
                             [required]="true"/>
                      <label for="{{disponibilidade.id}}" class="ml-1 mr-5">{{disponibilidade.descricao}}</label>
                    </ng-container>
                    <ng-container *ngIf="exibirDisponibilidadeEmpresa()">
                      <input id="disponibilidadeNaEmpresa{{disponibilidade.id}}" name="disponibilidadeNaEmpresa" type="radio"  class="k-radio"   kendoRadioButton
                             [(ngModel)]="produto.disponibilidadeNaEmpresa" [value]="disponibilidade.id"
                             [required]="true"/>
                      <label for="disponibilidadeNaEmpresa{{disponibilidade.id}}" class="ml-1 mr-5">{{disponibilidade.descricao}}</label>
                    </ng-container>
                  </div>
                </div>
              </div>

              <app-view-disponibilidade #viewDisponibilidades [disponibilidades]="produto.disponibilidades" [catalogo]="catalogo"
                                        [hidden]="exibirDisponibilidadeEmpresa() ? produto.disponibilidadeNaEmpresa !== 1 : produto.disponibilidade !== 1">

              </app-view-disponibilidade>

            </fieldset>

            <app-associar-insumo #telaInsumoAssociado  [objeto]="produto"></app-associar-insumo>

            <fieldset>
              <legend> Onde exibir </legend>

              <div class="row mt-1">
                <div class="col-6">
                  <div class="form-group mb-3">
                    <label class="k-checkbox-label">
                      <input type="checkbox" name="dipsonivelParaDelivery" class="k-checkbox " kendoCheckBox
                             [(ngModel)]="produto.disponivelParaDelivery" />
                      Cardápio de delivery</label>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group mb-3">
                    <label class="k-checkbox-label">
                      <input type="checkbox"   name="disponivelNaMesa" class="k-checkbox " kendoCheckBox
                             [(ngModel)]="produto.disponivelNaMesa" />
                      Cardápio de mesa</label>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="exibirNoSite()">
                <div class="col-6" >
                  <div class="form-group mb-3">
                    <label class="k-checkbox-label">
                      <input type="checkbox"   name="exibirNoSite" class="k-checkbox " kendoCheckBox  [(ngModel)]="produto.exibirNoSite" />
                      Exibir no site</label>
                  </div>
                </div>
                <div class="col-4" *ngIf="exibirNoSite() && produto.exibirNoSite">
                  <div class="form-group mb-3">
                    <label class="k-checkbox-label">
                      <input type="checkbox" name="exibirPrecoNoSite" class="k-checkbox " kendoCheckBox   [(ngModel)]="produto.exibirPrecoSite" />
                      Exibir Preço no site
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group mb-3" *ngIf="produto.exibirNoSite">
                    <label for="nome">Mensagem do Pedido</label>
                    <input type="text" class="form-control" autocomplete="off"
                           id="mensagemPedido" name="mensagemPedido" [(ngModel)]="produto.mensagemPedido" #mensagemPedido="ngModel"
                           placeholder="Mensagem do Pedido" value="" [required]="produto.exibirNoSite"   >
                    <div class="invalid-feedback">
                      <p *ngIf="mensagemPedido.errors?.required">Descrição é obrigatório</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="temModuloPedidos">
                <div class="col-12">
                  <div class="form-group mb-3">
                    <label class="k-checkbox-label">
                      <input type="checkbox" name="exibirPrecoNoCardapio" class="k-checkbox " kendoCheckBox  [(ngModel)]="produto.exibirPrecoNoCardapio" />
                      Exibir Preço no Cardápio
                    </label><br>
                    <span class="text-muted" *ngIf="!produto.exibirPrecoNoCardapio">Atenção: o preço continuará sendo exibido ao selecionar o produto no cardápio</span>
                  </div>
                </div>
              </div>

            </fieldset>

            <fieldset *ngIf="integradoComEcleticaOuGCOM() && trendsFoods()">
              <legend>Sincronização Catalogo Da Rede</legend>
              <div class="row">
                <div class="form-group  col-4"  >
                  <div class="form-group">
                    <label class="k-checkbox-label">
                      <input type="checkbox"   name="destaque" class="k-checkbox " kendoCheckBox  [(ngModel)]="produto.naoSincronizar" />
                      Não sincronizar com catálogo da rede</label>
                  </div>
                </div>
              </div>


            </fieldset>

            <div class="modal-footer">

              <p *ngIf="mensagemSucesso" class="text-success ">
                <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
              </p>

              <p  *ngIf="mensagemErro" class="text-danger ">
                <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
              </p>

              <button type="submit" class="btn btn-primary waves-effect waves-light"  [disabled]="salvando || !podeEditarProdutos" >
                Salvar</button>

              <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                      (click)="fecheModal()">
                Fechar</button>
            </div>


          </form>


        </ng-template>
      </kendo-tabstrip-tab>


      <kendo-tabstrip-tab id="pontuacao" [title]="'Pontuação Fidelidade'"  *ngIf="pontuarFidelidade()">
        <ng-template kendoTabContent>

          <form [ngClass]="{'needs-validation': !frmf.submitted, 'was-validated': frmf.submitted}"
                novalidate #frmf="ngForm" (ngSubmit)="onSalvarPontosFidelidade()" style="padding: 15px 15px;">

            <ng-container *ngIf="!acumulaReais() ">
              <div class="row" *ngIf="!produto.tamanhos">
                <div class="col col-sm-5" >
                  <div class="form-group mb-3"  >
                    <label for="pontosGanhos">Quantidade de  <b> {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}}</b> ganhos por Produto</label>
                    <kendo-numerictextbox id="pontosGanhos" [min]="0"  [(ngModel)]="produto.pontosGanhos"
                                          class="form-control" name="pontosGanhos" #pontosGanhos=ngModel >
                    </kendo-numerictextbox>

                    <div class="invalid-feedback">  </div>
                  </div>
                </div>

              </div>
              <ng-container *ngIf="produto.tamanhos?.length">
                <h5> Quantidade de  <b> {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}}</b> ganhos por Tamanho</h5>

                <div class="row" >
                  <div class="col-4" *ngFor="let tamanho of produto.tamanhos; let i = index">
                    <div class="form-group mb-3"  >
                      <label for="pontosGanhos{{i}}">
                         Tamanho <b>{{tamanho.descricao}}</b>
                      </label>
                      <kendo-numerictextbox id="pontosGanhos{{i}}" [min]="0"  [(ngModel)]="tamanho.template.pontosGanhos"  [disabled]="true"
                                            class="form-control" name="pontosGanhos{{i}}" #pontosGanhos=ngModel >
                      </kendo-numerictextbox>

                      <div class="invalid-feedback">  </div>
                    </div>
                  </div>
                </div>

                <p class="text-muted mt-2" >
                  Pontuação  padrão: <b> {{empresa.integracaoPedidoFidelidade.atividade.pontosGanhos}}
                  {{empresa.integracaoPedidoFidelidade.plano.tipoDeAcumulo}}</b></p>
              </ng-container>



            </ng-container>

            <ng-container *ngIf="acumulaReais()">
              <div class="row" *ngIf="!produto.tamanhos">
                <div class="col col-sm-5">
                  <div class="form-group mb-3"  >
                    <label for="cashback">Cashback</label>
                    <kendo-numerictextbox   id="cashback" name="cashback" class="form-control" autocomplete="off"  placeholder="Retorno do cashback"
                                            [format]="obtenhaFormatoPercentual()"  [step]="1"  [min]="0"  [max]="100"
                                            [(ngModel)]="produto.cashback" #cashback="ngModel"       >

                    </kendo-numerictextbox>
                    <div class="invalid-feedback">
                      <p *ngIf="cashback.errors?.required">Cashback é obrigatório</p>
                      <p *ngIf="cashback.errors?.maxError">Valor  máximo é {{cashback.errors?.maxError?.maxValue}} </p>
                      <p *ngIf="cashback.errors?.minError">Valor  mínimo é {{cashback.errors?.minError?.maxValue}} </p>
                    </div>

                    <p class="text-muted mt-2" >
                      Cashback padrão: <b> {{getCashbackExibir(empresa.integracaoPedidoFidelidade.atividade)}}</b></p>

                  </div>
                </div>
              </div>
              <ng-container *ngIf="produto.tamanhos?.length">
                <h5> Cashback ganho por Tamanho</h5>

                <div class="row" >
                  <div class="col-4" *ngFor="let tamanho of produto?.tamanhos; let i = index">
                    <div class="form-group mb-3"  >
                      <label for="pontosGanhos{{i}}">
                        Tamanho <b>{{tamanho.descricao}}</b>
                      </label>
                      <kendo-numerictextbox   id="cashback{{i}}" name="cashback{{i}}" class="form-control" autocomplete="off"
                                              placeholder="Retorno do cashback"  [disabled]="true"
                                              [format]="obtenhaFormatoPercentual()"  [step]="1"  [min]="0"  [max]="100"
                                              [(ngModel)]="tamanho.template.cashback" #cashback="ngModel"       >

                      </kendo-numerictextbox>
                      <div class="invalid-feedback">
                        <p *ngIf="cashback.errors?.required">Cashback é obrigatório</p>
                        <p *ngIf="cashback.errors?.maxError">Valor  máximo é {{cashback.errors?.maxError?.maxValue}} </p>
                        <p *ngIf="cashback.errors?.minError">Valor  mínimo é {{cashback.errors?.minError?.maxValue}} </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="text-muted mt-2" >
                  Cashback padrão: <b> {{getCashbackExibir(empresa.integracaoPedidoFidelidade.atividade)}}</b></p>
              </ng-container>

            </ng-container>

            <div class="modal-footer">

              <p *ngIf="mensagemSucesso" class="text-success ">
                <i class="mdi mdi-check-all mr-1"></i> {{mensagemSucesso}}
              </p>

              <p  *ngIf="mensagemErro" class="text-danger ">
                <i class="mdi mdi-check-all mr-1"></i> {{mensagemErro}}
              </p>

              <button type="submit" class="btn btn-primary waves-effect waves-light"  [disabled]="salvando || produto.tamanhos?.length" >
                Salvar</button>

              <button type="button" class="btn btn-light waves-effect" data-dismiss="modal"
                      (click)="fecheModal()">
                Fechar</button>
            </div>

          </form>






        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab id="Histórico" [title]="'Histórico'" *ngIf="produto.id && usuarioLogado && usuarioLogado.admin">
        <ng-template kendoTabContent>
          <app-historico-produto [produto]="produto"></app-historico-produto>
        </ng-template>
      </kendo-tabstrip-tab>
    </ng-container>


  </kendo-tabstrip>
</div>
