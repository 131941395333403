<div class="card">
  <div class="card-body">
    <form [ngClass]="{'needs-validation': !frmCardapio.submitted, 'was-validated': frmCardapio.submitted}"
          novalidate #frmCardapio="ngForm" (ngSubmit)="onSubmitCardapio()">


      <div *ngIf="exibirSalvar" class="mb-2 alert alert-blue">

        <b>Imagem está correta?</b>
        <button type="submit" class="btn btn-primary waves-effect waves-light  ml-2" [disabled]="salvando">Confirmar</button>
        <button type="button" class="btn btn-light  waves-effect waves-light  ml-2" (click)="canceleImagem()">Voltar</button>

      </div>



      <div class="alert alert-success alert-dismissible fade show mb-2" *ngIf="mensagemSucesso" role="alert">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucesso}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="fecheMensagemSucesso()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>



      <app-upload-imagem #upload [objeto]="cardapio"  [prop]="'arquivo'"  [label]="'Arquivo'"  [lg]="true" [restricoes]="{allowedExtensions: ['.jpg', '.jpeg', '.png', '.pdf']}"
                         (onEnviou)="exibirSalvar = true;"></app-upload-imagem>
    </form>
  </div>
</div>


<div class="card">
  <div class="card-body">
    <form #frmConfigCardapio="ngForm" (ngSubmit)="onSubmitConfigsCardapio()">
      <div class="alert alert-success" role="alert" *ngIf="mensagemSucessoCardapio">
        <i class="mdi mdi-check-all mr-2"></i> {{mensagemSucessoCardapio}}
      </div>

      <div class="form-group">
        <label>Cardápio em modo visualização</label>
        <div class="mt-0 mb-1 font-24 valor">
          <kendo-switch name="exibirCardapio" [(ngModel)]="cardapio.modoVisualizacao"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>
        <small  class="form-text text-muted">Se habilitado o cardápio não receberá pedidos
          e servirá apenas para consulta.</small>
      </div>
      <div class="form-group mt-1">
        <label>Cardápio QRCode em modo visualização</label>
        <div class="mt-0 mb-1 font-24 valor">
          <kendo-switch name="exibirCardapioQRcode" [(ngModel)]="cardapio.modoVisualizacaoQRcode"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>
        <small  class="form-text text-muted">Se habilitado o cardápio de Mesa não receberá pedidos
          e servirá apenas para consulta.</small>
      </div>
      <div class="form-group mt-1">
        <label>Exbir produtos indisponíveis no cardápio da Loja</label>
        <div class="mt-0 mb-1 font-24 valor">
          <kendo-switch name="exibirIndisponiveis" [(ngModel)]="cardapio.exibirIndisponiveis"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>
        <small id="emailHelp" class="form-text text-muted">Itens não poderão ser comprados, mas serão exibidos no cardápio.</small>
      </div>


      <div class="form-group mt-1">
        <label><i class="fa fa-table"></i> Exibir produtos com valor zerado para mesas</label>
        <div class="mt-0 mb-1 font-24 valor">
          <kendo-switch name="exibirProdutosValorZeradoMesa" [(ngModel)]="cardapio.exibirProdutosValorZeradoMesa"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>
        <small id="ex" class="form-text text-muted">Usado por clientes que oferecem rodízio.</small>
      </div>

      <div class="form-group">
        <label>Exibir seleção de produtos por categorias no cardápio da Loja </label>
        <div class="mt-0 mb-1 font-24 valor">
          <kendo-switch name="exibirSelecaoCategorias" [(ngModel)]="cardapio.exibirSelecaoCategorias"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>
        <small  class="form-text text-muted">
          se habilidado, na loja sera exibido os cards das categorias .</small>
      </div>

      <div class="ml-1 mt-1">
        <label>Para testar o chatbot, ative o modo teste</label>
        <div class="ui-g-12 mt-1">
          <label class="k-radio-label" for="cardapio">
            <input type="checkbox" name="modo_teste_chat_bot" id="cardapio" class="k-checkbox right" kendoCheckBox
                   [(ngModel)]="cardapio.modoTesteBot" value="Modo Teste" required/>
            &nbsp;Modo Teste
          </label>
        </div>
      </div>


      <!--<div class="ml-1 mt-1">
        <label>Notificaçoes novo pedido somente na confirmação do pagamento online</label>
        <div class="mt-0 mb-1 font-24 valor">

          <kendo-switch name="exibirIndisponiveis" [(ngModel)]="cardapio.exibirIndisponiveis"
                        [onLabel]="'Sim'"
                        [offLabel]="'Não'"></kendo-switch>
        </div>

        <small  class="form-text text-muted">
          Se habilitado a notifição para pagamentos online será feita somente na confirmaçao do pagamento</small>

      </div> -->

      <div class="ml-1 mt-1">
        <label>Limitar quantidade produtos por pedido</label>
        <div class="form-group  ">
          <kendo-numerictextbox [attr.type]="'number'"    class="form-control" [format]="'máximo ##### produtos por pedido'"   name="limiteProdutos"
                                [(ngModel)]="cardapio.limiteProdutos"
                                [min]="0"   placeholder="quantidade máxima de produtos por pedido" >   </kendo-numerictextbox>
        </div>

      </div>


      <div class="mt-2 ml-1">
        <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
      </div>
    </form>
  </div>
</div>

