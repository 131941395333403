<div class="container-fluid">
  <!-- Loading State -->
  <div class="loading-container" *ngIf="loading">
    <div class="k-i-loading"></div>
  </div>

  <div class="dashboard-container" *ngIf="empresa && !loading">
    <!-- Mensagem de Sucesso -->
    <div class="alert alert-success fade show" role="alert" *ngIf="msgSucesso">
      <i class="mdi mdi-check-all"></i> {{msgSucesso}}
    </div>

    <!-- Header com Ações -->
    <div class="dashboard-header">
      <div class="left-section">
        <!-- Botão Voltar -->
        <button class="btn btn-outline-secondary btn-voltar" (click)="voltar()">
          <i class="fas fa-arrow-left"></i>
          Voltar
        </button>
        
        <h4 class="page-title">
          <i class="fas fa-file-alt"></i>
          Cartões Cliente
        </h4>
        <button class="btn btn-primary" (click)="novo()">
          <i class="fe-plus"></i>
          Novo Cartão
        </button>
      </div>
      <div class="search-container">
        <kendo-textbox
          name="filtro"
          [(ngModel)]="filtro"
          [showSuccessIcon]="false"
          placeholder="Pesquisar cartões...">
          <ng-template kendoTextBoxSuffixTemplate>
            <button kendoButton [look]="'clear'" [icon]="'search'"></button>
          </ng-template>
        </kendo-textbox>
      </div>
    </div>

    <!-- Grid de Cartões -->
    <div class="cartoes-grid">
      <div class="cartao-item" *ngFor="let cartao of objetos | filtroObjetosNome: filtro">
        <!-- Card do Cartão -->
        <div class="cartao-card">
          <div class="cartao-card-header">
            <div class="codigo-container">
              <h3 class="codigo-cartao">
                <i class="fas fa-file-alt"></i>
                <span class="codigo-texto">{{cartao.codigo}}</span>
              </h3>
              <div class="codigo-badge">
                <span [class.badge-success]="cartao.ativo" [class.badge-danger]="!cartao.ativo">
                  {{cartao.ativo ? 'Ativo' : 'Inativo'}}
                </span>
              </div>
            </div>
            <div class="cartao-actions">
              <button (click)="editar(cartao)" class="btn-action edit" kendoTooltip title="Editar">
                <i class="fa fa-edit"></i>
              </button>
              <button class="btn-action delete" kendoTooltip title="Remover" (click)="abraDialogRemover(cartao)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>

          <div class="cartao-card-body">
            <div class="qr-container">
              <div class="qr-image">
                <img [src]="'/qrcode/' + cartao.codigo" alt="QR Code Cartão"/>
              </div>
              <div class="qr-info">
                <h5>
                  <i class="fas fa-file-alt"></i>
                  Cartão {{cartao.codigo}}
                </h5>
                <div class="status-container">
                  <i class="fas fa-circle" [class.text-success]="cartao.ativo" [class.text-danger]="!cartao.ativo"></i>
                  <span class="status-text" [class.text-success]="cartao.ativo" [class.text-danger]="!cartao.ativo">
                    {{cartao.ativo ? 'Ativo' : 'Inativo'}}
                  </span>
                </div>
                <div class="data-criacao">
                  <i class="fas fa-calendar-alt"></i>
                  Criado em: {{cartao.dataCriacao | date:'dd/MM/yyyy HH:mm'}}
                </div>
                <a [href]="'/qrcode/' + cartao.codigo" class="btn btn-success btn-sm download-qr" download>
                  <i class="fas fa-download"></i>
                  Download QR Code
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mensagem quando não há cartões -->
      <div class="empty-state" *ngIf="objetos.length === 0">
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle"></i>
          <span>Você ainda não tem cartões cadastrados</span>
        </div>
      </div>

      <!-- Mensagem quando nenhum cartão é encontrado -->
      <div class="empty-state" *ngIf="filtro && (objetos | filtroObjetosNome: filtro).length === 0">
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle"></i>
          <span>Nenhum cartão encontrado para <strong>{{filtro}}</strong></span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal de Confirmação -->
<kendo-dialog title="Confirmar a remoção" *ngIf="modalDeletar" (close)="close('cancel')" [minWidth]="250" [width]="450">
  <p class="confirmation-message">
    Você tem certeza que deseja apagar o cartão <strong>{{objetoApagar.codigo}}</strong>?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('sim')" [primary]="true">Sim</button>
    <button kendoButton (click)="close('nao')">Não</button>
  </kendo-dialog-actions>
</kendo-dialog>
