import {IItemVenda} from "../../../server/domain/interfaces/venda/ivenda";

export class ProdutoCashbackUtils{
  static calculeCashbakDosProdutos(itens: any, pontuacaoRegistrada){
    if (!pontuacaoRegistrada || !pontuacaoRegistrada.atividades || !pontuacaoRegistrada.atividades.length) return 0;

    if (pontuacaoRegistrada.valor < pontuacaoRegistrada.cartao.plano.valorMinimoPontuar)
      return 0;

    let atividade = pontuacaoRegistrada.atividades[0];

    if(itens && itens.length){
      // Calcula o total bruto da venda antes do desconto
      const totalBruto = itens.reduce((soma, item) => soma + (item.total), 0);
      const fatorDesconto: number = totalBruto > 0   ? (pontuacaoRegistrada.valor ) / totalBruto : 1;

      return itens.reduce((soma: number, item: IItemVenda) => {
        // Criamos um novo objeto mantendo a referência do método
        const itemAjustado: IItemVenda = fatorDesconto !== 1 ?
          Object.create(Object.getPrototypeOf(item), Object.getOwnPropertyDescriptors(item)) : item;
        // Ajusta o valor do item considerando o desconto proporcional
        itemAjustado.total =  item.total * fatorDesconto;
        // Usa a função obtenhaPontosFidelidade() com o valor ajustado
        return soma + itemAjustado.obtenhaPontosFidelidade(atividade);
      }, 0);
    } else {
      if(atividade.cashback){
        let valorCashback = pontuacaoRegistrada.valor * atividade.cashback;

        pontuacaoRegistrada.pontosInformado = Number((Math.round(valorCashback * 100) / 100).toFixed(2));
      }

      return pontuacaoRegistrada.pontosInformado || 0;
    }
  }
}
