import {ItemPedido} from "./ItemPedido";
import {Entrega} from "./Entrega";
import {Pagamento} from "./Pagamento";
import {IUITipoDePontuacaoFactory} from "../../objetos/IUITipoDePontuacaoFactory";
import {PontuacaoRegistrada} from "../../../../server/domain/PontuacaoRegistrada";
import {FormatadorPontos} from "./FormatadorPontos";
import {DTOEmpresa} from "./DTOEmpresa";
import {FormaDeEntrega} from "../../../../projects/loja/src/objeto/FormaDeEntrega";
import {CupomPedido} from "../../../../projects/loja/src/objeto/CupomPedido";

const CASHBACK = 'Reais';

export class PedidoLoja {
  id: number
  itens = [];
  total = 0.0;
  totalResgatado = 0;
  subTotal = 0.0;
  qtde = 0;
  entrega: Entrega;
  troco: number;
  novosEnderecos = [];
  trocas = [];
  pagamentos: Array<Pagamento>;
  contato: any;
  codigo: string;
  desconto: number;
  descontoFormaDePagamento: number;
  cashback: any;
  resgate: any;
  observacoes: string;
  dataEntrega: Date
  horarioEntrega: Date
  integracao: any;
  pontosReceber: string;
  timestamp: any
  cupom: CupomPedido;
  codigoCupom: string;
  taxaFormaDePagamento: number;
  mesa: any;
  dtoEmpresa: DTOEmpresa;
  guid: string
  removeuExtras: boolean;
  balcao: boolean;
  endereco: any;
  acumulo = 'pts'
  codigoComanda: string;
  cartaoClienteId= '';

  constructor() {
    this.entrega = new Entrega();
    this.pagamentos = [];
    this.desconto = 0;
    this.contato =  {}
    this.timestamp = new Date().getTime();
  }


  atualize(indice: number, item: any){
    let itemPedido =  this.itens[indice];

    itemPedido.qtde = item.qtde;
    itemPedido.observacao = item.observacao;
    itemPedido.adicionais = item.adicionais;
    itemPedido.produtoTamanho = item.produtoTamanho;
    itemPedido.sabores = item.sabores || [];

    itemPedido.atualizeTotal();

    this.calculeTotal();

    return itemPedido

  }

  adicione(produto: any, qtde: number, observacao: string, adicionais: any, tamanho: any , sabores: any) {
    let itemPedido = new ItemPedido(produto, qtde, observacao);
    itemPedido.adicionais = adicionais;
    itemPedido.produtoTamanho = tamanho;
    itemPedido.sabores = sabores || [];
    itemPedido.atualizeTotal();
    this.itens.push(itemPedido);

    this.calculeTotal();

    if(produto.brinde && produto.cupom){
      this.cupom = produto.cupom;
      this.cupom.brindeResgate = true;
    }

    return itemPedido
  }

  setTrocas() {
    this.trocas = [];

    if(this.integracao && this.integracao.resgatarBrinde){
      let cartaoResgate = this.contato.cartoes.find((item: any) => item.plano.id === this.integracao.plano.id);
      if(cartaoResgate)
        this.resgate = { cartao: cartaoResgate, usar: true }
    } else{
      this.contato.cartoes.forEach( (cartao) => {
        if(cartao.plano.tipoDeAcumulo ===  CASHBACK  ){
          if(!this.cashback)  this.cashback = {  };

          this.cashback.cartao = cartao;

        } else {
          let resgate = { cartao: cartao, brindes: []  }  ;
          this.trocas.push(resgate);
        }
      })
    }
  }

  limpeTrocas(){
    this.trocas = [];
    this.resgate = null;
    this.cashback = null;
  }

  setCashbackPagamento(pagamento: any, cartoes: any){
    cartoes.forEach( (cartao) => {
      if(cartao.plano.tipoDeAcumulo ===   CASHBACK){
        this.cashback = { id: pagamento.id, cartao: cartao, valor: pagamento.valor }
      }
    })
  }

  obtenhaSubTotal() {

    let subTotal = 0.0;


    this.itens.forEach( ( itemPedido: any) => {
      subTotal += itemPedido.obtenhaValor();
    });

    return  Number(subTotal.toFixed(2));
  }

  obtenhaValorTaxaEntrega(){
    if(!this.entrega) return 0;

    let taxaDeEntrega: number = this.entrega.taxaDeEntrega;

    if(this.pagamentos && this.pagamentos.length > 0){
      let cobraTaxaRetorno = false;

      this.pagamentos.forEach((pagamento: any) => {
        if(pagamento && pagamento.formaDePagamento && pagamento.formaDePagamento.cobrarTaxaRetorno)
          cobraTaxaRetorno = true
      })

      if(cobraTaxaRetorno && this.entrega.valorComRetorno)
        taxaDeEntrega =  this.entrega.valorComRetorno;

    }

    return taxaDeEntrega;
  }

  removaCupom() {
    delete this.codigoCupom
    delete this.cupom;
    this.desconto = 0;
    this.calculeTotal();
  }

  calculeTotal() {
    this.qtde = this.itens.length;
    this.total = 0.0;

    this.itens.forEach((itemPedido: any) => {
      if (!itemPedido.brinde)
        this.total += itemPedido.obtenhaValor();
    })

    this.subTotal = this.total;

    if(this.desconto > 0)
      this.total -= this.desconto

    if(this.entrega.taxaDeEntrega)
      this.total += this.obtenhaValorTaxaEntrega();

    this.total = Number( this.total.toFixed(2))

    this.descontoFormaDePagamento = 0;
    this.taxaFormaDePagamento = 0;

    let pagamentos = this.pagamentos.filter((item: any) => item.valor && !item.removido)

    let pagamentoUnico = pagamentos.length === 1;

    for(let pagamento of this.pagamentos ) {
      if (pagamento.formaDePagamento && pagamento.formaDePagamento.taxaCobranca)
        this.taxaFormaDePagamento += pagamento.taxa;

      if(pagamento.formaDePagamento && pagamento.formaDePagamento.possuiDesconto){
        if(pagamentoUnico) {
          let totalSemTaxa = this.total - (this.entrega.taxaDeEntrega || 0)
          if(this.cashback && this.cashback.valor)
            totalSemTaxa -= this.cashback.valor

          this.descontoFormaDePagamento = Number((totalSemTaxa * pagamento.formaDePagamento.desconto / 100).toFixed(2))
        } else if(pagamento.desconto){
          this.descontoFormaDePagamento += pagamento.desconto
        }
      }
    }

    this.total -= this.descontoFormaDePagamento
    this.total += this.taxaFormaDePagamento;

    this.totalResgatado  = this.obtenhaTotalPontos();

    this.calculeTroco();
    this.calculePontosAhReceber();
  }

  obtenhaTotalPontos(){
    let  totalEmPontos = 0;
    this.itens.forEach( ( itemPedido: any) => {
      if(itemPedido.valorResgatado)
        totalEmPontos += itemPedido.valorResgatado;
    });

    return  Number(totalEmPontos.toFixed(2));
  }

  calculePontosAhReceber() {
    delete this.pontosReceber;

    if (this.integracao && this.integracao.pontuando) {
      //nao pontuar novos pedidos....
      if(this.integracao.pontuarSoLoja && !this.id) return;
      if(this.mesa && !this.integracao.pontuarMesas) return;

      let pontuacaoRegistrada: PontuacaoRegistrada = new PontuacaoRegistrada();

      pontuacaoRegistrada.valor = this.subTotal;
      pontuacaoRegistrada.cartao = this.integracao.cartao ? this.integracao.cartao : {};
      pontuacaoRegistrada.cartao.plano = this.integracao.plano;
      pontuacaoRegistrada.atividades = [this.integracao.atividade];

      if(this.obtenhaTotalDescontosProdutos())
        pontuacaoRegistrada.valor -= this.obtenhaTotalDescontosProdutos()


      let tipoDePontuacao = this.integracao.plano.tipoDePontuacao;

      let pontos =
        IUITipoDePontuacaoFactory.obtenhaTipodePontuacao(this.dtoEmpresa, tipoDePontuacao).
                  calculePontuacao(pontuacaoRegistrada, this.itens);

      this.pontosReceber =
        FormatadorPontos.obtenhaPtsFormatado(this.integracao.plano.tipoDePontuacao, this.integracao.plano.tipoDeAcumulo, pontos)

    }
  }

  removaItem(item: any) {
    let index = this.itens.indexOf(item);

    this.removaItemNaPosicao(index)
  }

  removaItemNaPosicao(posicao) {
    let removidos = this.itens.splice(posicao, 1);

    if(removidos.length) {
      let removido = removidos[0]

      if(removido.produto && removido.produto.nome === 'Extras do Pedido') {
        console.log('removeu extras do pedido')
        this.removeuExtras = true
      }

    }


    this.calculeTotal();

  }

  expirou(){
    let tempoMinutos =  (new Date().getTime() - this.timestamp) / 1000 / 60;

    let expirado = tempoMinutos >  8 * 60 ; //8h;

    return   expirado
  }

  getItem(produto: any): ItemPedido{
    return this.itens.find( item => item.produto.id === produto.id)
  }

  obtenhaValorAhPagar() {
    let valor = this.total;

    if(this.cashback && this.cashback.valor)
      valor -= this.cashback.valor;

    if(this.taxaFormaDePagamento)
      valor -= this.taxaFormaDePagamento;

    return Number(valor.toFixed(2));
  }

  obtenhaValorAhPagarProdutos(){
    let valorAhPagar = this.obtenhaValorAhPagar();

    let totalTaxaEntrega = this.obtenhaValorTaxaEntrega();

    return Number((valorAhPagar - totalTaxaEntrega).toFixed(2))
  }


  calculeTroco() {
    this.troco = 0;
    for( let pagamento of this.pagamentos ) {
      if (pagamento.formaDePagamento.nome === 'dinheiro' && pagamento.trocoPara ) {
        this.troco = pagamento.trocoPara -  pagamento.valor;
        if (this.troco < 0) this.troco = 0;
      }
    }
  }

  obtenhaSaldo() {
    if(this.resgate) return this.resgate.cartao.pontos

    if(!this.cashback) return 0;

    if(this.cashback.id) return this.cashback.valor;

    let pontos = this.cashback.cartao.pontos;

    if(this.id && this.cashback && this.cashback.valor)
      pontos = Number( (pontos +  this.cashback.valor).toFixed(2));

    return pontos;
  }

  obtenhaTotalPago() {
    let totalPago = 0.0;

    if (this.cashback && this.cashback.valor)
      totalPago = this.cashback.valor;


    for(let pagamento of this.pagamentos)
      totalPago += pagamento.valor;


    return Number(totalPago.toFixed(2));
  }

  temTaxaPagamento(){
    let pagamentosComtaxa = this.pagamentos.filter((item: any) => item.taxa)

    return pagamentosComtaxa.length
  }

  temDescontoPagamento(){
    let pagamentosComtaxa = this.pagamentos.filter((item: any) => item.desconto)

    return pagamentosComtaxa.length
  }

  obtenhaTotalPagamentosSemTaxaDaForma() {
    let totalSemTaxa = this.obtenhaTotalPago();

    for(let pagamento of this.pagamentos)
      if(pagamento.taxa && !pagamento.removido)
        totalSemTaxa -= pagamento.taxa;

    return Number(totalSemTaxa.toFixed(2));
  }

  obtenhaTotalPedidoSemTaxaForma(){
    let total = this.total;

    if(this.taxaFormaDePagamento)
       total -= this.taxaFormaDePagamento;

    return Number(total.toFixed(2))
  }


  obtenhaValorRestanteAhPagar(pagamentoEditando: any = null) {
    let valor = this.total;

    if(this.cashback && this.cashback.valor)
      valor -= this.cashback.valor;

    if(this.taxaFormaDePagamento)
      valor -= this.taxaFormaDePagamento

    let totalPago = 0.0;
    for(let pagamento of this.pagamentos) {
      if(!pagamentoEditando || pagamentoEditando.id !== pagamento.id  )
      totalPago +=  pagamento.valor;
    }

    valor -= totalPago;

    if( valor < 0 ) {
      valor = 0;
    }

    return Number(valor.toFixed(2));
  }

  temTroco(){
    return this.pagamentos.find((pg: any) => !pg.removido && pg.trocoPara > 0) != null
  }

  obtenhaTrocoPara(){
    let pagamento = this.pagamentos.find((pg: any) => !pg.removido && pg.trocoPara > 0);

    return pagamento ? pagamento.trocoPara : 0;
  }

  obtenhaTotalDescontosProdutos(){
    return (this.desconto || 0) + (this.descontoFormaDePagamento + 0) + (this.cashback ? (this.cashback.valor || 0) : 0)
  }

  permiteAgendamento(nomeForma: string, formasDeEntrega: any) {
    let formaDeEntrega = this.obtenhaFormaEscolhida( formasDeEntrega, nomeForma)

    return formaDeEntrega ?  formaDeEntrega.permiteAgendamento : false;
  }

  obtenhaFormaEscolhida( formasDeEntrega: any, nomeForma: string = this.entrega.formaDeEntrega){
    if(!formasDeEntrega) return null;

    //todo: :( gambi, hoje salva string a forma, retirar quando mudar para salvar obj
    if(nomeForma === FormaDeEntrega.COMERNOLOCAL)
      nomeForma = FormaDeEntrega.RETIRAR;

    return formasDeEntrega.find( (forma: any) => forma.nome ===  nomeForma );

  }

  private obtenhaIdFormaEntrega(formaDeEntrega: FormaDeEntrega) {
    switch (formaDeEntrega) {
      case FormaDeEntrega.RETIRAR:
        return {id: 1, nome: formaDeEntrega};
      case FormaDeEntrega.COMERNOLOCAL:
        return {id: 1, nome: formaDeEntrega, comerNoLocal: true};
      case FormaDeEntrega.RECEBER_EM_CASA:
        return {id: 2, nome: formaDeEntrega};
      default:
        return null;
    }
  }

  apliqueCupom(dados: any) {
    if(dados && dados.desconto >= 0) {
      this.cupom = new CupomPedido(dados.id, dados.desconto, dados.codigo, dados.aplicarNaTaxaDeEntrega,
        dados.naoPontuarFidelidade, dados.aplicarAuto, dados.cashback, dados.brindeResgate);

      if(dados.erro)
        this.cupom.erro = dados.erro;

      this.desconto = this.cupom.desconto;
      //this.limpeTroco();
      this.calculeTotal();
    }
  }


  obtenhaDadosEnvio() {
      const objPedido: any = {
        id: this.id,
        codigo: this.codigo,
        contato: this.contato,
        formaDeEntrega: this.obtenhaIdFormaEntrega(this.entrega.formaDeEntrega),
        pagamentos: [],
        observacoes: this.observacoes,
        cupom: this.cupom,
        endereco: this.entrega.endereco,
        taxaEntrega: this.entrega.taxaDeEntrega,
        itens: this.itens,
        removeuExtras: this.removeuExtras,
        desconto: this.desconto,
        mesa: this.mesa
      };

      if(this.balcao) objPedido.origem = 'balcao';

      objPedido.mesa = this.mesa;
     // objPedido.garcom = this.garcom;

      if(this.dataEntrega) {
        let dataEntrega = new Date(this.dataEntrega)
        let horarioEntrega = new Date(this.horarioEntrega)
        let horarioEntregaAgendadaCompleto = dataEntrega

        horarioEntregaAgendadaCompleto = dataEntrega
        horarioEntregaAgendadaCompleto.setHours(horarioEntrega.getHours())
        horarioEntregaAgendadaCompleto.setMinutes(horarioEntrega.getMinutes())
        objPedido.horarioEntregaAgendada = horarioEntregaAgendadaCompleto
        objPedido.textoHorarioEntregaAgendada = horarioEntregaAgendadaCompleto.toLocaleString("pt-BR")
      }

      if(this.cashback && this.cashback.valor){
        objPedido.pagamentos.push({
          id: this.cashback.id,
          valor: this.cashback.valor,
          cartao: this.cashback.cartao,
          formaDePagamento:  this.cashback.formaDePagamento
        });
      }

      if( !objPedido.mesa ) {
        for (let pagamento of this.pagamentos) {
          objPedido.pagamentos.push({
            id: pagamento.id,
            trocoPara: pagamento.trocoPara,
            valor: pagamento.valor,
            formaDePagamento: pagamento.formaDePagamento
          });
        }
      }

      return objPedido;
  }
}
