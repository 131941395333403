import { Component, OnInit } from '@angular/core';
import {Mesa} from "../../objetos/Mesa";
import {MesaService} from "../../../services/mesa.service";
import {ConstantsService} from "../../../fidelidade/ConstantsService";
import moment from "moment";
import {ComandaService} from "../../../services/comanda.service";
import {AutorizacaoService} from "../../../services/autorizacao.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PedidosService} from "../../../services/pedidos.service";
import {MonitoradorPedidos} from "../../../fidelidade/MonitoradorPedidos";

@Component({
  selector: 'app-tela-pedidos-mesas',
  templateUrl: './tela-pedidos-mesas.component.html',
  styleUrls: ['./tela-pedidos-mesas.component.scss']
})
export class TelaPedidosMesasComponent implements OnInit {
  objetos: Array<Mesa> = [];
  empresa: any;
  carregando = false;
  modoGarcom: boolean;
  possuiModuloGarcom = false;
  mesasBloqueadas = false;
  comErros = false;
  selectedTab: string = 'aberto';
  mesas: any[] = [];
  mesaSelecionada: any;
  comandasAtuais: any[] = [];
  modalComandaAberto: boolean = false;
  filtro: string = '';

  opcoesConfiguracao = [
    { text: 'Gerenciar Mesas', icon: 'table', value: 'mesas' },
    { text: 'Cartão Cliente', icon: 'window', value: 'cartao' }
  ];

  constructor(private constantsService: ConstantsService, private mesaService: MesaService,
              private route: ActivatedRoute, private router: Router, private pedidosService: PedidosService,    public monitoradorPedidos: MonitoradorPedidos,
              private comandaService: ComandaService, private autorizacaoService: AutorizacaoService) { }

  ngOnInit(): void {
    if (this.comErros) {
      this.selectedTab = 'erro';
    }
    this.constantsService.empresa$.subscribe( (empresa) => {
      if (!empresa) return;

      if(this.route.snapshot.routeConfig.path.endsWith('erros'))
        this.comErros = true;


      this.constantsService.moduloGarcom$.subscribe( (data) => {
        this.possuiModuloGarcom = data;

       //adiciona a opção Garçons
        if(this.possuiModuloGarcom || empresa?.integracaoDelivery?.sistema ==='ecletica'){
          this.opcoesConfiguracao.push({ text: 'Garçons', icon: 'user', value: 'garcons' });
        }
      });

      if(this.autorizacaoService.getUser().garcom) this.modoGarcom = true;
      this.empresa = empresa;


      if(empresa.integracaoDelivery){
        let sistema = empresa.integracaoDelivery.sistema;
        this.mesasBloqueadas = sistema === 'gcom';
      }

      if(!this.comErros){
        this.pedidosService.listePedidosMesaComErro().then( (resposta) => {
          if(resposta.pedidos)
            this.monitoradorPedidos.notifiqueMesasComErro(resposta.pedidos.length)
        });
      }

      this.carregarMesas();

    });
  }

  novo() {}

  onConfiguracaoClick(event: any) {
    if( event.value === 'cartao'){
      this.router.navigate(['/admin/pedidos-mesas/cartoes-cliente']);
    }else{
      this.selectedTab = event.value;
    }
  }

  carregarMesas() {
    this.mesaService.obtenhaMesas(this.empresa).then((response: any) => {
      // Ajuste conforme a estrutura real da resposta
      const todasMesas = response.dados || response || [];

      // Filtrar apenas mesas em uso (não disponíveis) e válidas
      this.mesas = todasMesas.filter(mesa =>
        !mesa.disponivel &&
        mesa.id &&
        mesa.nome // Garantir que a mesa tenha ID e nome válidos
      );

      // Processar as mesas para adicionar informações como valor total, etc.
      this.mesas.forEach(mesa => {
        // Calcular valor total usando o método específico
        mesa.valorTotal = this.calcularValorTotalMesa(mesa);

        // O tempo já vem calculado do servidor como mesa.tempo
        // Não é necessário recalcular
      });

      console.log('Mesas em uso processadas:', this.mesas);
    }).catch(erro => {
      console.error('Erro ao carregar mesas:', erro);
      this.mesas = []; // Garantir que mesas seja um array vazio em caso de erro
    });
  }

  verComandas(mesa: any) {
    if (mesa.disponivel) {
      // Se a mesa estiver disponível, abrir diretamente uma nova comanda
      this.mesaSelecionada = mesa;
      this.onCriarNovaComanda();
    } else {
      // Se a mesa estiver ocupada, mostrar as comandas existentes
      this.mesaSelecionada = mesa;
      this.comandasAtuais = mesa.comandas || [];
      this.modalComandaAberto = true;
    }
  }

  adicionarComanda(mesa: any) {
    // Verificar se a empresa permite múltiplas comandas por mesa
    if (!this.empresa.permitirMultiplasComandasMesa && mesa.comandas && mesa.comandas.length > 0) {
      // Se não permitir múltiplas comandas e já existir uma comanda, não permitir adicionar nova
      alert('Esta empresa não permite múltiplas comandas por mesa.');
      return;
    }

    this.mesaSelecionada = mesa;
    this.onCriarNovaComanda();
  }

  onSelecionarComanda(comanda: any) {
    // Navegar para a tela de detalhes da comanda
    this.router.navigate(['/pedidos/mesa/comanda', comanda.id]);
    this.modalComandaAberto = false;
  }

  onCriarNovaComanda() {
    // Lógica para criar nova comanda
    this.mesaService.crieNovaComanda(this.mesaSelecionada.id).then((response: any) => {
      if (response.sucesso) {
        // Navegar para a nova comanda
        this.router.navigate(['/pedidos/mesa/comanda', response.dados.id]);
        this.modalComandaAberto = false;
      }
    }).catch(erro => {
      console.error('Erro ao criar nova comanda:', erro);
    });
  }

  onFecharModal() {
    this.modalComandaAberto = false;
  }

  abrirNovaMesa() {
    // Navegar para a tela de novo pedido de mesa com a URL correta
    this.router.navigate(['/admin/pedidos/novo-mesa']);
  }

  calcularValorTotalMesa(mesa: any): number {
    if (!mesa.comandas || mesa.comandas.length === 0) {
      return 0;
    }

    let total = 0;
    mesa.comandas.forEach(comanda => {
      // Verificar diferentes propriedades que podem conter o valor
      if (comanda.valorTotal && !isNaN(parseFloat(comanda.valorTotal))) {
        total += parseFloat(comanda.valorTotal);
      } else if (comanda.valor && !isNaN(parseFloat(comanda.valor))) {
        total += parseFloat(comanda.valor);
      } else if (comanda.itens && Array.isArray(comanda.itens)) {
        // Se a comanda tiver itens, somar os valores dos itens
        comanda.itens.forEach(item => {
          if (item.valorTotal && !isNaN(parseFloat(item.valorTotal))) {
            total += parseFloat(item.valorTotal);
          } else if (item.valor && !isNaN(parseFloat(item.valor))) {
            total += parseFloat(item.valor);
          }
        });
      }
    });

    return total;
  }

  formatarValorTotal(valor: number): string {
    return valor.toFixed(2).replace('.', ',');
  }

  limparFiltro() {
    this.filtro = '';
  }

  /**
   * Navega para a tela de detalhes da comanda quando o botão Detalhes é clicado
   * @param mesa A mesa que contém a comanda a ser visualizada
   */
  verDetalhesComanda(mesa: any) {
    // Quando não permite múltiplas comandas, sabemos que existe apenas uma comanda
    if (mesa.comandas && mesa.comandas.length > 0) {
      const comanda = mesa.comandas[0];
      // Usar a mesma rota que é usada no método onSelecionarComanda
      this.router.navigate(['/admin/fechar-mesa', comanda.id]);
    }
  }

  /**
   * Navega para a tela de novo pedido passando a comanda existente
   * @param mesa A mesa que contém a comanda para adicionar um novo pedido
   */
  novoPedidoComanda(mesa: any) {
    // Quando não permite múltiplas comandas, sabemos que existe apenas uma comanda
    if (mesa.comandas && mesa.comandas.length > 0) {
      const comanda = mesa.comandas[0];
      // Navegar para a tela de fazer pedido passando todos os parâmetros necessários
      this.router.navigate(['/admin/pedidos/novo'], {
        queryParams: {
          idm: mesa.id,
          idc: comanda.id,
          modoEdicao: true,
          urlRetorno: '/admin/pedidos-mesas'
        }
      });
    }
  }
}
