import {IUITipoDePontuacao} from "./IUITipoDePontuacao";
import {UITipoDePontuacaoCashback} from "./UITipoDePontuacaoCashback";
import {ProdutoCashbackUtils} from "./ProdutoCashbackUtils";

export class UITipoDePontuacaoQtdeVariavelAtividade implements IUITipoDePontuacao {
  empresa: any;

  constructor(empresa: any) {
    this.empresa = empresa;
  }

  calculePontuacao(pontuacaoRegistrada: any, itens: any) {
    return ProdutoCashbackUtils.calculeCashbakDosProdutos(itens, pontuacaoRegistrada)
  }
}
