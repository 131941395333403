<!-- Menu Lateral Moderno -->
<div class="left-side-menu modern-menu">
    <!-- Perfil do Usuário -->
    <div class="user-profile">
        <div class="profile-container">
            <div class="d-flex align-items-center">
                <div class="avatar-wrapper">
                    <img src="/assets/fidelidade/icones/user-icon-cinza.png" alt="user-image" class="avatar">
                </div>
                <div class="user-info ml-3">
                    <h6 class="mb-1">{{usuario.nome | firstWord}}</h6>
                    <div class="empresa-info">
                        <small>{{usuario.empresa.id}} - {{usuario.empresa.nome}}</small>
                    </div>
                </div>
            </div>

            <div class="user-status mt-3">
                <span class="status-badge">
                    <i class="fas fa-paper-plane mr-1"></i>
                    {{usuario.empresa.meioDeEnvio}}
                </span>
                <span class="status-badge dark ml-2" *ngIf="empresa.dark">
                    <i class="fas fa-moon mr-1"></i>
                    DARK
                </span>
            </div>

            <div class="store-link mt-3" *ngIf="empresa.linkLoja">
                <a href="{{empresa.linkLoja}}" target="_blank" class="btn btn-outline-primary btn-sm btn-block">
                    <i class="fas fa-store mr-1"></i>
                    Loja
                </a>
            </div>
        </div>
    </div>

    <!-- Menu Principal -->
    <div id="sidebar-menu" [hidden]="!carregou">
        <!-- Menu Wizard -->
        <ul class="metismenu" id="side-menu-w" *ngIf="wizard">
            <li class="menu-item">
                <a (click)="naveguePara('wizard')" [ngClass]="{'active':url=='wizard'}" href="javascript: void(0);">
                    <i class="fas fa-magic"></i>
                    <span>Criação da Loja</span>
                </a>
            </li>
        </ul>

        <!-- Menu Principal -->
        <ul class="metismenu" id="side-menu" *ngIf="!wizard">
            <!-- Dashboard -->
            <li class="menu-item" *ngIf="!somenteCardapio && !ehGarcom">
                <a (click)="naveguePara('index')" [ngClass]="{'active':url=='index'}" href="javascript: void(0);">
                    <i class="fas fa-chart-line"></i>
                    <span>Dashboard</span>
                </a>
            </li>

          <!-- CRM de Contatos -->
          <li class="menu-item" *ngIf="!somenteCardapio && !ehGarcom && visualizarContatos()" >
            <a href="javascript: void(0);" class="has-arrow" (click)="naveguePara('contatos')" [ngClass]="{'active':url=='contatos' || url=='contatos-kanban'}" data-target="#menuClientes"
               data-toggle="collapse">
              <i class="fas fa-users"></i>
              <span>CRM de Contatos</span>
              <i class="fas fa-chevron-right menu-arrow"></i>
            </a>
            <ul class="nav-second-level collapse" id="menuClientes">
              <li>
                <a (click)="naveguePara('contatos')" [ngClass]="{'active':url=='contatos'}" href="javascript: void(0);">
                  <i class="fas fa-address-book"></i>
                  <span>Lista de Contatos</span>
                </a>
              </li>
              <li>
                <a (click)="naveguePara('contatos-kanban')" [ngClass]="{'active':url=='contatos-kanban'}" href="javascript: void(0);">
                  <i class="fas fa-columns"></i>
                  <span>Kanban de Contatos</span>
                </a>
              </li>
            </ul>
          </li>

            <li class="menu-item" *ngIf="exibirFuncoesPedidos || ehGarcom">
                <a (click)="naveguePara('pedidos-mesas')" [ngClass]="{'active':url==='pedidos-mesas'}"
                   href="javascript: void(0);" data-toggle="collapse" data-target="#menuMesas">
                    <i class="fa fa-table"></i>
                    <span> Mesas </span>
                    <span class="badge  rounded-circle noti-icon-badge" style="    margin-top: -10px;"
                          [ngClass]="{'badge-danger': resumoMesas.totalNovo >  0,
                                'badge-light': !resumoMesas.totalNovo }">
                        {{resumoMesas.totalNovo}}</span>
                </a>

                <ul class="nav-second-level collapse"  id="menuMesas" *ngIf="temIntegracaoParceiro()">
                    <li >
                        <a  (click)="naveguePara('pedidos-mesas/erros')" [ngClass]="{'text-primary text-bold':url=='pedidos-mesas/erros'}"
                            href="javascript: void(0);"  *ngIf="temIntegracaoParceiro()">
                          <i class="fas fa-times-circle"></i>
                           <span>  <b>{{ this.empresa.integracaoDelivery.sistema}}</b>  erros</span>
                            <span class="badge  rounded-circle noti-icon-badge"
                                  [ngClass]="{'badge-danger':  resumoPedidos.totalErroParceiroMesa > 0,   'badge-light': !resumoPedidos.totalErroParceiroMesa  }">
                                {{resumoPedidos.totalErroParceiroMesa}}</span>
                        </a>

                    </li>
                </ul>
            </li>

            <!-- Gestão de Caixa -->
            <li class="menu-item" *ngIf="possuiModuloPDV && visualizarCaixa()">
                <a (click)="naveguePara('meupdv')" [ngClass]="{'active':url=='meupdv'}" href="javascript: void(0);">
                    <i class="fas fa-cash-register"></i>
                    <span>Gestão de Caixa</span>
                </a>
            </li>

            <!-- Pedidos -->
            <li class="menu-item" *ngIf="exibirFuncoesPedidos">
                <a href="javascript: void(0);"  (click)="naveguePara('pedidos')"  [ngClass]="{'active':url=='pedidos' || url === 'pedidos/onlineaguardando'}"
                   data-toggle="collapse" data-target="#menuPedidos" click="return false;">
                    <i class="fas fa-shopping-cart"></i>
                    <span>Pedidos</span>
                    <i class="fas fa-chevron-right menu-arrow"></i>
                </a>
                <ul class="nav-second-level collapse" id="menuPedidos" *ngIf="temIntegracaoParceiro() || vendeOnline() || integracaoIfood()">
                  <li>
                    <a (click)="naveguePara('pedidos')" [ngClass]="{'active':url=='pedidos'}" href="javascript: void(0);">
                      <i class="fas fa-list"></i>
                      <span>Todos os Pedidos</span>
                    </a>
                  </li>
                  <li >
                    <a  (click)="naveguePara('pedidos/erros')" [ngClass]="{'text-primary text-bold':url=='pedidos/erros'}"
                        href="javascript: void(0);"  *ngIf="temIntegracaoParceiro()">
                      <i class="fas fa-times-circle"></i>
                      <span>
                        <b>{{ this.empresa.integracaoDelivery.sistema}}</b> erros
                      </span>
                      <span class="badge  rounded-circle noti-icon-badge  "
                            [ngClass]="{'badge-danger':  resumoPedidos.totalErroParceiro > 0,   'badge-light': !resumoPedidos.totalErroParceiro  }">
                  {{resumoPedidos.totalErroParceiro}}</span>
                    </a>

                    <a  (click)="naveguePara('pedidos/onlineaguardando')" [ngClass]="{'text-primary text-bold':url=='pedidos/onlineaguardando'}"
                        href="javascript: void(0);"  *ngIf="vendeOnline()">

                      <i class="fas fa-clock"></i>
                      <span>Online pendentes</span>
                      <span class="badge  rounded-circle noti-icon-badge"
                            [ngClass]="{'badge-danger':  resumoPedidos.totalPendenteOnline > 0,   'badge-light': ! resumoPedidos.totalPendenteOnline }">
                   {{resumoPedidos.totalPendenteOnline}}</span>
                    </a>

                    <ng-container *ngIf="integracaoIfood()">
                        <a  (click)="naveguePara('pedidos/contestados')" [ngClass]="{'text-primary text-bold':url=='pedidos/contestados'}"
                            href="javascript: void(0);"   >
                          <i class="fas fa-exclamation-triangle"></i>
                          <span> {{empresa?.integracoesIfood?.length ? 'Ifood ': ''}} Contestações</span>
                          <span class="badge  rounded-circle noti-icon-badge"
                                [ngClass]="{'badge-danger':  resumoPedidos.totalContestados > 0,   'badge-light': ! resumoPedidos.totalContestados }">
                     {{resumoPedidos.totalContestados}}</span>
                        </a>

                        <a  (click)="naveguePara('pedidos/errosimportar')" [ngClass]="{'text-primary text-bold':url=='pedidos/errosimportar'}"
                            href="javascript: void(0);"   >
                          <i class="fas fa-times-circle"></i>
                          <span>  {{empresa?.integracoesIfood?.length ? 'Ifood ': ''}} Erros Impor.</span>
                          <span class="badge  rounded-circle noti-icon-badge" [hidden]="!monitoradorPedidos.falhasImportacao.length "
                                [ngClass]="{'badge-danger':  monitoradorPedidos.falhasImportacao.length > 0,
                           'badge-light': !  monitoradorPedidos.falhasImportacao.length}">
                     {{ monitoradorPedidos.falhasImportacao.length}}</span>
                        </a>

                    </ng-container>

                  </li>

                </ul>
            </li>

            <!-- Produtos -->
           <ng-container *ngIf="exibirProdutos">
             <li class="menu-item" >
               <a href="javascript: void(0);"  (click)="naveguePara('produtos')" [ngClass]="{'active':url.includes('produtos')}" data-toggle="collapse" data-target="#menuProdutos">
                 <i class="fas fa-box-open"></i>
                 <span>Produtos</span>
                 <i class="fas fa-chevron-right menu-arrow"></i>
               </a>
               <ul class="nav-second-level collapse" id="menuProdutos">
                 <li>
                   <a (click)="naveguePara('produtos')" [ngClass]="{'active':url=='produtos'}" href="javascript: void(0);">
                     <i class="fas fa-boxes"></i>
                     <span>Cadastro de Produtos</span>
                   </a>
                 </li>
                 <li>
                   <a (click)="naveguePara('vitrines')" [ngClass]="{'active':url=='vitrines'}" href="javascript: void(0);">
                     <i class="fas fa-store-alt"></i>
                     <span>Vitrines</span>
                   </a>
                 </li>
               </ul>
             </li>

             <li *ngIf="exibirEstoque" >
               <a  (click)="naveguePara('estoque')" [ngClass]="{'text-primary text-bold':url=='estoque'}" href="javascript: void(0);">
                 <i class="fas fa-box"></i>
                 <span> Estoque </span>
               </a>

               <ul class="nav-second-level" aria-expanded="false">
                 <li>
                   <a (click)="naveguePara('estoque/alertas')" [ngClass]="{'text-primary text-bold':url=='estoque/alertas'}" href="javascript: void(0);">
                     <span> Resposição estoque </span>
                     <span class="badge  rounded-circle noti-icon-badge"
                           [ngClass]="{'badge-danger':  resumoEstoque.totalAlertas > 0,   'badge-light': ! resumoEstoque.totalAlertas }">
                     {{resumoEstoque.totalAlertas}}</span>
                   </a>
                 </li>
               </ul>
             </li>

           </ng-container>


            <!-- Loja -->
            <li class="menu-item" *ngIf="visualizarLoja()">
                <a href="javascript: void(0);" (click)="naveguePara('loja')" [ngClass]="{'active': (url === 'qrcodes' || url === 'taxas' || url === 'areas-entrega' || url === 'temas' || url === 'loja')}"
                   data-toggle="collapse" data-target="#menuLoja">
                    <i class="far fa-building"></i>
                    <span>Loja</span>
                    <i class="fas fa-chevron-right menu-arrow"></i>
                </a>
                <ul class="nav-second-level collapse" id="menuLoja">
                    <li>
                        <a (click)="naveguePara('qrcodes')" [ngClass]="{'active':url==='qrcodes'}" href="javascript: void(0);">
                            <i class="fas fa-qrcode"></i>
                            <span>QR Codes</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('taxas')" [ngClass]="{'active':url==='taxas'}" href="javascript: void(0);">
                            <i class="fas fa-calculator"></i>
                            <span>Hist. Taxas Calculadas</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('areas-entrega')" [ngClass]="{'active':url==='areas-entrega'}" href="javascript: void(0);">
                            <i class="fas fa-map-marked-alt"></i>
                            <span>Áreas de Entrega (KML)</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('temas')" [ngClass]="{'active':url==='temas'}" href="javascript: void(0);">
                            <i class="fas fa-paint-roller"></i>
                            <span>Temas</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- MIA - Atendente Virtual -->
            <li class="menu-item" *ngIf="exibirFuncoesChatbot && visualizarLoja()">
                <a href="javascript: void(0);" (click)="naveguePara('chatbot/configuracoes')" [ngClass]="{'active':url.includes('chatbot')}" data-toggle="collapse" data-target="#menuChatbot">
                    <i class="fas fa-robot"></i>
                    <span>MIA - Atendente</span>
                    <i class="fas fa-chevron-right menu-arrow"></i>
                </a>
                <ul class="nav-second-level collapse" id="menuChatbot">
                    <li>
                      <a (click)="naveguePara('chatbot/configuracoes')" [ngClass]="{'active':url=='chatbot/configuracoes'}" href="javascript: void(0);">
                        <i class="fas fa-cog"></i>
                        <span>Configurações</span>
                      </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('templates-prompts-db')" [ngClass]="{'active':url=='templates-prompts-db'}" href="javascript: void(0);">
                            <i class="fas fa-file-alt"></i>
                            <span>Templates</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('chatbot/conversas')" [ngClass]="{'active':url=='chatbot/conversas'}" href="javascript: void(0);">
                            <i class="fas fa-comments"></i>
                            <span>Conversas</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('chatbot/chamadas-ia')" [ngClass]="{'active':url=='chatbot/chamadas-ia'}" href="javascript: void(0);">
                            <i class="fas fa-microchip"></i>
                            <span>Chamadas IA</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('chatbot/testar')" [ngClass]="{'active':url=='chatbot/testar'}" href="javascript: void(0);">
                            <i class="fas fa-vial"></i>
                            <span>Ferramenta de Testes</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Financeiro -->
            <li class="menu-item" *ngIf="exibirProdutos && visualizaRelatorios()">
                <a href="javascript: void(0);" (click)="naveguePara('financeiro-delivery')" [ngClass]="{'active':url.includes('financeiro')}" data-toggle="collapse" data-target="#menuFinanceiro">
                    <i class="fe-bar-chart-2"></i>
                    <span>Financeiro</span>
                    <i class="fas fa-chevron-right menu-arrow"></i>
                </a>
                <ul class="nav-second-level collapse" id="menuFinanceiro">
                    <li>
                        <a (click)="naveguePara('financeiro-delivery')" [ngClass]="{'active':url=='financeiro-delivery'}" href="javascript: void(0);">
                            <span>Delivery</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="naveguePara('financeiro-mesas')" [ngClass]="{'active':url=='financeiro-mesas'}" href="javascript: void(0);">
                            <span>Mesas</span>
                        </a>
                    </li>
                    <li *ngIf="!empresa?.integracaoDelivery">
                        <a (click)="naveguePara('financeiro-balcao')" [ngClass]="{'active':url=='financeiro-balcao'}" href="javascript: void(0);">
                            <span>Balcão</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Pontuações Fidelidade -->
            <li class="menu-item" *ngIf="exibirFuncoesFidelidade">
                <a (click)="naveguePara('fidelidade/pontuacoes')" [ngClass]="{'active':url.indexOf('pontuacoes') >= 0}" href="javascript: void(0);">
                    <i class="fas fa-medal"></i>
                    <span>Pontuações Fidelidade</span>
                </a>
            </li>

            <!-- Entregadores -->
            <li class="menu-item" *ngIf="exibirEntregadores && visualizarLoja()">
                <a (click)="naveguePara('entregadores')" [ngClass]="{'active':url=='entregadores'}" href="javascript: void(0);">
                    <i class="fa fa-motorcycle"></i>
                    <span>Entregadores (beta)</span>
                </a>
            </li>

            <!-- Avaliações -->
            <li class="menu-item" *ngIf="(exibirFuncoesPedidos || exibirProdutos) && visualizarLoja()">
                <a (click)="naveguePara('avaliacoes')" [ngClass]="{'active':url=='avaliacoes'}" href="javascript: void(0);">
                    <i class="fe-star-on" style="color: #f5b106;"></i>
                    <span>Avaliações de Pedidos</span>
                </a>
            </li>

            <!-- Inteligência do Negócio -->
            <li class="menu-item" *ngIf="visualizaRelatorios() && exibirFuncoesFidelidade">
                <a (click)="naveguePara('relatorios')" [ngClass]="{'active':url=='relatorios'}" href="javascript: void(0);">
                    <i class="fas fa-chart-pie"></i>
                    <span>Inteligência do Negócio</span>
                </a>
            </li>

            <!-- Integração Whatsapp -->
            <li class="menu-item" *ngIf="!ehGarcom && empresa.id === 1">
                <a (click)="naveguePara('whatsapp')" [ngClass]="{'active':url=='whatsapp'}" href="javascript: void(0);">
                    <i class="fab fa-whatsapp" style="color: green;"></i>
                    <span>Integração Whatsapp</span>
                </a>
            </li>

            <!-- Robôs -->
            <li class="menu-item" *ngIf="!ehGarcom && possuiModuloInstagramBot">
                <a href="javascript: void(0);" [ngClass]="{'active':url.includes('instagram-bot')}" data-toggle="collapse" data-target="#menuRobos">
                    <i class="fas fa-robot"></i>
                    <span>Robôs</span>
                    <i class="fas fa-chevron-right menu-arrow"></i>
                </a>
                <ul class="nav-second-level collapse" id="menuRobos">
                    <li>
                        <a (click)="naveguePara('instagram-bot')" [ngClass]="{'active':url=='instagram-bot'}" href="javascript: void(0);">
                            <i class="fab fa-instagram"></i>
                            <span>Instagram BOT</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Notificações -->
            <li class="menu-item" *ngIf="(exibirFuncoesFidelidade || exibirFuncoesPedidos) && visualizarNotificacoes()">
                <a (click)="naveguePara('notificacoes')" [ngClass]="{'active':url=='notificacoes'}" href="javascript: void(0);">
                    <i class="fab fa-whatsapp"></i>
                    <span>Notificações</span>
                </a>
            </li>

            <!-- Marketing -->
            <li class="menu-item" *ngIf="exibirProdutos && visualizarLoja()">
                <a href="javascript: void(0);" data-toggle="collapse" data-target="#menuMarketing" data-toggle="collapse">
                    <i class="fe-bar-chart-2"></i>
                    <span>Marketing</span>
                    <i class="fas fa-chevron-right menu-arrow"></i>
                </a>
                <ul class="nav-second-level collapse" id="menuMarketing">
                    <li>
                        <a (click)="naveguePara('marketing')" [ngClass]="{'active':url=='marketing'}" href="javascript: void(0);">
                            <i class="fab fa-whatsapp"></i>
                            <span>Whatsapp</span>
                        </a>
                    </li>
                    <li *ngIf="possuiModuloApp">
                        <a (click)="naveguePara('marketing-app')" [ngClass]="{'active':url=='marketing-app'}" href="javascript: void(0);">
                            <i class="fas fa-mobile"></i>
                            <span>Notificações APP</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Mensagens -->
            <li class="menu-item" *ngIf="!ehGarcom && visualizarNotificacoes()">
                <a (click)="naveguePara('mensagens')" [ngClass]="{'active':url=='mensagens'}" href="javascript: void(0);">
                    <i class="fas fa-inbox"></i>
                    <span>Mensagens</span>
                </a>
            </li>
        </ul>
    </div>
</div>

<style>
/* Estilos Modernos para o Menu */
.modern-menu {
    background: linear-gradient(180deg, #f8f9fe 0%, #ffffff 100%);
    box-shadow: 0 2px 20px rgba(0,0,0,0.05);
    height: 100%;
    border-right: 1px solid #eef2f7;
}

.user-profile {
    padding: 0.75rem;
    padding-top: 0.25rem;
    background: #ffffff;
    color: #2c3e50;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #eef2f7;
}

.profile-container {
    background: #ffffff;
    padding: 0.5rem;
    border-radius: 8px;
}

.avatar {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid #e9ecef;
}

.user-info h6 {
    color: #2c3e50;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0;
    line-height: 1.2;
}

.empresa-info small {
    color: #6c757d;
    font-size: 0.8rem;
}

.user-status {
    margin-top: 0.5rem !important;
}

.status-badge {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 11px;
    background: #f8f9fa;
    color: #6c757d;
    border: 1px solid #e9ecef;
}

.status-badge.dark {
    background: #2c3e50;
    color: #ffffff;
}

.store-link .btn {
    padding: 4px 8px;
    font-size: 12px;
    background: #ffffff;
    border: 1px solid #e9ecef;
    color: #3498db;
    border-radius: 6px;
}

.store-link .btn:hover {
    background: #f8f9fa;
    border-color: #3498db;
    color: #3498db;
}

.menu-item {
    margin: 4px 0;
}

.menu-item a {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #2c3e50;
    text-decoration: none;
    border-radius: 10px;
    margin: 0 10px;
    transition: all 0.3s;
    font-weight: 500;
    font-size: 13px;
    position: relative;
}

.menu-item a:hover {
    background: rgba(53, 152, 219, 0.05);
}

.menu-item a.active {
    background: rgba(53, 152, 219, 0.1);
    color: #3498db;
}

.menu-item i:not(.menu-arrow) {
    width: 20px;
    font-size: 15px;
    margin-right: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.menu-item span {
    flex: 1;
    margin-right: 8px;
}

.menu-arrow {
    font-size: 11px;
    transition: transform 0.3s ease;
    opacity: 0.5;
}

.has-arrow[aria-expanded="true"] .menu-arrow {
    transform: rotate(90deg);
    opacity: 1;
}

.notification-badge {
    background: #e74c3c;
    color: white;
    border-radius: 20px;
    padding: 2px 8px;
    font-size: 11px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    margin-left: auto;
}

.nav-second-level {
    list-style: none;
    padding: 5px 0 5px 46px;
    margin: 0 0 0 20px;
    position: relative;
    font-size: 12px;
}

.nav-second-level:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    width: 2px;
    border-radius: 2px;
}

.nav-second-level a {
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 8px;
    color: #5c6c7c;
}

.nav-second-level a:hover {
    background: rgba(53, 152, 219, 0.05);
}

.nav-second-level a.active {
    background: rgba(53, 152, 219, 0.1);
}

/* Ajustes para o Metismenu */
.metismenu .has-arrow::after {
    display: none;
}

.metismenu .mm-active > a {
    background: rgba(53, 152, 219, 0.1);
    color: #3498db;
}

/* Animação do submenu */
.nav-second-level.collapse {
    display: none;
}

.nav-second-level.collapse.show {
    display: block;
}

.nav-second-level.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

/* Espaço extra no final do menu */
#side-menu {
    padding-bottom: 120px;
}

/* Estilos para o modo enlarged */
body.enlarged .left-side-menu {
    min-width: 70px;
    max-width: 70px;
    z-index: 5;
}

body.enlarged .menu-item > a {
    padding: 15px;
    text-align: center;
    margin: 0;
}

body.enlarged .menu-item > a span,
body.enlarged .menu-item > a .menu-arrow {
    display: none;
}

body.enlarged .menu-item > a i {
    margin: 0;
    font-size: 18px;
    width: auto;
}

body.enlarged .nav-second-level {
    display: none;
    padding: 5px 0;
    margin: 0;
    background: #ffffff;
    box-shadow: 0 2px 20px rgba(0,0,0,0.1);
    border-radius: 4px;
}

body.enlarged .menu-item:hover > a + .nav-second-level {
    display: block;
    position: absolute;
    left: 70px;
    min-width: 190px;
    max-width: 220px;
}

body.enlarged .nav-second-level:before {
    display: none;
}

body.enlarged .nav-second-level a {
    padding: 8px 20px;
    position: relative;
}

body.enlarged .nav-second-level a span {
    display: inline-block;
}

body.enlarged .menu-item:hover > a {
    background: rgba(53, 152, 219, 0.1);
    color: #3498db;
    width: 70px;
}

body.enlarged .notification-badge {
    display: none;
}

body.enlarged .menu-item:hover .notification-badge {
    display: inline-flex;
    position: absolute;
    right: 12px;
}

/* Ajuste para o perfil do usuário no modo enlarged */
body.enlarged .user-profile {
    padding: 10px 5px;
}

body.enlarged .profile-container {
    text-align: center;
}

body.enlarged .user-info,
body.enlarged .user-status,
body.enlarged .store-link {
    display: none;
}

body.enlarged .avatar-wrapper {
    margin: 0 auto;
}

body.enlarged .avatar {
    width: 32px;
    height: 32px;
}

/* Animações suaves */
.left-side-menu,
.menu-item > a,
.nav-second-level,
.menu-item > a span,
.menu-item > a i {
    transition: all 0.3s ease;
}

/* Desabilitar collapse no modo enlarged */
body.enlarged [data-toggle="collapse"] {
    pointer-events: none;
}

body.enlarged .menu-item:hover [data-toggle="collapse"] {
    pointer-events: auto;
}

/* Garantir que o submenu apareça no hover mesmo com collapse */
body.enlarged .menu-item:hover .nav-second-level.collapse:not(.show) {
    display: block;
    position: absolute;
    left: 70px;
    min-width: 190px;
    max-width: 220px;
}

/* Remover animação de collapse no modo enlarged */
body.enlarged .nav-second-level.collapse,
body.enlarged .nav-second-level.collapsing {
    transition: none;
}

/* Ajuste para garantir que o submenu fique visível no hover */
body.enlarged .menu-item:hover .nav-second-level {
    z-index: 1000;
}
</style>


