<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()">
    <i class="fa fa-arrow-left ct-point"></i>
  </button>
  <i class="fe-search ml-2"></i> Buscar contatos
</h4>

<div class="card filtro-avancado">
  <div class="card-body">
    <!-- Plano -->
    <div class="form-group">
      <label>Plano</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="plano" name="plano" [(ngModel)]="filtro.plano.valor"
            [data]="planos" [suggest]="true" [allowCustom]="false"
            placeholder="Selecione um Plano" class="form-control" textField="label">
          </kendo-combobox>
        </div>
      </div>
    </div>

    <!-- Tag -->
    <div class="form-group" *ngIf="tags.length">
      <label>Tag</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="tags" name="tags" [(ngModel)]="filtro.tag.valor"
            [data]="tags" [suggest]="true" [allowCustom]="false"
            placeholder="Selecione uma tag" class="form-control" textField="label">
          </kendo-combobox>
        </div>
      </div>
    </div>

    <!-- DDD -->
    <div class="form-group">
      <label>DDD</label>
      <div class="form-control-wrapper">
        <div class="checkbox-group">
          <input type="checkbox" id="apenasDddLocal" name="apenasDddLocal"
            kendoCheckBox class="k-checkbox" [(ngModel)]="filtro.apenasDddLocal.valor" />
          <label class="k-checkbox-label" for="apenasDddLocal">Apenas clientes do DDD local</label>
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="form-group">
      <label>Status</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-multiselect id="cboStatus" name="status" [(ngModel)]="filtro.status.valor"
            [data]="classificacoes" [valuePrimitive]="true" placeholder="Selecione os status"
            textField="label" valueField="value">
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!-- Aniversariantes -->
    <div class="form-group">
      <label>Aniversariantes</label>
      <div class="form-control-wrapper">
        <kendo-daterange>
          <kendo-dateinput kendoDateRangeStartInput [(value)]="filtro.aniversario.inicio"></kendo-dateinput>
          <span class="ml-2 mr-2">Até</span>
          <kendo-dateinput kendoDateRangeEndInput [(value)]="filtro.aniversario.fim"></kendo-dateinput>
        </kendo-daterange>
      </div>
    </div>

    <!-- Última compra -->
    <div class="form-group linha-top">
      <label>Última compra</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="ultimasOperador" name="ultimasOperador" [(ngModel)]="filtro.ultimas.operador"
            [data]="operadoresMais" [allowCustom]="false" placeholder="Selecione" class="form-control"
            textField="label" [suggest]="true" [valueField]="'value'"
            (selectionChange)="vaiUsarFiltro(filtro.ultimas,valores,unidades)">
          </kendo-combobox>
        </div>
        <div class="control-item small">
          <kendo-combobox id="ultimasValor" name="ultimasValor" [(ngModel)]="filtro.ultimas.valor"
            [data]="valores" placeholder="Selecione" class="form-control" [suggest]="true"
            textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
        <div class="control-item small">
          <kendo-combobox id="ultimasUnidade" name="ultimasUnidade" [(ngModel)]="filtro.ultimas.unidade"
            [data]="unidades" [allowCustom]="false" placeholder="Selecione" class="form-control"
            [suggest]="true" textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
        <span class="atras">atrás</span>
      </div>
    </div>

    <!-- Sexo -->
    <div class="form-group">
      <label>Sexo</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="sexo" name="sexo" [(ngModel)]="filtro.sexo.valor" [data]="sexos"
            [suggest]="true" [allowCustom]="false" placeholder="Selecione" class="form-control"
            textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
      </div>
    </div>

    <!-- Brindes -->
    <div class="form-group">
      <label>Brindes</label>
      <div class="form-control-wrapper">
        <div class="checkbox-group">
          <input type="checkbox" id="jaTrocou" name="jaTrocou" class="k-checkbox"
            kendoCheckBox [(ngModel)]="filtro.jaTrocou.valor" />
          <label class="k-checkbox-label" for="jaTrocou">Já trocou por brindes</label>

          <input type="checkbox" id="podeTrocar" name="podeTrocar" class="k-checkbox"
            kendoCheckBox [(ngModel)]="filtro.podeTrocar.valor" />
          <label class="k-checkbox-label" for="podeTrocar">Pode trocar por brindes</label>
        </div>
      </div>
    </div>

    <!-- Pontuação -->
    <div class="form-group linha-top">
      <label>Pontuação</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="operadorPontuacao" name="operadorPontuacao" [(ngModel)]="filtro.pontuacao.operador"
            [data]="operadores" [suggest]="true" [allowCustom]="false" placeholder="Selecione"
            class="form-control" textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
        <div class="control-item small">
          <input type="number" name="valorPontuacao" class="form-control" [(ngModel)]="filtro.pontuacao.valor">
        </div>
      </div>
    </div>

    <!-- Ticket médio -->
    <div class="form-group">
      <label>Ticket médio</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="operadorTicketMedio" name="operadorTicketMedio" [(ngModel)]="filtro.ticketMedio.operador"
            [data]="operadores" [suggest]="true" [allowCustom]="false" placeholder="Selecione"
            class="form-control" textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
        <div class="control-item small">
          <input type="number" name="valorTicketmedio" class="form-control" [(ngModel)]="filtro.ticketMedio.valor">
        </div>
      </div>
    </div>

    <!-- Total comprado -->
    <div class="form-group">
      <label>Total comprado</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="operadorTotalcomprado" name="operadorTotalcomprado" [(ngModel)]="filtro.totalComprado.operador"
            [data]="operadores" [suggest]="true" [allowCustom]="false" placeholder="Selecione"
            class="form-control" textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
        <div class="control-item small">
          <input type="number" name="valorTotalcomprado" class="form-control" [(ngModel)]="filtro.totalComprado.valor">
        </div>
      </div>
    </div>

    <!-- Qtd. Compras -->
    <div class="form-group">
      <label>Qtd. Compras</label>
      <div class="form-control-wrapper">
        <div class="control-item">
          <kendo-combobox id="operadorQtdcomprado" name="operadorQtdcomprado" [(ngModel)]="filtro.qtdComprado.operador"
            [data]="operadores" [suggest]="true" [allowCustom]="false" placeholder="Selecione"
            class="form-control" textField="label" [valueField]="'value'">
          </kendo-combobox>
        </div>
        <div class="control-item small">
          <input type="number" name="valorQtdcomprado" class="form-control" [(ngModel)]="filtro.qtdComprado.valor">
        </div>
      </div>
    </div>

    <!-- Não foi notificado -->
    <div class="form-group mb-5">
      <label>Não foi notificado nos últimos</label>
      <div class="form-control-wrapper">
        <div class="control-item" style="flex: 1;">
          <kendo-combobox id="valorNN"
                         name="valorNN"
                         [(ngModel)]="filtro.naoNotificado.valor"
                         [data]="valores"
                         [suggest]="true"
                         [allowCustom]="false"
                         placeholder="Selecione"
                         class="form-control"
                         textField="label"
                         [valueField]="'value'">
          </kendo-combobox>
        </div>
        <div class="control-item" style="flex: 2;">
          <kendo-combobox id="unidadeNN"
                         name="unidadeNN"
                         [(ngModel)]="filtro.naoNotificado.unidade"
                         [data]="unidades"
                         [suggest]="true"
                         [allowCustom]="false"
                         placeholder="Selecione"
                         class="form-control"
                         textField="label"
                         [valueField]="'value'">
          </kendo-combobox>
        </div>
      </div>
    </div>

    <div class="botoes-fixos">
      <button class="btn btn-blue width-lg" (click)="filtre()">Pesquisar</button>
      <button class="btn btn-white width-lg" (click)="voltar()">Cancelar</button>
    </div>
  </div>
</div>
