  <h4   class="page-title">
  <i class="fe-bar-chart-2"></i>
  Financeiro {{this.balcao ? 'Balcão' : ''}}</h4>

<kendo-panelbar class="mb-0 pb-0" style="    border-bottom: 0;">
  <kendo-panelbar-item [title]="'Filtros'" [expanded]="true"  >
    <ng-template kendoPanelBarContent>
      <div class="card card-body  pt-2 pb-0">
        <div class="row filtro">
          <div class="col-md-auto" *ngIf="!periodoEspecifico">
            <label>
              <span class="label lh">Mês <br></span>
              <kendo-datepicker style="width: 20em"  class="form-control"
                                [bottomView]="'year'"
                                [topView]="'year'"
                                [format]="'MMMM yyyy'"
                                [activeView]="'year'"
                                [max]="hoje"
                                (valueChange)="mudouValorMes($event)"
                                [(value)]="valorMes">
              </kendo-datepicker>

              <p class="text-blue cpointer mt-2" (click)="exibirPeriodoEspecifico()">
                Período especifico</p>
            </label>

          </div>

          <div  class="col-md-auto" *ngIf="periodoEspecifico">
            <kendo-daterange  class="mr-2" >
              <label>
                <span class="label lh">De <br></span>
                <kendo-datetimepicker style="width: 16em"  [(ngModel)]="filtro.inicio"  (ngModelChange)="carregueResumoVendas()">
                  <kendo-datetimepicker-messages
                    today="Hoje"
                    toggle="Alterar data"
                    cancel="Cancelar"
                    dateTab="Data"
                    timeTab="Hora"
                    now="Agora"
                    accept="Ok"
                  ></kendo-datetimepicker-messages>

                </kendo-datetimepicker>
              </label>
              <label class="ml-2 ">
                <span class="label  lh">Até </span><br>
                <kendo-datetimepicker style="width: 16em" [(ngModel)]="filtro.fim" (ngModelChange)="carregueResumoVendas()" >
                  <kendo-datetimepicker-messages
                    today="Hoje"
                    toggle="Alterar data"
                    cancel="Cancelar"
                    dateTab="Data"
                    timeTab="Hora"
                    now="Agora"
                    accept="Ok"
                  ></kendo-datetimepicker-messages>
                </kendo-datetimepicker>

              </label>
              <p class="text-blue cpointer" (click)="exibirPeriodoMensal()">
                Período mensal</p>

            </kendo-daterange>
          </div>

          <div class="col-md-auto">
            <div class="form-group d-inline-block ">
              <div class="mb-4"></div>
              <input type="checkbox" id="pagos" name="pagos" class="k-checkbox " kendoCheckBox  [(ngModel)]="filtro.pagos"  (ngModelChange)="carregueResumoVendas()"/>
              <label class="k-checkbox-label" for="pagos">Pagos</label>
            </div>

            <div class="form-group  d-inline-block ml-2">
              <div class="mb-4"></div>
              <input type="checkbox" id="naoPagos" name="naoPagos" class="k-checkbox "  kendoCheckBox  [(ngModel)]="filtro.naoPagos"  (ngModelChange)="carregueResumoVendas()"/>
              <label class="k-checkbox-label" for="naoPagos">Não pagos</label>
            </div>
            <ng-container *ngIf="!this.balcao">
              <div class="ml-2 form-group d-inline-block " >
                <div class="mb-4"></div>
                <input type="checkbox" id="delivery" name="delivery" class="k-checkbox " kendoCheckBox  [(ngModel)]="filtro.delivery"  (ngModelChange)="carregueResumoVendas()"/>
                <label class="k-checkbox-label" for="delivery">Delivery</label>
              </div>

              <div class="form-group  d-inline-block ml-2"  >
                <div class="mb-4"></div>
                <input type="checkbox" id="mesa" name="mesa" class="k-checkbox " kendoCheckBox   [(ngModel)]="filtro.mesa"  (ngModelChange)="carregueResumoVendas()"/>
                <label class="k-checkbox-label" for="mesa">Mesa</label>
              </div>

              <div class="form-group  d-inline-block ml-2"  *ngIf="!empresa.integracaoDelivery">
                <div class="mb-4"></div>
                <input type="checkbox" id="balcao" name="balcao" class="k-checkbox " kendoCheckBox   [(ngModel)]="filtro.balcao"  (ngModelChange)="carregueResumoVendas()"/>
                <label class="k-checkbox-label" for="balcao">Balcão</label>
              </div>

            </ng-container>


          </div>

        </div>

        <div class="row filtro">
          <div class="col-12 col-sm-3" *ngIf="redes.length">
            <div class="form-group mb-3  "   >
              <label >Rede</label>
              <kendo-combobox  name="rede" [(ngModel)]="filtro.rede" [data]="redes"
                               placeholder="Filtre pela rede" class="form-control" [textField]="'nome'"
                               (ngModelChange)="carregueResumoVendas()">
              </kendo-combobox>


            </div>
          </div>

          <div class="col-12 col-sm-3">
            <div class="form-group mb-3  "   >
              <label for="forma">Formas de pagamento</label>

              <kendo-multiselect id="forma" name="forma" [(ngModel)]="filtro.formasDePagamento" [data]="empresa.formasDePagamento"
                                 placeholder="Filtre pelas formas de pagamento" class="form-control"

                                 textField="descricao" valueField="id"

                                 (ngModelChange)="carregueResumoVendas()">
              </kendo-multiselect>

            </div>
          </div>

          <div class="col-12 col-sm-3">
            <div class="form-group mb-3  "   >
              <label for="forma">Formas de entrega</label>

              <kendo-combobox id="formaEntrega" name="formaEntrega" [(ngModel)]="filtro.formaDeEntregaEmpresa" [data]="empresa.formasDeEntrega"
                              placeholder="Filtre pelas formas de entrega" class="form-control" [textField]="'nome'"
                              (ngModelChange)="carregueResumoVendas()">
              </kendo-combobox>

            </div>
          </div>

          <div class="col-12 col-sm-3" >
            <div class="form-group mb-3  "  *ngIf="cupons.length"  >
              <label for="cupons">Cupons</label>
              <kendo-multiselect id="cupons" name="cupons" [(ngModel)]="filtro.cupons" [data]="cupons"
                                 placeholder="Filtre por cupons" class="form-control"  textField="codigo" valueField="id"
                                 (ngModelChange)="carregueResumoVendas()">
              </kendo-multiselect>

            </div>

          </div>

          <div class="col-12 col-sm-3" >
            <div class="form-group mb-3  "  *ngIf="promocoes.length"  >
              <label for="promocoes">Promoções</label>
              <kendo-multiselect id="promocoes" name="promocoes" [(ngModel)]="filtro.promocoes" [data]="promocoes"
                                 placeholder="Filtre por promocoes" class="form-control"  textField="descricao" valueField="id"
                                 (ngModelChange)="carregueResumoVendas()">
              </kendo-multiselect>

            </div>

          </div>
        </div>
      </div>
    </ng-template>
  </kendo-panelbar-item>
</kendo-panelbar>

<div>
  <h4 class=" ">Resumo
    <button class="button btn btn-sm btn-blue ml-2 " (click)="imprimaRelatorio()">
      <i class="k-icon k-i-printer mr-1"></i> Imprimir </button>
  </h4>

</div>
<div class="clearfix"></div>
<div class="row">
  <div class="col" *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted">      Total de vendas       </h4>
      <h5 class="card-text text-warning">
        {{resumoVendas.totalComTaxa | currency: "BRL"}}

        <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>
  <div class="col" *ngIf="resumoVendas.totalPagamentosComTaxa && (resumoVendas.totalComTaxa != resumoVendas.totalPagamentosComTaxa)">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted">      Total pagamentos       </h4>
      <h5 class="card-text text-warning">
        {{resumoVendas.totalPagamentosComTaxa | currency: "BRL"}}

        <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>
  <div class="col" *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted">      Taxa de Entrega       </h4>
      <h5 class="card-text text-warning">
        {{resumoVendas.taxaDeEntrega | currency: "BRL"}}
        <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>

  <div class="col" *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
    <div class="card card-body text-xs-center" >
      <h4 class="card-title text-muted">  Taxa Forma Pagamento    </h4>
      <h5 class="card-text text-warning">
        {{resumoVendas.totalTaxaFormaPagamento | currency: "BRL"}}
        <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>

  <div class="col" *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted">      Total Produtos         </h4>
      <h5 class="card-text text-warning">
        {{(resumoVendas.total  ) | currency: "BRL"}}
        <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>

  <div class="col">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted"> Qtde. Pedidos   </h4>
      <h5 class="card-text ">
        {{resumoVendas.qtde | number}}  <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>

  <div class="col" *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
    <div class="card card-body text-xs-center">
      <h4 class="card-title text-muted"> Ticket Médio  </h4>
      <h5 class="card-text">
        {{resumoVendas.ticketMedio | currency: "BRL"}}  <i class="k-icon k-i-loading " *ngIf="carregando"></i>
      </h5>
    </div>
  </div>

</div>

<div class="card">
  <div class="card-body">
    <div  [hidden]="vendasDoDia || vendasProduto">

      <kendo-tabstrip  #tabVendas class="nav-bordered mt-3" (tabSelect)="selecionouTab($event)">
        <kendo-tabstrip-tab [title]="'Por loja'" [id]="'loja'" *ngIf="rede" [selected]="rede">
          <ng-template kendoTabContent>
            <h5>Vendas por loja <b>{{obtenhaDescricaoFiltro()}}</b></h5>

            <kendo-grid  [style]=""
                         [data]="vendasLojasResultSet"
                         [loading]="carregando" style="min-height: 300px;"
                         [sortable]="{mode: 'single'}"
                         [sort]="sortLoja"
                         (sortChange)="sortChangeLojas($event)">

              <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">Exportar</button>
              </ng-template>

              <kendo-grid-column title="Cod. Loja"  field="codigoLoja"   [width]="150"  >
                <ng-template kendoGridCellTemplate let-resumo  >
                  <span class="text-dark">{{resumo.codigoLoja}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Loja"     field="nomeLoja">
                <ng-template kendoGridCellTemplate let-resumo  >
                  <span class="text-dark">{{resumo.nomeLoja}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Qtde Pedidos"   field="qtde"    [width]="150"  >
                <ng-template kendoGridCellTemplate let-resumo  >
                  <b>{{resumo.qtde | number}}</b>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"    field="totalComTaxa"  [width]="250" >
                <ng-template kendoGridCellTemplate let-resumo  >
              <span class="text-success">
                 <b>   {{resumo.totalComTaxa | currency: "BRL"}}</b>
              </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Taxa de Entrega">
                <ng-template kendoGridCellTemplate let-resumo  >
              <span class="text-success">
                 <b>   {{resumo.totalTaxaDeEntrega | currency: "BRL"}}</b>
              </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Ticket médio"    >
                <ng-template kendoGridCellTemplate let-resumo  >
                  <span class="text-muted"><b>{{resumo.ticketMedio | currency: "BRL"}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('lojas')"></kendo-grid-excel>

            </kendo-grid>

            <div class="mt-2 mb-2"></div>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Vendas diárias'" [id]="'vendas'" [selected]="!rede">
          <ng-template kendoTabContent>
            <h5>Vendas diárias {{obtenhaDescricaoFiltro()}}</h5>
            <kendo-grid
              [style]=""
              [kendoGridBinding]="resumoVendas.diarias"
              [loading]="carregando" style="min-height: 300px;">

              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhuma venda no periódo"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm   "
                          kendoGridExcelCommand icon="file-excel">Exportar</button>


                </div>
              </ng-template>
              <kendo-grid-column title="Dia" field="dia"  [width]="150"  >
                <ng-template kendoGridCellTemplate let-resumoDia  >
                  <span class="text-dark">{{resumoDia.dia}}</span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Qtde Pedidos" field="qtde" [width]="150"  >
                <ng-template kendoGridCellTemplate let-resumoDia  >
                  <b>{{resumoDia.qtde | number}}</b>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"  field="totalComTaxa"   [width]="250"
                                 *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
                <ng-template kendoGridCellTemplate let-resumoDia  >
              <span class="text-success">
                 <b>   {{resumoDia.totalComTaxa | currency: "BRL"}}</b>
              </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total pagamentos"  field="totalPagamentosComTaxa"    [width]="250"
                                 *ngIf="resumoVendas.totalPagamentosComTaxa && (resumoVendas.totalComTaxa !== resumoVendas.totalPagamentosComTaxa)">
                <ng-template kendoGridCellTemplate let-resumoDia  >
              <span class="text-success">
                 <b>   {{resumoDia.totalPagamentosComTaxa | currency: "BRL"}}</b>
              </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Taxa de Entrega" field="totalTaxaDeEntrega"
                                 *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
                <ng-template kendoGridCellTemplate let-resumoDia  >
              <span class="text-success">
                 <b>   {{resumoDia.totalTaxaDeEntrega | currency: "BRL"}}</b>
              </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Valor Líquido" field="total"
                                 *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
                <ng-template kendoGridCellTemplate let-resumoDia  >
              <span class="text-success">
                 <b>   {{(resumoDia.total ) | currency: "BRL"}}</b>
              </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Ticket médio"     field="ticketMedio"
                                 *ngIf="!resumoVendas.totalPagamentosComTaxa || (resumoVendas.totalComTaxa === resumoVendas.totalPagamentosComTaxa)">
                <ng-template kendoGridCellTemplate let-resumoDia  >
                  <span class="text-muted"><b>{{resumoDia.ticketMedio | currency: "BRL"}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column      >

                <ng-template kendoGridCellTemplate let-resumoDia  >
                  <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verPedidos(resumoDia)"  title="ver pedidos" kendoTooltip>
                    <i class="fe-list fa-lg"></i>
                  </button>
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('vendas-diarias')"></kendo-grid-excel>

            </kendo-grid>

            <div class="mt-2 mb-2"></div>
          </ng-template>

        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Por Produtos'" [id]="'produtos'"  >
          <ng-template kendoTabContent>
            <h5  >Produtos vendidos {{obtenhaDescricaoFiltro()}}

              <div class="ml-2 form-group d-inline-block ">

                <input type="checkbox" id="porAdicionais" name="porAdicionais" class="k-checkbox " kendoCheckBox  [(ngModel)]="filtro.porAdicionais"
                       (ngModelChange)="carregueResumoProdutos()"/>
                <label class="k-checkbox-label" for="porAdicionais">Exibir vendas dos adicionais</label>
              </div>

            </h5>

            <kendo-grid
              [style]=""
              [kendoGridBinding]="resumoProdutos.produtos"
              [loading]="resumoProdutos.carregando"
              [groupable]="true"  [group]="group"   >

              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhuma produto vendido no periódo"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm   "
                          kendoGridExcelCommand icon="file-excel">Exportar</button>


                  <button class="btn btn-outline-secondary btn-sm  ml-1 " *ngIf="!filtro.porAdicionais" kendoTooltip title="exportar produtos/contatos csv"
                          (click)="downloadCsvProdutos()"  >
                    <i class="fa fa-download fa-lg"></i> Exportar vendas/contato
                  </button>
                </div>
              </ng-template>
              <kendo-grid-column title="Codigo" field="codigo"      [width]="100">
                <ng-template kendoGridCellTemplate let-resumoProduto  >
                  <span class="text-blue"><b>{{resumoProduto.codigo}}</b></span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Produto"    field="nome" >
                <ng-template kendoGridCellTemplate let-resumoProduto  >
                  <span class="text-dark">{{resumoProduto.nome}}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Qtde vendido"    field="qtdeDescricao"  [width]="250" >
                <ng-template kendoGridCellTemplate let-resumoProduto  >
              <span >
                 <b>   {{resumoProduto.qtdeDescricao  }}</b>
              </span>


                </ng-template>
                <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates">
                  <span> Qtde vendido: {{ aggregates.qtde.sum | number }} </span>
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"   field="total" [width]="250"  >
                <ng-template kendoGridCellTemplate let-resumoProduto  >
                <span class="text-success">
                   <b>{{resumoProduto.total | currency: "BRL"}}</b>
                </span>

                </ng-template>

                <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" >
                  <ng-container *ngIf="resumoProdutos.totalDescontos ">
                    <label> Total produtos: <span class="text-success"> +{{ aggregates.total.sum  | currency: 'BRL'}}</span> </label>
                    <label> Total descontos: <span class="text-danger"> -{{ resumoProdutos.totalDescontos  | currency: 'BRL'}}</span></label>
                  </ng-container>
                  <label> Total:  <span class="text-success">

                  <b>{{ (aggregates.total.sum - resumoProdutos.totalDescontos)   | currency: 'BRL'}}</b> </span> </label>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column    *ngIf="!filtro.porAdicionais"  >

                <ng-template kendoGridCellTemplate let-resumoProduto  >
                  <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verDetalhesVendasProduto(resumoProduto)"  title="ver detalhes" kendoTooltip>
                    <i class="fe-list fa-lg"></i>
                  </button>
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload(filtro.porAdicionais?  'vendas-adicionais' : 'vendas-produtos')"></kendo-grid-excel>

            </kendo-grid>

            <div class="mt-2 mb-2"></div>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Por Cupons'" [id]="'cupons'"  >
          <ng-template kendoTabContent>
            <h5 class="float-left">Cupons utilizados {{obtenhaDescricaoFiltro()}}</h5>

            <div class="clearfix"></div>

            <kendo-grid
              [style]=""
              [data]="vendasCuponsResultSet"
              [loading]="resumoCupons.carregando"
              [sortable]="{  allowUnsort: false,  mode:'single'  }"
              [sort]="sortCupom"
              (sortChange)="sortChange($event)"
            >
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhuma venda no periódo"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">

                  <button type="button" class="btn btn-outline-secondary btn-sm  ml-1"
                          kendoGridExcelCommand icon="file-excel">Exportar</button>


                  <button class="btn btn-outline-secondary btn-sm  ml-1"  *ngIf="rede"
                          kendoTooltip title="exportar cupons/loja"  (click)="downloadCsvCupons()"  >
                    <i class="fa fa-download fa-lg mr-1"></i> Exportar por lojas
                  </button>


                  <button class="btn  btn-outline-secondary btn-sm   ml-1"
                          kendoTooltip title="exportar cupons/contatos"  (click)="downloadCsvCuponsContatos()"  >
                    <i class="fa fa-download fa-lg mr-1"></i>  Exportar por contatos
                  </button>
                </div>


              </ng-template>

              <kendo-grid-column title="Cupom"  field="codigo"    >
                <ng-template kendoGridCellTemplate let-resumo   >
                  <span class="text-blue"><b>{{resumo.codigo}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Qtde vendido"  field="qtde"    [width]="250" >
                <ng-template kendoGridCellTemplate let-resumo  >
                  <span >
                     <b>   {{resumo.qtde  }}</b>
                  </span>

                </ng-template>

                <ng-template   kendoGridFooterTemplate       >
                  <ng-container *ngIf="this.resumoVendas.cupons.length">
                    {{ totalCupons['qtde'].sum }}
                  </ng-container>

                </ng-template>

              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"  [width]="150" field="total"   >
                <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-success">
                   <b>{{resumo.total | currency: "BRL"}}</b>
                </span>

                </ng-template>
                <ng-template   kendoGridFooterTemplate       >
                  <ng-container *ngIf="this.resumoVendas.cupons.length">
                    {{ totalCupons['total'].sum | currency: "BRL"}}
                  </ng-container>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total descontos"  [width]="150" field="totalDesconto"   >
                <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-success">
                   <b>{{resumo.totalDesconto | currency: "BRL"}}</b>
                </span>

                </ng-template>
                <ng-template   kendoGridFooterTemplate       >
                  <ng-container *ngIf="this.resumoVendas.cupons.length">
                    {{ totalCupons['totalDesconto'].sum  | currency: "BRL"}}
                  </ng-container>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column      >

                <ng-template kendoGridCellTemplate let-resumo  >
                  <!-- <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verDetalhesVendasProduto(resumo)"  title="ver detalhes" kendoTooltip>
                    <i class="fe-list fa-lg"></i>
                  </button> -->
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('vendas-cupons')"></kendo-grid-excel>
            </kendo-grid>


            <div class="mt-2 mb-2"></div>


          </ng-template>

        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Por Promoções'" [id]="'promocoes'"  >
          <ng-template kendoTabContent>
            <h5 class="float-left">Promoções utilizando {{obtenhaDescricaoFiltro()}}</h5>

            <div class="clearfix"></div>

            <kendo-grid
              [style]=""
              [data]="vendasPromocoesResultSet"
              [loading]="resumoPromocoes.carregando"
              [sortable]="{  allowUnsort: false,  mode:'single'  }"
              [sort]="sortCupom"
              (sortChange)="sortChange($event)"
            >
              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhuma venda no periódo"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">

                  <button type="button" class="btn btn-outline-secondary btn-sm  ml-1"
                          kendoGridExcelCommand icon="file-excel">Exportar</button>


                  <button class="btn btn-outline-secondary btn-sm  ml-1"  *ngIf="rede"
                          kendoTooltip title="exportar promoções/loja"  (click)="downloadCsvPromocoes()"  >
                    <i class="fa fa-download fa-lg mr-1"></i> Exportar por lojas
                  </button>


                  <button class="btn  btn-outline-secondary btn-sm   ml-1"
                          kendoTooltip title="exportar promoções/contatos"  (click)="downloadCsvPromocoesContatos()"  >
                    <i class="fa fa-download fa-lg mr-1"></i>  Exportar por contatos
                  </button>
                </div>


              </ng-template>

              <kendo-grid-column title="Promocao"  field="descricao"    >
                <ng-template kendoGridCellTemplate let-resumo   >
                  <span class="text-blue"><b>{{resumo.descricao}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Qtde vendido"  field="qtde"    [width]="250" >
                <ng-template kendoGridCellTemplate let-resumo  >
                  <span >
                     <b>   {{resumo.qtde  }}</b>
                  </span>

                </ng-template>

                <ng-template   kendoGridFooterTemplate       >
                  <ng-container *ngIf="this.resumoVendas.promocoes.length">
                    {{ totalPromocoes['qtde'].sum }}
                  </ng-container>

                </ng-template>

              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"  [width]="150" field="total"   >
                <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-success">
                   <b>{{resumo.total | currency: "BRL"}}</b>
                </span>

                </ng-template>
                <ng-template   kendoGridFooterTemplate       >
                  <ng-container *ngIf="this.resumoVendas.promocoes.length">
                    {{ totalPromocoes['total'].sum | currency: "BRL"}}
                  </ng-container>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total descontos"  [width]="150" field="totalDesconto"   >
                <ng-template kendoGridCellTemplate let-resumo  >
                <span class="text-success">
                   <b>{{resumo.totalDesconto | currency: "BRL"}}</b>
                </span>

                </ng-template>
                <ng-template   kendoGridFooterTemplate       >
                  <ng-container *ngIf="this.resumoVendas.promocoes.length">
                    {{ totalPromocoes['totalDesconto'].sum  | currency: "BRL"}}
                  </ng-container>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column      >

                <ng-template kendoGridCellTemplate let-resumo  >
                  <!-- <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verDetalhesVendasProduto(resumo)"  title="ver detalhes" kendoTooltip>
                    <i class="fe-list fa-lg"></i>
                  </button> -->
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('vendas-promocoes')"></kendo-grid-excel>
            </kendo-grid>


            <div class="mt-2 mb-2"></div>


          </ng-template>

        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Por Formas de Pagamento'" [id]="'formas'"  >
          <ng-template kendoTabContent>
            <h5  >Formas de pagamento  <b>{{obtenhaDescricaoFiltro()}}</b></h5>

            <kendo-grid
              [style]=""
              [kendoGridBinding]="resumoPorFormasPagamento"
              [loading]="carregandoResumoFormaPagamento"  >

              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhuma venda no periódo"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm   "
                          kendoGridExcelCommand icon="file-excel">Exportar</button>



                </div>
              </ng-template>
              <kendo-grid-column title="Forma"  field="nome"     >
                <ng-template kendoGridCellTemplate let-resumo   >
                  <span class="text-blue"><b>{{resumo.nome}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Qtde vendido"  field="qtde"     [width]="250" >
                <ng-template kendoGridCellTemplate let-resumo   >
          <span >
             <b>   {{resumo.qtde  }}</b>
          </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"  [width]="150" field="total"    >
                <ng-template kendoGridCellTemplate let-resumo  >
            <span class="text-success">
               <b>{{resumo.total | currency: "BRL"}}</b>
            </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column      >

                <ng-template kendoGridCellTemplate let-resumoProduto  >
                  <!-- <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verDetalhesVendasProduto(resumoProduto)"  title="ver detalhes" kendoTooltip>
                     <i class="fe-list fa-lg"></i>
                   </button> -->
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('vendas-formas-pagamento')"></kendo-grid-excel>

            </kendo-grid>

          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Por Formas Entrega'" [id]="'formasEntrega'"  *ngIf="!balcao" >

          <ng-template kendoTabContent>
            <h5  >Formas de entrega  <b>{{obtenhaDescricaoFiltro()}}</b></h5>

            <kendo-grid
              [style]=""
              [data]="resumoVendas.formasDeEntrega"
              [loading]="carregando"  >

              <kendo-grid-messages
                pagerPage="Página"
                pagerOf="de"
                pagerItems="itens"
                noRecords="nenhuma venda no periódo"
                loading="Carregando"
                pagerItemsPerPage="ítems por página"
              >
              </kendo-grid-messages>
              <ng-template kendoGridToolbarTemplate>
                <div class="float-right">
                  <button type="button" class="btn btn-outline-secondary btn-sm   "
                          kendoGridExcelCommand icon="file-excel">Exportar</button>



                </div>
              </ng-template>
              <kendo-grid-column title="Forma"  field="nome"     >
                <ng-template kendoGridCellTemplate let-resumo   >
                  <span class="text-blue"><b>{{resumo.nome}}</b></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Qtde vendido"  field="qtde"     [width]="250" >
                <ng-template kendoGridCellTemplate let-resumo   >
          <span >
             <b>   {{resumo.qtde  }}</b>
          </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Total vendido"  [width]="150" field="total"    >
                <ng-template kendoGridCellTemplate let-resumo  >
            <span class="text-success">
               <b>{{resumo.total | currency: "BRL"}}</b>
            </span>

                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column      >

                <ng-template kendoGridCellTemplate let-resumoProduto  >
                  <!-- <button class="btn btn-outline-blue btn-sm ml-1 " (click)="verDetalhesVendasProduto(resumoProduto)"  title="ver detalhes" kendoTooltip>
                     <i class="fe-list fa-lg"></i>
                   </button> -->
                </ng-template>

              </kendo-grid-column>

              <kendo-grid-excel [fileName]="obtenhaNomeArquivoDownload('vendas-formas-entrega')"></kendo-grid-excel>

            </kendo-grid>
          </ng-template>

        </kendo-tabstrip-tab>

      </kendo-tabstrip>


    </div>

    <ng-container *ngIf="vendasDoDia">
      <h4>
        <button class="btn btn-outline-blue btn-rounded" (click)="voltarVendasDia()">
          <i class="fa fa-arrow-left ct-point  "></i>
        </button>
        Vendas do dia <span><b>{{vendasDoDia.dia}}</b></span></h4>
      <app-grid-pedidos #gridPedidos [pedidos]="vendasDoDia.pedidos" [carregando]="vendasDoDia.carregando" [mesa]="false"></app-grid-pedidos>

    </ng-container>

    <ng-container *ngIf="vendasProduto">
      <h4>
        <button class="btn btn-outline-blue btn-rounded" (click)="voltarVendasProdutos()">
          <i class="fa fa-arrow-left ct-point  "></i>
        </button>
        Vendas do produto <span><b>{{vendasProduto.nome}}</b></span></h4>


      <h5 class="mt-2">
        Vendas dos adicionais {{obtenhaDescricaoFiltro()}}</h5>

      <kendo-grid
        [style]=""
        [kendoGridBinding]="vendasProduto.adicionais"
        [loading]="carregando" style="min-height: 300">

        <kendo-grid-messages
          pagerPage="Página"
          pagerOf="de"
          pagerItems="itens"
          noRecords="nenhum adicional  "
          loading="Carregando"
          pagerItemsPerPage="ítems por página"
        >
        </kendo-grid-messages>
        <ng-template kendoGridToolbarTemplate>
          <button type="button" class="float-right" kendoGridExcelCommand icon="file-excel">Exportar para Excel</button>
        </ng-template>

        <kendo-grid-column title="produto" field="produto"   [width]="250"  >
          <ng-template kendoGridCellTemplate let-resumoAdicional  >
            <span class="text-dark">{{resumoAdicional.produto}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Adicional"   field="nome"   >
          <ng-template kendoGridCellTemplate let-resumoAdicional  >
            <span class="text-dark">{{resumoAdicional.nome}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Qtde"  [width]="150" field="qtde"  >
          <ng-template kendoGridCellTemplate let-resumoAdicional  >
            <b>{{resumoAdicional.qtde | number}}</b>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Total"     [width]="250" field="total" >
          <ng-template kendoGridCellTemplate let-resumoAdicional  >
              <span class="text-success">
                 <b>   {{resumoAdicional.total | currency: "BRL"}}</b>
              </span>

          </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel [fileName]="obtenhaNomeArquivo()"></kendo-grid-excel>
      </kendo-grid>

    </ng-container>
  </div>
</div>






