import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmpresaService} from "../../services/empresa.service";

@Component({
  selector: 'app-form-integracao-ifood',
  templateUrl: './form-integracao-ifood.component.html',
  styleUrls: ['./form-integracao-ifood.component.scss']
})
export class FormIntegracaoIfoodComponent implements OnInit {
  @ViewChild('frm', {}) frm:  any;
  @Input() empresa: any;
  @Input() integracaoIfood: any;
  @Input() ifooodUserCode: any = {};
  mensagemErro: string;
  salvando: boolean;
  confirmarRemover: boolean;
  confirmarDesativar: boolean;
  sincronizando: boolean;
  removendo: boolean;
  ativando: boolean;
  gerandoLink: any;
  constructor(private  empresaService: EmpresaService) { }

  ngOnInit(): void {
    if(this.integracaoIfood && this.integracaoIfood.ifooodUserCode)
      this.ifooodUserCode = this.integracaoIfood.ifooodUserCode;

  }


  onSubmit (){
    if(this.frm.valid){
      this.salvando = true;

      if(this.integracaoIfood.nova){
        this.empresaService.autorizeNovaLoja(this.ifooodUserCode).then((integracao: any) => {
          this.integracaoIfood = integracao;
          //this.empresa.integracoesIfood.push(integracao) ;
          this.salvando = false;
          this.empresaService.sincronizeFormasPagamentoIfood().then(() => {})
        }).catch( (erro) => {
          this.mensagemErro = erro;
          this.salvando = false
        })
      } else {
        this.empresaService.atualizeIntegracaoIfood(this.integracaoIfood).then((integracao: any) => {
          //this.empresa.integracoesIfood.push(integracao) ;
          this.salvando = false;
          this.integracaoIfood = integracao;
        }).catch( (erro) => {
          this.mensagemErro = erro;
          this.salvando = false
        })
      }
    }
  }

  gereLinkAutorizacao(){
    this.gerandoLink =  true;
    let idLojaDigitado = this.ifooodUserCode.idLoja;

    this.empresaService.gereLinkAtivacaoIfood().then((ifooodUserCode: any) => {
      this.ifooodUserCode = ifooodUserCode;
      if(idLojaDigitado) this.ifooodUserCode.idLoja = idLojaDigitado;
      this.gerandoLink = false;
    }).catch( (erro) => {
      this.mensagemErro = erro;
      this.gerandoLink = false
    })
    return false;
  }

  removerIntegracao() {

    this.removendo = true;

    this.empresaService.removaIntegracaoIfood(this.integracaoIfood).then(  () => {
      this.removendo = false;
      this.integracaoIfood.removida = true;
    }).catch((erro) => {
      this.removendo = false;
      alert(erro)
    })
  }

  confirmeRemover(){
    this.confirmarRemover = true;
  }


  confirmeDesativar(){
    this.confirmarDesativar = true;
  }

  editarIntegracao(integracao: any){
    integracao.editar = true;
  }

  desativarIntegracao() {

    this.removendo = true;

    this.empresaService.desativeIntegracaoIfood(this.integracaoIfood).then(  () => {
      this.removendo = false;
      this.confirmarDesativar = false;
      this.integracaoIfood.desativado = true;
    }).catch((erro) => {
      this.removendo = false;
      alert(erro)
    })
  }

  ativeIntegracao(){
    this.ativando  = true;

    this.empresaService.ativeIntegracaoIfood(this.integracaoIfood).then(  () => {
      this.ativando = false;
      this.integracaoIfood.desativado = false;
    }).catch((erro) => {
      this.removendo = false;
      alert(erro)
    })
  }



  canceleRemover() {
    this.confirmarRemover = false;
    this.confirmarDesativar = false;
  }

  fecharAlerta(){
    this.mensagemErro = null
  }
}
