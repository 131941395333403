
<h4 class="page-title d-flex align-items-center">

  <span>Ifood Noticações e Eventos Recebidos</span>
</h4>



<div class="card"  >
  <div class="card-body p-0">

    <kendo-grid [data]="notificacoesPedido" [loading]="carregando"  >
      <kendo-grid-messages
        pagerPage="Página"
        pagerOf="de"
        pagerItems="itens"
        noRecords="Sem notificaçoes"
        loading="Carregando"
        pagerItemsPerPage="ítems por página"
      >
      </kendo-grid-messages>
      <kendo-grid-column title="ID"    >
        <ng-template kendoGridCellTemplate let-dataItem  >
          <span class="text-blue"><b>{{dataItem.id}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Codigo"   >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span><b>{{dataItem.code}} - </b></span>
          <span><b>{{dataItem.fullCode}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Order"    >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="text-muted"><b>{{dataItem.orderId}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Horário"  [width]="200" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="text-muted"><b>{{dataItem.horario | date: 'dd/MM/YYYY HH:mm:ss'}}</b></span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Executado"    >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="badge badge-success" *ngIf="dataItem.executada">Sim</span>
          <span class="badge badge-danger" *ngIf="!dataItem.executada">Não</span>

          <p *ngIf="!dataItem.executada && dataItem.erro">{{dataItem.erro}}</p>
        </ng-template>


      </kendo-grid-column>

    </kendo-grid>
  </div>

</div>
