<kendo-tabstrip class="nav-bordered" id="tabs" name="tabs" (tabSelect)="selecionouTipoDeEstoque($event)">
  <kendo-tabstrip-tab [id]="tipo.id" [title]="tipo.nome" *ngFor="let tipo of produtosPorEstoque; index as i"
                      [selected]="i === 0" [disabled]="carregando">
    <ng-template kendoTabContent>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<div class="card" style="width: 100%;
    box-shadow: none;
    position: sticky;
    top: 70px;
    z-index: 9;
    padding: 10px;
    background: #fff;
    border: solid 1px #f1f1f1;
    border-radius: 0px;">
  <div class="" style="width: 100%;">
    <div class="row">
      <div class="col-6 col-sm-auto">
        <div class="form-group">
          <label>Busca de Produtos</label>
          <div>
          <input kendoTextBox placeholder="Busque por nome, categoria , ordem ou valor" class="form-control busca"
                 name="txtFiltro" [(ngModel)]="filtro.texto"
           appAutoFocus kendoTextBox (input)="onFilter($event)"/>
          </div>
        </div>
      </div>

      <div class="col-6 col-sm-auto">
        <div class="form-group">
          <label>Categoria</label>
          <div>
            <kendo-combobox id="cboCategorias" class="form-control" [data]="categoriasDoCatalogo" style="width: 250px"
                            textField="nome" valueField="id" [(ngModel)]="categoriaSelecionada" [allowCustom]="false"
                            (selectionChange)="busqueProdutos()">
            </kendo-combobox>
          </div>
        </div>

      </div>

      <div class="col-6 col-sm-auto">
        <div class="form-group">
          <label>Disponibilidade</label>
          <div>
          <kendo-multiselect id="cboDisponibilidade" class="form-control" [data]="disponibilidades" style="width: 250px"
                             textField="descricao" valueField="id"   [(ngModel)]="filtroDisponibilidades"
                             [allowCustom]="false"
                             (valueChange)="busqueProdutos()"
          >
          </kendo-multiselect>
          </div>
        </div>
      </div>

      <div class="col-6 col-sm-auto">

        <div class="input-group mt-4">
          <input  name="primeiraCompra" id='primeiraCompra' kendoCheckBox    class="k-checkbox  " type="checkbox" (ngModelChange)="busqueProdutos()"
                  [(ngModel)]="filtro.semCodigoPdv"/>
          <label  class="k-checkbox-label">       Sem codigo de PDV</label>


        </div>

      </div>

      <div class="col-6 col-sm-auto" *ngIf="empresa && empresa.rede === 'chinainbox'" >

        <div class="input-group mt-4">
          <input  name="naoSincronizados" id='naoSincronizados' kendoCheckBox    class="k-checkbox  " type="checkbox" (ngModelChange)="busqueProdutos()"
                  [(ngModel)]="filtro.naoSincronizados"/>
          <label  class="k-checkbox-label"> Produtos não sincronizados</label>


        </div>

      </div>
    </div>

    <div class="row botoes">
      <div  kendoTooltip class="col-auto">
        <button  [disabled]="!podeEditarProdutos"
          [title]="podeEditarProdutos ? 'Criar um novo produto' : 'Criar produtos não é permitido em cardápios compartilhados'"
          class="btn btn-primary" (click)="novoProduto()"
                  >
            <i class="fe-plus"></i>
            <span class="hidden-phone ">
          Novo Produto
          </span>


        </button>


        <button  [disabled]="!podeEditarProdutos" *ngIf="empresa?.integracaoPedidoFidelidade?.resgatarBrinde"
                 [title]="podeEditarProdutos ? 'Criar um novo brinde' : 'Criar bridnes não é permitido em cardápios compartilhados'"
                 class="btn btn-warning" (click)="novoProduto(true)"
        >
          <i class="fe-plus"></i>
          <span class="hidden-phone ">
          Novo Brinde
          </span>


        </button>


        <button class="btn btn-blue" (click)="abrirCadTemplatePizza()" *ngIf="empresa && empresa.pizzaria">
          <i class="fe-pie-chart"></i>
          <span class="hidden-phone">
              Template Pizzas
          </span>
        </button>

        <button [disabled]="!podeEditarProdutos"
                [title]="podeEditarProdutos ? 'Ordenar e criar categorias' : 'Edição não permitida em cardápios compartilhados'"
                class="btn btn-danger k-primary ml-1" (click)="ordenarCategorias()"   >
          <i class="fe-list mr-1"></i>

          <span class="hidden-phone ml-1">
            Categorias
          </span>
        </button>

        <button
          [title]="podeEditarProdutos ? 'Coloca os produtos em ordem alfabética' : 'Não é permitido reordenar cardápios compartilhados' "
          class="btn btn-secondary" (click)="confirmeReornedarProdutos()" [disabled]="ordenando || !podeEditarProdutos">

          <i class="fa fa-random mr-1" *ngIf="!ordenando"></i>
          <i class="k-icon k-i-loading mr-1" *ngIf="ordenando"></i>
          Ordenar por nome
        </button>

        <div class="dropdown d-inline-block ml-1" *ngIf="podeEditarProdutos || usuario.admin">
            <button class="btn btn-light k-grid-edit-command dropdown-toggle" type="button"
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-file-upload mr-1"></i>Importar Cardápio
            </button>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <ng-container *ngIf="empresa?.modeloCatalogoDaRede">
                <h5 class="ml-2"><b>CATALOGO DE REDE</b> </h5>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item cpointer"  (click)="importarProdutosCatalogoModelo()" >
                  Sincronizar com <b>"{{empresa?.modeloCatalogoDaRede.catalogo.nome}}"</b>  </a>
              </ng-container>


              <ng-container *ngIf="empresa?.integracaoOpendelivery?.ativa">
                <h5 class="ml-2"><b>OPEN DELIVERY</b></h5>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item cpointer" (click)="importarOpenDelivery()" >Importar {{empresa.integracaoOpendelivery.merchantName}}</a>
              </ng-container>

              <ng-container *ngIf="sincronizarComParceiros()">
                <h5 class="ml-2"> <b>{{empresa.integracaoDelivery.sistema.toUpperCase()}}</b></h5>
                <div class="dropdown-divider"></div>

                <a class="dropdown-item cpointer" (click)="sincronizarPrecos()" >Preços</a>
                <a class="dropdown-item cpointer"  (click)="sincronizarEstoque()">Disponibilidade</a>
                <a class="dropdown-item cpointer"  (click)="importarProdutosERP()" >Novos Produtos</a>
                <a class="dropdown-item cpointer"  (click)="importarProdutoCodigoERP()" >Sincronizar Produto por Código</a>
                <a class="dropdown-item cpointer"  (click)="exibaModalProdutosIndisponiveis($event)" >Ver Produtos Indisponiveis</a>


                <ng-container *ngIf="empresa.integracaoDelivery?.unidadeGendai">
                  <h5 class="ml-2"><b>Site Gendai</b></h5>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item cpointer"  (click)="importarProdutosChinna()" >Importar site gendai</a>
                </ng-container>
              </ng-container>

              <h5 class="ml-2"><i class="fas fa-file-upload mr-1"></i> <b>IFood</b></h5>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item cpointer" (click)="importarDoIfood()" >Importar do IFood</a>

              <h5 class="ml-2"><i class="fas fa-file-csv mr-1"></i><b> Upload Arquivo</b></h5>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item cpointer" (click)="importarDeArquivoCSV()" >Importar de um CSV</a>
              <a class="dropdown-item cpointer" (click)="importarDeArquivoPrecosCSV()" >Preços CSV</a>

            </div>
          </div>

        <div class="dropdown d-inline-block ml-1">
          <button class="btn btn-light k-grid-edit-command dropdown-toggle" type="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-download mr-1"></i>Exportar Cardápio
          </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item cpointer" (click)="downloadCardapioPDF()" >
              <i class="fas fa-file-pdf"></i> Cardápio em PDF
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item cpointer"  kendoTooltip title="download produtos" (click)="abraModalExportarProdutos()"  >
              <i class="fa fa-download  "></i>  Cardápio em CSV
            </a>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div
  class="search-results">
  <div *ngIf="carregando" style="position: fixed;background: rgba(255, 255, 255, 0.2  );top: 0px;left: 0px;width: 100%;height: 100%;z-index: 99999">
  <div class="k-i-loading ml-1 mr-1 centralizado"
       style="font-size: 40px;height: 90px; position: absolute; top: 50%; left: 45%;" ></div>
  </div>

<div class="card" *ngFor="let categoria of categorias">
  <div class="categoria">
    <h4>
      {{categoria.nome}}

      <ng-container *ngIf="categoria.id">
        <button class="btn btn-sm btn-danger ml-3" (click)="pausarCategoria(categoria)" *ngIf="categoria.disponivel"
                [disabled]="processando || (!podeEditarProdutos && !catalogo.disponibilidadePorEmpresa)">
          <i class="far fa-pause-circle" kendoTooltip title="Parar Temporariamente a Venda dos Produtos da Categoria"></i>&nbsp;Parar Vendas
        </button>
        <button class="btn btn-sm btn-primary ml-3" (click)="ativarCategoria(categoria)" *ngIf="!categoria.disponivel"
                [disabled]="processando || (!podeEditarProdutos && !catalogo.disponibilidadePorEmpresa)">
          <i class="far fa-play-circle" kendoTooltip title="Ativar a venda dos produtos da categoria"></i>&nbsp;Ativar Vendas
        </button>

        <div class="alert alert-warning ml-2 font-13 mb-0 d-inline-block" *ngIf="!categoria.disponivel">
          <i>A venda de produtos desta categoria desativada</i>
        </div>
      </ng-container>
    </h4>
  </div>
  <kendo-grid
    (edit)="editarProduto($event)"
    (pageChange)="mudouPaginacao($event)"
    (remove)="abraDialogRemoverProduto($event)"
    [data]="produtosPorCategoria[categoria.id]"
    [kendoGridExpandDetailsBy]="expandDetailsBy"
    [class]="'gridProdutos'"
    [(expandedDetailKeys)]="chaves"
    [style.width]="largura"
    [scrollable]="'scrollable'">
    <kendo-grid-messages
      pagerPage="Página"
      pagerOf="de"
      pagerItems="itens"
      noRecords="Sem produtos"
      loading="Carregando"
      pagerItemsPerPage="ítems por página"
    >
    </kendo-grid-messages>
    <kendo-grid-column title="Ordem" [width]="70">
      <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex">
        <div  class="ordem" >

          <kendo-dropdownbutton class="btn btn-block btn-light"
                  [ngClass]="{ 'desabilitado': trocandoOrdem  }" [hidden]="temFiltro()" [data]="opcoesSubir"
                                (itemClick)="clicouSubir(dataItem, rowIndex, categoria, $event)">
            <i class="fas fa-chevron-up   cpointer"    ></i>
          </kendo-dropdownbutton>


          <span class="d-block font-16" [ngClass]="{'text-center': !filtro.texto, 'text-left ml-2': filtro.texto}">{{dataItem.ordem}}</span>

          <kendo-dropdownbutton class="btn btn-block btn-light"
                  [ngClass]="{ 'desabilitado': trocandoOrdem  }" [hidden]="temFiltro()" [data]="opcoesDescer"
                                (itemClick)="clicouDescer(dataItem, rowIndex, categoria, $event)">
            <i class="fas fa-chevron-down   cpointer"  ></i>
          </kendo-dropdownbutton>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Imagem" class="coluna_menor" [width]="110">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

        <img [src]="'/images/empresa/'+dataItem.imagens[0].linkImagem" style="width: 80px;" *ngIf="dataItem.imagens && dataItem.imagens.length > 0">

        <i class="fe-image fa-2x" *ngIf="!dataItem.imagens || dataItem.imagens.length == 0"></i>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Nome" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span><b> <ng-container *ngIf="dataItem.codigoPdv">
           {{dataItem.codigoPdv}} - </ng-container> {{dataItem.nome}}</b></span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Preço"   [width]="80">
      <ng-template kendoGridCellTemplate let-produto let-rowIndex="rowIndex">

        <app-produto-preco [podeEditar]="podeEditarPrecos" [produto]="produto" [catalogo]="catalogo" [empresa]="empresa"></app-produto-preco>

      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Descrição" class="coluna_menor"   [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="abreviar">{{dataItem.descricao}}</div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Disponibilidade" class="coluna_menor"   [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem.erroDisponivel  ">
          <div class="badge badge-danger text-break" >indisponivel </div>
          <p class="text-danger text-break">{{dataItem.erroDisponivel}}</p>
        </ng-container>

        <ng-container *ngIf="!disponibilidadePorEmpresa">
          <div class="badge badge-danger" *ngIf="dataItem.disponibilidade === 2">indisponível</div>
          <div class="badge badge-success" *ngIf="dataItem.disponibilidade === 0">sempre</div>
        </ng-container>
        <ng-container *ngIf="disponibilidadePorEmpresa">
          <div class="badge badge-danger" *ngIf="dataItem.disponibilidadeNaEmpresa === 2">indisponível</div>
          <div class="badge badge-success" *ngIf="dataItem.disponibilidadeNaEmpresa === 0">sempre</div>
        </ng-container>


        <ng-container *ngIf="disponibilidadePorEmpresa ? dataItem.disponibilidadeNaEmpresa === 1 : dataItem.disponibilidade === 1">
          <div class="badge badge-blue mt-1 text-break" *ngFor="let disponibilidade of dataItem.disponibilidades">
            {{getDescricaoDisponibilidade(disponibilidade.id)}}
          </div>

        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" [width]="160" [class]="{'comandos': true}">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-produto>
        <button kendoGridEditCommand [primary]="true" (selectedChange)="editarProduto(produto)">
          <i class="fa fa-edit" kendoTooltip title="editar"></i>
        </button>
        <button [disabled]="!podeEditarProdutos" kendoGridRemoveCommand kendoTooltip ><i
          kendoTooltip [title]="podeEditarProdutos ? 'Remover produto' : 'Remoção não permitida em cardápio compartilhado'"
          class="fa fa-trash"></i></button>

        <button class="btn btn-xs btn-danger ml-2" (click)="pausarVendasProduto(categoria, produto)"
                kendoTooltip title="Parar Temporariamente a venda desse produto"
                *ngIf="produto.estahDisponivel" [disabled]="processando || (!podeEditarProdutos && !catalogo.disponibilidadePorEmpresa)">
          <i class="far fa-pause-circle fa-lg">  </i>
        </button>
        <button class="btn btn-xs btn-primary ml-2" (click)="ativarVendasProduto(categoria, produto)"
                kendoTooltip title="Ativar a venda desse produto" *ngIf="!produto.estahDisponivel"
                [disabled]="processando || (!podeEditarProdutos && !catalogo.disponibilidadePorEmpresa)">
          <i class="far fa-play-circle fa-lg"  ></i>&nbsp;

        </button>

        <button class="k-button btn btn-sm  ml-2" (click)="clonarProduto( produto)"

                [disabled]="processando || !podeEditarProdutos">
          <i kendoTooltip class="fas fa-copy" [title]="podeEditarProdutos ? 'Clonar o produto' : 'Duplicar produto não é permitido em catálogo compartilhado'"></i>
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <div class="k-i-loading ml-1 mr-1" style="font-size: 40px;height: 400px;" *ngIf="carregando"></div>

    <ng-template kendoGridDetailTemplate let-dataItem
      [kendoGridDetailTemplateShowIf]="mostrarComplementos.bind(this)">
      <div style="margin-left: 20px;margin-top: 20px;" class="lista-complementos">
        <app-adicionais-produto [produto]="dataItem"></app-adicionais-produto>
      </div>
    </ng-template>
  </kendo-grid>
</div>

<kendo-datapager
  [style.width.%]="100"
  [pageSize]="pageSize"
  [skip]="skip"
  [total]="total" [pageSizeValues] = "pageSizes"
  (pageChange)="onPageChange($event)" *ngIf="total > 0">
</kendo-datapager>
</div>

<kendo-dialog title="Atenção!" *ngIf="abraDialogo" (close)="fechar()" [minWidth]="250" [width]="450">
  <div *ngIf="produtoSelecionado && !excluindoProduto">
    <p style="margin: 30px; text-align: center;">Esta operação é irreversível. Tem certeza que deseja excluir o produto "<strong>{{produtoSelecionado.nome}}?</strong>"</p>
  </div>
  <div *ngIf="excluindoProduto">
    <p style="margin: 30px; text-align: center;">Excluindo o produto "<strong>{{produtoSelecionado.nome}}"...</strong></p>
    <div class="k-i-loading ml-1 mr-1" style="font-size: 20px; padding-bottom: 20px  " ></div>
  </div>
  <div *ngIf="mensagemRemocao">
    <p style="margin: 30px; text-align: center;">{{mensagemRemocao}}</p>
  </div>

  <div class="modal-footer" *ngIf="!excluindoProduto">
    <button type="button" *ngIf="produtoSelecionado" (click)="removaProduto()" class="btn btn-primary waves-effect waves-light" >Remover</button>
    <button type="button" *ngIf="produtoSelecionado" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
    <button type="button" *ngIf="!produtoSelecionado" (click)="fechar()" class="btn btn-primary waves-effect waves-light" >Fechar</button>
  </div>
</kendo-dialog>


<kendo-dialog title="Confirme a Reordenação" *ngIf="confirmarReordenacao" (close)="respostaConfirmacaoReordenacao(false)" [minWidth]="250" [width]="'450px'">
  <p style="margin: 30px; text-align: center;">Confirma a reordenação de todos os  produtos do catálogo?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="respostaConfirmacaoReordenacao(false)">Não</button>
    <button kendoButton (click)="respostaConfirmacaoReordenacao(true)" [primary]="true">Sim</button>
  </kendo-dialog-actions>
</kendo-dialog>
