import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfiguracoesMiaService } from '../../../services/configuracoes-mia.service';
import { SplitterComponent, SplitterPaneComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-editor-fluxo',
  templateUrl: './editor-fluxo.component.html',
  styleUrls: ['./editor-fluxo.component.scss']
})
export class EditorFluxoComponent implements OnInit {
  publicando: any;
  idFluxoTypebot: string = '';
  publicIdTypebot: string = '';
  urlFluxo: any = null;
  publicadoComSucesso: any;
  @ViewChild('splitter') splitter!: SplitterComponent;
  @ViewChild('painel') painel!: SplitterPaneComponent;
  nomeFluxo: string = '';

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private configuracoesMiaService: ConfiguracoesMiaService, private router: Router) { }

  ngOnInit(): void {
    this.idFluxoTypebot = this.route.snapshot.queryParams['id'];
    this.publicIdTypebot = this.route.snapshot.queryParams['publicId'];
    this.nomeFluxo = this.route.snapshot.queryParams['nome'];

    this.urlFluxo = this.sanitizeUrl('https://promokit.app.br:8443/pt-BR/typebots/' + this.idFluxoTypebot + '/edit');
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  voltar() {
    this.router.navigate(['/admin/chatbot/configuracoes']);
  }

  publicarFluxo() {
    this.publicando = true;
    this.publicadoComSucesso = false;
    this.configuracoesMiaService.publicarFluxo(this.idFluxoTypebot).then((resposta: any) => {
      this.publicando = false;
      this.publicadoComSucesso = true;

      setTimeout(() => {
        this.publicadoComSucesso = false;
      }, 3000);
    });
  }

  testarFluxo() {
    this.painel.collapsed = false;
  }
}
