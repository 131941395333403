import {Component, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {FormaDePagamento} from "../../pedidos/objetos/FormaDePagamento";
import {EditEvent, GridDataResult, RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogService} from "@progress/kendo-angular-dialog";
import {NovaFormaDePagamentoComponent} from "../nova-forma-de-pagamento/nova-forma-de-pagamento.component";
import {FormasDePagamentoService} from "../../services/formas-de-pagamento.service";
import { GroupDescriptor, DataResult, process } from '@progress/kendo-data-query';
import {DOCUMENT} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {TabStripComponent} from "@progress/kendo-angular-layout";

declare  var Hub;

@Component({
  selector: 'app-cadastro-formas-de-pagamento',
  templateUrl: './cadastro-formas-de-pagamento.component.html',
  styleUrls: ['./cadastro-formas-de-pagamento.component.scss']
})
export class CadastroFormasDePagamentoComponent implements OnInit {
  @ViewChild('tabstrip', { static: true }) private tabstrip: TabStripComponent;
  @ViewChild('tabstriponline', { static: false }) private tabstriponline: TabStripComponent;
  loading: any = true;
  empresa: any;
  formasDePagamento: Array<FormaDePagamento>
  public gridDataResultLoja: GridDataResult;
  carregando: any;
  modalDeletar = false;
  filtro: any;
  formaApagar: FormaDePagamento;
  pagamentos: Array<any>  = [
    { valor: false, descricao: 'Pagamento Na Entrega'  }
  ];

  pagamentosOnline: Array<any>  = [ { valor: true, descricao: 'Pagamento Online' }];
  nomeTabOnline = 'Pagamentos online/site';
  public groups: GroupDescriptor[] = [{ field: 'tipoDePagamento' }];
  confirmarMigrar = false;
  migrandoFormasPagamento = false;
  usuario: any = {};
  formasDePagamentoOnline: Array<FormaDePagamento> = [];
  formasDePagamentoOnlineExterna: Array<FormaDePagamento> = [];
  constructor(private constantsService: ConstantsService, private dialogService: DialogService,
              private _renderer2: Renderer2,  @Inject(DOCUMENT) private  _document: Document,
              private  activatedRoute: ActivatedRoute, private autorizacaoService: AutorizacaoService,
              private formasDePagamentoService: FormasDePagamentoService) { }

  ngOnInit(): void {
    this.constantsService.empresa$.subscribe( (empresa) => {
      if( !empresa ) return;

      this.empresa = empresa;

      //todo: quando fazer cartao desativar add novo online
      //if(!empresa.cadastroPagamentoOnline)
         // this.pagamentosOnline = []

      this.formasDePagamentoService.obtenhaFormasDePagamentos(this.empresa).then( (formas) => {
        this.formasDePagamento = formas;

        for( let forma of this.formasDePagamento ) {
          if(forma.formaDePagamentoPdv && forma.formaDePagamentoPdv.tipo === 'FIDELIDADE' )
            forma.tipoDePagamento = 'Fidelidade'
          else
            forma.tipoDePagamento = forma.online ? 'Pagamento Online' : 'Pagamento Na Entrega';
        }


        this.formasDePagamentoOnline = this.formasDePagamento.filter(item => item.online);
        this.gridDataResultLoja = process( this.formasDePagamento.filter((item: any) => !item.online),
          { group: this.groups });

        this.formasDePagamentoOnlineExterna =
          this.formasDePagamentoOnline.filter((item: any) => item.configMeioDePagamento.meioDePagamento !== 'tunapay')


        this.loading = false;

      });

    });

    setTimeout(() => {
      let formapagamento = this.activatedRoute.snapshot.params.formapagamento;

      if(formapagamento && formapagamento !== 'offline' )
        this.exibaTabOnline(formapagamento)
    })

    this.usuario = this.autorizacaoService.getUser() || {};
  }

  exibaTabOnline(nomeiopagamento: string){
    const tabs = this.tabstrip.tabs.toArray(); // Obtenha todas as abas como um array
    const tabFormasPagamento: any = tabs.find((_tab: any) => _tab.title ===  this.nomeTabOnline);

    if (tabFormasPagamento)
      this.tabstrip.selectTab(tabs.indexOf(tabFormasPagamento))

    setTimeout(() => {
      const tabsOnline =  this.tabstriponline.tabs.toArray();
      if(tabsOnline.length > 1 && nomeiopagamento !== 'onlinemci')  this.tabstriponline.selectTab(1);
    }, 500);

  }

  abraModalMigrarFormasBandeira(){
    this.confirmarMigrar = true;
  }


  confirmarMigrarBanderias(){
    this.migrandoFormasPagamento = true;

    this.formasDePagamentoService.migreParaBandeiras().then(() => {
      this.migrandoFormasPagamento  = false;
      this.confirmarMigrar = false;
      window.location.assign('/admin/loja/formaspagamento/offline');
    }).catch((err) => {
      this.migrandoFormasPagamento  = false;
      alert(err)
    })
  }

  editar($event: EditEvent) {
    const forma: FormaDePagamento = $event.dataItem;

    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro de Forma de Pagamento',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;

    telaNovaFormaDePagamento.windowRef = windowRef;
    telaNovaFormaDePagamento.editar(forma, this.empresa);

    windowRef.result.subscribe((result: any) => {
      this.constantsService.recarregueEmpresa();
    });
  }

  abraDialogRemover($event: RemoveEvent) {
    this.formaApagar = $event.dataItem;

    this.modalDeletar = true;
  }

  onFilter($event: Event) {

  }

  onPagamentoChange(selected: { valor: boolean, descricao: string }) {
    this.novo(selected.valor);
  }

  novo(pagamentoOnline: boolean) {
    let altura = window.innerHeight - 100;

    const windowRef = this.dialogService.open({
      title: 'Cadastro de Forma de Pagamento',
      content: NovaFormaDePagamentoComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: altura
    });

    const telaNovaFormaDePagamento: NovaFormaDePagamentoComponent = windowRef.content.instance;
    telaNovaFormaDePagamento.novaForma(pagamentoOnline, this.empresa);
    telaNovaFormaDePagamento.windowRef = windowRef;

    windowRef.result.subscribe((result: any) => {
      this.constantsService.recarregueEmpresa();
    });
  }

  close(status: string) {
    if( status === 'sim') {
      this.formasDePagamentoService.removaFormaDePagamento(this.formaApagar).then( () => {
        this.constantsService.recarregueEmpresa();
        this.formaApagar = null;
        this.modalDeletar = false;
      });
    } else {
      this.formaApagar = null;
      this.modalDeletar = false;
    }
  }
  temTaxaRetorno(){
    return this.empresa && this.empresa.formasDeEntrega.find((item) => item.taxaExtraRetorno) != null
  }



  onTabSelect(e: any) {
    // Lógica adicional se necessário
  }

  ehPagarme(forma: any){
    if(!forma.configMeioDePagamento) return false;

    return forma.configMeioDePagamento.meioDePagamento.startsWith('pagarme')
  }

  ehMeucardapio(forma: any){
    if(!forma.configMeioDePagamento) return false;

    return forma.configMeioDePagamento.meioDePagamento.startsWith('tuna');
  }
}
