import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroObjetosNome'
})
export class FiltroObjetosNomePipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    if( !filterText ) {
      return list;
    }

    if( list ) {
      const listaFiltrada = list.filter(item => {
        return item.nome.search(new RegExp(filterText, 'i')) > -1;
      });

      if (listaFiltrada.length > 0)
        return listaFiltrada;
    }


    return [];
  }

}
