<div class="bg-light p-1 pl-2 headerCampo mt-2" [hidden]="campoAdicional.esconder || campoAdicional.semOpcaoDisponivel"  >
  <h4>{{campoAdicional.nome}}  <span *ngIf="exibirSabor" class="text-muted font-14">>(Parte de <b>{{campoAdicional.sabor}}</b>)</span>
    <ng-container *ngIf="campoAdicional.opcoesDisponiveis.length >= 10">
      <i class="fas fa-search" (click)="habilitarBusca()"></i>         <input *ngIf="clicouBusca" style="margin-bottom: 5px" kendoTextBox placeholder="Busque pelo nome" class="form-control busca"
                                                                              name="txtFiltro" [(ngModel)]="filtroOpcao"
                                                                              (input)="onFilter()"/>
    </ng-container>
    <div class="float-right">
    <span *ngIf=" campoAdicional.tipo == 'multipla-escolha'" class="badge badge-info mr-1"
          [class.badge-danger]="(totalSelecionado(posicao) < 1 && campoAdicional.obrigatorio) ||
                                (totalSelecionado(posicao) > 0 &&
                                totalSelecionado(posicao) < campoAdicional.qtdMinima)"
          [class.badge-info]="(  totalSelecionado(posicao) >= campoAdicional.qtdMinima || !campoAdicional.obrigatorio) &&
                                totalSelecionado(posicao) < campoAdicional.qtdMaxima"
          [class.badge-success]="totalSelecionado(posicao) == campoAdicional.qtdMaxima">
      {{totalSelecionado(posicao)}}/{{campoAdicional.qtdMaxima}}</span>
      <span *ngIf="campoAdicional.obrigatorio && campoAdicional.tipo == 'multipla-escolha'"
            class="badge"[class.badge-danger]="totalSelecionado(posicao) < campoAdicional.qtdMinima "
            [class.badge-success]="totalSelecionado(posicao) >= campoAdicional.qtdMinima">OBRIGATÓRIO</span>
      <span *ngIf="campoAdicional.obrigatorio && campoAdicional.tipo == 'escolha-simples'"
            class="badge" [class.badge-danger]="!itemPedido.adicionais['campo' + posicao]"
            [class.badge-success]="itemPedido.adicionais['campo' + posicao]">OBRIGATÓRIO</span>

    </div>
  </h4>
  <span *ngIf="campoAdicional.tipo == 'escolha-simples'"  class="text-muted">Escolha {{campoAdicional.obrigatorio ?'': 'até'}} 1 opção</span>
  <span *ngIf="campoAdicional.tipo == 'multipla-escolha'"  class="text-muted">Escolha entre {{campoAdicional.qtdMinima}} e {{campoAdicional.qtdMaxima}} opções</span>
</div>

<p class="alert alert-danger mt-1" role="alert" *ngIf="msgErro">
  <i class="fa fa-times mr-2"></i> {{msgErro}}
</p>

<div *ngIf="campoAdicional.tipo == 'multipla-escolha' && !campoAdicional.podeRepetirItem">
  <div class="mt-2 ml-0">
    <div *ngFor="let opcao of opcoesFiltradas; let i = index; let last = last;" [hidden]="!opcao.disponivel || opcao.esconder">

      <div [class.mb-2]="!last" [class.mt-2]="!last" style="" *ngIf="itemPedido.adicionais['lista' + posicao]">
        <img  *ngIf="opcao.linkImagem" style="max-width: 60px; max-height: 60px" class="img img-pequena" (click)="exibaFullScreen(i, opcao)" [src]="'/images/empresa/' + opcao.linkImagem"/>
        <div *ngIf="opcao.linkImagem"  style="position: absolute;left: -1500px;z-index: 100000">
          <ng-image-slider #nav [id]="'nav' + i"  class="navImagem"  [images]="[{'image': '/images/empresa/' + opcao.linkImagem, 'thumbImage': '/images/empresa/' + opcao.linkImagem}]"
                           (imageClick)="abriuImagem($event)"   [autoSlide]="1" slideImage="1"></ng-image-slider>
        </div>

        <label [for]="'campo' + posicao + '_' + opcao.id" class="mb-1" >
          <input id="campo{{posicao}}_{{opcao.id}}" type="checkbox"  kendoCheckBox
                 [(ngModel)]="itemPedido.adicionais['lista' + posicao]['opcao_' + opcao.id].selecionada"
                 [value]="opcao" name="campo{{posicao}}_{{opcao.id}}"
                 (click)="vaiMudarSelecao($event, opcao)" (ngModelChange)="mudouSelecao($event, opcao)"
                 [disabled]="!itemPedido.adicionais['lista' + posicao]['opcao_' + opcao.id].selecionada && totalSelecionado(posicao) == campoAdicional.qtdMaxima"/>

          <span class="nome_opcao">{{opcao.nome}}</span>

          <span class="badge badge-primary ml-1 preco-extra" *ngIf="opcao.valor">+{{opcao.valor | currency:'BRL'}}</span></label>
        <ng-container *ngIf="opcao.descricao">
          <br>
          <span *ngIf="opcao.descricao" class="text-muted descricao" style="margin-left: 33px;display:block">{{opcao.descricao}}</span>
        </ng-container>
      </div>

    </div>
  </div>
</div>
<div *ngIf="campoAdicional.tipo == 'escolha-simples'">
  <div class="mt-2 ml-0 radio radio-blue" >
    <div *ngFor="let opcao of opcoesFiltradas; let i = index; let last = last" [class.mb-2]="!last" [class.mt-2]="!last" style=""
         [hidden]="!opcao.disponivel || opcao.esconder">
      <img  *ngIf="opcao.linkImagem" style="max-width: 60px; max-height: 60px" class="img img-pequena" (click)="exibaFullScreen(i, opcao)" [src]="'/images/empresa/' + opcao.linkImagem"/>
      <div *ngIf="opcao.linkImagem"  style="position: absolute;left: -1500px;z-index: 100000">
        <ng-image-slider #nav [id]="'nav' + i"  class="navImagem"  [images]="[{'image': '/images/empresa/' + opcao.linkImagem, 'thumbImage': '/images/empresa/' + opcao.linkImagem}]"  (imageClick)="abriuImagem($event)"   [autoSlide]="1" slideImage="1"></ng-image-slider>
      </div>
      <label for="campo{{campoAdicional.id}}_{{posicao}}_{{i}}" class="mb-1">

        <input id="campo{{campoAdicional.id}}_{{posicao}}_{{i}}" [(ngModel)]="itemPedido.adicionais['escolhido_campo' + posicao]"
               name="campo{{posicao }}" type="radio" kendoRadioButton
               [value]="opcao.id" (click)="deselecione(posicao, opcao ,$event)" (ngModelChange)="selecionouAdicionalSimples(posicao, opcao)"
               [required]="true"/>


        <span class="nome_opcao">{{opcao.nome}}</span>

        <span class="badge badge-primary ml-1 preco-extra" *ngIf="opcao.valor">&nbsp; +{{opcao.valor | currency:'BRL'}}</span></label>
      <ng-container *ngIf="opcao.descricao">
        <br>

        <span class="text-muted descricao" style="margin-left: 33px;display:block">{{opcao.descricao}}</span>

      </ng-container>
    </div>
  </div>

</div>
<div *ngIf="campoAdicional.tipo == 'multipla-escolha' && campoAdicional.podeRepetirItem && itemPedido.adicionais['lista' + posicao]">
  <div class="mt-2 ml-0">
    <div *ngFor="let opcao of opcoesFiltradas; let i = index" [hidden]="!opcao.disponivel || opcao.esconder" class="row">
      <div class="col-auto" style="padding: 0 0 0 12px;"  *ngIf="opcao.linkImagem">
        <img  style="max-width: 60px; max-height: 60px" class="img img-pequena" (click)="exibaFullScreen(i, opcao)" [src]="'/images/empresa/' + opcao.linkImagem"/>
        <div   style="position: absolute;left: -1500px;z-index: 100000">
          <ng-image-slider #nav [id]="'nav' + i"  class="navImagem"  [images]="[{'image': '/images/empresa/' + opcao.linkImagem, 'thumbImage': '/images/empresa/' + opcao.linkImagem}]"  (imageClick)="abriuImagem($event)"   [autoSlide]="1" slideImage="1"></ng-image-slider>
        </div>

      </div>
      <div class="col" style="padding: 3px 0; margin: auto">
        <div [class.mb-1]="!opcao.descricao"   style="display: flex;align-items: center;justify-content: space-between;">

          <label class="k-checkbox-label mb-1">

            <div>
              <span class="nome_opcao">{{opcao.nome}}</span>

            </div>



            <span class="badge badge-primary ml-1"  style='margin-top: 3px;float: right' *ngIf="opcao.valor">+{{opcao.valor | currency:'BRL'}}</span>


          </label>
          <div>
            <div class="col flex-fixed-width-item pl-0 pr-0 botoes_mais_menos asdf2">
              <button type="button" class="btn btn-outline-light" style="color: #787878; padding-top: 2px; padding-bottom: 2px" (click)="diminuirQtde(opcao)"
                      [disabled]="itemPedido.adicionais['lista' + posicao]['opcao_' + opcao.id].qtde == 0" ><i class="fas fa-minus"></i></button>
              <input name="campo{{campoAdicional.id}}{{i}}_qtd" [(ngModel)]="itemPedido.adicionais['lista' + posicao]['opcao_' + opcao.id].qtde" [value]="itemPedido.adicionais['lista' + posicao]['opcao_' + opcao.id].qtde" style="width: 32px; min-width: 32px; border: none; text-align: center; font-size: 16px; background: #fff; padding: 0; margin: 0;" disabled/>
              <button type="button" class="btn btn-outline-light" style="color: #787878; padding-top: 2px; padding-bottom: 2px" (click)="aumentarQtde(opcao)"
                      [disabled]="totalSelecionado(posicao)== campoAdicional.qtdMaxima"><i class="fas fa-plus"></i></button>
            </div>
          </div>

        </div>
        <div class="mb-2" *ngIf="opcao.descricao">
          <span  class="text-muted descricao" style="margin-left: 11px;display:block" >{{opcao.descricao}}</span>
        </div>

      </div>




    </div>
  </div>
</div>
