<div class="integration-container" style="max-width: 900px">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        novalidate #frm="ngForm" (ngSubmit)="onSubmit()">

    <!-- Seção: Nossas Credenciais -->
    <div class="card mb-4">
      <div class="card-header bg-light">
        <h4 class="mb-0"><i class="fas fa-key mr-2"></i>Nossas Credenciais</h4>
        <small class="text-muted">Informações que devem ser configuradas no Uber Direct</small>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>
                <i class="fas fa-link mr-1"></i>
                URL Webhook (Tracking Event Webhook)
                <small class="text-muted d-block">Endereço para receber notificações de rastreamento</small>
              </label>
              <div class="input-group">
                <input kendoTextBox name="urlTracking" class="form-control"
                       [value]="urlWebHooks()" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button"
                          (click)="copyMessage(urlWebHooks())"
                          [ngClass]="{'btn-success': textoCopiado === urlWebHooks()}"
                          title="Copiar URL webhook">
                    <i class="fe-copy" [ngClass]="{'text-white': textoCopiado === urlWebHooks()}"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="integracao?.id">
          <div class="col-md-6">
            <div class="form-group">
              <label>
                <i class="fas fa-lock mr-1"></i>
                Chave de Autenticação Webhook
                <small class="text-muted d-block">Chave para validar as requisições do Uber Direct</small>
              </label>
              <div class="input-group">
                <input kendoTextBox class="form-control" #signingKey="ngModel"
                       name="signingKey" readonly
                       [(ngModel)]="integracao.signingKey"
                       placeholder="Chave de autenticação"/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button"
                          (click)="copyMessage(integracao.signingKey)"
                          [ngClass]="{'btn-success': textoCopiado === integracao.signingKey}"
                          title="Copiar Chave">
                    <i class="fe-copy" [ngClass]="{'text-white': textoCopiado === integracao.signingKey}"></i>
                  </button>
                </div>
              </div>
              <div class="invalid-feedback">Campo obrigatório</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seção: Credenciais do Uber Direct -->
    <div class="card mb-4">
      <div class="card-header bg-light">
        <h4 class="mb-0"><i class="fas fa-shield-alt mr-2"></i>Credenciais do Uber Direct</h4>
        <small class="text-muted">Credenciais fornecidas pelo Uber Direct</small>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>
                <i class="fas fa-user mr-1"></i>
                ID do Usuário
                <small class="text-muted d-block">Seu identificador no Uber Direct</small>
              </label>
              <input kendoTextBox name="appid" class="form-control"
                     placeholder="ID do usuário"
                     [(ngModel)]="integracao.costumerId" required/>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seção: Configurações do Estabelecimento -->
    <div class="card mb-4">
      <div class="card-header bg-light">
        <h4 class="mb-0">
          <i class="fas fa-store-alt mr-2"></i>Configurações do Estabelecimento
          <small class="text-muted d-block">Personalize as configurações do seu estabelecimento</small>
        </h4>
      </div>
      <div class="card-body">
        <!-- Tipo de Estabelecimento -->
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input"
                         id="restaurante" name="restaurante"
                         [(ngModel)]="integracao.restaurante">
                  <label class="custom-control-label" for="restaurante">
                    <strong>Estabelecimento é Restaurante</strong>
                  </label>
                </div>
              </div>

              <div class="col-md-6" *ngIf="integracao.restaurante">
                <div class="form-group mb-0">
                  <label>
                    <i class="fas fa-clock mr-1"></i>
                    Tempo de Preparo
                    <small class="text-muted d-block">Tempo médio de preparação</small>
                  </label>
                  <kendo-numerictextbox
                    class="form-control"
                    [min]="0"
                    name="tempoPreparo"
                    [format]="'0 min'"
                    required
                    [(ngModel)]="integracao.tempoPreparo"
                    placeholder="Ex.: 30 minutos">
                  </kendo-numerictextbox>
                  <div class="invalid-feedback">Campo obrigatório</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Instruções e Ações -->
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>
                <i class="fas fa-info-circle mr-1"></i>
                Instruções para Retirada
                <small class="text-muted d-block">Orientações para os entregadores</small>
              </label>
              <textarea class="form-control" id="instrucoesRetirada"
                        rows="3" name="instrucoesRetirada"
                        [(ngModel)]="integracao.instrucoesRetirada"
                        maxlength="500"
                        placeholder="Ex.: Retirar os pedidos no balcão de entregas...">
              </textarea>
            </div>

            <div class="form-group mb-0">
              <label>
                <i class="fas fa-exclamation-triangle mr-1"></i>
                Ação Padrão para Não Entrega
                <small class="text-muted d-block">O que fazer quando não for possível entregar</small>
              </label>
              <kendo-combobox
                name="acaoretorno"
                [(ngModel)]="integracao.acaoretorno"
                [data]="acoesRetorno"
                textField="descricao"
                valueField="id"
                placeholder="Selecione a ação a ser tomada"
                class="form-control"
                required>
              </kendo-combobox>
              <div class="invalid-feedback">Campo é obrigatório</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Botões de Ação -->
    <div class="card-footer bg-light text-right">
      <div *ngIf="mensagemErro" class="alert alert-danger alert-dismissible fade show mb-3" role="alert">
        <i class="fas fa-exclamation-triangle mr-2"></i>{{mensagemErro}}
        <button type="button" class="close" (click)="fecharAlerta()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <button class="btn btn-warning" type="button" [disabled]="processando"
              (click)="ativeIntegracao()" *ngIf="this.integracao.id && !this.integracao.ativa">
        <i class="fas fa-power-off mr-2"></i>Ativar
        <i class="k-i-loading k-icon ml-2" *ngIf="processando"></i>
      </button>

      <ng-container *ngIf="!this.integracao.id || this.integracao.ativa">
        <button class="btn btn-success" type="submit" [disabled]="salvando || processando">
          <i class="fas fa-save mr-2"></i>Salvar
          <i class="k-i-loading k-icon ml-2" *ngIf="salvando"></i>
        </button>

        <button class="btn btn-danger ml-2" type="button" [disabled]="salvando || processando"
                *ngIf="integracao.id" (click)="desativeIntegracao()">
          <i class="fas fa-power-off mr-2"></i>Desativar
          <i class="k-i-loading k-icon ml-2" *ngIf="processando"></i>
        </button>
      </ng-container>
    </div>
  </form>
</div>
