<div class="card">
  <div class="card-body">
    <!-- Botões de Ação -->
    <div class="d-flex justify-content-between align-items-start">
      <div class="d-flex flex-wrap align-items-center">
        <!-- Criar Conta (Quando não tem integração) -->
        <div class="mb-2">
          <a href="https://indicacao.pagar.me/?partner=001Dn00000k5AC1IAM&platform=Meu%20Cardapio.ai&logopartner=https://imgur.com/axaz7f5.png"
             target="_blank"
             class="btn btn-primary mr-3 mb-2"
             *ngIf="!empresa.integracaoGatewayPagamento">
            <i class="fas fa-plus-circle mr-2"></i>
            Criar Nova Conta Pagar.me
          </a>
          <small class="d-block text-muted">Clique aqui se ainda não tem conta</small>
        </div>


        <!-- Integrar Conta Existente -->
        <div class="mb-2">
          <span #pagarmehub id="pagarme-hub"></span>
          <small class="text-muted d-block mt-1" *ngIf="!empresa.integracaoGatewayPagamento">
            Já tem uma conta? Clique em "Integrar com Pagar.me"
          </small>
        </div>
      </div>

      <!-- Logo Stone -->
      <img src="/assets/loja/stone-parceiro-logo.png" alt="Stone Parceiro" style="height: 70px;">
    </div>

    <!-- Ambiente de Teste -->
    <div class="mt-3" *ngIf="empresa.integracaoGatewayPagamento?.sandbox">
      <span class="badge badge-warning">
        <i class="fas fa-flask mr-1"></i>
        Ambiente de Testes (Sandbox)
      </span>
      <small class="text-muted d-block mt-1">
        Transações realizadas neste ambiente não são reais
      </small>
    </div>
  </div>
</div>

