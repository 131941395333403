<kendo-grid [data]="gridDataResult"
            [style]=""
            [loading]="loading" style="min-height: 100px;"
            [scrollable]="'scrollable'"
            [height]="alturaGrid"
            footerStyle="font-size: 11px;"
            (remove)="removerProdutoDaLista($event)"
            (edit)="editarProdutoDaLista($event)"
>
  <kendo-grid-messages
    pagerPage="Página"
    pagerOf="de"
    pagerItems="itens"
    noRecords="Adicione os Produtos do Pedido"
    loading="Carregando"
    pagerItemsPerPage="ítems por página"
  >
  </kendo-grid-messages>
  <kendo-grid-column field="qtde" title="Qtde." [width]="35" [style]="{padding: '8px 6px'}"
                     [headerStyle]="{'vertical-align': 'middle', padding: '8px 6px'}">
    <ng-template kendoGridHeaderTemplate style="font-size: 13px;">
            <span style="font-size:11px;">
              Qtd.
            </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span style="font-size: 12px;">
            {{dataItem.qtde}} {{dataItem.obtenhaUnidade()}}
            </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="produto" title="Produto" [width]="100" [style]="{padding: '8px 6px'}"
                     [headerStyle]="{'vertical-align': 'middle', padding: '8px 6px'}">
    <ng-template kendoGridHeaderTemplate style="vertical-align: middle;">
      <span style="font-size:13px;">Produto</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span style="font-size: 12px;">{{dataItem.obtenhaDescricao()}}</span>
      <span style="font-size: 12px;" *ngIf="dataItem.observacao"><br>Observação: <i>{{dataItem.observacao}}</i></span>
      <span *ngIf="dataItem.valoresAdicionais && dataItem.valoresAdicionais.length > 0" class="font-11"><br>
                <ng-container *ngFor="let last=last;let elemento of dataItem.valoresAdicionais ">
                  {{elemento.qtde}}x {{elemento.nome}}{{last ? '' : ', '}}
                </ng-container>
      </span>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
      Total
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="subTotal" class="coluna_menor" [width]="90" [style]="{padding: '8px 6px'}"
                     [headerStyle]="{'vertical-align': 'middle', padding: '8px 6px'}">
    <ng-template kendoGridHeaderTemplate style="font-size: 12px;">
            <span style="font-size:11px;">
              Preço / Sub-Total
            </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="font-12" *ngIf="!dataItem.valorResgatado">
              {{dataItem.valorUnitario | currency:"BRL"}} /
              {{dataItem.total | currency:"BRL"}}
            </span>
            <span class="font-12" *ngIf="dataItem.valorResgatado">
                        {{(dataItem.valorResgatado / dataItem.qtde)  }} pts /
                        {{dataItem.valorResgatado }} pts
            </span>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
      {{pedido?.subTotal | currency: "BRL" }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="45" class="coluna_menor" [style]="{padding: '8px 6px'}"
                             [headerStyle]="{'vertical-align': 'middle', padding: '8px 6px'}" *ngIf=" !soExibir">
    <ng-template kendoGridCellTemplate let-dataItem >

      <button kendoGridEditCommand  *ngIf="!dataItem.troca "  style="background: none; border: none; padding: 0" >
        <i class="fa fa-edit  " ></i></button>

      <button kendoGridRemoveCommand *ngIf="!dataItem.troca  "  style="background: none; border: none; padding: 0" >
        <i class="fa fa-trash  " ></i></button>

    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>
