<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()" style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fas fa-table"></i> Trocar de Mesa</h4>


<div class="row mt-2">
  <div class="col-auto">
    <app-tela-mesa-escolhida #telaMesaEscolhida></app-tela-mesa-escolhida>

    <h5>
      <span>Horário: <strong>{{comanda.horarioAberturaDescricao}}</strong></span>
    </h5>
    <h5>
      <span>Valor: <span class="mt-0 mb-1 font-24 valor">{{comanda.valor | currency: 'BRL' }}</span></span>
    </h5>
  </div>
</div>

<div class="row mt-2">
  <div class="col-auto mt-1" *ngFor="let mesa of mesas" style="width: 200px;">
    <div class="card">
      <div class="card-header d-flex justify-content-center" [class.selecionada]="mesaEscolhida.id === mesa.id"
           [class.disponivel]="mesa.disponivel" [class.indisponivel]="!mesa.disponivel">
        <h4 class="mt-0 mb-0 font-16 text-truncate" title="{{mesa.nome}}">
          <b>{{mesa.nome}}</b>
        </h4>
      </div>
      <div class="card-body d-flex align-items-center justify-content-center" style="height: 100px;">
        <div class="row no-gutters">
          <div class="col">
            <div class="" *ngIf="mesa.disponivel">
              <p class="card-text">
                <button class="btn btn-secondary mt-1" (click)="mesaFoiEscolhida(mesa)" *ngIf="mesaEscolhida.id !== mesa.id">Escolher</button>
                <span>
                  <i class="fas fa-check-circle h1" style="color: #6DB31B" *ngIf="mesaEscolhida.id === mesa.id"></i>
                </span>
              </p>
            </div>
            <div *ngIf="!mesa.disponivel">
              <i class="far fa-times-circle h1" style="color: #ff3535;"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row mt-2" *ngIf="mesaEscolhida.id">
  <div class="col-auto">
    <h3>De:</h3>
    <app-tela-mesa-escolhida #telaMesaEscolhida></app-tela-mesa-escolhida>
  </div>
  <div class="col-auto justify-content-center align-items-center d-flex">
    <i class="fas fa-exchange-alt h1"></i>
  </div>
  <div class="col-auto">
    <h3>Para:</h3>cei
    <app-tela-mesa-escolhida #telaMesaEscolhida [mesa]="mesaEscolhida" [carregarMesa]="false"></app-tela-mesa-escolhida>
  </div>
</div>
<div class="row mt-2">

  <div class="col-12">
    <div class="alert alert-warning mt-2" role="alert" *ngIf="comanda.fecharNoParceiro">
      <i class="mdi mdi-alert mr-2"></i> <b>Atenção!</b> troca de mesa não será feita no parceiro
    </div>
  </div>

  <div class="col-12">
    <div class="alert alert-danger mt-2" role="alert" *ngIf="msgErro">
      <i class="mdi mdi-block-helper mr-2"></i> {{msgErro}}
    </div>
  </div>

  <div class="col">
    <button id="btnFechar" class="btn btn-blue" type="button"(click)="trocaMesa()">
      Trocar Mesa
    </button>
  </div>
</div>
