import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-config-colunas',
  template: `
    <div class="k-dialog-titlebar">
      <div class="k-dialog-title">Colunas Exibir</div>

    </div>

    <div class="k-dialog-content">
      <div class="form-check mb-2" *ngFor="let coluna of colunas">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input"
                 [(ngModel)]="coluna.visible"
                 (change)="onColumnChange(coluna)">
          {{coluna.title}}
        </label>
      </div>
    </div>


    <p *ngIf="msg" class="text-danger mb-0 mt-1">{{msg}}</p>

    <div class="k-dialog-buttongroup k-dialog-buttongroup-stretched">
      <button class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              (click)="salvar()">
        Salvar
      </button>
      <button class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              (click)="close()">
        Cancelar
      </button>
    </div>
  `,
  styles: [`
    .k-dialog-content {
      padding: 16px;
      max-height: 400px;
      overflow-y: auto;
    }
    .form-check {
      padding: 8px;
    }
    .form-check:hover {
      background-color: #f8f9fa;
    }
  `]
})
export class ConfigColunasComponent implements OnInit {
  colunas: any[] = [];
  msg: string;
  constructor(public dialogRef: DialogRef) {}

  ngOnInit() {
  }

  onColumnChange(coluna: any) {
    delete    this.msg;
    // Lógica adicional se necessário
    let total = this.colunas.filter((item: any) =>  item.visible).length;

    if(total < 3){
      this.msg = 'Minimo 3 colunas tem que estar visiveis.'
      setTimeout(() => {
        coluna.visible = true;
      })
    }
  }

  salvar() {
    this.dialogRef.close(this.colunas);
  }

  close() {
    this.dialogRef.close();
  }
}
