<form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
      novalidate #frm="ngForm" (ngSubmit)="onSalvar()">

  <div class="row">
    <div class="form-group mb-3 col-12">
      <label for="nome">Nome</label>
      <input type="text" class="form-control" autocomplete="off" appAutoFocus [autoFocus]="true"
             id="nome" name="nome" [(ngModel)]="catalogo.nome" #nome="ngModel"
             placeholder="Nome do catálogo" value="" required/>
      <div class="invalid-feedback">
        <p *ngIf="nome.errors?.required">Nome do catálogo é obrigatório</p>
      </div>
    </div>


  </div>
  <div class="row">
    <div class="col-6">
      <div class=" ml-4">
        <label class="k-checkbox-label">
          <input type="checkbox"   name="precoPorEmpresa" class="k-checkbox "  kendoCheckBox  [(ngModel)]="catalogo.precoPorEmpresa" />
          Preço diferente por empresa que usa o catálogo</label>
      </div>

    </div>
    <div class="col-6">
      <div class=" ml-4">
        <label class="k-checkbox-label">
          <input type="checkbox"   name="disponibilidadePorEmpresa" class="k-checkbox "  kendoCheckBox  [(ngModel)]="catalogo.disponibilidadePorEmpresa" />
          Disponibilidade diferente por empresa que usa o catálogo</label>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class=" ml-4">
        <label class="k-checkbox-label">
          <input type="checkbox"   name="compartilhado" class="k-checkbox "  kendoCheckBox  [(ngModel)]="catalogo.compartilhado" />
          Impedir edição diretamente no link da empresa</label>
      </div>

    </div>
  </div>



  <div class="alert alert-danger" role="alert" *ngIf="mensagemErro">
    <i class="fas fa-exclamation-triangle"></i> <span class="msg-erro">
    {{mensagemErro}}
  </span>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary waves-effect waves-light" [disabled]="salvando" >Salvar</button>
    <button type="button" (click)="fechar()" kendoButton [primary]="true"  class="btn btn-light waves-effect">Cancelar</button>
  </div>


</form>
