<h4 class="page-title">
  <button class="btn btn-outline-blue btn-rounded" (click)="voltar()" style="margin-right: 5px;">
    <i class="fa fa-arrow-left ct-point" ></i>
  </button>
  <i class="fas fa-table mr-1"></i> <span >Detalhes da Comanda</span></h4>

<div class="row" *ngIf="comanda">
  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
        class="col-12"
        novalidate #frm="ngForm" (ngSubmit)="onSubmitFecharComanda($event)" >

    <div class="card  ">
      <div class="card-body">
        <div class="row mt-2">
          <div class="col-sm-6 col-12">
            <h4>Mesa: <strong>{{comanda.mesa.nome}}</strong>
              <span *ngIf="comanda.codigoPdv"> - Comanda {{comanda.codigoPdv}}</span>
            </h4>

            <h4>
             <span class="badge badge-pill ml-2" [class.badge-success]="!comanda.fechada"
                   [class.badge-dark]="comanda.fechada"> <i class="fe-user"></i>{{comanda.status}}</span>

              <div *ngIf="comanda.fechada" class="alert alert-dark mt-2">
                <i class="fe-lock mr-1"></i>
                <strong>Mesa Fechada</strong> - Não é possível realizar alterações
              </div>

              <h5 class="d-inline-block ml-2" [class.text-muted]="comanda.fechada">
                <span>Horário: <strong>{{comanda.horarioAberturaDescricao}}</strong> </span>
                <span *ngIf="comanda.horarioFechamentoDescricao"><strong> -  {{comanda.horarioFechamentoDescricao}}</strong></span>
              </h5>
            </h4>
          </div>
          <div class="col-sm-6 col-12">
            <info-contato [contato]="comanda.cliente" [exibirUltimaVisita]="false" [empresa]="empresa"></info-contato>
          </div>
        </div>

        <div class="mt-2 d-none d-sm-block">
          <app-grid-pedidos #gridPedidos [comanda]="comanda" [pedidos]="comanda.pedidos" [carregando]="carregando" [mesa]="true"
                            (onAtualizouComanda)="recarregueComanda($event)"   [editarPedido]="true"></app-grid-pedidos>
        </div>
        <div class="mt-2">
          <h4>Itens:</h4>

          <div class="card mt-3 d-sm-none">
            <div class="card-body">
              <div class="mb-1">
                <div class="row border-bottom border-dark">
                  <div class="col-6 font-weight-bold">
                    Produto
                  </div>
                  <div class="col-3 font-weight-bold">
                    Qtde
                  </div>
                  <div class="col-3 font-weight-bold">
                    Total
                  </div>
                </div>
                <div *ngFor="let item of comanda.itens" class="row">
                  <div class="col-6">
                    {{item.descricao}} <app-pedido-status [pedido]="item.pedido"></app-pedido-status>
                  </div>
                  <div class="col-3 text-right">
                    {{item.qtde}}{{item.unidade}}
                  </div>
                  <div class="col-3 text-right">
                    <span>{{item.total | currency: "BRL"}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <kendo-grid
          [kendoGridBinding]="comanda.itens"
          [loading]="carregando"
          class=" d-none d-sm-block"
          footerStyle="font-size: 11px;">
          <kendo-grid-column title="Pedido" [width]="70">
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              {{item.pedido.codigo}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Produto"     >
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              <span>{{item.descricao}}</span>
              <ng-container *ngIf="item.adicionaisImprirmir ">
                <br>
                <ng-container *ngFor="let adicional of item.adicionaisImprirmir; let isLast=last">
                  <i>{{adicional.descricao}}{{!isLast ? ', ' : ''}}</i>
                  <br *ngIf="isLast">
                </ng-container>
              </ng-container>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Observacao"    >
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              <span *ngIf="item.observacao"><i>"{{item.observacao}}"</i></span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Qtde"   [width]="80"  >
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              <span>{{item.qtde}}{{item.unidade}}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Status"   [width]="90"  >
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              <app-pedido-status [pedido]="item.pedido"></app-pedido-status>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Valor"    >
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              <span>{{item.valor | currency: "BRL"}}</span>
            </ng-template>
          </kendo-grid-column>


          <kendo-grid-column title="Total"    >
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
              <span>{{item.total | currency: "BRL"}}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Horário"    media="(min-width: 900px)"  [width]="115">
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">
      <span [ngClass]=" { 'negrito': !item.pedido.visualizado }">
        {{item.pedido.horarioVenda  | date:  item.pedido.finalizado ? 'dd/MM/yy HH:mm' : 'HH:mm' }} </span> <br>

              <span *ngIf="item.pedido.duracaoHoras <= 1" [ngClass]=" { 'negrito badge badge-success': !item.pedido.visualizado }" >
        {{item.pedido.duracaoDescricao}} atrás
      </span>

              <span *ngIf="!item.pedido.finalizado && item.pedido.duracaoHoras > 1" class="badge badge-danger" >
        {{item.pedido.duracaoDescricao}} atrás
      </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Pedido"    media="(min-width: 900px)"  [width]="115" *ngIf="comanda.aberta">
            <ng-template kendoGridCellTemplate let-item let-rowIndex="rowIndex">

              <kendo-dropdownbutton [data]="opcoesItemPedido" [popupSettings]="{animate: true}" [themeColor]="'success'"
                                   (click)="prevenirSelecao($event)" style="    width: auto !important;"
                                    (itemClick)="escolheuOpcaoItemPedido($event, item)"  *ngIf="!item.pedido.cancelado">
                <i class="fa fa-edit"></i>
              </kendo-dropdownbutton>

            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>

    <div class="card mt-3">
        <div class="card-body">
          <div class="mb-1">
            <div class="row">
              <div class="col">

                <div class="form-group  "  >
                  <label>Valor Comanda</label>
                  <div>
                    <p class="mt-0 mb-1 font-24 valor  d-inline"  [class.riscado] = "desconto > 0">

                      {{comanda.valor | currency: 'BRL' }}

                    </p>

                    <ng-container *ngIf="desconto > 0">
                      <span class="d-inline ml-2 mr-2">por</span>
                      <p class="mt-0 mb-1 font-24 valor d-inline">
                        {{  this.totalSemTaxa() | currency: 'BRL' }}

                      </p>
                    </ng-container>

                  </div>


                </div>


                <div class="row" *ngIf="comanda.valor" [hidden]="comanda.fechada || comanda.emFechamento">
                  <div class="form-group col col-sm-4">
                    <label>Desconto</label>

                    <div class="input-group">
                      <kendo-numerictextbox name="valorFinal"   format="c2" appRemovaWheel  class="form-control"  [disabled]="comanda.fechada"
                                            [(ngModel)]="desconto" [min]="0"  (ngModelChange)="recalculeTotalComanda()"  >
                      </kendo-numerictextbox>
                    </div>
                  </div>

                  <ng-container *ngIf="empresa.cobrarTaxaServico">
                    <div class="form-group  col-12"   >
                      <label class="k-checkbox-label">
                        <input type="checkbox"   name="taxaDeServico" class="k-checkbox " kendoCheckBox
                               (change)="alterouCobarTaxa()"  [(ngModel)]="comanda.cobrarTaxaServico" />
                        Cobrar taxa de serviço</label>
                    </div>
                    <ng-container *ngIf="comanda.cobrarTaxaServico">
                      <div class="form-group col-12" >
                        <label>Taxa de Serviço ({{empresa.valorTaxaServico}}%):</label>
                        <div class="mt-0 mb-1 font-24 valor">{{comanda.taxaServico | currency: 'BRL' }}</div>

                      </div>
                      <div class="form-group col-12">
                        <label>Valor com Taxa de Serviço:</label>
                        <div class="mt-0 mb-1 font-24 valor">{{comanda.totalComTaxa | currency: 'BRL' }}</div>

                      </div>
                    </ng-container>

                  </ng-container>
                </div>

                <div class="row" *ngIf="comanda.valor && (comanda.fechada || comanda.emFechamento)"  >
                  <div class="form-group col-12" *ngIf="comanda.desconto">
                    <label>Desconto</label>
                    <div class="mt-0 mb-1 font-24  ">
                      {{comanda.desconto | currency: 'BRL' }}
                    </div>

                  </div>

                  <ng-container *ngIf="comanda.taxaServico">
                    <div class="form-group col-12" >
                      <label>Taxa de Serviço  </label>
                      <div class="mt-0 mb-1 font-24  ">{{comanda.taxaServico | currency: 'BRL' }}</div>

                    </div>

                    <div class="form-group col-12">
                      <label>Valor com Taxa de Serviço:</label>
                      <div class="mt-0 mb-1 font-24 valor">{{comanda.totalComTaxa | currency: 'BRL' }}</div>

                    </div>
                  </ng-container>

                </div>

                <div class="form-group" *ngIf="comanda.pagamentos.length > 0">
                  <label>Pagamentos</label>
                  <div class="mt-2 mb-1 font-24 valor">
                    <table class="table font-13 col-sm-6 col-12">
                      <thead>
                      <tr>
                        <th scope="col">Forma</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Troco Para</th>
                        <th scope="col">Troco</th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr class="pamento " *ngFor="let pagamento of comanda.pagamentos; let primeiro = first;">
                        <td>
                          <b> {{pagamento.formaDePagamento.descricao}}</b>
                        </td>
                        <td>
                          {{pagamento.valor | currency: "BRL" }}
                        </td>
                        <td>
                          <span *ngIf="pagamento.formaDePagamento.nome === 'dinheiro' && pagamento.trocoPara">
                            {{pagamento.trocoPara | currency: "BRL"}}

                          </span>
                          <ng-container *ngIf="!pagamento.trocoPara">-</ng-container>
                        </td>
                        <td>
                          <span *ngIf="pagamento.formaDePagamento.nome === 'dinheiro' && pagamento.valorTroco ">
                            {{pagamento.valorTroco | currency: "BRL"}}

                          </span>
                          <ng-container *ngIf="!pagamento.valorTroco">-</ng-container>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


                <div class="form-group"
                     *ngIf="(comanda.aberta || comanda.emFechamento) && comanda.valor && !exibirPrefechamento">

                  <h4 *ngIf="comanda.emFechamento" class="mb-2">Comanda em <b>Pré fechamento</b> </h4>

                  <i class="k-icon k-i-loading mt-2"  *ngIf="carregandoFormasPagamentos"></i>

                  <form [ngClass]="{'needs-validation': !frm.submitted, 'was-validated': frm.submitted}"
                        novalidate #frm="ngForm" *ngIf="!carregandoFormasPagamentos">
                    <app-grid-pagamento #gridPagamentos  [frm]="frm" [pagamentoComanda]="true"
                                        [formasDePagamento]="formasPagamentoMesa" [totalPagar]="totalDaComanda()">
                    </app-grid-pagamento>
                  </form>

                </div>

                <div class="botoes-acoes">
                  <div>
                    <button class="btn btn-blue btn-block mb-2" type="button" (click)="novoPedido()" [disabled]="fechando" *ngIf="comanda.aberta && !fecharSemPrechamento">
                      <i class="fas fa-plus mr-1"></i> Novo Pedido
                    </button>
                  </div>

                  <div>
                    <button class="btn btn-warning btn-block mb-2" type="button" (click)="enviePreFechamento()"
                            *ngIf="exibirPrefechamento" [hidden]="fecharSemPrechamento" [disabled]="fechando">
                      <i class="k-i-loading k-icon mr-1" *ngIf="fechando"></i> Pré Fechamento
                    </button>
                  </div>

                  <ng-container *ngIf="(comanda.aberta || comanda.emFechamento)">
                    <div [hidden]="operador.garcom && !empresa.garcomFecharComandas">
                      <button class="btn btn-primary btn-block mb-2 btn-fechar-comanda" [disabled]="fechando"
                              *ngIf="!comanda.preFechamentoObrigatorio || comanda.emFechamento || fecharSomenteMeucardapio">
                        <span *ngIf="fechando" class="loading-spinner"></span>
                        <span *ngIf="!fechando"><i class="fas fa-check mr-1"></i></span>
                        Fechar Comanda
                      </button>
                    </div>

                    <div>
                      <button class="btn btn-outline-secondary btn-block mb-2" type="button" [hidden]="fecharSomenteMeucardapio"
                              (click)="cancelarFecharDireto()" *ngIf="fecharSemPrechamento" [disabled]="fechando">
                        Voltar
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="comanda.aberta && !fecharSemPrechamento">
                    <div>
                      <button class="btn btn-blue btn-block mb-2" type="button" (click)="trocaMesa()" [disabled]="fechando">
                        <i class="fas fa-arrow-right mr-1"></i> Trocar Mesa
                      </button>
                    </div>
                  </ng-container>

                  <!-- Opções de impressão -->
                  <ng-container *ngIf="!configImpressao || !configImpressao.multiplasImpressoras">
                    <div *ngIf="!isMobile">
                      <button type="button" class="btn btn-outline-blue btn-block mb-2" *ngIf="configImpressao?.layoutPedido !== 'Ambos'"
                              (click)="imprimirComanda($event)" title="imprimir" kendoTooltip [disabled]="imprimindo">
                        <i class="fa fa-print"></i> Imprimir
                      </button>
                    </div>

                    <div *ngIf="!isMobile">
                      <kendo-dropdownbutton *ngIf="configImpressao?.layoutPedido === 'Ambos'"
                                          class="mb-2"
                                          [data]="opcoesImpressao"
                                          [popupSettings]="{animate: true}"
                                          (itemClick)="imprimirComanda($event)"
                                          [disabled]="imprimindo">
                        <i class="fa fa-print"></i> Imprimir
                      </kendo-dropdownbutton>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="configImpressao?.multiplasImpressoras && impressoras.length">
                    <div *ngIf="!isMobile">
                      <kendo-dropdownbutton class="mb-2"
                                          [data]="impressoras"
                                          [popupSettings]="{animate: true}"
                                          (itemClick)="imprimirComanda($event, true)"
                                          [textField]="'setor'">
                        <i class="fa fa-print"></i> Imprimir
                      </kendo-dropdownbutton>
                    </div>
                  </ng-container>
                </div>

                <div class="row mb-2" *ngIf="comanda.fecharNoParceiro" [hidden]="!comanda.aberta && !comanda.emFechamento">
                  <div class="form-group mb-2 col-7">

                    <div class="input-group mt-2">
                      <input name="fecharNoParceiro" id='fecharNoParceiro' class="k-checkbox  " kendoCheckBox
                             type="checkbox" [(ngModel)]="fecharSomenteMeucardapio" (change)="alterouFecharParceiro()" />
                      <label  class="k-checkbox-label" for="fecharNoParceiro">
                        Fechar somente no <b>Meucadapio</b> e ignorar fechamento no parceiro <b>{{empresa.integracaoDelivery.sistema}}</b></label>


                    </div>

                  </div>
                </div>

                <div *ngIf="comanda?.pontosReceber" class="row mb-2">
                  <div class="col">
                    <i class="fe-star-on   text-blue"></i>
                    Pontuação Receber: <b>{{this.comanda.pontosReceber}}</b>
                  </div>
                </div>

                <div>
                  <div *ngIf="erro" class="alert alert-danger mt-2 mb-2" role="alert" [class.erro-entrada]="erroAnimando">
                    <i class="fas fa-exclamation-circle mr-2"></i>
                    {{erro}}
                  </div>

                </div>


              </div>
            </div>
          </div>

        </div>

    </div>

  </form>
</div>

<div class="alert alert-danger alert-dismissible fade show" *ngIf="carregou && comanda && !comanda.id" role="alert">
  <p>Comanda não encontrada</p>
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="sucesso-fechamento" *ngIf="sucessoFechamento">
  <i class="fas fa-check-circle mr-2"></i>
  Comanda fechada com sucesso!
</div>



<kendo-dialog title="Confirme a remoçao" *ngIf="itemRemover"
              (close)="canceleRemover()" [minWidth]="250" [width]="'450px'">
  <p class="mb-2 text-center">Confirma remoçao do item "<b>{{itemRemover.qtde}}x - {{itemRemover.produto?.nome}} </b>"  no valor de
     <span class="preco"><b>{{itemRemover.total | currency: "BRL"}}</b></span> ?</p>

  <div class="alert alert-danger mb-2" *ngIf="this.itemRemover.erro">
     <b>{{ this.itemRemover.erro}}</b>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="canceleRemover()" >Não</button>
    <button kendoButton (click)="removaItemPedidoComanda()" [primary]="true" [disabled]="   this.itemRemover.removendo">
      <i class="k-i k-i-loading mr-1" *ngIf="this.itemRemover.removendo"></i>Sim</button>
  </kendo-dialog-actions>
</kendo-dialog>
