import {Component, OnInit, ViewChild} from '@angular/core';

import {EmpresaService} from "../../../services/empresa.service";
import {NgForm} from "@angular/forms";
import {ModalKendo} from "../../../lib/ModalKendo";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-cadastro-empresa-tuna',
  templateUrl: './cadastro-empresa-tuna.component.html',
  styleUrls: ['./cadastro-empresa-tuna.component.scss']
})
export class CadastroEmpresaTunaComponent  extends ModalKendo implements OnInit  {
  @ViewChild("frm") frm: NgForm;
  modalAberto = false;
  salvando = false;
  empresaTuna: any = {
    name: '',
    fantasyName: '',
    document: null,
    documentType: 'CNPJ',
    motherName: null,
    birthday: null,
    occupation: null,
    contact: {   },
    pixKeyType: '',
    pixKey: ''
  };
  bankAccount: any = {
    document: null,
    documentType: null,
    accountType: null,
    agency: null,
    number: null,
    bankCode: null,
    bankName: null
  }
  empresa: any  = {};
  bancos: any  = []
  bancoSelecionado: any;
  mensagemErro: string;
  carregando = true;
  constructor( private empresaService: EmpresaService) {
    super();
       this.empresaService.listeBanco().then((resp) => {
        this.bancos = resp;
      });
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };
  tiposChavesPix: any = [];
  tiposDeConta: any = [
    {text: 'Conta Corrente', value: 'C'},
    {text: 'Conta Poupança', value: 'S'}
  ];
  ngOnInit() {
    this.modalAberto = true;
    this.empresaService.obntehaMerchantTuna().then( (resposta: any) => {
      this.tiposChavesPix = resposta.tiposChavesPix;
      this.empresaTuna = resposta.merchant;

      if(resposta.merchant.bankAccounts && resposta.merchant.bankAccounts.length){
        this.bankAccount = resposta.merchant.bankAccounts[0]

        if(this.bankAccount.accountType)
          this.bankAccount.accountType = this.tiposDeConta.find((item: any) => item.value === this.bankAccount.accountType )


        this.bancoSelecionado = this.bancos.find((banco: any) => banco.codigo === this.bankAccount.bankCode)

      }

      if(this.empresaTuna.pixKeyType)
        this.empresaTuna.pixKeyType =
          this.tiposChavesPix.find((item: any) => item.value === this.empresaTuna.pixKeyType  );

      if(this.empresaTuna.documentType)
        this.mudouTipoDocumento(this.empresaTuna.documentType)

      this.carregando   = false;
    })
  }

  empresaComCNPJ(){
    return this.empresaTuna.documentType === 'CNPJ'
  }


  salvar() {
    if (this.frm.valid) {
      this.salvando = true;

      this.empresaTuna.bankAccounts = [this.bankAccount];

      this.empresaService.registreEmpresaTuna(this.empresaTuna).then((resp) => {
        this.salvando = false;
        this.fecheModal({result: true});
      }).catch((err) => {
        this.salvando = false;
        this.mensagemErro = err;
      });
    } else {
      this.mensagemErro = 'verifique campos obrigatorios';
    }
  }

  escolheuBanco(banco: any) {
    this.bankAccount.bankCode = banco.codigo;
    this.bankAccount.bankName = banco.nome;
  }

  mudouTipoDocumento(tipo: any) {
    if(tipo === 'CNPJ')
      this.empresaTuna.pixKeyType = this.tiposChavesPix[1];
    else
      this.empresaTuna.pixKeyType = this.tiposChavesPix[0];

    if(! this.empresaTuna.pixKey || tipo === 'CNPJ' )
      this.empresaTuna.pixKey = this.empresaTuna.document;

  }
}
