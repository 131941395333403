

export enum EnumDisponibilidadeProduto{
  SempreDisponivel,
  DiaDaSemana,
  NaoDisponivel,
  Oculto
}


export const DisponibilidadeProdutApiLabel = new Map<string, EnumDisponibilidadeProduto>(
  [
    ['sempre_disponivel', EnumDisponibilidadeProduto.SempreDisponivel],
    ['nao_disponivel', EnumDisponibilidadeProduto.NaoDisponivel]
  ]);
