import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { ContatosService } from '../../services/contatos.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Router } from '@angular/router';
import { FiltroAvancadoComponent } from "../../componentes/filtro-avancado/filtro-avancado.component";
import { UsuarioService } from "../../services/usuario.service";
import { PlanosService } from "../../services/planos.service";
import { FiltroTelaUI } from "../../../../projects/loja/src/objeto/FiltroTelaUI";
import { EmpresasService } from "../../superadmin/services/empresas.service";
import { ConstantsService } from "../ConstantsService";
declare var _;

@Component({
  selector: 'app-kanban-contatos',
  templateUrl: './kanban-contatos.component.html',
  styleUrls: ['./kanban-contatos.component.scss']
})
export class KanbanContatosComponent implements OnInit, AfterViewInit {
  @ViewChild('filtroComponent') filtroComponent: FiltroAvancadoComponent;

  public Object = Object;
  public filtroAberto = false;
  private empresaAtual: any;

  public colunas = [
    { id: 'Novo', titulo: 'Novos', cor: '#3498db', icone: 'fas fa-star', expanded: false },
    { id: 'Regular', titulo: 'Ativos', cor: '#2ecc71', icone: 'fas fa-user', expanded: false },
    { id: 'VIP', titulo: 'VIPs', cor: '#f1c40f', icone: 'fas fa-crown', expanded: false },
    { id: 'Perdido', titulo: 'Perdidos', cor: '#95a5a6', icone: 'fas fa-user-slash', expanded: false },
    { id: 'RegularEmRisco', titulo: 'Regulares em Risco', cor: '#ffc107', icone: 'fas fa-user-clock', expanded: false },
    { id: 'Recorrente', titulo: 'Recorrentes', cor: '#9b59b6', icone: 'fas fa-redo', expanded: false },
    { id: 'RecorrenteEmRisco', titulo: 'Recorrentes em Risco', cor: '#fd7e14', icone: 'fas fa-redo-alt', expanded: false },
    { id: 'VIPEmRisco', titulo: 'VIPs em Risco', cor: '#dc3545', icone: 'fas fa-crown-slash', expanded: false },
    { id: 'EmRisco', titulo: 'Em Risco', cor: '#e74c3c', icone: 'fas fa-exclamation-triangle', expanded: false },
    { id: 'Importado', titulo: 'Importados', cor: '#20c997', icone: 'fas fa-file-import', expanded: false },
  ];

  public contatos: any[] = [];
  public carregando = false;
  private itensPorStatus = 20; // Reduzido para carregar menos por status
  private isHorizontalScrolling = false;
  private canScrollPage = false;
  private scrollTimeout: any;
  public termoBusca = '';

  // Controle de paginação por status
  public paginacaoPorStatus: { [key: string]: number } = {};
  public carregandoPorStatus: { [key: string]: boolean } = {};
  private fimDaPaginaPorStatus: { [key: string]: boolean } = {};
  private carregamentoInicial = true;
  public totalPorStatus: { [key: string]: number } = {};

  public filtro: any = { id: -1, dados: {}, nome: 'Todos os contatos', exibir: false };
  public filtros: any = [ this.filtro ];
  public planos = [];
  public plano: any = {};
  public modalSalvarFiltro = false;
  public erroSalvarFiltro: string;

  public resumo: any[] = [];
  public paginacao: { [key: string]: number } = {};
  public contatosPorStatus: { [key: string]: any[] } = {};
  public tamanhoPagina = 20;

  constructor(
    private contatosService: ContatosService,
    private notificationService: NotificationService,
    private router: Router,
    private usuarioService: UsuarioService,
    private planosService: PlanosService,
    private empresaService: EmpresasService,
    private constantsService: ConstantsService
  ) {
    // Inicializa a paginação para cada status
    this.colunas.forEach(coluna => {
      this.paginacaoPorStatus[coluna.id] = 0;
      this.carregandoPorStatus[coluna.id] = false;
      this.fimDaPaginaPorStatus[coluna.id] = false;
      this.totalPorStatus[coluna.id] = 0;
    });
  }

  ngOnInit() {
    this.constantsService.empresa$.subscribe(empresa => {
      if (empresa) {
        this.empresaAtual = empresa;
        this.carregarDados();
      }
    });
  }

  ngAfterViewInit() {
    if (this.filtroComponent) {
      this.filtroComponent.setPlanos(this.planos);
    }
  }

  async carregarDados() {
    try {
      this.planos = await this.planosService.listePlanos();
      this.setPlanoPadrao();
      if (this.filtroComponent) {
        this.filtroComponent.setPlanos(this.planos);
      }
      await this.carregarContatos();
      const filtros = await this.usuarioService.listeFiltros();
      this.filtros = _.union(this.filtros, filtros);
    } catch (erro) {
      console.error('Erro ao carregar dados:', erro);
      this.notificationService.show({
        content: 'Erro ao carregar dados',
        hideAfter: 3000,
        position: { horizontal: 'right', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'error', icon: true }
      });
    }
  }

  setPlanoPadrao() {
    if (this.planos.length === 1)
      this.plano = this.planos[0];
    else
      this.plano = {};
  }

  getParamsFiltro() {
    // Se houver termo de busca, adiciona ao filtro
    if (this.termoBusca && this.termoBusca.trim() !== '') {
      this.filtro.dados.q = this.termoBusca;
    } else {
      // Se não houver termo, remove do filtro
      delete this.filtro.dados.q;
      delete this.filtro.q;
    }
    return FiltroTelaUI.getParamsRequest(this.filtro.dados);
  }

  exibaFiltroAvancado() {
    this.filtroAberto = true;
    this.filtro.exibir = true;
    // Aguarda o próximo ciclo do Angular para garantir que o componente foi criado
    setTimeout(() => {
      if (this.filtroComponent) {
        this.filtroComponent.setPlanos(this.planos);
        this.filtroComponent.setFiltroAvancado(this.filtro.dados);
      } else {
        console.error('Componente de filtro não inicializado');
        this.notificationService.show({
          content: 'Erro ao abrir filtro avançado',
          hideAfter: 3000,
          position: { horizontal: 'right', vertical: 'top' },
          animation: { type: 'fade', duration: 500 },
          type: { style: 'error', icon: true }
        });
      }
    });
  }

  fecharFiltroAvancado(): void {
    this.filtroAberto = false;
    this.filtro.exibir = false;
    if (this.filtroComponent) {
      this.filtroComponent.exibir = false;
    }
  }

  onFiltrarAvancado(filtro: any) {
    this.filtro.dados = filtro;
    this.filtro.salvar = true;
    this.filtro.exibir = false;

    // Reseta as paginações e limpa os contatos
    this.colunas.forEach(coluna => {
      this.paginacaoPorStatus[coluna.id] = 0;
      this.fimDaPaginaPorStatus[coluna.id] = false;
      this.contatosPorStatus[coluna.id] = [];
      this.totalPorStatus[coluna.id] = 0;
    });

    // Limpa os arrays de contatos
    this.contatos = [];
    this.contatosPorStatus = {};

    this.carregamentoInicial = true;
    this.carregarContatos();
  }

  async carregarContatos() {
    try {
      // Carrega o resumo primeiro
      await this.carregarResumo();

      // Prepara os parâmetros do filtro
      const filtroParams = this.getParamsFiltro();
      const params = {
        idEmpresa: this.empresaAtual.id,
        ...filtroParams
      };

      // Carrega os contatos para cada status
      const promises = this.colunas.map(coluna => {
        const paramsCompletos = {
          ...params,
          st: coluna.id // Usa st ao invés de status
        };

        return this.contatosService.listeContatos(
          this.paginacaoPorStatus[coluna.id] || 0,
          this.tamanhoPagina,
          paramsCompletos
        ).then(contatos => {
          if (this.paginacaoPorStatus[coluna.id] === 0) {
            this.contatosPorStatus[coluna.id] = contatos;
          } else {
            this.contatosPorStatus[coluna.id] = [...(this.contatosPorStatus[coluna.id] || []), ...contatos];
          }

          // Marca fim da paginação se não houver mais contatos
          if (contatos.length < this.tamanhoPagina) {
            this.fimDaPaginaPorStatus[coluna.id] = true;
          }
        });
      });

      await Promise.all(promises);
      this.carregamentoInicial = false;
    } catch (erro) {
      console.error('Erro ao carregar contatos:', erro);
      this.notificationService.show({
        content: 'Erro ao carregar contatos',
        hideAfter: 3000,
        position: { horizontal: 'right', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'error', icon: true }
      });
    }
  }

  async carregarResumo() {
    try {
      const params = this.getParamsFiltro();
      const resumo = await this.contatosService.listeResumo(params);

      if (resumo) {
        resumo.forEach(item => {
          this.totalPorStatus[item.status] = item.qtde || 0;
        });
      }
    } catch (erro) {
      console.error('Erro ao carregar resumo:', erro);
      this.notificationService.show({
        content: 'Erro ao carregar resumo',
        hideAfter: 3000,
        position: { horizontal: 'right', vertical: 'top' },
        animation: { type: 'fade', duration: 500 },
        type: { style: 'error', icon: true }
      });
    }
  }

  getDescricaoFiltro(filtro: any) {
    if (typeof filtro === 'string') {
      if (!this.filtro.q) return '';
      return 'Buscando por: \"' + this.filtro.q + "\"";
    }

    let label = FiltroTelaUI.getLabel(filtro);

    if (!(label === true || label === false)) {
      return filtro.nome + ": " + label;
    } else {
      return filtro.nome;
    }
  }

  abraModalSalvarFiltro() {
    if (!this.filtro.id) this.filtro.nome = '';
    this.modalSalvarFiltro = true;
  }

  fecheModalFiltro() {
    this.modalSalvarFiltro = false;
  }

  salveFiltro() {
    delete this.erroSalvarFiltro;
    if (!this.filtro.nome) {
      this.erroSalvarFiltro = 'informa um nome para o filtro';
      return;
    }
    this.usuarioService.salveFiltro(this.filtro).then(resp => {
      this.filtro.id = resp.id;
      this.fecheModalFiltro();
    }).catch((erro) => {
      this.erroSalvarFiltro = erro;
    });
  }

  limpeFiltros() {
    this.filtro.dados = {};
    delete this.filtro.id;
    this.carregamentoInicial = true;
    this.carregarContatos();
  }

  removerFiltro(key: any) {
    delete this.filtro.dados[key.toString()];
    delete this.filtro[key];

    // Limpa o termo de busca se o filtro removido for 'q'
    if (key === 'q') {
      this.termoBusca = '';
    }

    this.carregamentoInicial = true;
    this.carregarContatos();
  }

  onScrollColuna(status: string) {
    if (this.fimDaPaginaPorStatus[status] || this.carregandoPorStatus[status]) {
      return;
    }

    this.paginacaoPorStatus[status]++;

    // Prepara os parâmetros do filtro
    const filtroParams = this.getParamsFiltro();
    const params = {
      idEmpresa: this.empresaAtual.id,
      ...filtroParams
    };

    const paramsCompletos = {
      ...params,
      st: status // Usa st ao invés de status
    };

    this.carregandoPorStatus[status] = true;
    this.contatosService.listeContatos(
      this.paginacaoPorStatus[status] * this.tamanhoPagina,
      this.tamanhoPagina,
      paramsCompletos
    ).then(contatos => {
      this.contatosPorStatus[status] = [...(this.contatosPorStatus[status] || []), ...contatos];

      if (contatos.length < this.tamanhoPagina) {
        this.fimDaPaginaPorStatus[status] = true;
      }
      this.carregandoPorStatus[status] = false;
    }).catch(erro => {
      console.error(`Erro ao carregar mais contatos do status ${status}:`, erro);
      this.carregandoPorStatus[status] = false;
    });
  }

  onBuscaChange(termo: string) {
    this.termoBusca = termo;

    // Se o termo estiver vazio, remove o filtro de busca
    if (!termo || termo.trim() === '') {
      delete this.filtro.q;
    } else {
      this.filtro.q = termo;
    }

    // Reseta as paginações e limpa os contatos
    this.colunas.forEach(coluna => {
      this.paginacaoPorStatus[coluna.id] = 0;
      this.fimDaPaginaPorStatus[coluna.id] = false;
      this.contatosPorStatus[coluna.id] = [];
      this.totalPorStatus[coluna.id] = 0;
    });

    // Limpa os arrays de contatos
    this.contatos = [];
    this.contatosPorStatus = {};

    this.carregamentoInicial = true;
    this.carregarContatos();
  }

  abrirBuscaAvancada() {
    // TODO: Implementar busca avançada
    console.log('Abrir busca avançada');
  }

  getContatosPorStatus(status: string): any[] {
    return this.contatosPorStatus[status] || [];
  }

  getExibindoTotal(status: string) {
    const exibindo = this.getContatosPorStatus(status).length;
    const total = this.totalPorStatus[status] || 0;

    return `${exibindo}/${total}`;
  }

  abrirWhatsApp(telefone: string) {
    const numeroLimpo = telefone.replace(/\D/g, '');
    window.open(`https://wa.me/${numeroLimpo}`, '_blank');
  }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
      if (this.filtro?.exibir) {
      return true;
    }

    const target = event.target as HTMLElement;
    const columnContent = target.closest('.column-content');
    const board = document.querySelector('.kanban-board');

    // Limpa o timeout anterior
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    // Define um timeout para resetar os estados após 500ms sem rolar
    this.scrollTimeout = setTimeout(() => {
      this.isHorizontalScrolling = false;
      this.canScrollPage = false;
    }, 600);

    // Se pode rolar a página, não interfere no evento
    if (this.canScrollPage) {
      return;
    }

    // Se está em scroll horizontal, verifica se chegou ao fim
    if (this.isHorizontalScrolling && board) {
      const isAtEnd = board.scrollLeft + board.clientWidth >= board.scrollWidth;
      const isAtStart = board.scrollLeft <= 0;

      // Se estiver no início ou fim do scroll e continuar rolando na mesma direção
      if ((isAtEnd && event.deltaY > 0) || (isAtStart && event.deltaY < 0)) {
        this.canScrollPage = true;
        setTimeout(() => {
          this.isHorizontalScrolling = false;
        }, 100); // Pequeno delay para evitar saltos
        return;
      }

      event.preventDefault();
      board.scrollLeft += event.deltaY * 3;
    }
    // Se não está em scroll horizontal, verifica se deve iniciar
    else if (!columnContent || (columnContent && columnContent.scrollHeight <= columnContent.clientHeight)) {
      this.isHorizontalScrolling = true;
      event.preventDefault();
      if (board) {
        board.scrollLeft += event.deltaY * 3;
      }
    }
  }

  verContato(contato: any) {
    let link = '/admin/contatos'

    if(contato.telefone)
      link = String(`${link}/${contato.codigoPais}${contato.telefone}`)
    else
      link = String(`${link}/cpf/${contato.cpf}`)

    this.router.navigate([link], { state: { contato: contato }});
  }
}
