import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ModalKendo} from "../../lib/ModalKendo";

import {PedidosService} from "../../services/pedidos.service";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {TelaAdicionarProdutoComponent} from "../../pedidos/tela-adicionar-produto/tela-adicionar-produto.component";
import {ComandaService} from "../../services/comanda.service";

@Component({
  selector: 'app-editar-itempedido-mesa',
  templateUrl: './modal-editar-itempedido-mesa.component.html',
  styleUrls: ['./modal-editar-itempedido-mesa.component.scss']
})
export class ModalEditarItempedidoMesaComponent extends ModalKendo implements OnInit, OnDestroy  {
  @ViewChild('telaAdicionarProduto') telaAdicionarProduto: TelaAdicionarProdutoComponent;
  pedido: any = {};
  item: any  = {}
  produto: any = {};
  comanda: any = {}
  alterandoItemPedido = false;
  atualizou = false;
  constructor(  private pedidosService: PedidosService, private comandaService: ComandaService,
                private pedidoLocalService: PedidoLocalService) {
    super()
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.pedido = Object.assign({}, this.item.pedido)
    this.produto = this.item.produto;
    this.pedido.itens = [this.item];
    let pedido = this.pedidoLocalService.obtenhaPedidoLojaPeloPedidoServer(this.pedido);

    setTimeout(() => {
      this.telaAdicionarProduto.editandoItemMesa = true;
      this.telaAdicionarProduto.pedido = pedido;
      this.telaAdicionarProduto.produtos = [this.produto]
      this.telaAdicionarProduto.editeItem(0, pedido.itens[0] ) ;
    })
  }

  atualizeProduto($event: any) {
    this.atualizou = true;

  }

  canceleAdicionar($event: any) {
    this.fecheModal(null)
  }

  confirmeAlteracaoItemPedido(){
    this.alterandoItemPedido = true;

    this.comandaService.altereItemPedido(this.comanda, this.pedido, this.item).then(() => {
      this.fecheModal({ recarregar: true});
    }).catch((err) => {
      this.alterandoItemPedido = false;
      alert(err)
    })
  }


}
